import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import JoditEditor from "jodit-react";
import React, {useRef} from "react";
import {Label} from "../../api/model/value/Label";
import {FormSize} from "../form/FormSize";

export enum LabelType {

    SINGLE_LINE,

    MULTI_LINE,

    HTML

}

export interface FieldProps {
    id: string,
    value: string,
    type: LabelType,
    disabled: boolean,
    size: FormSize,
    lang: string,
    allowLinks: boolean,
    onChange: (value: string) => void
}

const LabelInputField: React.FunctionComponent<FieldProps> = (props: FieldProps) => {

    const {id, value, type, disabled, size, lang, allowLinks, onChange} = props

    const editor = useRef(null)

    const config = {
        disabled: disabled,
        buttons: allowLinks ? ['bold', 'italic', 'strikethrough', 'ol', 'ul', 'link'] : ['bold', 'italic', 'strikethrough', 'ol', 'ul'],
        disablePlugins: "ordered-list",
        toolbarAdaptive: false,
        toolbarSticky: false,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: "insert_only_text",
        controls: {
            ol: {list: null},
            ul: {list: null}
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange(event.target.value);
    }

    switch (type) {
        case LabelType.SINGLE_LINE:
            return (
                <TextField
                    id={id + "-de"}
                    value={value}
                    disabled={disabled}
                    size={size}
                    lang={lang}
                    onChange={handleChange}
                />
            );

        case LabelType.MULTI_LINE:
            return (
                <TextField
                    id={id + "-de"}
                    value={value}
                    disabled={disabled}
                    multiline
                    rows={6}
                    size={size}
                    lang="de"
                    onChange={handleChange}
                />
            );

        case LabelType.HTML:
            return (
                <JoditEditor
                    ref={editor}
                    value={value}
                    config={config}
                    onBlur={onChange}
                />
            );
    }

};

export interface Props {
    id: string,
    value: Label,
    type: LabelType,
    disabled: boolean,
    size: FormSize,
    allowLinks?: boolean,
    setValue: (value: Label) => void
}

const LabelInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, type, disabled, size, allowLinks, setValue} = props

    const handleEnChange = (en: string) => {
        setValue(value.withEn(en));
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="column">
                    English
                    <LabelInputField
                        id={id + "-en"}
                        value={value.en}
                        type={type}
                        disabled={disabled}
                        size={size}
                        lang="en"
                        allowLinks={allowLinks || false}
                        onChange={handleEnChange}
                    />
                </Stack>
            </Grid>
        </Grid>
    )

};

export default LabelInput;
