import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from "react";
import {ENTITY_STATE_EQUALS_FILTER, EntityState} from "../../../api/model/Entity";
import {
    Event,
    EVENT_EVENT_DATE_AFTER_FILTER,
    EVENT_EVENT_DATE_NOT_AFTER_FILTER,
    EVENT_STATE_EQUALS_FILTER,
    EventFilter,
    EventState
} from "../../../api/model/Event";
import {Optional} from "../../../api/model/util/Optional";
import EventCreateForm from "./EventCreateForm";
import EventList from "./EventList";
import {useEventSlicer} from "./EventSlicerProvider";
import EventUpdateForm from "./EventUpdateForm";

type TabDefinition = {
    id: string,
    label: string,
    filter: ReadonlyArray<EventFilter<any>>
}

const DAY_MILLIS = 24 * 60 * 60 * 1000;

const tabs: (date: Date) => ReadonlyArray<TabDefinition> = (date: Date) => {
    const dayBefore = new Date(date.getTime() - DAY_MILLIS);
    return [
        {
            id: "disabled",
            label: "Disabled",
            filter: [
                {
                    operation: ENTITY_STATE_EQUALS_FILTER,
                    comparisonValue: EntityState.CREATED
                },
                {
                    operation: EVENT_EVENT_DATE_NOT_AFTER_FILTER,
                    comparisonValue: dayBefore
                },
                {
                    operation: EVENT_STATE_EQUALS_FILTER,
                    comparisonValue: EventState.DISABLED
                }
            ]
        },
        {
            id: "enabled",
            label: "Enabled",
            filter: [
                {
                    operation: ENTITY_STATE_EQUALS_FILTER,
                    comparisonValue: EntityState.CREATED
                },
                {
                    operation: EVENT_EVENT_DATE_NOT_AFTER_FILTER,
                    comparisonValue: dayBefore
                },
                {
                    operation: EVENT_STATE_EQUALS_FILTER,
                    comparisonValue: EventState.ENABLED
                }
            ]
        },
        {
            id: "past",
            label: "Past",
            filter: [
                {
                    operation: ENTITY_STATE_EQUALS_FILTER,
                    comparisonValue: EntityState.CREATED
                },
                {
                    operation: EVENT_EVENT_DATE_AFTER_FILTER,
                    comparisonValue: dayBefore
                }
            ]
        },
        {
            id: "removed",
            label: "Removed",
            filter: [
                {
                    operation: ENTITY_STATE_EQUALS_FILTER,
                    comparisonValue: EntityState.REMOVED
                }
            ]
        }
    ];
}

const INITIAL_TAB = 1;

export interface Props {
}

const EventsPage: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    const currentTabs: ReadonlyArray<TabDefinition> = tabs(new Date());

    const eventSlicer = useEventSlicer();
    const [selectedTab, setSelectedTab] = useState<number>(INITIAL_TAB);
    const [selectedEvent, setSelectedEvent] = useState<Optional<"NEW" | Event>>(null);

    useEffect(() => {
        eventSlicer.setStaticFilters(currentTabs[selectedTab].filter);
    }, [selectedTab]);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const handleSelect = (event: Event) => {
        setSelectedEvent(event);
    }

    const handleCreate = () => {
        setSelectedEvent("NEW");
    }

    const handleClose = () => {
        setSelectedEvent(null);
    }

    if (selectedEvent === "NEW") {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    New Event
                </Typography>
                <EventCreateForm onClose={handleClose}/>
            </>
        )
    } else if (selectedEvent !== null) {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Event: {selectedEvent.name.value}
                </Typography>
                <EventUpdateForm event={selectedEvent} onDelete={handleClose} onClose={handleClose}/>
            </>
        )
    } else {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Events
                </Typography>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={handleTabSelection}>
                        {currentTabs.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                    </Tabs>
                </Box>
                <EventList onSelect={handleSelect}/>
                <Stack direction={"row"} spacing={1}>
                    <Button variant={"contained"} onClick={handleCreate}>
                        Create
                    </Button>
                </Stack>
            </>
        )
    }

};

export default EventsPage;
