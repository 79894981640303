import {EditOutlined} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from "@mui/material/TablePagination";
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import {
    Affiliate,
    AFFILIATE_AFFILIATE_ID_SORT_ORDER,
    AFFILIATE_NAME_CONTAINS_FILTER,
    AFFILIATE_NAME_SORT_ORDER,
    AFFILIATE_STATE_SORT_ORDER,
    AffiliateFilter,
    AffiliateSortOrder
} from "../../../api/model/Affiliate";
import {ENTITY_ID_CONTAINS_FILTER, SortDirection} from "../../../api/model/Entity";
import FilterDialogButton from "../../form/FilterDialogButton";
import FilterFormButtons from "../../form/FilterFormButtons";
import FilterFormRow from "../../form/FilterFormRow";
import SortOrderButton from "../../form/SortOrderButton";
import StringInput from "../../input/StringInput";
import {useAffiliateSlicer} from "./AffiliateSlicerProvider";

interface FilterDialogProps {
    open: boolean;
    onClose: (filter: InternalFilter) => void;
}

const FilterDialog: React.FunctionComponent<FilterDialogProps> = (props: FilterDialogProps) => {

    const {open, onClose} = props;

    const [id, setId] = useState<string>("");
    const [name, setName] = useState<string>("");

    const handleClear = () => {
        setId("");
        setName("");
    };

    const handleClose = () => {
        onClose({
            id: id.length === 0 ? undefined : id,
            name: name.length === 0 ? undefined : name
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Filter</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FilterFormRow label="ID" operation="contains">
                    <StringInput
                        id="id"
                        value={id}
                        disabled={false}
                        size="small"
                        setValue={setId}
                    />
                </FilterFormRow>
                <FilterFormRow label="Name" operation="contains">
                    <StringInput
                        id="name"
                        value={name}
                        disabled={false}
                        size="small"
                        setValue={setName}
                    />
                </FilterFormRow>
                <FilterFormButtons onClear={handleClear} onClose={handleClose}/>
            </Grid>
        </Dialog>
    );
}

interface RowProps {
    affiliate: Affiliate,
    onSelect: (affiliate: Affiliate) => void
}

const Row: React.FunctionComponent<RowProps> = ({affiliate, onSelect}: RowProps) => {
    return (
        <TableRow>
            <TableCell padding={"checkbox"}>
                <IconButton size="small" onClick={() => onSelect(affiliate)}>
                    <EditOutlined/>
                </IconButton>
            </TableCell>
            <TableCell>{affiliate.name.value}</TableCell>
            <TableCell>{affiliate.affiliateId.value}</TableCell>
            <TableCell>{affiliate.state}</TableCell>
        </TableRow>
    );
}

export interface Props {
    affiliates: ReadonlyArray<Affiliate>,
    onSelect?: (affiliate: Affiliate) => void
}

type InternalFilter = {
    id?: string,
    name?: string
}

const AffiliateTable: React.FunctionComponent<Props> = ({affiliates, onSelect}: Props) => {

    const affiliateSlicer = useAffiliateSlicer();

    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const [internalFilter, setInternalFilter] = useState<InternalFilter>({});

    const handleFilterDialogOpen = () => {
        setFilterDialogOpen(true);
    };

    const handleFilterDialogClose = (filter: InternalFilter) => {
        setFilterDialogOpen(false);
        setInternalFilter(filter)
    };

    useEffect(() => {

        const dynamicFilters = new Array<AffiliateFilter<any>>();

        if (internalFilter.id) {
            dynamicFilters.push({
                operation: ENTITY_ID_CONTAINS_FILTER,
                comparisonValue: internalFilter.id
            });
        }
        if (internalFilter.name) {
            dynamicFilters.push({
                operation: AFFILIATE_NAME_CONTAINS_FILTER,
                comparisonValue: internalFilter.name
            });
        }

        affiliateSlicer.setDynamicFilters(dynamicFilters);
        affiliateSlicer.setPaginationPage(0);

    }, [internalFilter]);

    function handleSortChange(sortOrder: AffiliateSortOrder, direction: SortDirection) {
        affiliateSlicer.setSortOrder(sortOrder);
        affiliateSlicer.setSortDirection(direction);
        affiliateSlicer.setPaginationPage(0);
    }

    const handlePaginationPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        affiliateSlicer.setPaginationPage(newPage);
    };

    const handlePaginationRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        affiliateSlicer.setPaginationRowsPerPage(newRowsPerPage);
        affiliateSlicer.setPaginationPage(0);
    };

    const handleSelectFactory = (affiliate: Affiliate) => {
        return () => {
            onSelect && onSelect(affiliate)
        }
    }

    const [affiliateSlice, affiliateSliceCount] = affiliateSlicer.slice(affiliates);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <FilterDialogButton
                                    filterCount={affiliateSlicer.dynamicFilters.length}
                                    onClick={handleFilterDialogOpen}
                                />
                                <FilterDialog
                                    open={filterDialogOpen}
                                    onClose={handleFilterDialogClose}
                                />
                            </TableCell>
                            <TableCell>
                                Name
                                <SortOrderButton
                                    sortOrder={AFFILIATE_NAME_SORT_ORDER}
                                    currentSortOrder={affiliateSlicer.sortOrder}
                                    currentSortDirection={affiliateSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Affiliate ID
                                <SortOrderButton
                                    sortOrder={AFFILIATE_AFFILIATE_ID_SORT_ORDER}
                                    currentSortOrder={affiliateSlicer.sortOrder}
                                    currentSortDirection={affiliateSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                State
                                <SortOrderButton
                                    sortOrder={AFFILIATE_STATE_SORT_ORDER}
                                    currentSortOrder={affiliateSlicer.sortOrder}
                                    currentSortDirection={affiliateSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {affiliateSlice.map((affiliate: Affiliate) => (
                            <Row key={affiliate.id.value} affiliate={affiliate}
                                 onSelect={handleSelectFactory(affiliate)}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={affiliateSlicer.paginationRowsPerPageOptions}
                component="div"
                count={affiliateSliceCount}
                rowsPerPage={affiliateSlicer.paginationRowsPerPage}
                page={affiliateSlicer.paginationPage}
                onRowsPerPageChange={handlePaginationRowsPerPageChange}
                onPageChange={handlePaginationPageChange}
            />
        </>
    );

}

export default AffiliateTable;

