import {DataMapper} from "../Entity";
import {ImageFormat} from "./ImageFormat";
import {storageData, StorageData} from "./StorageData";
import {Url} from "./Url";

export type storedImage = {
    format: string;
    storageData: storageData;
    downloadUrl: string;
}

export class StoredImage {

    public readonly format: ImageFormat;

    public readonly storageData: StorageData;

    public readonly downloadUrl: Url;

    constructor(format: ImageFormat, storageData: StorageData, downloadUrl: Url) {
        this.format = format;
        this.storageData = storageData;
        this.downloadUrl = downloadUrl;
    }

    public static toData(value: StoredImage): storedImage {
        return {
            format: ImageFormat.toData(value.format),
            storageData: StorageData.toData(value.storageData),
            downloadUrl: Url.toData(value.downloadUrl)
        };
    }

    static fromData(data: storedImage): StoredImage {
        return new StoredImage(
            ImageFormat.fromData(data.format),
            StorageData.fromData(data.storageData),
            Url.fromData(data.downloadUrl)
        );
    }

}

export const STORED_IMAGE_DATA_MAPPER: DataMapper<StoredImage, storedImage> = {
    toData: StoredImage.toData,
    fromData: StoredImage.fromData
}

