import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from "react";
import {ENTITY_STATE_EQUALS_FILTER, EntityState} from "../../../api/model/Entity";
import {Optional} from "../../../api/model/util/Optional";
import {UNUSED, USED} from "../../../api/model/value/UsageState";
import {Voucher} from "../../../api/model/Voucher";
import {
    VOUCHER_CODE_STATE_EQUALS_FILTER,
    VOUCHER_CODE_USAGE_STATE_EQUALS_FILTER,
    VOUCHER_CODE_VOUCHER_EQUALS_FILTER,
    VoucherCode,
    VoucherCodeFilter,
    VoucherCodeState
} from "../../../api/model/VoucherCode";
import VoucherCodeList from "./VoucherCodeList";
import {useVoucherCodeSlicer} from "./VoucherCodeSlicerProvider";
import VoucherCodeUpdatePage from "./VoucherCodeUpdatePage";

type TabDefinition = {
    id: string,
    label: string,
    filter: ReadonlyArray<VoucherCodeFilter<any>>
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "disabled",
        label: "Disabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: VOUCHER_CODE_STATE_EQUALS_FILTER,
                comparisonValue: VoucherCodeState.DISABLED
            }
        ]
    },
    {
        id: "enabled",
        label: "Enabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: VOUCHER_CODE_STATE_EQUALS_FILTER,
                comparisonValue: VoucherCodeState.ENABLED
            }
        ]
    },
    {
        id: "all",
        label: "All",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            }
        ]
    },
    {
        id: "used",
        label: "Used",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: VOUCHER_CODE_USAGE_STATE_EQUALS_FILTER,
                comparisonValue: USED
            }
        ]
    },
    {
        id: "unused",
        label: "Unused",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: VOUCHER_CODE_USAGE_STATE_EQUALS_FILTER,
                comparisonValue: UNUSED
            }
        ]
    }

]

const INITIAL_TAB = 2;

export interface Props {
    voucher: Voucher
}

const VoucherCodesPage: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {

    const {voucher} = props;

    const codeSlicer = useVoucherCodeSlicer();
    const [selectedTab, setSelectedTab] = useState<number>(INITIAL_TAB);
    const [selectedCode, setSelectedCode] = useState<Optional<VoucherCode>>(null);

    useEffect(() => {
        codeSlicer.setStaticFilters([
            ...TABS[selectedTab].filter,
            {
                operation: VOUCHER_CODE_VOUCHER_EQUALS_FILTER,
                comparisonValue: voucher.id
            }
        ]);
    }, [selectedTab]);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const handleSelect = (code: VoucherCode) => {
        setSelectedCode(code);
    }

    const handleClose = () => {
        setSelectedCode(null);
    }

    if (selectedCode !== null) {
        return (
            <>
                <Typography variant="h5" gutterBottom>
                    Code: {selectedCode.codeId.value}
                </Typography>
                <VoucherCodeUpdatePage code={selectedCode} onClose={handleClose}/>
            </>
        )
    } else {
        return (
            <>
                <Typography variant="h5" gutterBottom>
                    Codes
                </Typography>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={handleTabSelection}>
                        {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                    </Tabs>
                </Box>
                <VoucherCodeList onSelect={handleSelect} voucher={voucher}/>
            </>
        )
    }

};

export default VoucherCodesPage;
