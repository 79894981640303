import TextField from "@mui/material/TextField";
import React from "react";
import {Email} from "../../api/model/value/ContactOption";
import {ContactOptions} from "../../api/model/value/ContactOptions";
import {EmailAddress} from "../../api/model/value/EmailAddress";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: ContactOptions,
    disabled: boolean,
    size: FormSize;
    setValue: (value: ContactOptions) => void
}

const ContactOptionsEmailInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleEmailAddressChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withEmail(event.target.value.length === 0 ? null : new Email(new EmailAddress(event.target.value))));
    }

    return (
        <TextField
            id={id + "-email"}
            value={value.email ? value.email.address.value : ""}
            disabled={disabled}
            size={size}
            lang=""
            onChange={handleEmailAddressChange}
        />
    )

};

export default ContactOptionsEmailInput;
