import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from "react";
import {ENTITY_STATE_EQUALS_FILTER, EntityState} from "../../../api/model/Entity";
import {Flag, FLAG_STATE_EQUALS_FILTER, FlagFilter, FlagState} from "../../../api/model/Flag";
import {Optional} from "../../../api/model/util/Optional";
import FlagCreatePage from "./FlagCreatePage";
import FlagList from "./FlagList";
import {useFlagSlicer} from "./FlagSlicerProvider";
import FlagUpdatePage from "./FlagUpdatePage";

type TabDefinition = {
    id: string,
    label: string,
    filter: ReadonlyArray<FlagFilter<any>>
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "disabled",
        label: "Disabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: FLAG_STATE_EQUALS_FILTER,
                comparisonValue: FlagState.DISABLED
            }
        ]
    },
    {
        id: "enabled",
        label: "Enabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: FLAG_STATE_EQUALS_FILTER,
                comparisonValue: FlagState.ENABLED
            }
        ]
    },
    {
        id: "removed",
        label: "Removed",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.REMOVED
            }
        ]
    }
]

const INITIAL_TAB = 1;

export interface Props {
}

const FlagsPage: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    const flagSlicer = useFlagSlicer();
    const [selectedTab, setSelectedTab] = useState<number>(INITIAL_TAB);
    const [selectedFlag, setSelectedFlag] = useState<Optional<"NEW" | Flag>>(null);

    useEffect(() => {
        flagSlicer.setStaticFilters(TABS[selectedTab].filter);
    }, [selectedTab]);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const handleSelect = (flag: Flag) => {
        setSelectedFlag(flag);
    }

    const handleCreate = () => {
        setSelectedFlag("NEW");
    }

    const handleClose = () => {
        setSelectedFlag(null);
    }

    if (selectedFlag === "NEW") {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    New Flag
                </Typography>
                <FlagCreatePage onClose={handleClose}/>
            </>
        )
    } else if (selectedFlag !== null) {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Flag: {selectedFlag.name.value}
                </Typography>
                <FlagUpdatePage flag={selectedFlag} onClose={handleClose}/>
            </>
        )
    } else {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Flags
                </Typography>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={handleTabSelection}>
                        {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                    </Tabs>
                </Box>
                <FlagList onSelect={handleSelect}/>
                <Stack direction={"row"} spacing={1}>
                    <Button variant={"contained"} onClick={handleCreate}>
                        Create
                    </Button>
                </Stack>
            </>
        )
    }

};

export default FlagsPage;
