import Typography from "@mui/material/Typography";
import {
    EmailAuthProvider,
    onAuthStateChanged,
    reauthenticateWithCredential as reauthenticateWithCredentialAuth,
    signInWithEmailAndPassword as signInWithEmailAndPasswordAuth,
    signOut as signOutAuth,
    updatePassword as updatePasswordAuth,
    User,
    UserCredential
} from "firebase/auth";
import React, {createContext, ReactElement, useContext, useEffect, useState} from 'react';
import {Optional} from "../api/model/util/Optional";
import LogInPage from "../component/LogInPage";
import {auth} from "../firebase/firebase";

export type Props = {
    children: ReactElement
}

export type LoggedIn = {
    currentUser: User,
    updatePassword: (currentPassword: string, newPassword: string) => Promise<void>
    signOut: () => Promise<void>
}

export type LoggedOut = {
    signIn: (email: string, password: string) => Promise<UserCredential>,
}

const LoggedInContext = createContext<LoggedIn>(null!);

const LoggedOutContext = createContext<LoggedOut>(null!);

const AuthProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const [currentUser, setCurrentUser] = useState<Optional<User>>(null);
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        return onAuthStateChanged(auth, (user: Optional<User>) => {
                if (user !== null) {
                    user.getIdTokenResult().then(idTokenResult => {
                        console.log("TOKEN", idTokenResult)
                        console.log("CLAIMS", idTokenResult.claims)
                    });
                }
                setCurrentUser(user);
                setInitialized(true);
            }
        )
    }, []);

    const signIn = (email: string, password: string) => {
        return signInWithEmailAndPasswordAuth(auth, email, password);
    }

    const updatePassword = (currentPassword: string, newPassword: string) => {
        return reauthenticateWithCredentialAuth(currentUser!, EmailAuthProvider.credential(currentUser!.email!, currentPassword)).then(c => updatePasswordAuth(currentUser!, newPassword));
    }

    const signOut = () => {
        return signOutAuth(auth);
    }

    if (initialized) {
        if (currentUser !== null) {
            return (
                <LoggedInContext.Provider value={{currentUser, updatePassword, signOut}}>
                    {children}
                </LoggedInContext.Provider>
            );
        } else {
            return (
                <LoggedOutContext.Provider value={{signIn}}>
                    <LogInPage/>
                </LoggedOutContext.Provider>
            );
        }
    } else {
        return (
            <Typography variant="h3">
                Authentication not yet initialized!
            </Typography>
        );
    }

}

export function useLoggedIn(): LoggedIn {
    return useContext(LoggedInContext);
}

export function useLoggedOut(): LoggedOut {
    return useContext(LoggedOutContext);
}

export default AuthProvider;
