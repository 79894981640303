import React from "react";
import {COUNTRIES, Country} from "../../api/model/value/Country";
import {FormSize} from "../form/FormSize";
import StringOptionsInput from "./StringOptionsInput";

export interface Props {
    id: string,
    value: Country,
    disabled: boolean,
    size: FormSize,
    setValue: (value: Country) => void
}

const CountryInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <StringOptionsInput
            id={id}
            value={value.code.value}
            options={COUNTRIES.map(country => {
                return {value: country.code.value, label: country.name.value}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: string) => setValue(Country.fromData(value))}
        />
    )

};

export default CountryInput;
