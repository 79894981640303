import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import React from "react";
import {SortDirection, SortOrder} from "../../api/model/Entity";

export interface Props {
    sortOrder: SortOrder<any, any, any>,
    currentSortOrder: SortOrder<any, any, any>,
    currentSortDirection: SortDirection,
    handleSortChange: (sortOrder: SortOrder<any, any, any>, sortDirection: SortDirection) => void
}

const SortOrderButton: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {

    const {sortOrder, currentSortOrder, currentSortDirection, handleSortChange} = props;

    return (
        <>
            {currentSortOrder.column === sortOrder.column ?
                currentSortDirection === SortDirection.ASC ?
                    <IconButton size="small" color="primary"
                                onClick={() => handleSortChange(sortOrder, SortDirection.DESC)}>
                        <KeyboardArrowUp/>
                    </IconButton>
                    :
                    <IconButton size="small" color="primary"
                                onClick={() => handleSortChange(sortOrder, SortDirection.ASC)}>
                        <KeyboardArrowDown/>
                    </IconButton>
                :
                <IconButton size="small" color="default"
                            onClick={() => handleSortChange(sortOrder, SortDirection.ASC)}>
                    <KeyboardArrowUp/>
                </IconButton>
            }
        </>
    )

};

export default SortOrderButton;
