import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {CountryCode} from "./CountryCode";
import {Name} from "./Name";

export class PaymentState {

    public readonly name: Name;

    public readonly code: CountryCode;

    constructor(name: Name, code: Code) {
        this.name = name;
        this.code = code;
    }

    public static toData(value: PaymentState): string {
        return value.code.value;
    }

    public static fromData(data: string): PaymentState {
        return PaymentState.fromCode(new Code(data));
    }

    public static fromCode(paymentStateCode: Code): PaymentState {
        const paymentState: Maybe<PaymentState> = PAYMENT_STATES.find(paymentState => paymentState.code.value === paymentStateCode.value);
        if (paymentState === undefined) {
            throw new Error("Unknown payment state: " + JSON.stringify(paymentStateCode));
        }
        return paymentState;
    }

}

export const UNPAID: PaymentState = new PaymentState(
    new Name("Unpaid"),
    new Code("UNPAID")
);

export const PAID: PaymentState = new PaymentState(
    new Name("Paid"),
    new Code("PAID")
);

export const PAYMENT_STATES: ReadonlyArray<PaymentState> = [
    UNPAID,
    PAID,
] as const;

export const PAYMENT_STATE_DATA_MAPPER: DataMapper<PaymentState, string> = {
    toData: PaymentState.toData,
    fromData: PaymentState.fromData
}

