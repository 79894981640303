import {ErrorOutline} from "@mui/icons-material";
import {CircularProgress} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";
import {EntityRepo} from "../../api/EntityRepo";
import {Image, IMAGE_DEFINITION, ImageState} from "../../api/model/Image";
import {Optional} from "../../api/model/util/Optional";
import {EntityType} from "../../api/model/value/EntityType";
import {IdRef} from "../../api/model/value/IdRef";
import {Url} from "../../api/model/value/Url";
import {database} from "../../firebase/firebase";
import {FormSize} from "../form/FormSize";

export interface Props {
    value: IdRef<EntityType.IMAGE>,
    disabled: boolean,
    size: FormSize
}

const ImageOutput: React.FunctionComponent<Props> = (props: Props) => {

    const {value, disabled, size} = props

    const [image, setImage] = useState<Optional<Image>>(null);
    const [imageUrl, setImageUrl] = useState<Optional<Url>>(null);
    const [loading, setLoading] = useState<Optional<boolean>>(null);
    const [message, setMessage] = useState<Optional<string>>(null);

    useEffect(() => {
        const imageRepo = new EntityRepo(database, IMAGE_DEFINITION);
        return imageRepo.subById(value, i => setImage(i), i => setImage(null));
    }, [value]);


    useEffect(() => {

        if (image) {

            setImageUrl(image.original.downloadUrl);
            if (image.state === ImageState.UPLOADED) {
                setLoading(true);
                setMessage("scaling...");
            } else if (image.state === ImageState.FAILED) {
                setLoading(false);
                setMessage("failed");
            } else {
                setLoading(null);
                setMessage(null);
            }

        } else {

            setImageUrl(null);
            setLoading(null);
            setMessage("loading...");

        }

    }, [image]);


    return (
        <div style={{
            position: "relative"
        }}>
            <div style={{
                content: "",
                display: "block",
                paddingBottom: "100%"
            }}>
                <div style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "#f9f9f9"
                }}
                >
                    {imageUrl && <img src={imageUrl.value} style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center"
                    }}/>}
                </div>
                {message && <div style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "rgba(255,255,255,0.75)"
                }}
                >
                    <Stack direction="row" sx={{justifyContent: "center"}}>
                        <Stack style={{marginTop: "50px", color: "#222"}}>
                            {loading === false
                                ?
                                <ErrorOutline color={"error"} fontSize={"large"}/>
                                :
                                <CircularProgress/>
                            }
                            {message}
                        </Stack>
                    </Stack>
                </div>}
            </div>
        </div>
    )

};

export default ImageOutput;
