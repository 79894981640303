import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";

export class BenefitType {

    public readonly code: Code;

    constructor(code: Code) {
        this.code = code;
    }

    public static toData(value: BenefitType): string {
        return value.code.value;
    }

    public static fromData(data: string): BenefitType {
        return BenefitType.fromCode(new Code(data));
    }

    public static fromCode(benefitTypeCode: Code): BenefitType {
        const benefitType: Maybe<BenefitType> = BENEFIT_TYPES.find(benefitType => benefitType.code.value === benefitTypeCode.value);
        if (benefitType === undefined) {
            throw new Error("Unknown benefit type: " + JSON.stringify(benefitTypeCode));
        }
        return benefitType;
    }

}

export const DISCOUNT: BenefitType = new BenefitType(
    new Code("DISCOUNT"),
);

export const GOODIE: BenefitType = new BenefitType(
    new Code("GOODIE"),
);

export const BENEFIT_TYPES: ReadonlyArray<BenefitType> = [
    DISCOUNT,
    GOODIE
] as const;

export const BENEFIT_TYPE_DATA_MAPPER: DataMapper<BenefitType, string> = {
    toData: BenefitType.toData,
    fromData: BenefitType.fromData
}

