import React, {createContext, useContext} from "react";

export type Props = {}

export type Layout = {}

const LayoutContext = createContext<Layout>(null!);

const LayoutProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const value: Layout = {}

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );

};

export function useLayout(): Layout {
    return useContext(LayoutContext);
}

export default LayoutProvider;