import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import Layout from "./component/Layout";
import AffiliateRoute from "./route/AffiliateRoute";
import BundleRoute from "./route/BundleRoute";
import CustomerRoute from "./route/CustomerRoute";
import EventRoute from "./route/EventRoute";
import FlagRoute from "./route/FlagRoute";
import HomeRoute from "./route/HomeRoute";
import LocationRoute from "./route/LocationRoute";
import PageRoute from "./route/PageRoute";
import PartnerRoute from "./route/PartnerRoute";
import ProfileRoute from "./route/ProfileRoute";
import PurchaseRoute from "./route/PurchaseRoute";
import TagRoute from "./route/TagRoute";
import VenueRoute from "./route/VenueRoute";
import VoucherRoute from "./route/VoucherRoute";

function App() {

    useEffect(() => {
        document.title = "Loco Manager";
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path="/" element={<HomeRoute/>}/>
                        <Route path="/customer" element={<CustomerRoute/>}/>
                        <Route path="/affiliate" element={<AffiliateRoute/>}/>
                        <Route path="/partner" element={<PartnerRoute/>}/>
                        <Route path="/purchase" element={<PurchaseRoute/>}/>
                        <Route path="/voucher" element={<VoucherRoute/>}/>
                        <Route path="/event" element={<EventRoute/>}/>
                        <Route path="/venue" element={<VenueRoute/>}/>
                        <Route path="/location" element={<LocationRoute/>}/>
                        <Route path="/tag" element={<TagRoute/>}/>
                        <Route path="/flag" element={<FlagRoute/>}/>
                        <Route path="/page" element={<PageRoute/>}/>
                        <Route path="/bundle" element={<BundleRoute/>}/>
                        <Route path="/profile" element={<ProfileRoute/>}/>
                    </Routes>
                </Layout>
            </BrowserRouter>
        </div>
    );
}

export default App;
