import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";
import {positiveIntegerCompare} from "../../../api/model/value/PositiveInteger";
import {Voucher} from "../../../api/model/Voucher";
import {VoucherCode} from "../../../api/model/VoucherCode";
import {csvEscape} from "../../../api/util";
import {Project, useProject} from "../../../context/ProjectProvider";
import {useVoucherCodeSlicer} from "./VoucherCodeSlicerProvider";
import VoucherCodeTable from "./VoucherCodeTable";

export interface Props {
    onSelect?: (code: VoucherCode) => void,
    voucher: Voucher
}

const VoucherCodeList: React.FunctionComponent<Props> = ({onSelect, voucher}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const codeSlicer = useVoucherCodeSlicer();
    const [codes, setCodes] = useState<ReadonlyArray<VoucherCode>>([]);

    useEffect(() => {

        const loadCodes = async () => {

            const codes = await project.voucherCodeManager.getAllAsArray({region: project.selectedRegion});
            setCodes(codes);

        }

        loadCodes().catch(console.error);

    }, [project]);

    const handleDownload = () => {

        const rows = [...codeSlicer.base(codes)]
            .sort((left: VoucherCode, right: VoucherCode) => positiveIntegerCompare(left.number, right.number))
            .map(code => {
                return [
                    csvEscape(code.number.value.toString()),
                    csvEscape(code.codeId.value),
                    csvEscape(code.usage.state.code.value),
                    csvEscape(code.state)
                ]
            });

        const csv = rows.map((row: string[]) => row.join(",")).join("\r\n");
        const uri = "data:text/csv;charset=utf-8," + csv;
        const encodedUri = encodeURI(uri);
        const filename = voucher.name.value.trim() + ".csv"

        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        link.click()
    }

    return (
        <>
            <VoucherCodeTable
                codes={codes}
                onSelect={onSelect}
            />
            <Stack direction={"row"} spacing={1}>
                <Button variant={"contained"} onClick={handleDownload}>
                    Download
                </Button>
            </Stack>
        </>
    );

};

export default VoucherCodeList;
