import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";
import {Phone} from "../../api/model/value/ContactOption";
import {ContactOptions} from "../../api/model/value/ContactOptions";
import {PhoneNumber} from "../../api/model/value/PhoneNumber";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: ContactOptions,
    disabled: boolean,
    size: FormSize;
    setValue: (value: ContactOptions) => void
}

const ContactOptionsPhoneInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleCountryCodeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const countryCode = event.target.value;
        const phone = value.phone;
        if (phone === null) {
            if (countryCode.length !== 0) {
                setValue(value.withPhone(new Phone(new PhoneNumber(countryCode, "", ""))))
            }
        } else {
            const number = phone.number;
            if (countryCode.length === 0 && number.areaCode.length === 0 && number.localNumber.length === 0) {
                setValue(value.withPhone(null));
            } else {
                setValue(value.withPhone(new Phone(number.withCountryCode(countryCode))))
            }
        }
    }

    const handleAreaCodeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const areaCode = event.target.value;
        const phone = value.phone;
        if (phone === null) {
            if (areaCode.length !== 0) {
                setValue(value.withPhone(new Phone(new PhoneNumber("", areaCode, ""))))
            }
        } else {
            const number = phone.number;
            if (number.countryCode.length === 0 && areaCode.length === 0 && number.localNumber.length === 0) {
                setValue(value.withPhone(null));
            } else {
                setValue(value.withPhone(new Phone(number.withAreaCode(areaCode))))
            }
        }
    }

    const handleLocalNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const localNumber = event.target.value;
        const phone = value.phone;
        if (phone === null) {
            if (localNumber.length !== 0) {
                setValue(value.withPhone(new Phone(new PhoneNumber("", "", localNumber))))
            }
        } else {
            const number = phone.number;
            if (number.countryCode.length === 0 && number.areaCode.length === 0 && localNumber.length === 0) {
                setValue(value.withPhone(null));
            } else {
                setValue(value.withPhone(new Phone(number.withLocalNumber(localNumber))))
            }
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={2}>
                <Stack direction="column">
                    Country Code
                    <TextField
                        id={id + "-country-affiliateCode"}
                        value={value.phone ? value.phone.number.countryCode : ""}
                        disabled={disabled}
                        size={size}
                        onChange={handleCountryCodeChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction="column">
                    Area Code
                    <TextField
                        id={id + "-area-affiliateCode"}
                        value={value.phone ? value.phone.number.areaCode : ""}
                        disabled={disabled}
                        size={size}
                        onChange={handleAreaCodeChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={7}>
                <Stack direction="column">
                    Local Number
                    <TextField
                        id={id + "-local-number"}
                        value={value.phone ? value.phone.number.localNumber : ""}
                        disabled={disabled}
                        size={size}
                        onChange={handleLocalNumberChange}
                    />
                </Stack>
            </Grid>
        </Grid>
    )

};

export default ContactOptionsPhoneInput;
