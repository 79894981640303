import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";

export class ContactType {

    public readonly code: Code;

    constructor(code: Code) {
        this.code = code;
    }

    public static toData(value: ContactType): string {
        return value.code.value;
    }

    public static fromData(data: string): ContactType {
        return ContactType.fromCode(new Code(data));
    }

    public static fromCode(code: Code): ContactType {
        const contactType: Maybe<ContactType> = CONTACT_TYPES.find(contactType => contactType.code.value === code.value);
        if (contactType === undefined) {
            throw new Error("Unknown contact: " + JSON.stringify(code));
        }
        return contactType;
    }

}

export const CEO: ContactType = new ContactType(
    new Code("CEO")
);

export const MARKETING: ContactType = new ContactType(
    new Code("MARKETING")
);


export const CONTACT_TYPES: ReadonlyArray<ContactType> = [
    CEO,
    MARKETING
] as const;

export const CONTACT_TYPE_DATA_MAPPER: DataMapper<ContactType, string> = {
    toData: ContactType.toData,
    fromData: ContactType.fromData
}

