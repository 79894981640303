import React, {createContext, useContext, useState} from "react";
import {SortDirection} from "../../../api/model/Entity";
import {
    VOUCHER_CODE_CODE_ID_SORT_ORDER, VOUCHER_CODE_NUMBER_SORT_ORDER,
    VoucherCode,
    VoucherCodeFilter,
    VoucherCodeSortOrder
} from "../../../api/model/VoucherCode";

export type Props = {}

export type VoucherCodeSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: VoucherCodeSortOrder,
    setSortOrder: (sortOrder: VoucherCodeSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<VoucherCodeFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<VoucherCodeFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<VoucherCodeFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<VoucherCodeFilter<any>>) => void,
    base: (codes: ReadonlyArray<VoucherCode>) => ReadonlyArray<VoucherCode>
    slice: (codes: ReadonlyArray<VoucherCode>) => [ReadonlyArray<VoucherCode>, number]
}

const CodeSlicerContext = createContext<VoucherCodeSlicer>(null!);

const VoucherCodeSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<VoucherCodeSortOrder>(VOUCHER_CODE_NUMBER_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<VoucherCodeFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<VoucherCodeFilter<any>>>([]);

    const base = (codes: ReadonlyArray<VoucherCode>): ReadonlyArray<VoucherCode> => {

        let filteredCodes = codes;
        staticFilters.forEach(filter => {
            filteredCodes = filteredCodes.filter(code => filter.operation.apply(code, filter.comparisonValue));
        });

        return filteredCodes;

    }

    const slice = (codes: ReadonlyArray<VoucherCode>): [ReadonlyArray<VoucherCode>, number] => {

        let filteredCodes = codes;
        staticFilters.forEach(filter => {
            filteredCodes = filteredCodes.filter(code => filter.operation.apply(code, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredCodes = filteredCodes.filter(code => filter.operation.apply(code, filter.comparisonValue));
        });

        const sortedCodes = [...filteredCodes].sort((left: VoucherCode, right: VoucherCode) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedCodes.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedCodes = sortedCodes.slice(start, end);

        return [paginatedCodes, filteredCodes.length];

    }

    const value: VoucherCodeSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        base,
        slice
    }

    return (
        <CodeSlicerContext.Provider value={value}>
            {children}
        </CodeSlicerContext.Provider>
    );

};

export function useVoucherCodeSlicer(): VoucherCodeSlicer {
    return useContext(CodeSlicerContext);
}

export default VoucherCodeSlicerProvider;