import React from "react";
import {EventState} from "../../api/model/Event";
import {FormSize} from "../form/FormSize";
import StringOptionsInput from "./StringOptionsInput";

export interface Props {
    id: string,
    value: EventState,
    disabled: boolean,
    size: FormSize;
    setValue: (value: EventState) => void
}

const EventStateInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <StringOptionsInput
            id={id}
            value={value}
            options={Object.keys(EventState).map(state => {
                return {value: state, label: state}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: string) => setValue(value as EventState)}
        />
    )

};

export default EventStateInput;
