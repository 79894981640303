import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Affiliate, AffiliatePayload, AffiliateState} from "../../../../api/model/Affiliate";
import {Optional} from "../../../../api/model/util/Optional";
import {Name} from "../../../../api/model/value/Name";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import AffiliateStateInput2 from "../../../input/AffiliateStateInput2";
import NameInput2 from "../../../input/NameInput2";
import StringOutput from "../../../output/StringOutput";

export type AffiliateCore = Pick<AffiliatePayload, "state" | "name">;

export interface Props {
    affiliate?: Affiliate,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => AffiliateCore>>,
    reset: number
}

const AffiliateCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {affiliate, disabled, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => AffiliateState>> = useRef<Optional<() => AffiliateState>>(null)
    const getNameRef: MutableRefObject<Optional<() => Name>> = useRef<Optional<() => Name>>(null)

    getValue.current = () => ({
        state: getStateRef.current!(),
        name: getNameRef.current!()
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {affiliate?.id && <FormRow label="Id">
                    <StringOutput
                        value={affiliate.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <AffiliateStateInput2
                        id="state"
                        value={affiliate?.state || AffiliateState.ENABLED}
                        disabled={disabled}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                {affiliate && <FormRow label="Affiliate ID">
                    <StringOutput
                        value={affiliate.affiliateId.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="Name">
                    <NameInput2
                        id="name"
                        value={affiliate?.name || new Name("")}
                        disabled={disabled}
                        size={size}
                        getValue={getNameRef}
                        reset={reset}
                    />
                </FormRow>
            </Grid>
        </>
    );

};

export default AffiliateCoreInput;
