import React, {createContext, useContext, useState} from "react";
import {Affiliate} from "../../../api/model/Affiliate";
import {AffiliateCode, AFFILIATE_CODE_NAME_SORT_ORDER, AffiliateCodeFilter, AffiliateCodeSortOrder} from "../../../api/model/AffiliateCode";
import {SortDirection} from "../../../api/model/Entity";

export type Props = {}

export type AffiliateCodeSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: AffiliateCodeSortOrder,
    setSortOrder: (sortOrder: AffiliateCodeSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<AffiliateCodeFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<AffiliateCodeFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<AffiliateCodeFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<AffiliateCodeFilter<any>>) => void,
    slice: (codes: ReadonlyArray<AffiliateCode>) => [ReadonlyArray<AffiliateCode>, number]
}

const CodeSlicerContext = createContext<AffiliateCodeSlicer>(null!);

const AffiliateCodeSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<AffiliateCodeSortOrder>(AFFILIATE_CODE_NAME_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<AffiliateCodeFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<AffiliateCodeFilter<any>>>([]);

    const slice = (codes: ReadonlyArray<AffiliateCode>): [ReadonlyArray<AffiliateCode>, number] => {

        let filteredCodes = codes;
        staticFilters.forEach(filter => {
            filteredCodes = filteredCodes.filter(code => filter.operation.apply(code, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredCodes = filteredCodes.filter(code => filter.operation.apply(code, filter.comparisonValue));
        });

        const sortedCodes = [...filteredCodes].sort((left: AffiliateCode, right: AffiliateCode) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedCodes.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedCodes = sortedCodes.slice(start, end);

        return [paginatedCodes, filteredCodes.length];

    }

    const value: AffiliateCodeSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <CodeSlicerContext.Provider value={value}>
            {children}
        </CodeSlicerContext.Provider>
    );

};

export function useAffiliateCodeSlicer(): AffiliateCodeSlicer {
    return useContext(CodeSlicerContext);
}

export default AffiliateCodeSlicerProvider;