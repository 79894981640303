import {DataMapper} from "../Entity";
import {GeoArea} from "./GeoArea";

export class Longitude {

    public readonly value: number;

    constructor(value: number) {
        if (value < -180 || value > 180) {
            throw new Error("Invalid longitude: " + value);
        }
        this.value = value;
    }

    public static toData(value: Longitude): number {
        return value.value;
    }

    public static fromData(data: number): Longitude {
        return new Longitude(data);
    }

    static max(longitude1: Longitude, longitude2: Longitude): Longitude {
        return longitude1.value > longitude2.value ? longitude1 : longitude2;
    }

    static min(longitude1: Longitude, longitude2: Longitude): Longitude {
        return longitude1.value < longitude2.value ? longitude1 : longitude2;
    }

    static clamp(longitude: Longitude, boundingBox: GeoArea): Longitude {
        return new Longitude(Math.max(Math.min(longitude.value, boundingBox.northEast.longitude.value), boundingBox.southWest.longitude.value))
    }

    static fromNumber(longitude: number): Longitude {
        return new Longitude(Math.max(Math.min(longitude, 180), -180))
    }

}

export const LONGITUDE_DATA_MAPPER: DataMapper<Longitude, number> = {
    toData: Longitude.toData,
    fromData: Longitude.fromData
}

