import React from 'react';
import ProfilePage from "../component/profile/ProfilePage";

function ProfileRoute() {

    return (
        <>
            <ProfilePage/>
        </>
    );

}

export default ProfileRoute;
