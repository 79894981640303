import {
    addDoc,
    collectionGroup, doc,
    DocumentChange,
    DocumentData,
    DocumentReference,
    DocumentSnapshot,
    endAt,
    Firestore,
    getDoc,
    getDocs,
    onSnapshot,
    orderBy,
    Query,
    query,
    QuerySnapshot,
    serverTimestamp, setDoc,
    Timestamp,
    Unsubscribe,
    updateDoc
} from "@firebase/firestore";
import {Entity, EntityAnchor, EntityDefinition, EntityScope, EntityState, PersistableEntity} from "./model/Entity";
import {Optional} from "./model/util/Optional";
import {EntityType} from "./model/value/EntityType";
import {Id} from "./model/value/Id";
import {IdRef} from "./model/value/IdRef";
import {IdAlike} from "./util";

export class EntityRepo<T extends EntityType, A extends EntityAnchor<T>, P extends PersistableEntity<T>, E extends Entity<T>> {

    private readonly database: Firestore;

    private readonly entityDefinition: EntityDefinition<T, A, P, E>

    constructor(database: Firestore, entityDefinition: EntityDefinition<T, A, P, E>) {
        this.database = database;
        this.entityDefinition = entityDefinition;
    }

    public async persist(entity: P): Promise<IdRef<T>> {
        const docRef = await this.createOrUpdate(entity);
        return new IdRef<T>(new Id(this.entityDefinition.type, docRef.path));
    }

    private async createOrUpdate(entity: P): Promise<DocumentReference> {
        if (entity.entityScope === EntityScope.PREPARED) {
            const anchor = this.entityDefinition.preparedEntityToAnchor(entity);
            const colRef = this.entityDefinition.anchorToColRef(this.database, anchor)
            const docId = this.entityDefinition.getDocId(entity);
            if (docId !== null) {
                const docRef = doc(colRef, docId);
                await setDoc(doc(colRef, docId), this.mapToData(entity));
                return docRef;
            } else {
                const docRef = await addDoc(colRef, this.mapToData(entity));
                return docRef;
            }
        } else {
            const docRef = this.entityDefinition.idAlikeToDocRef(this.database, entity)
            await updateDoc(docRef, this.mapToData(entity));
            return docRef;
        }
    }

    public async getById(idAlike: IdAlike<T>): Promise<Optional<E>> {
        const docRef = this.entityDefinition.idAlikeToDocRef(this.database, idAlike);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return this.mapFromData(docRef, docSnap.data());
        } else {
            return null;
        }
    }

    public subById(idAlike: IdAlike<T>, onEnter: (entity: E) => void, onLeave: (entity: E) => void): Unsubscribe {
        const docRef = this.entityDefinition.idAlikeToDocRef(this.database, idAlike);
        return onSnapshot(docRef, (snapshot: DocumentSnapshot) => {
            this.handleDocumentSnapshot(snapshot, onEnter, onLeave);
        });
    }


    public subAll(anchor: A, onEnter: (entity: E) => void, onLeave: (entity: E) => void): Unsubscribe {
        return onSnapshot(this.getAllQuery(anchor), (snapshot: QuerySnapshot) => {
            this.handleQuerySnapshot(snapshot, onEnter, onLeave);
        });
    }

    public async getAll(anchor: Optional<A>): Promise<ReadonlyArray<E>> {
        const snapshot = await getDocs(this.getAllQuery(anchor));
        return this.mapFromSnapshot(snapshot);
    }

    private getAllQuery(anchor: Optional<A>): Query {
        if (anchor !== null) {
            const colRef = this.entityDefinition.anchorToColRef(this.database, anchor)
            return query(colRef);
        } else {
            return collectionGroup(this.database, this.entityDefinition.type.toLowerCase());
        }
    }

    public subAllSince(date: Date, anchor: A, onEnter: (entity: E) => void, onLeave: (entity: E) => void): Unsubscribe {
        return onSnapshot(this.getAllSinceQuery(date, anchor), (snapshot: QuerySnapshot) => {
            this.handleQuerySnapshot(snapshot, onEnter, onLeave);
        });
    }

    public async getAllSince(date: Date, anchor: Optional<A>): Promise<ReadonlyArray<E>> {
        const snapshot = await getDocs(this.getAllSinceQuery(date, anchor));
        return this.mapFromSnapshot(snapshot);
    }

    private getAllSinceQuery(date: Date, anchor: Optional<A>): Query {
        const constraints = [orderBy("updatedAt", "desc"), endAt(Timestamp.fromDate(date))]
        if (anchor !== null) {
            const colRef = this.entityDefinition.anchorToColRef(this.database, anchor)
            return query(colRef, ...constraints);
        } else {
            const colGroupQuery = collectionGroup(this.database, this.entityDefinition.type.toLowerCase());
            return query(colGroupQuery, ...constraints);
        }
    }

    private handleQuerySnapshot(snapshot: QuerySnapshot<DocumentData>, onEnter: (entity: E) => void, onLeave: (entity: E) => void) {
        snapshot.docChanges().forEach((change: DocumentChange) => {
            const entity = this.mapFromData(change.doc.ref, change.doc.data());
            if (change.type === "removed" || entity.entityState === EntityState.DELETED) {
                onLeave(entity);
            } else {
                onEnter(entity);
            }
        });
    }

    private handleDocumentSnapshot(snapshot: DocumentSnapshot<DocumentData>, onEnter: (entity: E) => void, onLeave: (entity: E) => void) {
        if (snapshot.exists()) {
            const entity = this.mapFromData(snapshot.ref, snapshot.data());
            if (entity.entityState === EntityState.DELETED) {
                onLeave(entity);
            } else {
                onEnter(entity);
            }
        }
    }

    public async removeById(idAlike: IdAlike<T>): Promise<void> {
        const docRef = this.entityDefinition.idAlikeToDocRef(this.database, idAlike);
        await updateDoc(docRef, {entityState: EntityState.REMOVED, updatedAt: serverTimestamp()});
    }

    public async restoreById(idAlike: IdAlike<T>): Promise<void> {
        const docRef = this.entityDefinition.idAlikeToDocRef(this.database, idAlike);
        await updateDoc(docRef, {entityState: EntityState.CREATED, updatedAt: serverTimestamp()});
    }

    public async deleteById(idAlike: IdAlike<T>): Promise<void> {
        const docRef = this.entityDefinition.idAlikeToDocRef(this.database, idAlike);
        await updateDoc(docRef, {entityState: EntityState.DELETED, updatedAt: serverTimestamp()});
    }

    private mapFromSnapshot(snapshots: QuerySnapshot): ReadonlyArray<E> {
        const entities = new Array<E>();
        snapshots.forEach(docSnap => {
            entities.push(this.mapFromData(docSnap.ref, docSnap.data()));
        });
        return entities;
    }

    private mapToData(entity: P): object {
        return this.entityDefinition.toData(this.database, entity);
    }

    private mapFromData(ref: DocumentReference, data: DocumentData): E {
        return this.entityDefinition.fromData(ref, data);
    }

}


