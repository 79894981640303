import {collection, CollectionReference, doc, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {address, Address, ADDRESS_DATA_MAPPER, addressContains} from "./value/Address";
import {DEFAULT_COUNTRY} from "./value/Country";
import {EntityType} from "./value/EntityType";
import {GEO_POINT_DATA_MAPPER, geoPoint, GeoPoint} from "./value/GeoPoint";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {Latitude} from "./value/Latitude";
import {Longitude} from "./value/Longitude";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {stringCompare, stringEquals} from "./value/String";

export enum LocationState {

    ENABLED = "ENABLED",

    DISABLED = "DISABLED"

}

export type LocationPayload = {
    state: LocationState,
    name: Name,
    address: Address,
    geoLocation: GeoPoint,
    region: IdRef<EntityType.REGION>
}

export type LocationAnchor = EntityAnchor<EntityType.LOCATION> & {
    region: IdAlike<EntityType.REGION>
}

export type PreparedLocation = PreparedEntity<EntityType.LOCATION> & LocationPayload;

export type Location = Entity<EntityType.LOCATION> & LocationPayload;

export type PersistableLocation = PreparedLocation | Location;

export type LocationManager = EntityManager<EntityType.LOCATION, LocationAnchor, PersistableLocation, Location>;

export const LOCATION_STATE_DATA_MAPPER: DataMapper<LocationState, string> = {
    toData: (value: LocationState) => value,
    fromData: (data: string) => data as LocationState
}

export const LOCATION_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableLocation, LocationState, string>(
        "state",
        e => e.state,
        LOCATION_STATE_DATA_MAPPER,
        LocationState.ENABLED
    );

export const LOCATION_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableLocation, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Location")
    );

export const LOCATION_ADDRESS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableLocation, Address, address>(
        "address",
        e => e.address,
        ADDRESS_DATA_MAPPER,
        new Address("", "", "", "", DEFAULT_COUNTRY)
    );

export const LOCATION_GEO_LOCATION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableLocation, GeoPoint, geoPoint>(
        "geoLocation",
        e => e.geoLocation,
        GEO_POINT_DATA_MAPPER,
        new GeoPoint(new Latitude(0), new Longitude(0))
    );

export const LOCATION_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableLocation>(
        EntityType.REGION,
        "region",
        e => e.region
    );

export type LocationFilterColumn = EntityFilterColumn | "STATE" | "NAME" | "ADDRESS";

export type LocationFilterOperation<V> = FilterOperation<EntityType.LOCATION, Location, LocationFilterColumn, V>;

export type LocationFilter<V> = Filter<EntityType.LOCATION, Location, LocationFilterColumn, V>;

export const LOCATION_STATE_EQUALS_FILTER: LocationFilterOperation<LocationState> = {
    column: "STATE",
    apply: (location: Location, comparisonValue: LocationState) => stringEquals(location.state, comparisonValue)
}

export const LOCATION_NAME_CONTAINS_FILTER: LocationFilterOperation<string> = {
    column: "NAME",
    apply: (location: Location, comparisonValue: string) => nameContains(location.name, comparisonValue)
}

export const LOCATION_ADDRESS_CONTAINS_FILTER: LocationFilterOperation<string> = {
    column: "ADDRESS",
    apply: (location: Location, comparisonValue: string) => addressContains(location.address, comparisonValue)
}

export type LocationSortColumn =
    EntitySortColumn
    | "STATE"
    | "NAME"
    | "ADDRESS_STREET"
    | "ADDRESS_STREET_NUMBER"
    | "ADDRESS_ZIP"
    | "ADDRESS_CITY";

export type LocationSortOrder = SortOrder<EntityType.LOCATION, Location, LocationSortColumn>;

export const LOCATION_STATE_SORT_ORDER: LocationSortOrder = {
    column: "STATE",
    apply: (left: Location, right: Location) => stringCompare(left.state, right.state)
}

export const LOCATION_NAME_SORT_ORDER: LocationSortOrder = {
    column: "NAME",
    apply: (left: Location, right: Location) => nameCompare(left.name, right.name)
}

export const LOCATION_ADDRESS_STREET_SORT_ORDER: LocationSortOrder = {
    column: "ADDRESS_STREET",
    apply: (left: Location, right: Location) => stringCompare(left.address.street, right.address.street)
}

export const LOCATION_ADDRESS_STREET_NUMBER_SORT_ORDER: LocationSortOrder = {
    column: "ADDRESS_STREET_NUMBER",
    apply: (left: Location, right: Location) => stringCompare(left.address.streetNumber, right.address.streetNumber)
}

export const LOCATION_ADDRESS_ZIP_SORT_ORDER: LocationSortOrder = {
    column: "ADDRESS_ZIP",
    apply: (left: Location, right: Location) => stringCompare(left.address.zip, right.address.zip)
}

export const LOCATION_ADDRESS_CITY_SORT_ORDER: LocationSortOrder = {
    column: "ADDRESS_CITY",
    apply: (left: Location, right: Location) => stringCompare(left.address.city, right.address.city)
}

class LocationDefinition extends EntityDefinition<EntityType.LOCATION, LocationAnchor, PersistableLocation, Location> {

    constructor() {
        super(
            EntityType.LOCATION,
            [
                LOCATION_STATE_ATTRIBUTE_DEFINITION,
                LOCATION_NAME_ATTRIBUTE_DEFINITION,
                LOCATION_ADDRESS_ATTRIBUTE_DEFINITION,
                LOCATION_GEO_LOCATION_ATTRIBUTE_DEFINITION,
                LOCATION_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableLocation): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableLocation): LocationAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.LOCATION>): LocationAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: LocationAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.LOCATION.toLowerCase());
    }

}

export const LOCATION_DEFINITION = new LocationDefinition();