import React from "react";
import {Slot, SLOTS} from "../../api/model/value/Slot";
import {FormSize} from "../form/FormSize";
import StringOptionsInput from "./StringOptionsInput";

export interface Props {
    id: string,
    value: Slot,
    disabled: boolean,
    size: FormSize,
    setValue: (value: Slot) => void
}

const SlotInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <StringOptionsInput
            id={id}
            value={value.code.value}
            options={SLOTS.map(slot => {
                return {value: slot.code.value, label: slot.name.value}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: string) => setValue(Slot.fromData(value))}
        />
    )

};

export default SlotInput;
