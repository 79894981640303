import React, {createContext, useContext, useState} from "react";
import {SortDirection} from "../../../api/model/Entity";
import {Partner, PARTNER_NAME_SORT_ORDER, PartnerFilter, PartnerSortOrder} from "../../../api/model/Partner";

export type Props = {}

export type PartnerSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: PartnerSortOrder,
    setSortOrder: (sortOrder: PartnerSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<PartnerFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<PartnerFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<PartnerFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<PartnerFilter<any>>) => void,
    slice: (partners: ReadonlyArray<Partner>) => [ReadonlyArray<Partner>, number]
}

const PartnerSlicerContext = createContext<PartnerSlicer>(null!);

const PartnerSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<PartnerSortOrder>(PARTNER_NAME_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<PartnerFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<PartnerFilter<any>>>([]);

    const slice = (partners: ReadonlyArray<Partner>): [ReadonlyArray<Partner>, number] => {

        let filteredPartners = partners;
        staticFilters.forEach(filter => {
            filteredPartners = filteredPartners.filter(partner => filter.operation.apply(partner, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredPartners = filteredPartners.filter(partner => filter.operation.apply(partner, filter.comparisonValue));
        });

        const sortedPartners = [...filteredPartners].sort((left: Partner, right: Partner) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedPartners.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedPartners = sortedPartners.slice(start, end);

        return [paginatedPartners, filteredPartners.length];

    }

    const value: PartnerSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <PartnerSlicerContext.Provider value={value}>
            {children}
        </PartnerSlicerContext.Provider>
    );

};

export function usePartnerSlicer(): PartnerSlicer {
    return useContext(PartnerSlicerContext);
}

export default PartnerSlicerProvider;