import {DataMapper} from "../Entity";
import {GeoArea} from "./GeoArea";

export class Latitude {

    public readonly value: number;

    constructor(value: number) {
        if (value < -90 || value > 90) {
            throw new Error("Invalid latitude: " + value);
        }
        this.value = value;
    }

    public static toData(value: Latitude): number {
        return value.value;
    }

    public static fromData(data: number): Latitude {
        return new Latitude(data);
    }

    static max(latitude1: Latitude, latitude2: Latitude): Latitude {
        return latitude1.value > latitude2.value ? latitude1 : latitude2;
    }

    static min(latitude1: Latitude, latitude2: Latitude): Latitude {
        return latitude1.value < latitude2.value ? latitude1 : latitude2;
    }

    static clamp(latitude: Latitude, boundingBox: GeoArea): Latitude {
        return new Latitude(Math.max(Math.min(latitude.value, boundingBox.northEast.latitude.value), boundingBox.southWest.latitude.value))
    }

    static fromNumber(latitude: number): Latitude {
        return new Latitude(Math.max(Math.min(latitude, 90), -90))
    }

}

export const LATITUDE_DATA_MAPPER: DataMapper<Latitude, number> = {
    toData: Latitude.toData,
    fromData: Latitude.fromData
}



