import {collection, doc, DocumentReference, Firestore, serverTimestamp, setDoc} from "@firebase/firestore";
import {DateTime} from "luxon";
import {useEffect, useState} from "react";
import {BundleState, PreparedBundle} from "../api/model/Bundle";
import {EntityScope, EntityState} from "../api/model/Entity";
import {Event, EventState} from "../api/model/Event";
import {Flag, FlagState} from "../api/model/Flag";
import {Image, ImageState} from "../api/model/Image";
import {Location, LocationState} from "../api/model/Location";
import {Page, PageState} from "../api/model/Page";
import {Region} from "../api/model/Region";
import {Tag, TagState} from "../api/model/Tag";
import {Optional} from "../api/model/util/Optional";
import {Comment} from "../api/model/value/Comment";
import {EntityType} from "../api/model/value/EntityType";
import {IdRef} from "../api/model/value/IdRef";
import {LABEL_LANGUAGES, LabelLanguage} from "../api/model/value/Label";
import {Name} from "../api/model/value/Name";
import {NominalDate} from "../api/model/value/NominalDate";
import {NominalTime} from "../api/model/value/NominalTime";
import {GuestList} from "../api/model/value/Participation";
import {GUEST_LIST} from "../api/model/value/ParticipationType";
import {SLOTS} from "../api/model/value/Slot";
import {Url} from "../api/model/value/Url";
import {Venue, VenueState} from "../api/model/Venue";
import {Project} from "../context/ProjectProvider";

export type Success = { success: true, url: Url }

export type Error = { success: false, message: string }

export type Result = Success | Error;

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function regionalDate(date: NominalDate, region: Region): DateTime {
    return DateTime.local(date.year, date.month, date.day, {zone: region.timeZone.zoneId.value});
}

function regionalDateTime(date: NominalDate, time: NominalTime, region: Region): DateTime {
    return DateTime.local(date.year, date.month, date.day, time.hour, time.minute, {zone: region.timeZone.zoneId.value});
}

function utcTimestamp(dateTime: DateTime): number {
    return dateTime.toUTC().toJSDate().getTime();
}

function venueDisplayFrom(venue: Venue, region: Region): number {
    return utcTimestamp(regionalDate(venue.openFrom, region).minus({day: venue.announcementDays.value}).startOf('day'));
}

function venueDisplayUntil(venue: Venue, region: Region): number {
    return venueOpenUntil(venue, region);
}

function venueOpenFrom(venue: Venue, region: Region): number {
    return utcTimestamp(regionalDate(venue.openFrom, region).startOf('day'));
}

function venueOpenUntil(venue: Venue, region: Region): number {
    return utcTimestamp(regionalDate(venue.openUntil, region).endOf('day'));
}

function eventDisplayFrom(event: Event, region: Region): number {
    return utcTimestamp(regionalDate(event.eventDate, region).minus({day: event.announcementDays.value}).startOf('day'));
}

function eventDisplayUntil(event: Event, region: Region): number {
    return eventActiveUntil(event, region);
}

function eventActiveFrom(event: Event, region: Region): number {
    return utcTimestamp(regionalDateTime(event.eventDate, event.eventTime, region));
}

function eventActiveUntil(event: Event, region: Region): number {
    return utcTimestamp(regionalDateTime(event.eventDate, event.eventTime, region).plus({hour: event.durationHours.value}));
}

function guestListUntil(guestList: GuestList, region: Region): number {
    return utcTimestamp(regionalDateTime(guestList.closingDate, guestList.closingTime, region));
}

function guestList(guestList: GuestList, region: Region, labelLanguage: LabelLanguage) {
    return {
        guestListUntil: guestListUntil(guestList, region),
        guestListClosingDate: labelLanguage.nominalDateFormatter(guestList.closingDate),
        guestListClosingTime: labelLanguage.nominalTimeFormatter(guestList.closingTime, false),
        guestListClosingTime12: labelLanguage.nominalTimeFormatter(guestList.closingTime, true)
    };
}

const useBundler = (firestore: Firestore, project: Project, request: Optional<[Name, Comment]>) => {

    const [steps, setSteps] = useState<ReadonlyArray<[string, boolean, boolean]>>([]);
    const [progress, setProgress] = useState<number>(0);
    const [result, setResult] = useState<Optional<Result>>(null);

    useEffect(() => {


        if (request !== null) {

            setSteps([]);

            const [name, comment] = request;

            const bundle = async () => {

                setSteps(prevState => [...prevState, ["Loading data", true, false]]);

                setSteps(prevState => [...prevState, ["Loading region", false, false]]);
                await delay(25);
                const region = project.selectedRegion;

                setSteps(prevState => [...prevState, ["Loading pages", false, false]]);
                await delay(25);
                const pages = await project.pageManager.getAllAsArray({region});

                setSteps(prevState => [...prevState, ["Loading flags", false, false]]);
                await delay(25);
                const flags = await project.flagManager.getAllAsArray({region});

                setSteps(prevState => [...prevState, ["Loading tags", false, false]]);
                await delay(25);
                const tags = await project.tagManager.getAllAsArray({region});

                setSteps(prevState => [...prevState, ["Loading locations", false, false]]);
                await delay(25);
                const locations = await project.locationManager.getAllAsArray({region});

                setSteps(prevState => [...prevState, ["Loading images", false, false]]);
                await delay(25);
                const images = await project.imageManager.getAllAsArray({region});

                setSteps(prevState => [...prevState, ["Loading venues", false, false]]);
                await delay(25);
                const venues = await project.venueManager.getAllAsArray({region});

                setSteps(prevState => [...prevState, ["Loading events", false, false]]);
                await delay(25);
                const events = await project.eventManager.getAllAsArray({region});

                setSteps(prevState => [...prevState, ["Preparing data", true, false]]);

                setSteps(prevState => [...prevState, ["Preparing pages", false, false]]);
                await delay(25);
                const filteredPages = pages.filter(page => {
                    const name = page.name.value + " (" + page.id.value + ")"
                    if (page.entityState === EntityState.REMOVED) {
                        setSteps(prevState => [...prevState, [name + " is removed, will be ignored", false, false]]);
                        return false;
                    }
                    if (page.state !== PageState.ENABLED) {
                        setSteps(prevState => [...prevState, [name + " is not enabled, will be ignored", false, false]]);
                        return false;
                    }
                    return true;
                })
                const filteredPagesById = new Map<string, Page>();
                filteredPages.forEach(page => filteredPagesById.set(page.id.value, page));

                setSteps(prevState => [...prevState, ["Preparing flags", false, false]]);
                await delay(25);
                const filteredFlags = flags.filter(flag => {
                    const name = flag.name.value + " (" + flag.id.value + ")"
                    if (flag.entityState === EntityState.REMOVED) {
                        setSteps(prevState => [...prevState, [name + " is removed, will be ignored", false, false]]);
                        return false;
                    }
                    if (flag.state !== FlagState.ENABLED) {
                        setSteps(prevState => [...prevState, [name + " is not enabled, will be ignored", false, false]]);
                        return false;
                    }
                    return true;
                })
                const filteredFlagsById = new Map<string, Flag>();
                filteredFlags.forEach(flag => filteredFlagsById.set(flag.id.value, flag));

                setSteps(prevState => [...prevState, ["Preparing tags", false, false]]);
                await delay(25);
                const filteredTags = tags.filter(tag => {
                    const name = tag.name.value + " (" + tag.id.value + ")"
                    if (tag.entityState === EntityState.REMOVED) {
                        setSteps(prevState => [...prevState, [name + " is removed, will be ignored", false, false]]);
                        return false;
                    }
                    if (tag.state !== TagState.ENABLED) {
                        setSteps(prevState => [...prevState, [name + " is not enabled, will be ignored", false, false]]);
                        return false;
                    }
                    return true;
                })
                const filteredTagsById = new Map<string, Tag>();
                filteredTags.forEach(tag => filteredTagsById.set(tag.id.value, tag));

                setSteps(prevState => [...prevState, ["Preparing locations", false, false]]);
                await delay(25);
                const filteredLocations = locations.filter(location => {
                    const name = location.name.value + " (" + location.id.value + ")"
                    if (location.entityState === EntityState.REMOVED) {
                        setSteps(prevState => [...prevState, [name + " is removed, will be ignored", false, false]]);
                        return false;
                    }
                    if (location.state !== LocationState.ENABLED) {
                        setSteps(prevState => [...prevState, [name + " is not enabled, will be ignored", false, false]]);
                        return false;
                    }
                    return true;
                })
                const filteredLocationsById = new Map<string, Location>();
                filteredLocations.forEach(location => filteredLocationsById.set(location.id.value, location));

                setSteps(prevState => [...prevState, ["Preparing images", false, false]]);
                await delay(25);
                const filteredImages = images.filter(image => {
                    const name = image.id.value + " (" + image.id.value + ")"
                    if (image.entityState === EntityState.REMOVED) {
                        setSteps(prevState => [...prevState, [name + " is removed, will be ignored", false, false]]);
                        return false;
                    }
                    if (image.state !== ImageState.SCALED_V1) {
                        setSteps(prevState => [...prevState, [name + " is not scaled, will be ignored", false, false]]);
                        return false;
                    }
                    return true;
                })
                const filteredImagesById = new Map<string, Image>();
                filteredImages.forEach(image => filteredImagesById.set(image.id.value, image));

                setSteps(prevState => [...prevState, ["Preparing venues", false, false]]);
                await delay(25);
                const filteredVenues = venues.filter(venue => {
                    const name = venue.name.value + " (" + venue.id.value + ")"
                    if (venue.entityState === EntityState.REMOVED) {
                        setSteps(prevState => [...prevState, [name + " is removed, will be ignored", false, false]]);
                        return false;
                    }
                    if (venue.state !== VenueState.ENABLED) {
                        setSteps(prevState => [...prevState, [name + " is not enabled, will be ignored", false, false]]);
                        return false;
                    }
                    venue.images = venue.images.filter(imageIdRef => filteredImagesById.has(imageIdRef.value));
                    if (venue.images.length === 0) {
                        setSteps(prevState => [...prevState, [name + " has no images, will be ignored", false, false]]);
                        return false;
                    }
                    if (venue.benefits.items.length === 0) {
                        setSteps(prevState => [...prevState, [name + " has no benefits, will be ignored", false, false]]);
                        return false;
                    }
                    if (!filteredLocationsById.has(venue.location.id.value)) {
                        setSteps(prevState => [...prevState, [name + " has no location, will be ignored", false, false]]);
                        return false;
                    }
                    return true;
                })
                const filteredVenuesById = new Map<string, Venue>();
                filteredVenues.forEach(venue => filteredVenuesById.set(venue.id.value, venue));

                setSteps(prevState => [...prevState, ["Preparing events", false, false]]);
                await delay(25);
                const filteredEvents = events.filter(event => {
                    const name = event.name.value + " (" + event.id.value + ")"
                    if (event.entityState === EntityState.REMOVED) {
                        setSteps(prevState => [...prevState, [name + " is removed, will be ignored", false, false]]);
                        return false;
                    }
                    if (event.state !== EventState.ENABLED) {
                        setSteps(prevState => [...prevState, [name + " is not enabled, will be ignored", false, false]]);
                        return false;
                    }
                    event.images = event.images.filter(imageIdRef => filteredImagesById.has(imageIdRef.value));
                    if (event.images.length === 0) {
                        setSteps(prevState => [...prevState, [name + " has no images, will be ignored", false, false]]);
                        return false;
                    }
                    if (event.benefits.items.length === 0) {
                        setSteps(prevState => [...prevState, [name + " has no benefits, will be ignored", false, false]]);
                        return false;
                    }
                    if (!filteredLocationsById.has(event.location.id.value)) {
                        setSteps(prevState => [...prevState, [name + " has no location, will be ignored", false, false]]);
                        return false;
                    }
                    return true;
                })
                const filteredEventsById = new Map<string, Event>();
                filteredEvents.forEach(event => filteredEventsById.set(event.id.value, event));

                setSteps(prevState => [...prevState, ["Checking data", true, false]]);

                setSteps(prevState => [...prevState, ["Checking pages", false, false]]);
                SLOTS.forEach(slot => {
                    const pagesForSlot = filteredPages.filter(page => page.slot.name.value === slot.name.value);
                    if (pagesForSlot.length === 0) {
                        throw new Error("Found no page for slot: " + slot.name.value);
                    }
                    if (pagesForSlot.length > 1) {
                        throw new Error("Found multiple pages for slot: " + slot.name.value);
                    }
                })


                const documents: Array<{ ref: DocumentReference, content: any }> = [];

                LABEL_LANGUAGES.forEach(labelLanguage => {

                    setSteps(prevState => [...prevState, ["Mapping data for language: " + labelLanguage.language.name.value, true, false]]);

                    const {language, accessor} = labelLanguage;
                    const languageCode = language.code.value.toLowerCase();

                    setSteps(prevState => [...prevState, ["Mapping region", false, false]]);
                    documents.push({
                        ref: doc(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-region/" + region.id.value),
                        content: {
                            id: region.id.value,
                            label: region.name.value,
                            code: region.code.value,
                            countryCode: region.country.code.value,
                            languageCode: region.language.code.value,
                            timeZoneId: region.timeZone.zoneId.value,
                            area: {
                                north: region.area.northEast.latitude.value,
                                east: region.area.northEast.longitude.value,
                                south: region.area.southWest.latitude.value,
                                west: region.area.southWest.longitude.value
                            }
                        }
                    });

                    setSteps(prevState => [...prevState, ["Mapping pages", false, false]]);
                    filteredPages.forEach(page => {
                        documents.push({
                            ref: doc(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-page/" + page.id.value),
                            content: {
                                id: page.id.value,
                                label: page.name.value,
                                description: accessor(page.description),
                                slot: page.slot.code.value
                            }
                        });
                    });

                    setSteps(prevState => [...prevState, ["Mapping flags", false, false]]);
                    filteredFlags.forEach(flag => {
                        documents.push({
                            ref: doc(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-flag/" + flag.id.value),
                            content: {
                                id: flag.id.value,
                                name: flag.name.value
                            }
                        });
                    });

                    setSteps(prevState => [...prevState, ["Mapping tags", false, false]]);
                    filteredTags.forEach(tag => {
                        documents.push({
                            ref: doc(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-tag/" + tag.id.value),
                            content: {
                                id: tag.id.value,
                                label: tag.name.value,
                                category: tag.category.code.value,
                                categoryTargets: tag.category.targets.map(target => {
                                        return target
                                    }
                                )
                            }
                        });
                    });

                    setSteps(prevState => [...prevState, ["Mapping venues", false, false]]);
                    filteredVenues.forEach(venue => {
                        const flags: Array<Flag> = venue.flags.map(flagIdRef => filteredFlagsById.get(flagIdRef.value)).filter(flag => flag !== undefined) as Array<Flag>;
                        const tags: Array<Tag> = venue.tags.map(tagIdRef => filteredTagsById.get(tagIdRef.value)).filter(tag => tag !== undefined) as Array<Tag>;
                        const location: Location = filteredLocationsById.get(venue.location.value)!;
                        documents.push({
                            ref: doc(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-venue/" + venue.id.value),
                            content: {
                                id: venue.id.value,
                                label: venue.name.value,
                                label2: venue.subtitle.value,
                                description: accessor(venue.description),
                                categories: venue.categories.items.map(category => category.code.value),
                                tags: tags.map(tag => {
                                    return {
                                        id: tag.id.value,
                                        label: tag.name.value,
                                        category: tag.category.code.value,
                                        categoryTargets: tag.category.targets.map(target => {
                                                return target
                                            }
                                        )
                                    };
                                }),
                                location: {
                                    id: location.id.value,
                                    position: {
                                        latitude: location.geoLocation.latitude.value,
                                        longitude: location.geoLocation.longitude.value
                                    },
                                    address: {
                                        street: location.address.street,
                                        streetNumber: location.address.streetNumber,
                                        city: location.address.city,
                                        zip: location.address.zip,
                                        country: location.address.country.name.value
                                    }
                                },
                                displayInMap: !venue.hidden,
                                imageUrls: venue.images.map(imageIdRef => filteredImagesById.get(imageIdRef.value)!.original.downloadUrl.value),
                                images: venue.images.map(imageIdRef => {
                                    const image = filteredImagesById.get(imageIdRef.value)!;
                                    const images: { [key: string]: string } = {};
                                    image.scaled.items.forEach(scaledImage => {
                                        images[scaledImage.scale.code.value.toLowerCase() as string] = scaledImage.image.downloadUrl.value;
                                    });
                                    return images;
                                }),
                                displayFrom: venueDisplayFrom(venue, region),
                                displayUntil: venueDisplayUntil(venue, region),
                                openFrom: venueOpenFrom(venue, region),
                                openUntil: venueOpenUntil(venue, region),
                                openingHours: accessor(venue.openingHours),
                                websites: {
                                    homepage: (venue.websites.homepage !== null ? venue.websites.homepage.url.value : null)
                                },
                                socialMediaProfiles: {
                                    facebook: (venue.socialMediaProfiles.facebook !== null ? venue.socialMediaProfiles.facebook.username : null),
                                    instagram: (venue.socialMediaProfiles.instagram !== null ? venue.socialMediaProfiles.instagram.username : null)
                                },
                                contactOptions: {
                                    email: (venue.contactOptions.email !== null ? venue.contactOptions.email.address.value : null),
                                    phone: (venue.contactOptions.phone !== null ? {
                                        countryCode: venue.contactOptions.phone.number.countryCode,
                                        areaCode: venue.contactOptions.phone.number.areaCode,
                                        localNumber: venue.contactOptions.phone.number.localNumber
                                    } : null),
                                    whatsapp: (venue.contactOptions.whatsapp !== null ? {
                                        countryCode: venue.contactOptions.whatsapp.number.countryCode,
                                        areaCode: venue.contactOptions.whatsapp.number.areaCode,
                                        localNumber: venue.contactOptions.whatsapp.number.localNumber
                                    } : null)
                                },
                                benefit: {
                                    types: venue.benefits.items[0].types.items.map(benefitType => benefitType.code.value),
                                    description: accessor(venue.benefits.items[0].description)
                                },
                                valuation: venue.valuation.value,
                                flags: flags.map(flag => {
                                    return {
                                        id: flag.id.value,
                                        name: flag.name.value
                                    };
                                }),
                                selectors: [
                                    ...flags.map(flag => {
                                        return flag.name.value
                                    }),
                                    ...tags.map(tag => {
                                        return tag.name.value
                                    })
                                ]
                            }
                        });
                    });

                    setSteps(prevState => [...prevState, ["Mapping events", false, false]]);
                    filteredEvents.forEach(event => {
                        const flags: Array<Flag> = event.flags.map(flagIdRef => filteredFlagsById.get(flagIdRef.value)).filter(flag => flag !== undefined) as Array<Flag>;
                        const tags: Array<Tag> = event.tags.map(tagIdRef => filteredTagsById.get(tagIdRef.value)).filter(tag => tag !== undefined) as Array<Tag>;
                        const location: Location = filteredLocationsById.get(event.location.value)!;
                        documents.push({
                            ref: doc(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-event/" + event.id.value),
                            content: {
                                id: event.id.value,
                                label: event.name.value,
                                label2: event.subtitle.value,
                                description: accessor(event.description),
                                categories: event.categories.items.map(category => category.code.value),
                                tags: tags.map(tag => {
                                    return {
                                        id: tag.id.value,
                                        label: tag.name.value,
                                        category: tag.category.code.value,
                                        categoryTargets: tag.category.targets.map(target => {
                                                return target
                                            }
                                        )
                                    };
                                }),
                                location: {
                                    id: location.id.value,
                                    position: {
                                        latitude: location.geoLocation.latitude.value,
                                        longitude: location.geoLocation.longitude.value
                                    },
                                    address: {
                                        street: location.address.street,
                                        streetNumber: location.address.streetNumber,
                                        city: location.address.city,
                                        zip: location.address.zip,
                                        country: location.address.country.name.value
                                    }
                                },
                                displayInMap: true,
                                imageUrls: event.images.map(imageIdRef => filteredImagesById.get(imageIdRef.value)!.original.downloadUrl.value),
                                images: event.images.map(imageIdRef => {
                                    const image = filteredImagesById.get(imageIdRef.value)!;
                                    const images: { [key: string]: string } = {};
                                    image.scaled.items.forEach(scaledImage => {
                                        images[scaledImage.scale.code.value.toLowerCase() as string] = scaledImage.image.downloadUrl.value;
                                    });
                                    return images;
                                }),
                                displayFrom: eventDisplayFrom(event, region),
                                displayUntil: eventDisplayUntil(event, region),
                                activeFrom: eventActiveFrom(event, region),
                                activeUntil: eventActiveUntil(event, region),
                                participation: event.participation.type.code.value,
                                ...(event.participation.type.code.value === GUEST_LIST.code.value && guestList(event.participation as GuestList, region, labelLanguage)),
                                eventDate: labelLanguage.nominalDateFormatter(event.eventDate),
                                eventTime: labelLanguage.nominalTimeFormatter(event.eventTime, false),
                                eventTime12: labelLanguage.nominalTimeFormatter(event.eventTime, true),
                                admittance: accessor(event.admittance),
                                websites: {
                                    homepage: (event.websites.homepage !== null ? event.websites.homepage.url.value : null)
                                },
                                socialMediaProfiles: {
                                    facebook: (event.socialMediaProfiles.facebook !== null ? event.socialMediaProfiles.facebook.username : null),
                                    instagram: (event.socialMediaProfiles.instagram !== null ? event.socialMediaProfiles.instagram.username : null)
                                },
                                contactOptions: {
                                    email: (event.contactOptions.email !== null ? event.contactOptions.email.address.value : null),
                                    phone: (event.contactOptions.phone !== null ? {
                                        countryCode: event.contactOptions.phone.number.countryCode,
                                        areaCode: event.contactOptions.phone.number.areaCode,
                                        localNumber: event.contactOptions.phone.number.localNumber
                                    } : null),
                                    whatsapp: (event.contactOptions.whatsapp !== null ? {
                                        countryCode: event.contactOptions.whatsapp.number.countryCode,
                                        areaCode: event.contactOptions.whatsapp.number.areaCode,
                                        localNumber: event.contactOptions.whatsapp.number.localNumber
                                    } : null)
                                },
                                benefit: {
                                    types: event.benefits.items[0].types.items.map(benefitType => benefitType.code.value),
                                    description: accessor(event.benefits.items[0].description)
                                },
                                valuation: event.valuation.value,
                                flags: flags.map(flag => {
                                    return {
                                        id: flag.id.value,
                                        name: flag.name.value
                                    };
                                }),
                                selectors: [
                                    ...flags.map(flag => {
                                        return flag.name.value
                                    }),
                                    ...tags.map(tag => {
                                        return tag.name.value
                                    })
                                ]
                            }
                        });
                    });

                });

                setSteps(prevState => [...prevState, ["Persisting bundle", true, false]]);

                setSteps(prevState => [...prevState, ["Persisting documents", false, false]]);
                await Promise.all(
                    documents.map(async (document: { ref: DocumentReference, content: any }) => {
                            console.log("Storing", document.ref, document.content);
                            return await setDoc(document.ref, document.content);
                        }
                    )
                );

                setSteps(prevState => [...prevState, ["Persisting bundle", false, false]]);

                const ref = doc(firestore, "adminManager/export/bundle/" + name.value);
                const currentRef = doc(firestore, "adminManager/export/bundle/" + region.code.value.toLowerCase());
                const content = {
                    name: name.value,
                    schema: {
                        v1: LABEL_LANGUAGES.reduce((accumulator: {}, labelLanguage: LabelLanguage) => {
                            const language = labelLanguage.language;
                            const languageCode = language.code.value.toLowerCase();
                            return {
                                ...accumulator,
                                [languageCode]: {
                                    region: collection(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-region").path,
                                    page: collection(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-page").path,
                                    flag: collection(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-flag").path,
                                    tag: collection(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-tag").path,
                                    venue: collection(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-venue").path,
                                    event: collection(firestore, "adminManager/export/bundle/" + name.value + "/v1-" + languageCode + "-event").path
                                }
                            };
                        }, {}),
                    },
                    regionCode: region.code.value,
                    createdAt: serverTimestamp()
                }

                console.log("Storing", ref, content);
                await setDoc(ref, content);

                console.log("Storing", currentRef, content);
                await setDoc(currentRef, content);

                const bundle: PreparedBundle = {
                    entityScope: EntityScope.PREPARED,
                    entityType: EntityType.BUNDLE,
                    state: BundleState.PUBLISHED,
                    name: name,
                    date: new Date(),
                    comment: comment,
                    region: new IdRef<EntityType.REGION>(project.selectedRegion.id)
                };

                await project.bundleManager.persist(bundle);

                setResult({success: true, url: new Url("")});

            }

            bundle().catch(error => {
                console.error(error);
                setResult({success: false, message: error.message})
            });

        }

    }, [request]);

    return {steps, progress, result};

}

export default useBundler;