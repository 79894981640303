import {DataMapper} from "../Entity";

export enum CategoryTarget {

    EVENT = "EVENT",

    VENUE = "VENUE"

}

export const CATEGORY_TARGET_DATA_MAPPER: DataMapper<CategoryTarget, string> = {
    toData: (value: CategoryTarget) => value,
    fromData: (data: string) => data as CategoryTarget
}