import {DataMapper} from "../Entity";
import {ScaledImage, scaledImage} from "./ScaledImage";

export type scaledImages = scaledImage[];

export class ScaledImages {

    public readonly items: ReadonlyArray<ScaledImage>;

    constructor(items: ReadonlyArray<ScaledImage>) {
        this.items = items;
    }

    public static toData(value: ScaledImages): scaledImages {
        return value.items.map(item => ScaledImage.toData(item));
    }

    static fromData(data: scaledImages): ScaledImages {
        return new ScaledImages(
            data.map(datum => ScaledImage.fromData(datum))
        );
    }

}

export const SCALED_IMAGES_DATA_MAPPER: DataMapper<ScaledImages, scaledImages> = {
    toData: ScaledImages.toData,
    fromData: ScaledImages.fromData
}
