import {randomAlphaNumericalString} from "../../util";
import {DataMapper} from "../Entity";
import {stringCompare, stringContains} from "./String";

export class VoucherCodeId {

    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public static toData(value: VoucherCodeId): string {
        return value.value;
    }

    public static fromData(data: string): VoucherCodeId {
        return new VoucherCodeId(data);
    }

    public static random(): VoucherCodeId {
        return new VoucherCodeId(randomAlphaNumericalString(9));
    }

    static empty() {
        return new VoucherCodeId("000000000");
    }

}

export const VOUCHER_CODE_ID_DATA_MAPPER: DataMapper<VoucherCodeId, string> = {
    toData: VoucherCodeId.toData,
    fromData: VoucherCodeId.fromData
}

export function voucherCodeIdContains(haystack: VoucherCodeId, needle: string): boolean {
    return stringContains(haystack.value, needle);
}

export function voucherCodeIdCompare(left: VoucherCodeId, right: VoucherCodeId): number {
    return stringCompare(left.value, right.value)
}

