import React, {createContext, useContext, useEffect, useState} from "react";
import {SortDirection} from "../../../api/model/Entity";
import {Tag, TAG_NAME_SORT_ORDER, TagFilter, TagSortOrder} from "../../../api/model/Tag";

export type Props = {}

export type TagSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: TagSortOrder,
    setSortOrder: (sortOrder: TagSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<TagFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<TagFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<TagFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<TagFilter<any>>) => void,
    slice: (tags: ReadonlyArray<Tag>) => [ReadonlyArray<Tag>, number]
}

const TagSlicerContext = createContext<TagSlicer>(null!);

const TagSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<TagSortOrder>(TAG_NAME_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<TagFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<TagFilter<any>>>([]);

    const slice = (tags: ReadonlyArray<Tag>): [ReadonlyArray<Tag>, number] => {

        let filteredTags = tags;
        staticFilters.forEach(filter => {
            filteredTags = filteredTags.filter(tag => filter.operation.apply(tag, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredTags = filteredTags.filter(tag => filter.operation.apply(tag, filter.comparisonValue));
        });

        const sortedTags = [...filteredTags].sort((left: Tag, right: Tag) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedTags.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedTags = sortedTags.slice(start, end);

        return [paginatedTags, filteredTags.length];

    }

    const value: TagSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <TagSlicerContext.Provider value={value}>
            {children}
        </TagSlicerContext.Provider>
    );

};

export function useTagSlicer(): TagSlicer {
    return useContext(TagSlicerContext);
}

export default TagSlicerProvider;