import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useState} from "react";

export interface Props {
    disabled: boolean,
    onRemove: () => void
}

const RemoveButton: React.FunctionComponent<Props> = (props: Props) => {

    const {disabled, onRemove} = props
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleNo = () => {
        setOpen(false);
    };

    const handleYes = () => {
        setOpen(false);
        onRemove();
    };

    return (
        <>
            <Button disabled={disabled} variant="outlined" onClick={handleOpen}>remove</Button>
            <Dialog open={open} onClose={handleNo} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">
                    {"Really?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to remove?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNo} variant={"outlined"}>No</Button>
                    <Button onClick={handleYes} variant={"contained"}>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    );

};

export default RemoveButton;
