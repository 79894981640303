import {EditOutlined} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from "@mui/material/TablePagination";
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import {
    Customer, CUSTOMER_CREATED_AT_SORT_ORDER,
    CUSTOMER_CUSTOMER_ID_SORT_ORDER,
    CUSTOMER_EMAIL_ADDRESS_SORT_ORDER,
    CUSTOMER_NAME_CONTAINS_FILTER,
    CUSTOMER_NAME_SORT_ORDER,
    CUSTOMER_STATE_SORT_ORDER,
    CustomerFilter,
    CustomerSortOrder
} from "../../../api/model/Customer";
import {ENTITY_ID_CONTAINS_FILTER, SortDirection} from "../../../api/model/Entity";
import FilterDialogButton from "../../form/FilterDialogButton";
import FilterFormButtons from "../../form/FilterFormButtons";
import FilterFormRow from "../../form/FilterFormRow";
import SortOrderButton from "../../form/SortOrderButton";
import StringInput from "../../input/StringInput";
import {useCustomerSlicer} from "./CustomerSlicerProvider";

interface FilterDialogProps {
    open: boolean;
    onClose: (filter: InternalFilter) => void;
}

const FilterDialog: React.FunctionComponent<FilterDialogProps> = (props: FilterDialogProps) => {

    const {open, onClose} = props;

    const [id, setId] = useState<string>("");
    const [name, setName] = useState<string>("");

    const handleClear = () => {
        setId("");
        setName("");
    };

    const handleClose = () => {
        onClose({
            id: id.length === 0 ? undefined : id,
            name: name.length === 0 ? undefined : name
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Filter</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FilterFormRow label="ID" operation="contains">
                    <StringInput
                        id="id"
                        value={id}
                        disabled={false}
                        size="small"
                        setValue={setId}
                    />
                </FilterFormRow>
                <FilterFormRow label="Name" operation="contains">
                    <StringInput
                        id="name"
                        value={name}
                        disabled={false}
                        size="small"
                        setValue={setName}
                    />
                </FilterFormRow>
                <FilterFormButtons onClear={handleClear} onClose={handleClose}/>
            </Grid>
        </Dialog>
    );
}

interface RowProps {
    customer: Customer,
    onSelect: (customer: Customer) => void
}

const Row: React.FunctionComponent<RowProps> = ({customer, onSelect}: RowProps) => {
    return (
        <TableRow>
            <TableCell padding={"checkbox"}>
                <IconButton size="small" onClick={() => onSelect(customer)}>
                    <EditOutlined/>
                </IconButton>
            </TableCell>
            <TableCell>{customer.name.value}</TableCell>
            <TableCell>{customer.createdAt.toLocaleString()}</TableCell>
            <TableCell>{customer.emailAddress.value}</TableCell>
            <TableCell>{customer.customerId.value}</TableCell>
            <TableCell>{customer.state}</TableCell>
        </TableRow>
    );
}

export interface Props {
    customers: ReadonlyArray<Customer>,
    onSelect?: (customer: Customer) => void
}

type InternalFilter = {
    id?: string,
    name?: string
}

const CustomerTable: React.FunctionComponent<Props> = ({customers, onSelect}: Props) => {

    const customerSlicer = useCustomerSlicer();

    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const [internalFilter, setInternalFilter] = useState<InternalFilter>({});

    const handleFilterDialogOpen = () => {
        setFilterDialogOpen(true);
    };

    const handleFilterDialogClose = (filter: InternalFilter) => {
        setFilterDialogOpen(false);
        setInternalFilter(filter)
    };

    useEffect(() => {

        const dynamicFilters = new Array<CustomerFilter<any>>();

        if (internalFilter.id) {
            dynamicFilters.push({
                operation: ENTITY_ID_CONTAINS_FILTER,
                comparisonValue: internalFilter.id
            });
        }
        if (internalFilter.name) {
            dynamicFilters.push({
                operation: CUSTOMER_NAME_CONTAINS_FILTER,
                comparisonValue: internalFilter.name
            });
        }

        customerSlicer.setDynamicFilters(dynamicFilters);
        customerSlicer.setPaginationPage(0);

    }, [internalFilter]);

    function handleSortChange(sortOrder: CustomerSortOrder, direction: SortDirection) {
        customerSlicer.setSortOrder(sortOrder);
        customerSlicer.setSortDirection(direction);
        customerSlicer.setPaginationPage(0);
    }

    const handlePaginationPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        customerSlicer.setPaginationPage(newPage);
    };

    const handlePaginationRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        customerSlicer.setPaginationRowsPerPage(newRowsPerPage);
        customerSlicer.setPaginationPage(0);
    };

    const handleSelectFactory = (customer: Customer) => {
        return () => {
            onSelect && onSelect(customer)
        }
    }

    const [customerSlice, customerSliceCount] = customerSlicer.slice(customers);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <FilterDialogButton
                                    filterCount={customerSlicer.dynamicFilters.length}
                                    onClick={handleFilterDialogOpen}
                                />
                                <FilterDialog
                                    open={filterDialogOpen}
                                    onClose={handleFilterDialogClose}
                                />
                            </TableCell>
                            <TableCell>
                                Name
                                <SortOrderButton
                                    sortOrder={CUSTOMER_NAME_SORT_ORDER}
                                    currentSortOrder={customerSlicer.sortOrder}
                                    currentSortDirection={customerSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Created At
                                <SortOrderButton
                                    sortOrder={CUSTOMER_CREATED_AT_SORT_ORDER}
                                    currentSortOrder={customerSlicer.sortOrder}
                                    currentSortDirection={customerSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Email
                                <SortOrderButton
                                    sortOrder={CUSTOMER_EMAIL_ADDRESS_SORT_ORDER}
                                    currentSortOrder={customerSlicer.sortOrder}
                                    currentSortDirection={customerSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Customer ID
                                <SortOrderButton
                                    sortOrder={CUSTOMER_CUSTOMER_ID_SORT_ORDER}
                                    currentSortOrder={customerSlicer.sortOrder}
                                    currentSortDirection={customerSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                State
                                <SortOrderButton
                                    sortOrder={CUSTOMER_STATE_SORT_ORDER}
                                    currentSortOrder={customerSlicer.sortOrder}
                                    currentSortDirection={customerSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customerSlice.map((customer: Customer) => (
                            <Row key={customer.id.value} customer={customer}
                                 onSelect={handleSelectFactory(customer)}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={customerSlicer.paginationRowsPerPageOptions}
                component="div"
                count={customerSliceCount}
                rowsPerPage={customerSlicer.paginationRowsPerPage}
                page={customerSlicer.paginationPage}
                onRowsPerPageChange={handlePaginationRowsPerPageChange}
                onPageChange={handlePaginationPageChange}
            />
        </>
    );

}

export default CustomerTable;

