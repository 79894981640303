import {DataMapper} from "../Entity";
import {Benefit, benefit} from "./Benefit";

export type benefits = benefit[];

export class Benefits {

    public readonly items: ReadonlyArray<Benefit>;

    constructor(items: ReadonlyArray<Benefit>) {
        this.items = items;
    }

    public static toData(value: Benefits): benefits {
        return value.items.map(item => Benefit.toData(item));
    }

    static fromData(data: benefits): Benefits {
        if (data) {
            if (data.length > 1) {
                throw new Error("Multiple instances of benefits are not yet supported");
            }
            return new Benefits(
                data.map(datum => Benefit.fromData(datum))
            );
        } else {
            return new Benefits([]);
        }
    }

}

export const BENEFITS_DATA_MAPPER: DataMapper<Benefits, benefits> = {
    toData: Benefits.toData,
    fromData: Benefits.fromData
}
