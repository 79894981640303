import {DataMapper} from "../Entity";
import {Optional} from "../util/Optional";
import {ContactOption, contactOption, Email, Phone, Whatsapp} from "./ContactOption";
import {EMAIL, PHONE, WHATSAPP} from "./ContactOptionType";

export type contactOptions = contactOption[];

export class ContactOptions {

    public readonly email: Optional<Email>;

    public readonly phone: Optional<Phone>;

    public readonly whatsapp: Optional<Whatsapp>;

    constructor(email: Optional<Email>, phone: Optional<Phone>, whatsapp: Optional<Whatsapp>) {
        this.email = email;
        this.phone = phone;
        this.whatsapp = whatsapp;
    }

    public withEmail(email: Optional<Email>): ContactOptions {
        return new ContactOptions(email, this.phone, this.whatsapp);
    }

    public withPhone(phone: Optional<Phone>): ContactOptions {
        return new ContactOptions(this.email, phone, this.whatsapp);
    }

    public withWhatsapp(whatsapp: Optional<Whatsapp>): ContactOptions {
        return new ContactOptions(this.email, this.phone, whatsapp);
    }

    public static toData(value: ContactOptions): contactOptions {
        const contactOptions = new Array<contactOption>();
        if (value.email !== null) {
            contactOptions.push(ContactOption.toData(value.email));
        }
        if (value.phone !== null) {
            contactOptions.push(ContactOption.toData(value.phone));
        }
        if (value.whatsapp !== null) {
            contactOptions.push(ContactOption.toData(value.whatsapp));
        }
        return contactOptions;
    }

    public static fromData(data: contactOptions): ContactOptions {
        let email: Optional<Email> = null;
        let phone: Optional<Phone> = null;
        let whatsapp: Optional<Whatsapp> = null;
        if(data) {
            data.forEach(datum => {
                const contactOption = ContactOption.fromData(datum);
                if (contactOption.type.code.value === EMAIL.code.value) {
                    if (email !== null) {
                        throw new Error("Multiple instances of the same contact option are not yet supported");
                    }
                    email = contactOption as Email;
                } else if (contactOption.type.code.value === PHONE.code.value) {
                    if (phone !== null) {
                        throw new Error("Multiple instances of the same contact option profile are not yet supported");
                    }
                    phone = contactOption as Phone;
                } else if (contactOption.type.code.value === WHATSAPP.code.value) {
                    if (whatsapp !== null) {
                        throw new Error("Multiple instances of the same contact option profile are not yet supported");
                    }
                    whatsapp = contactOption as Whatsapp;
                }
            });
        }
        return new ContactOptions(email, phone, whatsapp);
    }


}

export const CONTACT_OPTIONS_DATA_MAPPER: DataMapper<ContactOptions, contactOptions> = {
    toData: ContactOptions.toData,
    fromData: ContactOptions.fromData
}