import {collection, CollectionReference, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {Address} from "./value/Address";
import {AFFILIATE_ID_DATA_MAPPER, AffiliateId, affiliateIdCompare} from "./value/AffiliateId";
import {contacts, Contacts, CONTACTS_DATA_MAPPER} from "./value/Contacts";
import {DEFAULT_COUNTRY} from "./value/Country";
import {EMAIL_ADDRESSES_DATA_MAPPER, emailAddresses, EmailAddresses} from "./value/EmailAddresses";
import {EntityType} from "./value/EntityType";
import {LEGAL_ENTITY_DATA_MAPPER, legalEntity, LegalEntity} from "./value/LegalEntity";
import {CORPORATE} from "./value/LegalEntityType";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {stringCompare, stringEquals} from "./value/String";

export enum AffiliateState {

    DISABLED = "DISABLED",

    ENABLED = "ENABLED"

}

export type AffiliatePayload = {
    state: AffiliateState,
    affiliateId: AffiliateId,
    name: Name,
    legalEntity: LegalEntity,
    contacts: Contacts,
    notificationEmailAddresses: EmailAddresses
}

export type AffiliateAnchor = EntityAnchor<EntityType.AFFILIATE> & {};

export type PreparedAffiliate = PreparedEntity<EntityType.AFFILIATE> & AffiliatePayload;

export type Affiliate = Entity<EntityType.AFFILIATE> & AffiliatePayload;

export type PersistableAffiliate = PreparedAffiliate | Affiliate;

export type AffiliateManager = EntityManager<EntityType.AFFILIATE, AffiliateAnchor, PersistableAffiliate, Affiliate>;

export const AFFILIATE_STATE_DATA_MAPPER: DataMapper<AffiliateState, string> = {
    toData: (value: AffiliateState) => value,
    fromData: (data: string) => data as AffiliateState
}

export const AFFILIATE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliate, AffiliateState, string>(
        "state",
        e => e.state,
        AFFILIATE_STATE_DATA_MAPPER,
        AffiliateState.ENABLED
    );

export const AFFILIATE_AFFILIATE_ID_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliate, AffiliateId, string>(
        "affiliateId",
        e => e.affiliateId,
        AFFILIATE_ID_DATA_MAPPER,
        null
    );

export const AFFILIATE_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliate, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Affiliate")
    );

export const AFFILIATE_LEGAL_ENTITY_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliate, LegalEntity, legalEntity>(
        "legalEntity",
        e => e.legalEntity,
        LEGAL_ENTITY_DATA_MAPPER,
        new LegalEntity(CORPORATE, new Name(""), new Address("", "", "", "", DEFAULT_COUNTRY))
    );

export const AFFILIATE_CONTACTS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliate, Contacts, contacts>(
        "contacts",
        e => e.contacts,
        CONTACTS_DATA_MAPPER,
        new Contacts([])
    );

export const AFFILIATE_NOTIFICATION_EMAIL_ADDRESSES_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliate, EmailAddresses, emailAddresses>(
        "notificationEmailAddresses",
        e => e.notificationEmailAddresses,
        EMAIL_ADDRESSES_DATA_MAPPER,
        new EmailAddresses([])
    );

export type AffiliateFilterColumn = EntityFilterColumn | "STATE" | "NAME";

export type AffiliateFilterOperation<V> = FilterOperation<EntityType.AFFILIATE, Affiliate, AffiliateFilterColumn, V>;

export type AffiliateFilter<V> = Filter<EntityType.AFFILIATE, Affiliate, AffiliateFilterColumn, V>;

export const AFFILIATE_STATE_EQUALS_FILTER: AffiliateFilterOperation<AffiliateState> = {
    column: "STATE",
    apply: (affiliate: Affiliate, comparisonValue: AffiliateState) => stringEquals(affiliate.state, comparisonValue)
}

export const AFFILIATE_NAME_CONTAINS_FILTER: AffiliateFilterOperation<string> = {
    column: "NAME",
    apply: (affiliate: Affiliate, comparisonValue: string) => nameContains(affiliate.name, comparisonValue)
}

export type AffiliateSortColumn = EntitySortColumn | "STATE" | "AFFILIATE_ID" | "NAME";

export type AffiliateSortOrder = SortOrder<EntityType.AFFILIATE, Affiliate, AffiliateSortColumn>;

export const AFFILIATE_STATE_SORT_ORDER: AffiliateSortOrder = {
    column: "STATE",
    apply: (left: Affiliate, right: Affiliate) => stringCompare(left.state, right.state)
}

export const AFFILIATE_AFFILIATE_ID_SORT_ORDER: AffiliateSortOrder = {
    column: "AFFILIATE_ID",
    apply: (left: Affiliate, right: Affiliate) => affiliateIdCompare(left.affiliateId, right.affiliateId)
}

export const AFFILIATE_NAME_SORT_ORDER: AffiliateSortOrder = {
    column: "NAME",
    apply: (left: Affiliate, right: Affiliate) => nameCompare(left.name, right.name)
}


class AffiliateDefinition extends EntityDefinition<EntityType.AFFILIATE, AffiliateAnchor, PersistableAffiliate, Affiliate> {

    constructor() {
        super(
            EntityType.AFFILIATE,
            [
                AFFILIATE_STATE_ATTRIBUTE_DEFINITION,
                AFFILIATE_AFFILIATE_ID_ATTRIBUTE_DEFINITION,
                AFFILIATE_NAME_ATTRIBUTE_DEFINITION,
                AFFILIATE_LEGAL_ENTITY_ATTRIBUTE_DEFINITION,
                AFFILIATE_CONTACTS_ATTRIBUTE_DEFINITION,
                AFFILIATE_NOTIFICATION_EMAIL_ADDRESSES_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableAffiliate): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableAffiliate): AffiliateAnchor {
        return {};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.AFFILIATE>): AffiliateAnchor {
        return {};
    }

    public anchorToColRef(database: Firestore, anchor: AffiliateAnchor): CollectionReference {
        return collection(database, "adminManager", "data", EntityType.AFFILIATE.toLowerCase());
    }

}

export const AFFILIATE_DEFINITION = new AffiliateDefinition();