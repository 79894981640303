import {leftPadWithZero} from "../../util";
import {DataMapper} from "../Entity";
import {ENGLISH, Language} from "./Language";
import {NominalDate} from "./NominalDate";
import {NominalTime} from "./NominalTime";
import {stringContains} from "./String";

export type label = {
    en: string;
}

export class Label {

    public readonly en: string;

    constructor(en: string) {
        this.en = en;
    }

    public withEn(en: string): Label {
        return new Label(en);
    }

    public static toData(value: Label): label {
        return {
            en: value.en
        }
    }

    public static fromData(data: label): Label {
        return new Label(
            data.en
        )
    }

}

export type LabelLanguage = {
    language: Language,
    accessor: (label: Label) => string,
    nominalDateFormatter: (date: NominalDate) => string
    nominalTimeFormatter: (time: NominalTime, twelveHourClock: boolean) => string
}

const ENGLISH_MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function twoDigit(value: number): string {
    return value < 10 ? "0" + value : "" + value;
}

export const LABEL_LANGUAGES: ReadonlyArray<LabelLanguage> = [
    {
        language: ENGLISH,
        accessor: (label: Label) => label.en,
        nominalDateFormatter: (date: NominalDate) => {
            return date.day + " " + ENGLISH_MONTHS[date.month - 1];
        },
        nominalTimeFormatter: (time: NominalTime, twelveHourClock: boolean) => {
            if (twelveHourClock) {
                if (time.hour === 0) {
                    return "12:" + twoDigit(time.minute) + " AM"
                } else if (time.hour < 13) {
                    return time.hour + ":" + twoDigit(time.minute) + " AM"
                } else {
                    return (time.hour - 12) + ":" + twoDigit(time.minute) + " PM"
                }
            } else {
                return time.hour + ":" + leftPadWithZero(time.minute, 2);
            }
        }
    }
]


export const LABEL_DATA_MAPPER: DataMapper<Label, label> = {
    toData: Label.toData,
    fromData: Label.fromData
}


export function labelContains(haystack: Label, needle: string): boolean {
    return stringContains(haystack.en, needle);
}

