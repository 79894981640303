import React from "react";
import {ParticipationType, PARTICIPATION_TYPES} from "../../api/model/value/ParticipationType";
import {FormSize} from "../form/FormSize";
import StringOptionsInput from "./StringOptionsInput";

export interface Props {
    id: string,
    value: ParticipationType,
    disabled: boolean,
    size: FormSize,
    setValue: (value: ParticipationType) => void
}

const ParticipationTypeInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <StringOptionsInput
            id={id}
            value={value.code.value}
            options={PARTICIPATION_TYPES.map(participationType => {
                return {value: participationType.code.value, label: participationType.name.value}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: string) => setValue(ParticipationType.fromData(value))}
        />
    )

};

export default ParticipationTypeInput;
