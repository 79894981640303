import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, {MutableRefObject, useRef, useState} from "react";
import {Bundle} from "../../../api/model/Bundle";
import {Optional} from "../../../api/model/util/Optional";
import BundleCoreInput, {BundleCore} from "./input/BundleCoreInput";

type TabDefinition = {
    id: string,
    label: string
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core"
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    bundle: Bundle,
    onClose?: () => void
}

const BundleUpdatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {bundle: bundle, onClose} = props

    const [currentBundle] = useState<Bundle>(bundle)
    const [reset] = useState<number>(0);

    const [selectedTab, setSelectedTab] = useState<number>(0);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getBundleCoreRef: MutableRefObject<Optional<() => BundleCore>> = useRef<Optional<() => BundleCore>>(null)

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <BundleCoreInput
                    bundle={currentBundle}
                    disabled={true}
                    size="small" getValue={getBundleCoreRef}
                    reset={reset}
                />
            </TabPanel>
            <Stack direction={"row"} spacing={1}>
                {onClose && <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>}
            </Stack>
        </Stack>
    );

};

export default BundleUpdatePage;
