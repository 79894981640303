import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {Optional} from "../../api/model/util/Optional";
import {Address} from "../../api/model/value/Address";
import {Country} from "../../api/model/value/Country";
import {FormSize} from "../form/FormSize";
import CountryInput2 from "./CountryInput2";

export interface Props {
    id: string,
    value: Address,
    disabled: boolean,
    size: FormSize;
    getValue: MutableRefObject<Optional<() => Address>>,
    reset: number
}

const AddressInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, getValue, reset} = props

    const [currentStreet, setCurrentStreet] = useState<string>(value.street);
    const [currentStreetNumber, setCurrentStreetNumber] = useState<string>(value.streetNumber);
    const [currentCity, setCurrentCity] = useState<string>(value.city);
    const [currentZip, setCurrentZip] = useState<string>(value.zip);

    const getCountryRef: MutableRefObject<Optional<() => Country>> = useRef<Optional<() => Country>>(null);

    useEffect(() => {
        setCurrentStreet(value.street)
        setCurrentStreetNumber(value.streetNumber)
        setCurrentCity(value.city)
        setCurrentZip(value.zip)
    }, [reset]);

    getValue.current = () => new Address(currentStreet, currentStreetNumber, currentCity, currentZip, getCountryRef.current!());

    const handleStreetChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCurrentStreet(event.target.value);
    }

    const handleStreetNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCurrentStreetNumber(event.target.value);
    }

    const handleCityChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCurrentCity(event.target.value);
    }

    const handleZipChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCurrentZip(event.target.value);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Stack direction="column">
                    Street
                    <TextField
                        id={id + "-street"}
                        value={currentStreet}
                        disabled={disabled}
                        size={size}
                        onChange={handleStreetChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction="column">
                    Street Number
                    <TextField
                        id={id + "-street-number"}
                        value={currentStreetNumber}
                        disabled={disabled}
                        size={size}
                        onChange={handleStreetNumberChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={5}>
                <Stack direction="column">
                    City
                    <TextField
                        id={id + "-city"}
                        value={currentCity}
                        disabled={disabled}
                        size={size}
                        onChange={handleCityChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction="column">
                    ZIP
                    <TextField
                        id={id + "-zip"}
                        value={currentZip}
                        disabled={disabled}
                        size={size}
                        onChange={handleZipChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack direction="column">
                    Country
                    <CountryInput2
                        id={id + "-country"}
                        value={value.country}
                        disabled={disabled} size={size}
                        getValue={getCountryRef}
                        reset={reset}
                    />
                </Stack>
            </Grid>
        </Grid>
    )

};

export default AddressInput;
