import {DataMapper} from "../Entity";
import {FACEBOOK, INSTAGRAM, SocialMediaProfileType} from "./SocialMediaProfileType";
import {Url} from "./Url";

export type socialMediaProfile = {
    type: string,
    username: string
}

export abstract class SocialMediaProfile {

    public readonly type: SocialMediaProfileType;

    public readonly username: string;

    protected constructor(type: SocialMediaProfileType, username: string) {
        this.type = type;
        this.username = username;
    }

    public getUrl(): Url {
        return this.type.formatter(this.username);
    }

    public static toData(value: SocialMediaProfile): socialMediaProfile {
        return {
            type: SocialMediaProfileType.toData(value.type),
            username: value.username
        }
    }

    public static fromData(data: socialMediaProfile): SocialMediaProfile {
        const type = SocialMediaProfileType.fromData(data.type);
        switch (type.code.value) {
            case "FACEBOOK":
                return new FacebookProfile(data.username);
            case "INSTAGRAM":
                return new InstagramProfile(data.username);
        }
        throw new Error("Unknown social media profile type: " + type.code.value);
    }

}

export class FacebookProfile extends SocialMediaProfile {

    constructor(username: string) {
        super(FACEBOOK, username);
    }

}

export class InstagramProfile extends SocialMediaProfile {

    constructor(username: string) {
        super(INSTAGRAM, username);
    }

}

export const SOCIAL_MEDIA_PROFILE_DATA_MAPPER: DataMapper<SocialMediaProfile, socialMediaProfile> = {
    toData: SocialMediaProfile.toData,
    fromData: SocialMediaProfile.fromData
}