import {DataMapper} from "../Entity";

export type nominalTime = {
    hour: number;
    minute: number;
}

export class NominalTime {

    public readonly hour: number;

    public readonly minute: number;

    constructor(hour: number, minute: number) {
        if (hour < 0 || hour > 23) {
            throw new Error("Invalid hour: " + hour);
        }
        if (minute < 0 || minute > 59) {
            throw new Error("Invalid minute: " + minute);
        }
        this.hour = hour;
        this.minute = minute;
    }

    public withHour(hour: number): NominalTime {
        return new NominalTime(hour, this.minute);
    }

    public withMinute(minute: number): NominalTime {
        return new NominalTime(this.hour, minute,);
    }

    public static toData(value: NominalTime): nominalTime {
        return {
            hour: value.hour,
            minute: value.minute
        };
    }

    static fromData(data: nominalTime): NominalTime {
        return new NominalTime(
            data.hour,
            data.minute
        );
    }

}

export const NOMINAL_TIME_DATA_MAPPER: DataMapper<NominalTime, nominalTime> = {
    toData: NominalTime.toData,
    fromData: NominalTime.fromData
}

