import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, {MutableRefObject, useRef, useState} from "react";
import {Customer} from "../../../api/model/Customer";
import {EntityState} from "../../../api/model/Entity";
import {Optional} from "../../../api/model/util/Optional";
import {useProject} from "../../../context/ProjectProvider";
import CustomerCoreInput, {CustomerCore} from "./input/CustomerCoreInput";

type TabDefinition = {
    id: string,
    label: string,
    buttons: boolean
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core",
        buttons: true
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    customer: Customer,
    onClose?: () => void
}

const CustomerUpdatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {customer: customer, onClose} = props

    const [currentCustomer] = useState<Customer>(customer)
    const [reset] = useState<number>(0);

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [disabled] = useState<boolean>(false);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getCustomerCoreRef: MutableRefObject<Optional<() => CustomerCore>> = useRef<Optional<() => CustomerCore>>(null)

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <CustomerCoreInput
                    customer={currentCustomer}
                    disabled={disabled}
                    size="small" getValue={getCustomerCoreRef}
                    reset={reset}
                />
            </TabPanel>
            {TABS[selectedTab].buttons && <Stack direction={"row"} spacing={1}>
                {onClose && <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>}
            </Stack>}
        </Stack>
    );

};

export default CustomerUpdatePage;
