import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Bundle, BundlePayload, BundleState} from "../../../../api/model/Bundle";
import {Optional} from "../../../../api/model/util/Optional";
import {Comment} from "../../../../api/model/value/Comment";
import {Name} from "../../../../api/model/value/Name";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import BundleStateInput2 from "../../../input/BundleStateInput2";
import CommentInput2 from "../../../input/CommentInput2";
import NameInput2 from "../../../input/NameInput2";
import StringOutput from "../../../output/StringOutput";

export type BundleCore = Pick<BundlePayload, "state" | "name" | "comment">;

export interface Props {
    bundle?: Bundle,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => BundleCore>>,
    reset: number
}

const BundleCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {bundle, disabled, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => BundleState>> = useRef<Optional<() => BundleState>>(null)
    const getNameRef: MutableRefObject<Optional<() => Name>> = useRef<Optional<() => Name>>(null)
    const getCommentRef: MutableRefObject<Optional<() => Comment>> = useRef<Optional<() => Comment>>(null)

    getValue.current = () => ({
        state: getStateRef.current!(),
        name: getNameRef.current!(),
        comment: getCommentRef.current!()
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {bundle?.id && <FormRow label="Id">
                    <StringOutput
                        value={bundle.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <BundleStateInput2
                        id="state"
                        value={bundle?.state || BundleState.PUBLISHED}
                        disabled={disabled}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="UUID">
                    <NameInput2
                        id="name"
                        value={bundle?.name || new Name("")}
                        disabled={disabled}
                        size={size}
                        getValue={getNameRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Comment">
                    <CommentInput2
                        id="comment"
                        value={bundle?.comment || new Comment("")}
                        disabled={disabled}
                        size={size}
                        getValue={getCommentRef}
                        reset={reset}
                    />
                </FormRow>
            </Grid>
        </>
    );

};

export default BundleCoreInput;
