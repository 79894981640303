import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {Url} from "./Url";

export class SocialMediaProfileType {

    public readonly code: Code;

    public readonly formatter: (username: string) => Url;

    constructor(code: Code, username: (username: string) => Url) {
        this.code = code;
        this.formatter = username;
    }

    public static toData(value: SocialMediaProfileType): string {
        return value.code.value;
    }

    public static fromData(data: string): SocialMediaProfileType {
        return SocialMediaProfileType.fromCode(new Code(data));
    }

    public static fromCode(socialMediaTypeCode: Code): SocialMediaProfileType {
        const socialMediaProfileType: Maybe<SocialMediaProfileType> = SOCIAL_MEDIA_PROFILE_TYPES.find(socialMediaType => socialMediaType.code.value === socialMediaTypeCode.value);
        if (socialMediaProfileType === undefined) {
            throw new Error("Unknown social media type: " + JSON.stringify(socialMediaTypeCode));
        }
        return socialMediaProfileType;
    }

}

export const FACEBOOK: SocialMediaProfileType = new SocialMediaProfileType(
    new Code("FACEBOOK"),
    (profile: string) => new Url("https://www.facebook.com/" + profile)
);

export const INSTAGRAM: SocialMediaProfileType = new SocialMediaProfileType(
    new Code("INSTAGRAM"),
    (profile: string) => new Url("https://www.instagram.com/" + profile)
);

export const SOCIAL_MEDIA_PROFILE_TYPES: ReadonlyArray<SocialMediaProfileType> = [
    FACEBOOK,
    INSTAGRAM
] as const;

export const SOCIAL_MEDIA_PROFILE_TYPE_DATA_MAPPER: DataMapper<SocialMediaProfileType, string> = {
    toData: SocialMediaProfileType.toData,
    fromData: SocialMediaProfileType.fromData
}

