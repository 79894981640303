import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import React, {MutableRefObject, useEffect, useState} from "react";
import {Optional} from "../../api/model/util/Optional";
import {PositiveInteger} from "../../api/model/value/PositiveInteger";
import {FormSize} from "../form/FormSize";

const adornmentStyle = makeStyles((theme?: any) => ({
    adornment: {
        background: '#eee',
        padding: "1px 8px",
        borderRadius: "8px"
    },
}));

export interface Props {
    id: string,
    value: PositiveInteger,
    max: PositiveInteger,
    adornmentEnd: string,
    disabled: boolean,
    size: FormSize;
    getValue: MutableRefObject<Optional<() => PositiveInteger>>,
    reset: number
}

const PositiveIntegerInput2: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, max, adornmentEnd, disabled, size, getValue, reset} = props

    const [currentValue, setCurrentValue] = useState<PositiveInteger>(value);

    useEffect(() => {
        setCurrentValue(value)
    }, [reset]);


    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rawValue = event.target.value;
        if (rawValue.length === 0) {
            setCurrentValue(new PositiveInteger(1));
        } else {
            const integerValue = Math.max(1, Math.min(max.value, parseInt(rawValue)));
            if (isFinite(integerValue)) {
                setCurrentValue(new PositiveInteger(integerValue));
            }
        }
    }

    getValue.current = () => currentValue;

    return (
        <TextField
            id={id}
            type="number"
            value={currentValue.value}
            InputProps={{
                inputProps: {min: 1, max: max.value}, endAdornment: <InputAdornment position="end">
                    <span className={adornmentStyle().adornment}>{adornmentEnd}</span>
                </InputAdornment>
            }}
            disabled={disabled}
            size={size}
            onChange={handleChange}
        />
    )

};

export default PositiveIntegerInput2;
