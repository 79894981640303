import {collection, CollectionReference, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, valueFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {
    AFFILIATE_CODE_ID_DATA_MAPPER,
    AffiliateCodeId,
    affiliateCodeIdCompare,
    affiliateCodeIdContains
} from "./value/AffiliateCodeId";
import {EntityType} from "./value/EntityType";
import {IdRef} from "./value/IdRef";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {NonNegativeInteger} from "./value/NonNegativeInteger";
import {POSITIVE_INTEGER_DATA_MAPPER, PositiveInteger} from "./value/PositiveInteger";
import {stringCompare, stringEquals} from "./value/String";
import {Target, TARGET_DATA_MAPPER, targetCompare, targetEquals, WEBAPP} from "./value/Target";

export enum AffiliateCodeState {

    ENABLED = "ENABLED",

    DISABLED = "DISABLED"

}

export type AffiliateCodePayload = {
    state: AffiliateCodeState,
    codeId: AffiliateCodeId,
    name: Name,
    target: Target,
    eligibleDays: NonNegativeInteger,
    affiliate: IdRef<EntityType.AFFILIATE>,
}

export type AffiliateCodeAnchor = EntityAnchor<EntityType.AFFILIATE_CODE> & {}

export type PreparedAffiliateCode = PreparedEntity<EntityType.AFFILIATE_CODE> & AffiliateCodePayload;

export type AffiliateCode = Entity<EntityType.AFFILIATE_CODE> & AffiliateCodePayload;

export type PersistableAffiliateCode = PreparedAffiliateCode | AffiliateCode;

export type AffiliateCodeManager = EntityManager<EntityType.AFFILIATE_CODE, AffiliateCodeAnchor, PersistableAffiliateCode, AffiliateCode>;

export const AFFILIATE_CODE_STATE_DATA_MAPPER: DataMapper<AffiliateCodeState, string> = {
    toData: (value: AffiliateCodeState) => value,
    fromData: (data: string) => data as AffiliateCodeState
}

export const AFFILIATE_CODE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliateCode, AffiliateCodeState, string>(
        "state",
        e => e.state,
        AFFILIATE_CODE_STATE_DATA_MAPPER,
        AffiliateCodeState.ENABLED
    );

export const AFFILIATE_CODE_CODE_ID_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliateCode, Name, string>(
        "codeId",
        e => e.codeId,
        AFFILIATE_CODE_ID_DATA_MAPPER,
        null
    );

export const AFFILIATE_CODE_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliateCode, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("AffiliateCode")
    );

export const AFFILIATE_CODE_TARGET_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliateCode, Target, string>(
        "target",
        e => e.target,
        TARGET_DATA_MAPPER,
        WEBAPP
    );

export const AFFILIATE_ELIGIBLE_DAYS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableAffiliateCode, PositiveInteger, number>(
        "eligibleDays",
        e => e.eligibleDays,
        POSITIVE_INTEGER_DATA_MAPPER,
        new PositiveInteger(14)
    );

export const AFFILIATE_CODE_AFFILIATE_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.AFFILIATE, PersistableAffiliateCode>(
        EntityType.AFFILIATE,
        "affiliate",
        e => e.affiliate
    );

export type AffiliateCodeFilterColumn = EntityFilterColumn | "STATE" | "CODE_ID" | "NAME" | "TARGET" | "AFFILIATE";

export type AffiliateCodeFilterOperation<V> = FilterOperation<EntityType.AFFILIATE_CODE, AffiliateCode, AffiliateCodeFilterColumn, V>;

export type AffiliateCodeFilter<V> = Filter<EntityType.AFFILIATE_CODE, AffiliateCode, AffiliateCodeFilterColumn, V>;

export const AFFILIATE_CODE_STATE_EQUALS_FILTER: AffiliateCodeFilterOperation<AffiliateCodeState> = {
    column: "STATE",
    apply: (code: AffiliateCode, comparisonValue: AffiliateCodeState) => stringEquals(code.state, comparisonValue)
}

export const AFFILIATE_CODE_CODE_ID_CONTAINS_FILTER: AffiliateCodeFilterOperation<string> = {
    column: "CODE_ID",
    apply: (code: AffiliateCode, comparisonValue: string) => affiliateCodeIdContains(code.codeId, comparisonValue)
}

export const AFFILIATE_CODE_NAME_CONTAINS_FILTER: AffiliateCodeFilterOperation<string> = {
    column: "NAME",
    apply: (code: AffiliateCode, comparisonValue: string) => nameContains(code.name, comparisonValue)
}

export const AFFILIATE_CODE_TARGET_EQUALS_FILTER: AffiliateCodeFilterOperation<Target> = {
    column: "TARGET",
    apply: (code: AffiliateCode, comparisonValue: Target) => targetEquals(code.target, comparisonValue)
}

export const AFFILIATE_CODE_AFFILIATE_EQUALS_FILTER: AffiliateCodeFilterOperation<IdAlike<EntityType.AFFILIATE>> = {
    column: "AFFILIATE",
    apply: (code: AffiliateCode, comparisonValue: IdAlike<EntityType.AFFILIATE>) => stringEquals(valueFromIdAlike(code.affiliate), valueFromIdAlike(comparisonValue))
}

export type AffiliateCodeSortColumn = EntitySortColumn | "STATE" | "CODE_ID" | "NAME" | "TARGET";

export type AffiliateCodeSortOrder = SortOrder<EntityType.AFFILIATE_CODE, AffiliateCode, AffiliateCodeSortColumn>;

export const AFFILIATE_CODE_STATE_SORT_ORDER: AffiliateCodeSortOrder = {
    column: "STATE",
    apply: (left: AffiliateCode, right: AffiliateCode) => stringCompare(left.state, right.state)
}

export const AFFILIATE_CODE_CODE_ID_SORT_ORDER: AffiliateCodeSortOrder = {
    column: "CODE_ID",
    apply: (left: AffiliateCode, right: AffiliateCode) => affiliateCodeIdCompare(left.codeId, right.codeId)
}

export const AFFILIATE_CODE_NAME_SORT_ORDER: AffiliateCodeSortOrder = {
    column: "NAME",
    apply: (left: AffiliateCode, right: AffiliateCode) => nameCompare(left.name, right.name)
}

export const AFFILIATE_CODE_TARGET_SORT_ORDER: AffiliateCodeSortOrder = {
    column: "TARGET",
    apply: (left: AffiliateCode, right: AffiliateCode) => targetCompare(left.target, right.target)
}

class AffiliateCodeDefinition extends EntityDefinition<EntityType.AFFILIATE_CODE, AffiliateCodeAnchor, PersistableAffiliateCode, AffiliateCode> {

    constructor() {
        super(
            EntityType.AFFILIATE_CODE,
            [
                AFFILIATE_CODE_STATE_ATTRIBUTE_DEFINITION,
                AFFILIATE_CODE_CODE_ID_ATTRIBUTE_DEFINITION,
                AFFILIATE_CODE_NAME_ATTRIBUTE_DEFINITION,
                AFFILIATE_CODE_TARGET_ATTRIBUTE_DEFINITION,
                AFFILIATE_ELIGIBLE_DAYS_ATTRIBUTE_DEFINITION,
                AFFILIATE_CODE_AFFILIATE_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableAffiliateCode): Optional<string> {
        return entity.codeId.value;
    }

    public preparedEntityToAnchor(entity: PersistableAffiliateCode): AffiliateCodeAnchor {
        return {};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.AFFILIATE_CODE>): AffiliateCodeAnchor {
        return {};
    }

    public anchorToColRef(database: Firestore, anchor: AffiliateCodeAnchor): CollectionReference {
        return collection(database, "adminManager", "data", EntityType.AFFILIATE_CODE.toLowerCase());
    }

}

export const AFFILIATE_CODE_DEFINITION = new AffiliateCodeDefinition();

const BASE_URL = process.env.REACT_APP_CODE_BASE_URL || "/";

export function affiliateCodeUrl(code: Pick<AffiliateCodePayload, "codeId" | "target">) {
    return BASE_URL + (BASE_URL.endsWith("/") ? "" : "/") + "?c=" + code.codeId.value + "&t=" + code.target.code.value;
}