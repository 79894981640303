import React, {useEffect, useState} from "react";
import {Location} from "../../../api/model/Location";
import {Project, useProject} from "../../../context/ProjectProvider";
import LocationTable from "./LocationTable";

export interface Props {
    onSelect?: (location: Location) => void
}

const LocationList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [locations, setLocations] = useState<ReadonlyArray<Location>>([]);

    useEffect(() => {

        const loadLocations = async () => {

            const locations = await project.locationManager.getAllAsArray({region: project.selectedRegion});
            setLocations(locations);

        }

        loadLocations().catch(console.error);

    }, [project]);

    return (
        <LocationTable
            locations={locations}
            onSelect={onSelect}
        />
    );

};

export default LocationList;
