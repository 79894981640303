import React, {useEffect, useState} from "react";
import {Venue} from "../../../api/model/Venue";
import {Project, useProject} from "../../../context/ProjectProvider";
import VenueTable from "./VenueTable";

export interface Props {
    onSelect?: (venue: Venue) => void
}

const VenueList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [venues, setVenues] = useState<ReadonlyArray<Venue>>([]);

    useEffect(() => {

        const loadVenues = async () => {

            const venues = await project.venueManager.getAllAsArray({region: project.selectedRegion});
            setVenues(venues);

        }

        loadVenues().catch(console.error);

    }, [project]);

    return (
        <VenueTable
            venues={venues}
            onSelect={onSelect}
        />
    );

};

export default VenueList;
