import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, {MutableRefObject, useRef, useState} from "react";
import {EntityState} from "../../../api/model/Entity";
import {Tag} from "../../../api/model/Tag";
import {Optional} from "../../../api/model/util/Optional";
import {useProject} from "../../../context/ProjectProvider";
import RemoveButton from "../../form/RemoveButton";
import RestoreButton from "../../form/RestoreButton";
import TagCoreInput, {TagCore} from "./input/TagCoreInput";

type TabDefinition = {
    id: string,
    label: string
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core"
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    tag: Tag,
    onClose?: () => void
}

const TagUpdatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {tag: tag, onClose} = props

    const [latestTag, setLatestTag] = useState<Tag>(tag)
    const [currentTag, setCurrentTag] = useState<Tag>(tag)
    const [reset, setReset] = useState<number>(0);

    const project = useProject();
    const tagManager = project.tagManager;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getTagCoreRef: MutableRefObject<Optional<() => TagCore>> = useRef<Optional<() => TagCore>>(null)

    const handlePersist = async () => {
        setDisabled(true);
        const tag: Tag = {
            ...latestTag,
            ...getTagCoreRef.current!()
        }
        const updatedTag = await tagManager.persist(tag);
        setLatestTag(updatedTag);
        setCurrentTag(updatedTag);
        setDisabled(false);
    }

    const handleReset = async () => {
        setDisabled(true);
        setCurrentTag(latestTag);
        setReset(reset + 1);
        setDisabled(false);
    }

    const handleRemove = async () => {
        const updatedTag = await tagManager.removeById(latestTag);
        setLatestTag(updatedTag);
        setCurrentTag(updatedTag);
        setReset(reset + 1);
    }

    const handleRestore = async () => {
        const updatedTag = await tagManager.restoreById(latestTag);
        setLatestTag(updatedTag);
        setCurrentTag(updatedTag);
        setReset(reset + 1);
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <TagCoreInput
                    tag={currentTag}
                    disabled={disabled}
                    size="small" getValue={getTagCoreRef}
                    reset={reset}
                />
            </TabPanel>
            <Stack direction={"row"} spacing={1}>
                {currentTag.entityState === EntityState.CREATED &&
                    <Button variant={"contained"} onClick={handlePersist}>
                        Update
                    </Button>}
                {currentTag.entityState === EntityState.CREATED &&
                    <Button variant={"outlined"} onClick={handleReset}>
                        Reset
                    </Button>}
                {currentTag.entityState === EntityState.REMOVED &&
                    <RestoreButton disabled={false} onRestore={handleRestore}/>}
                {currentTag.entityState === EntityState.CREATED &&
                    <RemoveButton disabled={false} onRemove={handleRemove}/>}
                {onClose && <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>}
            </Stack>
        </Stack>
    );

};

export default TagUpdatePage;
