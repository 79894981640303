import Stack from "@mui/material/Stack";
import React from "react";
import {Benefit} from "../../api/model/value/Benefit";
import {BenefitTypes} from "../../api/model/value/BenfitTypes";
import {Label} from "../../api/model/value/Label";
import {FormSize} from "../form/FormSize";
import BenefitTypesInput from "./BenefitTypesInput";
import LabelInput, {LabelType} from "./LabelInput";

export interface Props {
    id: string,
    value: Benefit,
    disabled: boolean,
    size: FormSize;
    setValue: (value: Benefit) => void
}

const BenefitInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleTypesChangeChange = (types: BenefitTypes) => {
        setValue(value.withTypes(types));
    }

    const handleDescriptionChange = (description: Label) => {
        setValue(value.withDescription(description));
    }

    return (
        <Stack direction="column" spacing={3}>
            <LabelInput
                id={id + "-description"}
                value={value.description}
                type={LabelType.MULTI_LINE}
                disabled={disabled}
                size={size}
                setValue={handleDescriptionChange}
            />
            <Stack direction="column">
                Types
                <BenefitTypesInput
                    id={id + "-types"}
                    value={value.types}
                    disabled={disabled}
                    size={size}
                    setValue={handleTypesChangeChange}
                />
            </Stack>
        </Stack>
    )

};

export default BenefitInput;
