import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, {useEffect, useState} from "react";
import {Optional} from "../../api/model/util/Optional";
import {EmailAddress} from "../../api/model/value/EmailAddress";
import {EmailAddresses} from "../../api/model/value/EmailAddresses";
import {arrayAdd, arrayDown, arrayRemove, arrayUp, arrayUpdate} from "../../api/util";
import DialogFormButtons from "../form/DialogFormButtons";
import FormRow from "../form/FormRow";
import {FormSize} from "../form/FormSize";
import EmailAddressInput from "./EmailAddressInput";


interface EmailAddressDialogProps {
    open: boolean;
    value: EmailAddress;
    onClose: (value: Optional<EmailAddress>) => void;
}

const EmailAddressDialog: React.FunctionComponent<EmailAddressDialogProps> = (props: EmailAddressDialogProps) => {

    const {open, value, onClose} = props;
    const [currentValue, setCurrentValue] = useState<EmailAddress>(value);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleReset = () => {
        setCurrentValue(value);
    }

    const handleStore = () => {
        if (currentValue.value.length === 0) {
            onClose(null);
        } else {
            onClose(currentValue)
        }
    };

    const handleClose = () => {
        if (value.value.length === 0) {
            onClose(null);
        } else {
            onClose(value)
        }
    };

    return (
        <Dialog maxWidth={"md"} fullWidth={true} onClose={handleClose} open={open}>
            <DialogTitle>Email Address</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FormRow label="Email Address">
                    <EmailAddressInput
                        id="email-address"
                        value={currentValue}
                        disabled={false}
                        size="small"
                        setValue={setCurrentValue}
                    />
                </FormRow>
                <DialogFormButtons onReset={handleReset} onStore={handleStore}/>
            </Grid>
        </Dialog>
    );
}


export interface Props {
    id: string,
    value: EmailAddresses,
    disabled: boolean,
    size: FormSize;
    setValue: (value: EmailAddresses) => void
}

const EmailAddressesInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedPosition, setSelectedPosition] = useState<Optional<number>>(null);
    const [item, setItem] = useState<EmailAddress>(new EmailAddress(""));

    useEffect(() => {
        setSelectedPosition(null);
        setItem(new EmailAddress(""));
    }, [value]);


    const handleAdd = () => {
        setSelectedPosition(null);
        setItem(new EmailAddress(""));
        setDialogOpen(true);
    };

    const handleSelect = (position: number) => {
        setSelectedPosition(position);
        setItem(value.items[position]);
        setDialogOpen(true);
    };

    const handleClose = (emailAddress: Optional<EmailAddress>) => {
        if (selectedPosition !== null) {
            if (emailAddress !== null) {
                setValue(new EmailAddresses(arrayUpdate(value.items, selectedPosition, emailAddress)));
            } else {
                handleDelete(selectedPosition);
            }
        } else {
            if (emailAddress !== null) {
                setValue(new EmailAddresses(arrayAdd(value.items, emailAddress)));
            }
        }
        setDialogOpen(false);
    };

    const handleMoveUpFactory = (position: number) => {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleMoveUp(position);
        }
    }

    const handleMoveUp = (position: number) => {
        setValue(new EmailAddresses(arrayUp(value.items, position!)));
    };

    const handleMoveDownFactory = (position: number) => {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleMoveDown(position);
        }
    }

    const handleMoveDown = (position: number) => {
        setValue(new EmailAddresses(arrayDown(value.items, position!)));
    };

    const handleDeleteFactory = (position: number) => {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleDelete(position);
        }
    }

    const handleDelete = (position: number) => {
        setValue(new EmailAddresses(arrayRemove(value.items, position!)));
    };


    return (
        <>
            <TableContainer sx={{mb: 3}}>
                <Table sx={{minWidth: 650}} size={"small"} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Email Address</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {value.items.map((item: EmailAddress, position: number) => (
                            <TableRow key={position} hover onClick={() => handleSelect(position)}>
                                <TableCell>
                                    {item.value}
                                </TableCell>
                                <TableCell align={"right"}>
                                    <IconButton
                                        size={"small"}
                                        disabled={disabled}
                                        onClick={handleMoveUpFactory(position)}>
                                        <KeyboardArrowUp/>
                                    </IconButton>
                                    <IconButton
                                        size={"small"}
                                        disabled={disabled}
                                        onClick={handleMoveDownFactory(position)}>
                                        <KeyboardArrowDown/>
                                    </IconButton>
                                    <IconButton
                                        size={"small"}
                                        disabled={disabled}
                                        onClick={handleDeleteFactory(position)}>
                                        <Delete/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EmailAddressDialog open={dialogOpen} value={item} onClose={handleClose}/>
            <Stack direction="row">
                <FormControl sx={{pt: 1}}>
                    <Button onClick={handleAdd} variant={"outlined"} disabled={disabled}>Add</Button>
                </FormControl>
            </Stack>
        </>
    )

};

export default EmailAddressesInput;
