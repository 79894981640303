import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import React from "react";
import {PositiveInteger} from "../../api/model/value/PositiveInteger";
import {FormSize} from "../form/FormSize";

const adornmentStyle = makeStyles((theme?: any) => ({
    adornment: {
        background: '#eee',
        padding: "1px 8px",
        borderRadius: "8px"
    },
}));

export interface Props {
    id: string,
    value: PositiveInteger,
    max: PositiveInteger,
    adornmentEnd: string,
    disabled: boolean,
    size: FormSize;
    setValue: (value: PositiveInteger) => void
}

const PositiveIntegerInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, max, adornmentEnd, disabled, size, setValue} = props

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rawValue = event.target.value;
        if (rawValue.length === 0) {
            setValue(new PositiveInteger(1));
        } else {
            const integerValue = Math.max(1, Math.min(max.value, parseInt(rawValue)));
            if (isFinite(integerValue)) {
                setValue(new PositiveInteger(integerValue));
            }
        }
    }

    return (
        <TextField
            id={id}
            type="number"
            value={value.value}
            InputProps={{
                inputProps: {min: 1, max: max.value}, endAdornment: <InputAdornment position="end">
                    <span className={adornmentStyle().adornment}>{adornmentEnd}</span>
                </InputAdornment>
            }}
            disabled={disabled}
            size={size}
            onChange={handleChange}
        />
    )

};

export default PositiveIntegerInput;
