import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import React from "react";
import {FacebookProfile, InstagramProfile} from "../../api/model/value/SocialMediaProfile";
import {SocialMediaProfiles} from "../../api/model/value/SocialMediaProfiles";
import {FACEBOOK, INSTAGRAM} from "../../api/model/value/SocialMediaProfileType";
import {FormSize} from "../form/FormSize";

const adornmentStyle = makeStyles((theme?: any) => ({
    adornment: {
        background: '#eee',
        padding: "1px 8px",
        borderRadius: "8px"
    },
}));

export interface Props {
    id: string,
    value: SocialMediaProfiles,
    disabled: boolean,
    size: FormSize;
    setValue: (value: SocialMediaProfiles) => void
}

const SocialMediaProfilesInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const adornmentClasses = adornmentStyle();

    const handleFacebookChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withFacebook(event.target.value.length === 0 ? null : new FacebookProfile(event.target.value)));
    }

    const handleInstagramChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withInstagram(event.target.value.length === 0 ? null : new InstagramProfile(event.target.value)));
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="column">
                    Facebook
                    <TextField
                        id={id + "-facebook"}
                        value={value.facebook ? value.facebook.username : ""}
                        disabled={disabled}
                        size={size}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <span className={adornmentClasses.adornment}>{FACEBOOK.formatter("").value}</span>
                            </InputAdornment>
                        }}
                        onChange={handleFacebookChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="column">
                    Instagram
                    <TextField
                        id={id + "-instagram"}
                        value={value.instagram ? value.instagram.username : ""}
                        disabled={disabled}
                        size={size}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <span className={adornmentClasses.adornment}>{INSTAGRAM.formatter("").value}</span>
                            </InputAdornment>
                        }}
                        onChange={handleInstagramChange}
                    />
                </Stack>
            </Grid>
        </Grid>
    )

};

export default SocialMediaProfilesInput;
