import {Entity} from "./model/Entity";
import {Optional} from "./model/util/Optional";
import {EntityType} from "./model/value/EntityType";
import {Id} from "./model/value/Id";
import {IdRef} from "./model/value/IdRef";
import {Name} from "./model/value/Name";

const ALPHA_NUMERICAL_CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789' as const;

const NUMERICAL_CHARSET = '0123456789' as const;

function randomString(charset: string, length: number) {
    let result = '';
    for (let i = 0; i < length; i++) {
        result += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return result;
}

export function randomAlphaNumericalString(length: number) {
    return randomString(ALPHA_NUMERICAL_CHARSET, length);
}

export function randomNumericalString(length: number) {
    return randomString(NUMERICAL_CHARSET, length);
}

export type IdValueAlike<T extends EntityType> = string | Id<T> | IdRef<T> | Entity<T>;

export type IdAlike<T extends EntityType> = Id<T> | IdRef<T> | Entity<T>;

export function valueFromIdValueAlike<T extends EntityType>(idValue: IdValueAlike<T>): string {
    if (typeof idValue === "string") {
        return idValue;
    } else {
        return valueFromIdAlike(idValue);
    }
}

export function valueFromIdAlike<T extends EntityType>(idAlike: IdAlike<T>): string {
    return idFromIdAlike(idAlike).value;
}

export function idFromIdAlike<T extends EntityType>(idAlike: IdAlike<T>): Id<T> {
    if ('id' in idAlike) {
        return idAlike.id;
    } else {
        return idAlike;
    }
}

export function findByIdValue<T extends EntityType, E extends Entity<T>>(entities: ReadonlyArray<E>, idValueAlike: IdValueAlike<T>): Optional<E> {
    return findByIdString(entities, valueFromIdValueAlike(idValueAlike));
}

export function findById<T extends EntityType, E extends Entity<T>>(entities: ReadonlyArray<E>, idAlike: IdAlike<T>): Optional<E> {
    return findByIdString(entities, valueFromIdAlike(idAlike));
}

function findByIdString<T extends EntityType, E extends Entity<T>>(entities: ReadonlyArray<E>, id: string): Optional<E> {
    return entities.find(e => e.id.value === id) || null;
}

export function sortByName<N extends { name: Name }>(namedValues: ReadonlyArray<N>, locale: string = 'en'): ReadonlyArray<N> {
    return [...namedValues].sort((left, right) => left.name.value.localeCompare(right.name.value, locale));
}

export function arrayAdd<T>(array: ReadonlyArray<T>, value: T): ReadonlyArray<T> {
    return [...array, value];
}

export function arrayUpdate<T>(array: ReadonlyArray<T>, position: number, value: T): ReadonlyArray<T> {
    return [...array.slice(0, position), value, ...array.slice(position + 1)];
}

export function arrayUp<T>(array: ReadonlyArray<T>, position: number): ReadonlyArray<T> {
    if (position === 0) {
        return [...array.slice(1), array[0]];
    } else {
        return arraySwap(array, position - 1, position);
    }
}

export function arrayDown<T>(array: ReadonlyArray<T>, position: number): ReadonlyArray<T> {
    if (position === array.length - 1) {
        return [array[position], ...array.slice(0, position)];
    } else {
        return arraySwap(array, position, position + 1);
    }
}

export function arraySwap<T>(array: ReadonlyArray<T>, position1: number, position2: number): ReadonlyArray<T> {
    const a = [...array];
    const t = a[position1];
    a[position1] = a[position2];
    a[position2] = t;
    return a;
}


export function arrayRemove<T>(array: ReadonlyArray<T>, position: number): ReadonlyArray<T> {
    return [...array.slice(0, position), ...array.slice(position + 1)];
}

export function leftPadWithZero(value: number, length: number) {
    return String(value).padStart(length, '0')
}

export function csvEscape(value: string): string {
    return "\"" + value.replaceAll("\"", "\"\"") + "\"";
}

export function optionalCompare<T>(compare: (left: T, right: T) => number, left: Optional<T>, right: Optional<T>) {
    if (left === null) {
        return -1;
    } else if (right === null) {
        return 1;
    } else {
        return compare(left, right);
    }
}