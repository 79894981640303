import {DataMapper} from "../Entity";
import {stringCompare, stringContains} from "./String";

export class Name {

    public readonly value: string;

    constructor(value: string) {
        this.value = value;
    }

    public static toData(value: Name): string {
        return value.value;
    }

    public static fromData(data: string): Name {
        return new Name(data);
    }

}

export const NAME_DATA_MAPPER: DataMapper<Name, string> = {
    toData: Name.toData,
    fromData: Name.fromData
}


export function nameContains(haystack: Name, needle: string): boolean {
    return stringContains(haystack.value, needle);
}

export function nameCompare(left: Name, right: Name): number {
    return stringCompare(left.value, right.value)
}
