import React, {useEffect, useState} from "react";
import {Flag} from "../../../api/model/Flag";
import {Project, useProject} from "../../../context/ProjectProvider";
import FlagTable from "./FlagTable";

export interface Props {
    onSelect?: (flag: Flag) => void
}

const FlagList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [flags, setFlags] = useState<ReadonlyArray<Flag>>([]);

    useEffect(() => {

        const loadFlags = async () => {

            const flags = await project.flagManager.getAllAsArray({region: project.selectedRegion});
            setFlags(flags);

        }

        loadFlags().catch(console.error);

    }, [project]);

    return (
        <FlagTable
            flags={flags}
            onSelect={onSelect}
        />
    );

};

export default FlagList;
