import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {Affiliate} from "../../../api/model/Affiliate";
import {PreparedAffiliateCode} from "../../../api/model/AffiliateCode";
import {EntityScope} from "../../../api/model/Entity";
import {Optional} from "../../../api/model/util/Optional";
import {AffiliateCodeId} from "../../../api/model/value/AffiliateCodeId";
import {EntityType} from "../../../api/model/value/EntityType";
import {IdRef} from "../../../api/model/value/IdRef";
import {useProject} from "../../../context/ProjectProvider";
import AffiliateCodeCoreInput, {CodeCore} from "./input/AffiliateCodeCoreInput";

type TabDefinition = {
    id: string,
    label: string
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core"
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    affiliate: Affiliate,
    onClose: () => void
}

const AffiliateCodeCreatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {affiliate, onClose} = props;

    const project = useProject();
    const codeManager = project.affiliateCodeManager;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getCodeCoreRef: MutableRefObject<Optional<() => CodeCore>> = useRef<Optional<() => CodeCore>>(null)

    const handlePersist = async () => {

        setDisabled(true);

        const preparedCode: PreparedAffiliateCode = {
            entityType: EntityType.AFFILIATE_CODE,
            entityScope: EntityScope.PREPARED,
            ...getCodeCoreRef.current!(),
            codeId: AffiliateCodeId.random(affiliate.affiliateId),
            affiliate: new IdRef<EntityType.AFFILIATE>(affiliate.id)
        }

        await codeManager.persist(preparedCode);

        onClose();

    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <AffiliateCodeCoreInput
                    disabled={disabled}
                    size="small"
                    getValue={getCodeCoreRef}
                    reset={0}
                />
            </TabPanel>
            <Stack direction={"row"} spacing={1}>
                <Button variant={"contained"} onClick={handlePersist}>
                    Save
                </Button>
                <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>
            </Stack>
        </Stack>
    );

};

export default AffiliateCodeCreatePage;
