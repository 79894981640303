import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import React, {MutableRefObject, useEffect, useState} from "react";
import {Optional} from "../../api/model/util/Optional";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: string,
    options: ReadonlyArray<{ value: string, label: string }>,
    disabled: boolean,
    size: FormSize;
    getValue: MutableRefObject<Optional<() => string>>,
    reset: number
}

const StringOptionsInput2: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, options, disabled, size, getValue, reset} = props

    const [currentValue, setCurrentValue] = useState<string>(value);

    useEffect(() => {
        setCurrentValue(value)
    }, [reset]);

    const handleCurrentValueChange = (event: SelectChangeEvent) => {
        setCurrentValue(event.target.value);
    }

    getValue.current = () => currentValue

    return (
        <Select
            id={id}
            value={currentValue}
            disabled={disabled}
            size={size}
            onChange={handleCurrentValueChange}
        >
            {options.map(option =>
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            )}
        </Select>
    )

};

export default StringOptionsInput2;
