import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {Name} from "./Name";
import {stringCompare} from "./String";

export class Target {

    public readonly name: Name;

    public readonly code: Code;

    constructor(name: Name, code: Code) {
        this.name = name;
        this.code = code;
    }

    public static toData(value: Target): string {
        return value.code.value;
    }

    public static fromData(data: string): Target {
        return Target.fromCode(new Code(data));
    }

    public static fromCode(targetCode: Code): Target {
        const target: Maybe<Target> = CATEGORIES.find(target => target.code.value === targetCode.value);
        if (target === undefined) {
            throw new Error("Unknown target: " + JSON.stringify(targetCode));
        }
        return target;
    }

}

export const WEBAPP: Target = new Target(
    new Name("Webapp"),
    new Code("0"),
);

export const LANDING_PAGE_WEBAPP: Target = new Target(
    new Name("Landing page (Link to Webapp)"),
    new Code("1"),
);

export const LANDING_PAGE_PREREGISTRATION: Target = new Target(
    new Name("Landing page (Registration)"),
    new Code("2"),
);

export const CATEGORIES: ReadonlyArray<Target> = [
    WEBAPP,
    LANDING_PAGE_WEBAPP,
    LANDING_PAGE_PREREGISTRATION,
] as const;

export const TARGET_DATA_MAPPER: DataMapper<Target, string> = {
    toData: Target.toData,
    fromData: Target.fromData
}

export function targetEquals(left: Target, right: Target): boolean {
    return left.code.value === right.code.value;
}

export function targetCompare(left: Target, right: Target): number {
    return stringCompare(left.code.value, right.code.value)
}