import {collection, CollectionReference, doc, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    IdRefsAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {benefits, Benefits, BENEFITS_DATA_MAPPER} from "./value/Benefits";
import {categories, Categories, CATEGORIES_DATA_MAPPER} from "./value/Categories";
import {CONTACT_OPTIONS_DATA_MAPPER, contactOptions, ContactOptions} from "./value/ContactOptions";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {label, Label, LABEL_DATA_MAPPER} from "./value/Label";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {
    NOMINAL_DATE_DATA_MAPPER,
    nominalDate,
    NominalDate,
    nominalDateAfter,
    nominalDateCompare
} from "./value/NominalDate";
import {NOMINAL_TIME_DATA_MAPPER, nominalTime, NominalTime} from "./value/NominalTime";
import {NON_NEGATIVE_INTEGER_DATA_MAPPER, NonNegativeInteger} from "./value/NonNegativeInteger";
import {
    CheckIn,
    participation,
    Participation,
    PARTICIPATION_DATA_MAPPER,
    participationCompare
} from "./value/Participation";
import {POSITIVE_INTEGER_DATA_MAPPER, PositiveInteger} from "./value/PositiveInteger";
import {SOCIAL_MEDIA_PROFILES_DATA_MAPPER, socialMediaProfiles, SocialMediaProfiles} from "./value/SocialMediaProfiles";
import {stringCompare, stringEquals} from "./value/String";
import {websites, Websites, WEBSITES_DATA_MAPPER} from "./value/Websites";

export enum EventState {

    ENABLED = "ENABLED",

    DISABLED = "DISABLED"

}

export type EventPayload = {
    state: EventState,
    name: Name,
    subtitle: Name,
    partner: IdRef<EntityType.PARTNER>,
    location: IdRef<EntityType.LOCATION>,
    description: Label,
    categories: Categories,
    tags: ReadonlyArray<IdRef<EntityType.TAG>>,
    images: ReadonlyArray<IdRef<EntityType.IMAGE>>,
    announcementDays: PositiveInteger,
    participation: Participation,
    eventDate: NominalDate,
    eventTime: NominalTime,
    durationHours: PositiveInteger,
    admittance: Label,
    websites: Websites,
    socialMediaProfiles: SocialMediaProfiles,
    contactOptions: ContactOptions,
    benefits: Benefits,
    valuation: NonNegativeInteger,
    flags: ReadonlyArray<IdRef<EntityType.FLAG>>,
    region: IdRef<EntityType.REGION>
}

export type EventAnchor = EntityAnchor<EntityType.EVENT> & {
    region: IdAlike<EntityType.REGION>
}

export type PreparedEvent = PreparedEntity<EntityType.EVENT> & EventPayload;

export type Event = Entity<EntityType.EVENT> & EventPayload;

export type PersistableEvent = PreparedEvent | Event;

export type EventManager = EntityManager<EntityType.EVENT, EventAnchor, PersistableEvent, Event>;

export const EVENT_STATE_DATA_MAPPER: DataMapper<EventState, string> = {
    toData: (value: EventState) => value,
    fromData: (data: string) => data as EventState
}

export const EVENT_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, EventState, string>(
        "state",
        e => e.state,
        EVENT_STATE_DATA_MAPPER,
        EventState.ENABLED
    );

export const EVENT_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Event")
    );

export const EVENT_SUBTITLE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, Name, string>(
        "subtitle",
        e => e.subtitle,
        NAME_DATA_MAPPER,
        new Name("")
    );

export const EVENT_PARTNER_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.PARTNER, PersistableEvent>(
        EntityType.PARTNER,
        "partner",
        e => e.partner
    );

export const EVENT_LOCATION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.LOCATION, PersistableEvent>(
        EntityType.LOCATION,
        "location",
        e => e.location
    );

export const EVENT_DESCRIPTION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, Label, label>(
        "description",
        e => e.description,
        LABEL_DATA_MAPPER,
        new Label("")
    );

export const EVENT_CATEGORIES_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, Categories, categories>(
        "categories",
        e => e.categories,
        CATEGORIES_DATA_MAPPER,
        new Categories([])
    );

export const EVENT_TAGS_ATTRIBUTE_DEFINITION =
    new IdRefsAttributeDefinition<EntityType.TAG, PersistableEvent>(
        EntityType.TAG,
        "tags",
        e => e.tags
    );

export const EVENT_IMAGES_ATTRIBUTE_DEFINITION =
    new IdRefsAttributeDefinition<EntityType.IMAGE, PersistableEvent>(
        EntityType.IMAGE,
        "images",
        e => e.images
    );

export const EVENT_ANNOUNCEMENT_DAYS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, PositiveInteger, number>(
        "announcementDays",
        e => e.announcementDays,
        POSITIVE_INTEGER_DATA_MAPPER,
        new PositiveInteger(10)
    );

export const EVENT_PARTICIPATION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, Participation, participation>(
        "participation",
        e => e.participation,
        PARTICIPATION_DATA_MAPPER,
        new CheckIn()
    );

export const EVENT_EVENT_DATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, NominalDate, nominalDate>(
        "eventDate",
        e => e.eventDate,
        NOMINAL_DATE_DATA_MAPPER,
        NominalDate.fromLocalDate(new Date())
    );

export const EVENT_EVENT_TIME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, NominalTime, nominalTime>(
        "eventTime",
        e => e.eventTime,
        NOMINAL_TIME_DATA_MAPPER,
        new NominalTime(18, 30)
    );

export const EVENT_DURATION_HOURS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, PositiveInteger, number>(
        "durationHours",
        e => e.durationHours,
        POSITIVE_INTEGER_DATA_MAPPER,
        new NonNegativeInteger(4)
    );

export const EVENT_ADMITTANCE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, Label, label>(
        "admittance",
        e => e.admittance,
        LABEL_DATA_MAPPER,
        new Label("")
    );

export const EVENT_WEBSITES_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, Websites, websites>(
        "websites",
        e => e.websites,
        WEBSITES_DATA_MAPPER,
        new Websites(null)
    );

export const EVENT_SOCIAL_MEDIA_PROFILES_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, SocialMediaProfiles, socialMediaProfiles>(
        "socialMediaProfiles",
        e => e.socialMediaProfiles,
        SOCIAL_MEDIA_PROFILES_DATA_MAPPER,
        new SocialMediaProfiles(null, null)
    );

export const EVENT_CONTACT_OPTIONS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, ContactOptions, contactOptions>(
        "contactOptions",
        e => e.contactOptions,
        CONTACT_OPTIONS_DATA_MAPPER,
        new ContactOptions(null, null, null)
    );

export const EVENT_BENEFITS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, Benefits, benefits>(
        "benefits",
        e => e.benefits,
        BENEFITS_DATA_MAPPER,
        new Benefits([])
    );

export const EVENT_VALUATION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEvent, NonNegativeInteger, number>(
        "valuation",
        e => e.valuation,
        NON_NEGATIVE_INTEGER_DATA_MAPPER,
        new NonNegativeInteger(0)
    );

export const EVENT_FLAGS_ATTRIBUTE_DEFINITION =
    new IdRefsAttributeDefinition<EntityType.FLAG, PersistableEvent>(
        EntityType.FLAG,
        "flags",
        e => e.flags
    );

export const EVENT_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableEvent>(
        EntityType.REGION,
        "region",
        e => e.region
    );


export type EventFilterColumn = EntityFilterColumn | "STATE" | "NAME" | "EVENT_DATE";

export type EventFilterOperation<V> = FilterOperation<EntityType.EVENT, Event, EventFilterColumn, V>;

export type EventFilter<V> = Filter<EntityType.EVENT, Event, EventFilterColumn, V>;

export const EVENT_STATE_EQUALS_FILTER: EventFilterOperation<EventState> = {
    column: "STATE",
    apply: (event: Event, comparisonValue: EventState) => stringEquals(event.state, comparisonValue)
}

export const EVENT_NAME_CONTAINS_FILTER: EventFilterOperation<string> = {
    column: "NAME",
    apply: (event: Event, comparisonValue: string) => nameContains(event.name, comparisonValue)
}

export const EVENT_EVENT_DATE_AFTER_FILTER: EventFilterOperation<Date> = {
    column: "EVENT_DATE",
    apply: (event: Event, comparisonValue: Date) => nominalDateAfter(event.eventDate, comparisonValue)
}

export const EVENT_EVENT_DATE_NOT_AFTER_FILTER: EventFilterOperation<Date> = {
    column: "EVENT_DATE",
    apply: (event: Event, comparisonValue: Date) => !nominalDateAfter(event.eventDate, comparisonValue)
}

export type EventSortColumn = EntitySortColumn | "STATE" | "NAME" | "SUBTITLE" | "PARTICIPATION" | "EVENT_DATE";

export type EventSortOrder = SortOrder<EntityType.EVENT, Event, EventSortColumn>;

export const EVENT_STATE_SORT_ORDER: EventSortOrder = {
    column: "STATE",
    apply: (left: Event, right: Event) => stringCompare(left.state, right.state)
}

export const EVENT_NAME_SORT_ORDER: EventSortOrder = {
    column: "NAME",
    apply: (left: Event, right: Event) => nameCompare(left.name, right.name)
}

export const EVENT_SUBTITLE_SORT_ORDER: EventSortOrder = {
    column: "SUBTITLE",
    apply: (left: Event, right: Event) => nameCompare(left.subtitle, right.subtitle)
}

export const EVENT_PARTICIPATION_SORT_ORDER: EventSortOrder = {
    column: "PARTICIPATION",
    apply: (left: Event, right: Event) => participationCompare(left.participation, right.participation)
}

export const EVENT_EVENT_DATE_SORT_ORDER: EventSortOrder = {
    column: "EVENT_DATE",
    apply: (left: Event, right: Event) => nominalDateCompare(left.eventDate, right.eventDate)
}


class EventDefinition extends EntityDefinition<EntityType.EVENT, EventAnchor, PersistableEvent, Event> {

    constructor() {
        super(
            EntityType.EVENT,
            [
                EVENT_STATE_ATTRIBUTE_DEFINITION,
                EVENT_SUBTITLE_ATTRIBUTE_DEFINITION,
                EVENT_NAME_ATTRIBUTE_DEFINITION,
                EVENT_PARTNER_ATTRIBUTE_DEFINITION,
                EVENT_LOCATION_ATTRIBUTE_DEFINITION,
                EVENT_DESCRIPTION_ATTRIBUTE_DEFINITION,
                EVENT_CATEGORIES_ATTRIBUTE_DEFINITION,
                EVENT_TAGS_ATTRIBUTE_DEFINITION,
                EVENT_IMAGES_ATTRIBUTE_DEFINITION,
                EVENT_ANNOUNCEMENT_DAYS_ATTRIBUTE_DEFINITION,
                EVENT_PARTICIPATION_ATTRIBUTE_DEFINITION,
                EVENT_EVENT_DATE_ATTRIBUTE_DEFINITION,
                EVENT_EVENT_TIME_ATTRIBUTE_DEFINITION,
                EVENT_DURATION_HOURS_ATTRIBUTE_DEFINITION,
                EVENT_ADMITTANCE_ATTRIBUTE_DEFINITION,
                EVENT_WEBSITES_ATTRIBUTE_DEFINITION,
                EVENT_SOCIAL_MEDIA_PROFILES_ATTRIBUTE_DEFINITION,
                EVENT_CONTACT_OPTIONS_ATTRIBUTE_DEFINITION,
                EVENT_BENEFITS_ATTRIBUTE_DEFINITION,
                EVENT_VALUATION_ATTRIBUTE_DEFINITION,
                EVENT_FLAGS_ATTRIBUTE_DEFINITION,
                EVENT_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }


    public getDocId(entity: PersistableEvent): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableEvent): EventAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.EVENT>): EventAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: EventAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.EVENT.toLowerCase());
    }

}

export const EVENT_DEFINITION = new EventDefinition();