import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, {useEffect, useState} from "react";
import {Flag} from "../../api/model/Flag";
import {Categories} from "../../api/model/value/Categories";
import {CATEGORIES} from "../../api/model/value/Category";
import {arrayDown, arrayRemove, arrayUp, findByIdValue} from "../../api/util";
import DialogFormButtons from "../form/DialogFormButtons";
import FormRow from "../form/FormRow";
import {FormSize} from "../form/FormSize";
import StringsOptionsInput from "./StringsOptionsInput";

export interface Props {
    id: string,
    value: ReadonlyArray<Flag>,
    flags: ReadonlyArray<Flag>,
    disabled: boolean,
    size: FormSize,
    setValue: (value: ReadonlyArray<Flag>) => void
}

const FlagsInput: React.FunctionComponent<Props> = (props: Props) => {

    const {
        id,
        value: selectedFlags,
        flags,
        disabled,
        size,
        setValue: setSelectedFlags
    } = props

    return (
        <>
            <StringsOptionsInput
                id={id}
                value={selectedFlags.map(flag => flag.id.value)}
                options={flags.map(flag => {
                    return {value: flag.id.value, label: flag.name.value}
                })}
                disabled={disabled}
                size={size}
                setValue={(flagIds: ReadonlyArray<string>) => setSelectedFlags(flagIds.map(flagId => findByIdValue(flags, flagId)!))}
            />
        </>
    )

};

export default FlagsInput;
