import TextField from "@mui/material/TextField";
import React from "react";
import {Url} from "../../api/model/value/Url";
import {Homepage} from "../../api/model/value/Website";
import {Websites} from "../../api/model/value/Websites";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: Websites,
    disabled: boolean,
    size: FormSize;
    setValue: (value: Websites) => void
}

const WebsitesHomepageInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleHomepageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withHomepage(event.target.value.length === 0 ? null : new Homepage(new Url(event.target.value))));
    }

    return (
        <TextField
            id={id + "-homepage"}
            value={value.homepage ? value.homepage.url.value : ""}
            disabled={disabled}
            size={size}
            onChange={handleHomepageChange}
        />
    )

};

export default WebsitesHomepageInput;
