import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Optional} from "../../../../api/model/util/Optional";
import {Name} from "../../../../api/model/value/Name";
import {NominalDate} from "../../../../api/model/value/NominalDate";
import {PassDuration, SEVEN_DAYS} from "../../../../api/model/value/PassDuration";
import {PositiveInteger} from "../../../../api/model/value/PositiveInteger";
import {Voucher, VoucherPayload, VoucherState} from "../../../../api/model/Voucher";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import NameInput2 from "../../../input/NameInput2";
import NominalDateInput2 from "../../../input/NominalDateInput2";
import PassDurationInput2 from "../../../input/PassDurationInput2";
import PositiveIntegerInput2 from "../../../input/PositiveIntegerInput2";
import VoucherStateInput2 from "../../../input/VoucherStateInput2";
import StringOutput from "../../../output/StringOutput";

export type VoucherCore = Pick<VoucherPayload, "state" | "name" | "amount" | "passDuration" | "validUntil">;

export interface Props {
    voucher?: Voucher,
    disabled: boolean,
    created: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => VoucherCore>>,
    reset: number
}

const VoucherCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {voucher, disabled, created, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => VoucherState>> = useRef<Optional<() => VoucherState>>(null)
    const getNameRef: MutableRefObject<Optional<() => Name>> = useRef<Optional<() => Name>>(null)
    const getAmountRef: MutableRefObject<Optional<() => PositiveInteger>> = useRef<Optional<() => PositiveInteger>>(null)
    const getPassDurationRef: MutableRefObject<Optional<() => PassDuration>> = useRef<Optional<() => PassDuration>>(null)
    const getValidUntilRef: MutableRefObject<Optional<() => NominalDate>> = useRef<Optional<() => NominalDate>>(null)

    getValue.current = () => ({
        state: getStateRef.current!(),
        name: getNameRef.current!(),
        amount: getAmountRef.current!(),
        passDuration: getPassDurationRef.current!(),
        validUntil: getValidUntilRef.current!()
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {voucher?.id && <FormRow label="Id">
                    <StringOutput
                        value={voucher.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <VoucherStateInput2
                        id="state"
                        value={voucher?.state || VoucherState.ENABLED}
                        disabled={disabled}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Name">
                    <NameInput2
                        id="name"
                        value={voucher?.name || new Name("")}
                        disabled={disabled}
                        size={size}
                        getValue={getNameRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Amount">
                    <PositiveIntegerInput2
                        id="amount"
                        value={voucher?.amount || new PositiveInteger(100)}
                        max={new PositiveInteger(250)}
                        adornmentEnd={"vouchers"}
                        disabled={disabled || created}
                        size={size}
                        getValue={getAmountRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Pass Duration">
                    <PassDurationInput2
                        id="duration"
                        value={voucher?.passDuration || SEVEN_DAYS}
                        disabled={disabled}
                        size={size}
                        getValue={getPassDurationRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Valid Until">
                    <NominalDateInput2
                        id="valid-until"
                        value={voucher?.validUntil || new NominalDate(2022, 12, 31)}
                        disabled={disabled}
                        size={size}
                        getValue={getValidUntilRef}
                        reset={reset}
                    />
                </FormRow>
            </Grid>
        </>
    );

};

export default VoucherCoreInput;
