import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React, {useState} from "react";
import {EntityState} from "../../../api/model/Entity";
import {Location} from "../../../api/model/Location";
import {useProject} from "../../../context/ProjectProvider";
import DeleteButton from "../../form/DeleteButton";
import RemoveButton from "../../form/RemoveButton";
import RestoreButton from "../../form/RestoreButton";
import LocationInput, {LocationInputResult} from "./LocationInput";

export interface Props {
    location: Location,
    onDelete: () => void
    onClose?: () => void
}

const LocationUpdateForm: React.FunctionComponent<Props> = (props: Props) => {

    const {location: initialLocation, onDelete, onClose} = props

    const locationManager = useProject().locationManager;
    const [editing, setEditing] = useState<boolean>(false);
    const [latestLocation, setLatestLocation] = useState<Location>(initialLocation)
    const [currentLocation, setCurrentLocation] = useState<Location>(initialLocation)

    const handleEdit = () => {
        setEditing(true);
        setCurrentLocation({...latestLocation});
    }

    const handleCancel = () => {
        setEditing(false);
        setCurrentLocation(latestLocation);
    }

    const handlePersist = async (locationInputResult: LocationInputResult) => {
        setEditing(false);
        const updatedLocation = await locationManager.persist({...latestLocation, ...locationInputResult});
        setLatestLocation(updatedLocation);
        setCurrentLocation(updatedLocation);
    }

    const handleRemove = async () => {
        const updatedLocation = await locationManager.removeById(latestLocation);
        setLatestLocation(updatedLocation);
        setCurrentLocation(updatedLocation);
    }

    const handleRestore = async () => {
        const updatedLocation = await locationManager.restoreById(latestLocation);
        setLatestLocation(updatedLocation);
        setCurrentLocation(updatedLocation);
    }

    const handleDelete = async () => {
        await locationManager.deleteById(latestLocation);
        onDelete();
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <LocationInput
                location={currentLocation}
                disabled={!editing}
                size="small"
                onPersist={currentLocation.entityState === EntityState.CREATED ? handlePersist : undefined}
            />
            <Stack direction={"row"} spacing={1}>
                {editing && <>
                    <Button variant={"outlined"} onClick={handleCancel}>
                        Cancel
                    </Button>
                </>}
                {!editing && <>
                    {currentLocation.entityState === EntityState.CREATED &&
                        <Button variant={"contained"} onClick={handleEdit}>
                            Edit
                        </Button>}
                    {currentLocation.entityState === EntityState.REMOVED &&
                        <DeleteButton disabled={false} onDelete={handleDelete}/>}
                    {currentLocation.entityState === EntityState.REMOVED &&
                        <RestoreButton disabled={false} onRestore={handleRestore}/>}
                    {currentLocation.entityState === EntityState.CREATED &&
                        <RemoveButton disabled={false} onRemove={handleRemove}/>}
                    {onClose && <Button variant={"outlined"} onClick={handleClose}>
                        Close
                    </Button>}
                </>}
            </Stack>
        </Stack>
    );

};

export default LocationUpdateForm;
