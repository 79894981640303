import React from 'react';
import LocationSlicerProvider from "../component/entity/location/LocationSlicerProvider";
import LocationsPage from "../component/entity/location/LocationsPage";

function LocationRoute() {

    return (
        <LocationSlicerProvider>
            <LocationsPage/>
        </LocationSlicerProvider>
    );

}

export default LocationRoute;
