import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {CountryCode} from "./CountryCode";
import {Name} from "./Name";

export class AffiliationState {

    public readonly name: Name;

    public readonly code: CountryCode;

    constructor(name: Name, code: Code) {
        this.name = name;
        this.code = code;
    }

    public static toData(value: AffiliationState): string {
        return value.code.value;
    }

    public static fromData(data: string): AffiliationState {
        return AffiliationState.fromCode(new Code(data));
    }

    public static fromCode(affiliationStateCode: Code): AffiliationState {
        const affiliationState: Maybe<AffiliationState> = AFFILIATION_STATES.find(affiliationState => affiliationState.code.value === affiliationStateCode.value);
        if (affiliationState === undefined) {
            throw new Error("Unknown affiliation state: " + JSON.stringify(affiliationStateCode));
        }
        return affiliationState;
    }

}

export const INELIGIBLE: AffiliationState = new AffiliationState(
    new Name("Ineligible"),
    new Code("INELIGIBLE")
);

export const ELIGIBLE: AffiliationState = new AffiliationState(
    new Name("Eligible"),
    new Code("ELIGIBLE")
);

export const PENDING: AffiliationState = new AffiliationState(
    new Name("Pending"),
    new Code("PENDING")
);

export const DISBURSED: AffiliationState = new AffiliationState(
    new Name("Disbursed"),
    new Code("DISBURSED")
);

export const AFFILIATION_STATES: ReadonlyArray<AffiliationState> = [
    INELIGIBLE,
    ELIGIBLE,
    PENDING,
    DISBURSED
] as const;

export const AFFILIATION_STATE_DATA_MAPPER: DataMapper<AffiliationState, string> = {
    toData: AffiliationState.toData,
    fromData: AffiliationState.fromData
}

export function affiliationStateEquals(left: AffiliationState, right: AffiliationState): boolean {
    return left.code.value === right.code.value;
}

