import {Timestamp} from "@firebase/firestore";
import {DataMapper} from "../Entity";

export const DATE_DATA_MAPPER: DataMapper<Date, Timestamp> = {
    toData: Timestamp.fromDate,
    fromData: (data: Timestamp) => data.toDate()
}

export function dateAfter(haystack: Date, needle: Date): boolean {
    return haystack.getTime() < needle.getTime();
}

export function dateCompare(left: Date, right: Date): number {
    return left.getTime() - right.getTime();
}