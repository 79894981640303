import React from "react";
import {BundleState} from "../../api/model/Bundle";
import {Optional} from "../../api/model/util/Optional";
import {FormSize} from "../form/FormSize";
import OptionalStringOptionsInput from "./OptionalStringOptionsInput";

export interface Props {
    id: string,
    value: Optional<BundleState>,
    disabled: boolean,
    size: FormSize;
    setValue: (value: Optional<BundleState>) => void
}

const OptionalBundleStateInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <OptionalStringOptionsInput
            id={id}
            value={value ?? ""}
            options={Object.keys(BundleState).map(state => {
                return {value: state, label: state}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: Optional<string>) => setValue(value as BundleState)}
        />
    )

};

export default OptionalBundleStateInput;
