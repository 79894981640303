import React, {useEffect, useState} from "react";
import {Partner} from "../../api/model/Partner";
import {findByIdValue} from "../../api/util";
import {FormSize} from "../form/FormSize";
import StringOptionsInput, {Options, sortOptions} from "./StringOptionsInput";

export interface Props {
    id: string,
    value: Partner,
    disabled: boolean,
    partners: ReadonlyArray<Partner>,
    size: FormSize;
    setValue: (value: Partner) => void
}

const PartnerInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, partners, size, setValue} = props

    const [options, setOptions] = useState<Options>([]);

    useEffect(() => {
        setOptions(sortOptions(partners.map(partner => {
            return {value: partner.id.value, label: partner.name.value}
        })));
    }, [partners]);

    return (
        <StringOptionsInput
            id={id}
            value={value.id.value}
            options={options}
            disabled={disabled}
            size={size}
            setValue={(value: string) => setValue(findByIdValue(partners, value)!)}
        />
    )

};

export default PartnerInput;
