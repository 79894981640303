import TextField from "@mui/material/TextField";
import React from "react";
import {Name} from "../../api/model/value/Name";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: Name,
    disabled: boolean,
    size: FormSize;
    setValue: (value: Name) => void
}

const NameInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(new Name(event.target.value));
    }

    return (
        <TextField
            id={id}
            value={value.value}
            disabled={disabled}
            size={size}
            onChange={handleChange}
        />
    )

};

export default NameInput;
