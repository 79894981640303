import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";
import {Address} from "../../api/model/value/Address";
import {Country} from "../../api/model/value/Country";
import {FormSize} from "../form/FormSize";
import CountryInput from "./CountryInput";

export interface Props {
    id: string,
    value: Address,
    disabled: boolean,
    size: FormSize;
    setValue: (value: Address) => void
}

const AddressInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleStreetChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withStreet(event.target.value));
    }

    const handleStreetNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withStreetNumber(event.target.value));
    }

    const handleCityChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withCity(event.target.value));
    }

    const handleZipChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withZip(event.target.value));
    }

    const handleCountryChange = (country: Country) => {
        setValue(value.withCountry(country));
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Stack direction="column">
                    Street
                    <TextField
                        id={id + "-street"}
                        value={value.street}
                        disabled={disabled}
                        size={size}
                        onChange={handleStreetChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction="column">
                    Street Number
                    <TextField
                        id={id + "-street-number"}
                        value={value.streetNumber}
                        disabled={disabled}
                        size={size}
                        onChange={handleStreetNumberChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={5}>
                <Stack direction="column">
                    City
                    <TextField
                        id={id + "-city"}
                        value={value.city}
                        disabled={disabled}
                        size={size}
                        onChange={handleCityChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction="column">
                    ZIP
                    <TextField
                        id={id + "-zip"}
                        value={value.zip}
                        disabled={disabled}
                        size={size}
                        onChange={handleZipChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack direction="column">
                    Country
                    <CountryInput
                        id={id + "-country"}
                        value={value.country}
                        disabled={disabled} size={size}
                        setValue={handleCountryChange}
                    />
                </Stack>
            </Grid>
        </Grid>
    )

};

export default AddressInput;
