export class Flag {

    public readonly value: string;

    constructor(value: string) {
        this.value = value;
    }

}

