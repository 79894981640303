import TextField from "@mui/material/TextField";
import React from "react";
import {Comment} from "../../api/model/value/Comment";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: Comment,
    disabled: boolean,
    size: FormSize;
    setValue: (value: Comment) => void
}

const CommentInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(new Comment(event.target.value));
    }

    return (
        <TextField
            id={id}
            value={value.value}
            multiline
            rows={6}
            disabled={disabled}
            size={size}
            onChange={handleChange}
        />
    )

};

export default CommentInput;
