import {DataMapper} from "../Entity";

export type storageData = {
    bucket: string;
    fullPath: string;
    name: string;
    size: number;
}

export class StorageData {

    public readonly bucket: string;

    public readonly fullPath: string;

    public readonly name: string;

    public readonly size: number;

    constructor(bucket: string, fullPath: string, name: string, size: number) {
        this.bucket = bucket;
        this.fullPath = fullPath;
        this.name = name;
        this.size = size;
    }

    public static toData(value: StorageData): storageData {
        return {
            bucket: value.bucket,
            fullPath: value.fullPath,
            name: value.name,
            size: value.size
        };
    }

    static fromData(data: storageData): StorageData {
        return new StorageData(
            data.bucket,
            data.fullPath,
            data.name,
            data.size
        );
    }

}

export const STORAGE_DATA_DATA_MAPPER: DataMapper<StorageData, storageData> = {
    toData: StorageData.toData,
    fromData: StorageData.fromData
}

