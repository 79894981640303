import {DataMapper} from "../Entity";
import {Category} from "./Category";

export type categories = string[]

export class Categories {

    public readonly items: ReadonlyArray<Category>;

    constructor(items: ReadonlyArray<Category>) {
        this.items = items;
    }

    public static toData(value: Categories): categories {
        return value.items.map(item => Category.toData(item));
    }

    static fromData(data: categories): Categories {
        if(data) {
            return new Categories(
                data.map(datum => Category.fromData(datum))
            );
        } else {
            return new Categories([]);
        }
    }

}

export const CATEGORIES_DATA_MAPPER: DataMapper<Categories, categories> = {
    toData: Categories.toData,
    fromData: Categories.fromData
}
