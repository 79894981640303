import {collection, CollectionReference, doc, Firestore, Timestamp} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike, optionalCompare} from "../util";
import {AFFILIATE_STATE_DATA_MAPPER, AffiliateState} from "./Affiliate";
import {AFFILIATE_CODE_STATE_DATA_MAPPER, AffiliateCodeState} from "./AffiliateCode";
import {
    DataMapper,
    Entity, ENTITY_STATE_DATA_MAPPER,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn, EntityState,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    OptionalIdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SimpleOptionalAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {AFFILIATE_CODE_ID_DATA_MAPPER, AffiliateCodeId, affiliateCodeIdCompare} from "./value/AffiliateCodeId";
import {AFFILIATE_ID_DATA_MAPPER, AffiliateId} from "./value/AffiliateId";
import {AFFILIATION_STATE_DATA_MAPPER, AffiliationState, affiliationStateEquals} from "./value/AffiliationState";
import {Currency, CURRENCY_DATA_MAPPER, currencyCompare} from "./value/Currency";
import {CUSTOMER_ID_DATA_MAPPER, CustomerId} from "./value/CustomerId";
import {DATE_DATA_MAPPER, dateCompare} from "./value/Date";
import {EMAIL_ADDRESS_DATA_MAPPER, EmailAddress, emailAddressContains} from "./value/EmailAddress";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {
    NON_NEGATIVE_INTEGER_DATA_MAPPER,
    NonNegativeInteger,
    nonNegativeIntegerCompare
} from "./value/NonNegativeInteger";
import {PASS_DURATION_DATA_MAPPER, PassDuration, passDurationCompare} from "./value/PassDuration";
import {PAYMENT_STATE_DATA_MAPPER, PaymentState} from "./value/PaymentState";
import {PAYMENT_TYPE_DATA_MAPPER, PaymentType, paymentTypeCompare} from "./value/PaymentType";
import {PURCHASE_ID_DATA_MAPPER, PurchaseId} from "./value/PurchaseId";
import {stringCompare, stringEquals} from "./value/String";

export enum PurchaseState {

    APPROVED = "APPROVED",

    ACTIVATED = "ACTIVATED",

    EXPIRED = "EXPIRED",

    CANCELLED = "CANCELLED"

}

export type PurchasePayload = {
    state: PurchaseState,
    purchaseId: PurchaseId,
    approvedAt: Date,
    activatedAt: Optional<Date>,
    activeUntil: Optional<Date>,
    expiredAt: Optional<Date>,
    cancellableUntil: Date,
    cancelledAt: Optional<Date>,
    paymentType: PaymentType,
    paymentId: Optional<Name>,
    paymentStatus: Optional<Name>,
    paymentState: PaymentState,
    price: Optional<NonNegativeInteger>,
    currency: Optional<Currency>,
    eligibleFrom: Date,
    affiliatedAt: Optional<Date>,
    affiliateCodeId: Optional<AffiliateCodeId>,
    affiliateCode: Optional<IdRef<EntityType.AFFILIATE_CODE>>,
    affiliateCodeName: Optional<Name>,
    affiliateCodeState: Optional<AffiliateCodeState>,
    affiliateCodeEntityState: Optional<EntityState>,
    affiliateId: Optional<AffiliateId>,
    affiliate: Optional<IdRef<EntityType.AFFILIATE>>,
    affiliateName: Optional<Name>,
    affiliateState: Optional<AffiliateState>,
    affiliateEntityState: Optional<EntityState>,
    affiliationState: AffiliationState,
    customer: IdRef<EntityType.CUSTOMER>,
    customerId: CustomerId,
    customerName: Name,
    customerEmailAddress: EmailAddress,
    duration: PassDuration,
    region: IdRef<EntityType.REGION>
}

export type PurchaseAnchor = EntityAnchor<EntityType.PURCHASE> & {
    region: IdAlike<EntityType.REGION>
};

export type PreparedPurchase = PreparedEntity<EntityType.PURCHASE> & PurchasePayload;

export type Purchase = Entity<EntityType.PURCHASE> & PurchasePayload;

export type PersistablePurchase = PreparedPurchase | Purchase;

export type PurchaseManager = EntityManager<EntityType.PURCHASE, PurchaseAnchor, PersistablePurchase, Purchase>;

export const PURCHASE_STATE_DATA_MAPPER: DataMapper<PurchaseState, string> = {
    toData: (value: PurchaseState) => value,
    fromData: (data: string) => data as PurchaseState
}

export const PURCHASE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, PurchaseState, string>(
        "state",
        e => e.state,
        PURCHASE_STATE_DATA_MAPPER,
        null
    );

export const PURCHASE_ID_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, PurchaseId, string>(
        "purchaseId",
        e => e.purchaseId,
        PURCHASE_ID_DATA_MAPPER,
        null
    );

export const PURCHASE_APPROVED_AT_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, Date, Timestamp>(
        "approvedAt",
        e => e.approvedAt,
        DATE_DATA_MAPPER,
        null
    );

export const PURCHASE_ACTIVATED_AT_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Date, Timestamp>(
        "activatedAt",
        e => e.activatedAt,
        DATE_DATA_MAPPER
    );

export const PURCHASE_ACTIVE_UNTIL_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Date, Timestamp>(
        "activeUntil",
        e => e.activeUntil,
        DATE_DATA_MAPPER
    );

export const PURCHASE_EXPIRED_AT_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Date, Timestamp>(
        "expiredAt",
        e => e.expiredAt,
        DATE_DATA_MAPPER
    );

export const PURCHASE_CANCELABLE_UNTIL_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, Date, Timestamp>(
        "cancellableUntil",
        e => e.cancellableUntil,
        DATE_DATA_MAPPER,
        null
    );

export const PURCHASE_CANCELLED_AT_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Date, Timestamp>(
        "cancelledAt",
        e => e.cancelledAt,
        DATE_DATA_MAPPER
    );

export const PURCHASE_PAYMENT_TYPE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, PaymentType, string>(
        "paymentType",
        e => e.paymentType,
        PAYMENT_TYPE_DATA_MAPPER,
        null
    );

export const PURCHASE_PAYMENT_ID_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Name, string>(
        "paymentId",
        e => e.paymentId,
        NAME_DATA_MAPPER
    );

export const PURCHASE_PAYMENT_STATUS_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Name, string>(
        "paymentStatus",
        e => e.paymentStatus,
        NAME_DATA_MAPPER
    );

export const PURCHASE_PAYMENT_STATE_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, PaymentState, string>(
        "paymentState",
        e => e.paymentState,
        PAYMENT_STATE_DATA_MAPPER
    );

export const PURCHASE_PRICE_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, NonNegativeInteger, number>(
        "price",
        e => e.price,
        NON_NEGATIVE_INTEGER_DATA_MAPPER
    );

export const PURCHASE_CURRENCY_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Currency, string>(
        "currency",
        e => e.currency,
        CURRENCY_DATA_MAPPER
    );

export const PURCHASE_ELIGIBLE_FROM_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, Date, Timestamp>(
        "eligibleFrom",
        e => e.eligibleFrom,
        DATE_DATA_MAPPER,
        null
    );

export const PURCHASE_AFFILIATED_AT_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Date, Timestamp>(
        "affiliatedAt",
        e => e.affiliatedAt,
        DATE_DATA_MAPPER
    );

export const PURCHASE_AFFILIATE_CODE_ID_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, AffiliateCodeId, string>(
        "affiliateCodeId",
        e => e.affiliateCodeId,
        AFFILIATE_CODE_ID_DATA_MAPPER,
    );

export const PURCHASE_AFFILIATE_CODE_ATTRIBUTE_DEFINITION =
    new OptionalIdRefAttributeDefinition<EntityType.AFFILIATE_CODE, PersistablePurchase>(
        EntityType.AFFILIATE_CODE,
        "affiliateCode",
        e => e.affiliateCode
    );

export const PURCHASE_AFFILIATE_CODE_NAME_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Name, string>(
        "affiliateCodeName",
        e => e.affiliateCodeName,
        NAME_DATA_MAPPER
    );

export const PURCHASE_AFFILIATE_CODE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, AffiliateCodeState, string>(
        "affiliateCodeState",
        e => e.affiliateCodeState,
        AFFILIATE_CODE_STATE_DATA_MAPPER
    );

export const PURCHASE_AFFILIATE_CODE_ENTITY_STATE_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, EntityState, string>(
        "affiliateCodeEntityState",
        e => e.affiliateCodeEntityState,
        ENTITY_STATE_DATA_MAPPER
    );

export const PURCHASE_AFFILIATE_ID_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, AffiliateId, string>(
        "affiliateId",
        e => e.affiliateId,
        AFFILIATE_ID_DATA_MAPPER,
    );

export const PURCHASE_AFFILIATE_ATTRIBUTE_DEFINITION =
    new OptionalIdRefAttributeDefinition<EntityType.AFFILIATE, PersistablePurchase>(
        EntityType.AFFILIATE,
        "affiliate",
        e => e.affiliate
    );

export const PURCHASE_AFFILIATE_NAME_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, Name, string>(
        "affiliateName",
        e => e.affiliateName,
        NAME_DATA_MAPPER
    );

export const PURCHASE_AFFILIATE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, AffiliateState, string>(
        "affiliateState",
        e => e.affiliateState,
        AFFILIATE_STATE_DATA_MAPPER
    );

export const PURCHASE_AFFILIATE_ENTITY_STATE_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistablePurchase, EntityState, string>(
        "affiliateEntityState",
        e => e.affiliateEntityState,
        ENTITY_STATE_DATA_MAPPER
    );

export const PURCHASE_AFFILIATION_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, AffiliationState, string>(
        "affiliationState",
        e => e.affiliationState,
        AFFILIATION_STATE_DATA_MAPPER,
        null
    );

export const PURCHASE_CUSTOMER_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.CUSTOMER, PersistablePurchase>(
        EntityType.CUSTOMER,
        "customer",
        e => e.customer
    );

export const PURCHASE_CUSTOMER_ID_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, CustomerId, string>(
        "customerId",
        e => e.customerId,
        CUSTOMER_ID_DATA_MAPPER,
        null
    );

export const PURCHASE_CUSTOMER_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, Name, string>(
        "customerName",
        e => e.customerName,
        NAME_DATA_MAPPER,
        null
    );

export const PURCHASE_DURATION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, PassDuration, string>(
        "duration",
        e => e.duration,
        PASS_DURATION_DATA_MAPPER,
        null
    );

export const PURCHASE_CUSTOMER_EMAIL_ADDRESS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePurchase, EmailAddress, string>(
        "customerEmailAddress",
        e => e.customerEmailAddress,
        EMAIL_ADDRESS_DATA_MAPPER,
        null
    );

export const PURCHASE_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistablePurchase>(
        EntityType.REGION,
        "region",
        e => e.region
    );

export type PurchaseFilterColumn = EntityFilterColumn | "STATE" |  "AFFILIATION_STATE" | "CUSTOMER_NAME" | "CUSTOMER_EMAIL_ADDRESS";

export type PurchaseFilterOperation<V> = FilterOperation<EntityType.PURCHASE, Purchase, PurchaseFilterColumn, V>;

export type PurchaseFilter<V> = Filter<EntityType.PURCHASE, Purchase, PurchaseFilterColumn, V>;

export const PURCHASE_STATE_EQUALS_FILTER: PurchaseFilterOperation<PurchaseState> = {
    column: "STATE",
    apply: (purchase: Purchase, comparisonValue: PurchaseState) => stringEquals(purchase.state, comparisonValue)
}

export const PURCHASE_AFFILIATION_STATE_EQUALS_FILTER: PurchaseFilterOperation<AffiliationState> = {
    column: "AFFILIATION_STATE",
    apply: (purchase: Purchase, comparisonValue: AffiliationState) => affiliationStateEquals(purchase.affiliationState, comparisonValue)
}

export const PURCHASE_CUSTOMER_NAME_CONTAINS_FILTER: PurchaseFilterOperation<string> = {
    column: "CUSTOMER_NAME",
    apply: (purchase: Purchase, comparisonValue: string) => nameContains(purchase.customerName, comparisonValue)
}

export const PURCHASE_CUSTOMER_EMAIL_ADDRESS_CONTAINS_FILTER: PurchaseFilterOperation<string> = {
    column: "CUSTOMER_EMAIL_ADDRESS",
    apply: (purchase: Purchase, comparisonValue: string) => emailAddressContains(purchase.customerEmailAddress, comparisonValue)
}

export type PurchaseSortColumn =
    EntitySortColumn
    | "STATE"
    | "CUSTOMER_NAME"
    | "APPROVED_AT"
    | "PAYMENT_TYPE"
    | "PAYMENT_ID"
    | "PRICE"
    | "CURRENCY"
    | "DURATION"
    | "AFFILIATE_CODE_ID";

export type PurchaseSortOrder = SortOrder<EntityType.PURCHASE, Purchase, PurchaseSortColumn>;

export const PURCHASE_STATE_SORT_ORDER: PurchaseSortOrder = {
    column: "STATE",
    apply: (left: Purchase, right: Purchase) => stringCompare(left.state, right.state)
}

export const PURCHASE_CUSTOMER_NAME_SORT_ORDER: PurchaseSortOrder = {
    column: "CUSTOMER_NAME",
    apply: (left: Purchase, right: Purchase) => nameCompare(left.customerName, right.customerName)
}

export const PURCHASE_APPROVED_AT_SORT_ORDER: PurchaseSortOrder = {
    column: "APPROVED_AT",
    apply: (left: Purchase, right: Purchase) => dateCompare(left.approvedAt, right.approvedAt)
}

export const PURCHASE_PAYMENT_TYPE_SORT_ORDER: PurchaseSortOrder = {
    column: "PAYMENT_TYPE",
    apply: (left: Purchase, right: Purchase) => paymentTypeCompare(left.paymentType, right.paymentType)
}

export const PURCHASE_PAYMENT_ID_SORT_ORDER: PurchaseSortOrder = {
    column: "PAYMENT_ID",
    apply: (left: Purchase, right: Purchase) => optionalCompare(nameCompare, left.paymentId, right.paymentId)
}

export const PURCHASE_PRICE_SORT_ORDER: PurchaseSortOrder = {
    column: "PRICE",
    apply: (left: Purchase, right: Purchase) => optionalCompare(nonNegativeIntegerCompare, left.price, right.price)
}

export const PURCHASE_CURRENCY_SORT_ORDER: PurchaseSortOrder = {
    column: "CURRENCY",
    apply: (left: Purchase, right: Purchase) => optionalCompare(currencyCompare, left.currency, right.currency)
}

export const PURCHASE_DURATION_SORT_ORDER: PurchaseSortOrder = {
    column: "DURATION",
    apply: (left: Purchase, right: Purchase) => passDurationCompare(left.duration, right.duration)
}

export const PURCHASE_AFFILIATE_CODE_ID_SORT_ORDER: PurchaseSortOrder = {
    column: "AFFILIATE_CODE_ID",
    apply: (left: Purchase, right: Purchase) => optionalCompare(affiliateCodeIdCompare, left.affiliateCodeId, right.affiliateCodeId)
}

class PurchaseDefinition extends EntityDefinition<EntityType.PURCHASE, PurchaseAnchor, PersistablePurchase, Purchase> {

    constructor() {
        super(
            EntityType.PURCHASE,
            [
                PURCHASE_STATE_ATTRIBUTE_DEFINITION,
                PURCHASE_ID_ATTRIBUTE_DEFINITION,
                PURCHASE_APPROVED_AT_ATTRIBUTE_DEFINITION,
                PURCHASE_ACTIVATED_AT_ATTRIBUTE_DEFINITION,
                PURCHASE_ACTIVE_UNTIL_ATTRIBUTE_DEFINITION,
                PURCHASE_EXPIRED_AT_ATTRIBUTE_DEFINITION,
                PURCHASE_CANCELABLE_UNTIL_ATTRIBUTE_DEFINITION,
                PURCHASE_CANCELLED_AT_ATTRIBUTE_DEFINITION,
                PURCHASE_PAYMENT_TYPE_ATTRIBUTE_DEFINITION,
                PURCHASE_PAYMENT_ID_ATTRIBUTE_DEFINITION,
                PURCHASE_PAYMENT_STATUS_ATTRIBUTE_DEFINITION,
                PURCHASE_PAYMENT_STATE_ATTRIBUTE_DEFINITION,
                PURCHASE_PRICE_ATTRIBUTE_DEFINITION,
                PURCHASE_CURRENCY_ATTRIBUTE_DEFINITION,
                PURCHASE_ELIGIBLE_FROM_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATED_AT_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_CODE_ID_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_CODE_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_CODE_NAME_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_CODE_STATE_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_CODE_ENTITY_STATE_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_ID_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_NAME_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_STATE_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATE_ENTITY_STATE_ATTRIBUTE_DEFINITION,
                PURCHASE_AFFILIATION_STATE_ATTRIBUTE_DEFINITION,
                PURCHASE_CUSTOMER_ATTRIBUTE_DEFINITION,
                PURCHASE_CUSTOMER_ID_ATTRIBUTE_DEFINITION,
                PURCHASE_CUSTOMER_NAME_ATTRIBUTE_DEFINITION,
                PURCHASE_CUSTOMER_EMAIL_ADDRESS_ATTRIBUTE_DEFINITION,
                PURCHASE_DURATION_ATTRIBUTE_DEFINITION,
                PURCHASE_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistablePurchase): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistablePurchase): PurchaseAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.PURCHASE>): PurchaseAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: PurchaseAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.PURCHASE.toLowerCase());
    }

}

export const PURCHASE_DEFINITION = new PurchaseDefinition();