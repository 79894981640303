import Grid from "@mui/material/Grid";
import React, {MutableRefObject, useRef} from "react";
import {Optional} from "../../api/model/util/Optional";
import {Address} from "../../api/model/value/Address";
import {LegalEntity} from "../../api/model/value/LegalEntity";
import {LegalEntityType} from "../../api/model/value/LegalEntityType";
import {Name} from "../../api/model/value/Name";
import FormRow from "../form/FormRow";
import {FormSize} from "../form/FormSize";
import AddressInput2 from "./AddressInput2";
import LegalEntityTypeInput2 from "./LegalEntityTypeInput2";
import NameInput2 from "./NameInput2";

export interface Props {
    id: string,
    value: LegalEntity,
    disabled: boolean,
    size: FormSize;
    getValue: MutableRefObject<Optional<() => LegalEntity>>,
    reset: number
}

const LegalEntityInput2: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, getValue, reset} = props

    const getTypeRef: MutableRefObject<Optional<() => LegalEntityType>> = useRef<Optional<() => LegalEntityType>>(null);
    const getNameRef: MutableRefObject<Optional<() => Name>> = useRef<Optional<() => Name>>(null);
    const getAddressRef: MutableRefObject<Optional<() => Address>> = useRef<Optional<() => Address>>(null);

    getValue.current = () => new LegalEntity(getTypeRef.current!(), getNameRef.current!(), getAddressRef.current!());

    return (
        <>
            <>
                <Grid container spacing={3} maxWidth={1000}>
                    <FormRow label="Type">
                        <LegalEntityTypeInput2
                            id={id+"-type"}
                            value={value.type}
                            disabled={disabled}
                            size={size}
                            getValue={getTypeRef}
                            reset={reset}
                        />
                    </FormRow>
                    <FormRow label="Name">
                        <NameInput2
                            id={id+"-name"}
                            value={value.name}
                            disabled={disabled}
                            size={size}
                            getValue={getNameRef}
                            reset={reset}
                        />
                    </FormRow>
                    <FormRow label="Address">
                        <AddressInput2
                            id={id+"-address"}
                            value={value.address}
                            disabled={disabled}
                            size={size}
                            getValue={getAddressRef}
                            reset={reset}
                        />
                    </FormRow>
                </Grid>
            </>
        </>

    )

};

export default LegalEntityInput2;
