import {DataMapper} from "../Entity";
import {benefitTypes, BenefitTypes} from "./BenfitTypes";
import {Label, label} from "./Label";

export type benefit = {
    types: benefitTypes;
    description: label;
}

export class Benefit {

    public readonly types: BenefitTypes;

    public readonly description: Label;

    constructor(types: BenefitTypes, description: Label) {
        this.types = types;
        this.description = description;
    }

    public withTypes(types: BenefitTypes): Benefit {
        return new Benefit(types, this.description);
    }

    public withDescription(description: Label): Benefit {
        return new Benefit(this.types, description);
    }

    public static toData(value: Benefit): benefit {
        return {
            types: BenefitTypes.toData(value.types),
            description: Label.toData(value.description)
        }
    }

    public static fromData(data: benefit): Benefit {
        return new Benefit(
            BenefitTypes.fromData(data.types),
            Label.fromData(data.description)
        )
    }

}

export const BENEFIT_DATA_MAPPER: DataMapper<Benefit, benefit> = {
    toData: Benefit.toData,
    fromData: Benefit.fromData
}

