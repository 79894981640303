import TextField from "@mui/material/TextField";
import React from "react";
import {VoucherCodeId} from "../../api/model/value/VoucherCodeId";
import {FormSize} from "../form/FormSize";

export interface Props {
    value: string,
    size: FormSize;
}

const StringOutput: React.FunctionComponent<Props> = (props: Props) => {

    const {value, size} = props

    return (
        <TextField
            value={value}
            disabled={true}
            size={size}
        />
    )

};

export default StringOutput;
