import {DataMapper} from "../Entity";
import {contact, Contact} from "./Contact";

export type contacts = contact[];

export class Contacts {

    public readonly items: ReadonlyArray<Contact>;

    constructor(items: ReadonlyArray<Contact>) {
        this.items = items;
    }

    public static toData(value: Contacts): contacts {
        return value.items.map(item => Contact.toData(item));
    }

    static fromData(data: contacts): Contacts {
        return new Contacts(
            data.map(datum => Contact.fromData(datum))
        );
    }

}

export const CONTACTS_DATA_MAPPER: DataMapper<Contacts, contacts> = {
    toData: Contacts.toData,
    fromData: Contacts.fromData
}
