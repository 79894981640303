import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, {useState} from 'react';
import {Optional} from "../../api/model/util/Optional";
import FormRow from "../../component/form/FormRow";
import PasswordInput from "../../component/input/PasswordInput";
import {useLoggedIn} from "../../context/AuthProvider";

function ProfilePage() {

    const loggedIn = useLoggedIn();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [error, setError] = useState<Optional<string>>(null);
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");

    const handleUpdate = async () => {
        try {
            setError(null);
            setDisabled(true);
            const trimmedNewPassword = newPassword.trim();
            if (trimmedNewPassword.length < 12) {
                setError("New password has less then 12 characters")
            } else {
                await loggedIn.updatePassword(currentPassword, trimmedNewPassword);
                setCurrentPassword("");
                setNewPassword("");
            }
        } catch (error) {
            setError("" + error)
        } finally {
            setDisabled(false);
        }
    }

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Profile
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Grid container spacing={3} maxWidth={1000}>
                <FormRow label="Current Password">
                    <PasswordInput
                        id="password"
                        value={currentPassword}
                        disabled={disabled}
                        size={"small"}
                        setValue={setCurrentPassword}
                    />
                </FormRow>
                <FormRow label="New Password">
                    <PasswordInput
                        id="password"
                        value={newPassword}
                        disabled={disabled}
                        size={"small"}
                        setValue={setNewPassword}
                    />
                </FormRow>
            </Grid>
            {<Stack direction={"row"} spacing={1}>
                <Button
                    variant={"contained"}
                    disabled={disabled}
                    onClick={handleUpdate}
                >
                    Update
                </Button>
            </Stack>}
        </>
    );

}

export default ProfilePage;
