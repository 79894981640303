import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {CountryCode} from "./CountryCode";
import {Flag} from "./Flag";
import {Name} from "./Name";

export class Country {

    public readonly name: Name;

    public readonly flag: Flag;

    public readonly code: CountryCode;

    constructor(name: Name, flag: Flag, code: CountryCode) {
        this.name = name;
        this.flag = flag;
        this.code = code;
    }

    public static toData(value: Country): string {
        return value.code.value;
    }

    public static fromData(data: string): Country {
        return Country.fromCode(new CountryCode(data));
    }

    public static fromCode(countryCode: CountryCode): Country {
        const country: Maybe<Country> = COUNTRIES.find(country => country.code.value === countryCode.value);
        if (country === undefined) {
            throw new Error("Unknown country: " + JSON.stringify(countryCode));
        }
        return country;
    }

}

export const GERMANY: Country = new Country(
    new Name("Germany"),
    new Flag('🇩🇪'),
    new CountryCode("DE")
);

export const SPAIN: Country = new Country(
    new Name("Spain"),
    new Flag('🇪🇸'),
    new CountryCode("ES")
);

export const COUNTRIES: ReadonlyArray<Country> = [
    GERMANY,
    SPAIN
] as const;

export const DEFAULT_COUNTRY: Country = SPAIN;

export const COUNTRY_DATA_MAPPER: DataMapper<Country, string> = {
    toData: Country.toData,
    fromData: Country.fromData
}
