import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, {useRef} from "react";
import {Optional} from "../../api/model/util/Optional";
import {GeoArea, WORLD} from "../../api/model/value/GeoArea";
import {GeoPoint} from "../../api/model/value/GeoPoint";
import {Latitude} from "../../api/model/value/Latitude";
import {Longitude} from "../../api/model/value/Longitude";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: GeoPoint,
    disabled: boolean,
    size: FormSize;
    geoArea?: GeoArea;
    setValue: (value: GeoPoint) => void
}

const GeoPointInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, geoArea, setValue} = props

    const mapsNotationRef = useRef<Optional<HTMLInputElement>>(null);

    const boundingBox = geoArea ?? WORLD;

    const handleLatitudeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withLatitude(Latitude.clamp(Latitude.fromNumber(parseFloat(event.target.value)), boundingBox)));
    }

    const handleLongitudeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withLongitude(Longitude.clamp(Longitude.fromNumber(parseFloat(event.target.value)), boundingBox)));
    }

    const handleMapsNotationImport = () => {
        if (mapsNotationRef.current) {
            const value = mapsNotationRef.current?.value || "";
            const regex: RegExp = /^\s*(\d+(?:.\d+)?)\s*,\s*(\d+(?:.\d+)?)\s*$/g;
            const match: Optional<RegExpExecArray> = regex.exec(value);
            if (match !== null) {
                const latitude = Latitude.clamp(Latitude.fromNumber(parseFloat(match[1])), boundingBox);
                const longitude = Longitude.clamp(Longitude.fromNumber(parseFloat(match[2])), boundingBox);
                setValue(new GeoPoint(latitude, longitude));
            }
            mapsNotationRef.current.value = ""
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Stack direction="column">
                    Latitude
                    <TextField
                        id={id + "-lat"}
                        value={value.latitude.value}
                        type="number"
                        disabled={disabled}
                        size={size}
                        onChange={handleLatitudeChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column">
                    Longitude
                    <TextField
                        id={id + "-long"}
                        value={value.longitude.value}
                        type="number"
                        disabled={disabled}
                        size={size}
                        onChange={handleLongitudeChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="column">
                    Google Maps Notation
                    <Stack direction="row">
                        <FormControl fullWidth>
                            <TextField
                                id={id + "-maps-notation"}
                                inputRef={mapsNotationRef}
                                placeholder={"54.31192080514471, 10.13317095117342"}
                                disabled={disabled}
                                size={size}
                            />
                        </FormControl>
                        <FormControl sx={{pl: 1}}>
                            <Button onClick={handleMapsNotationImport} variant={"outlined"}>Import</Button>
                        </FormControl>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )

};

export default GeoPointInput;
