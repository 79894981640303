import {Timestamp} from "@firebase/firestore";
import {DataMapper} from "../Entity";
import {DATE_DATA_MAPPER} from "./Date";
import {Name} from "./Name";
import {UNUSED, UsageState, USED} from "./UsageState";

export type unused = {
    state: string,
}

export type used = {
    state: string,
    key: string,
    usedAt: Timestamp,
}

export type usage = unused | used;

export abstract class Usage {

    public readonly state: UsageState;

    protected constructor(state: UsageState) {
        this.state = state;
    }

    public static toData(value: Usage): usage {
        if (value.state.code.value === UNUSED.code.value) {
            const unusedValue = value as Unused;
            return {
                state: UsageState.toData(unusedValue.state),
            }
        } else {
            const usedValue = value as Used;
            return {
                state: UsageState.toData(usedValue.state),
                key: Name.toData(usedValue.key),
                usedAt: DATE_DATA_MAPPER.toData(usedValue.usedAt)
            }
        }
    }

    public static fromData(data: usage): Usage {
        const state = UsageState.fromData(data.state);
        switch (state.code.value) {
            case "UNUSED":
                return new Unused();
            case "USED":
                const usedData = data as used;
                return new Used(
                    Name.fromData(usedData.key),
                    DATE_DATA_MAPPER.fromData(usedData.usedAt)
                );
        }
        throw new Error("Unknown usage state: " + state.code.value);
    }

}

export class Unused extends Usage {

    constructor() {
        super(UNUSED)
    }

}

export class Used extends Usage {

    public readonly key: Name;

    public readonly usedAt: Date;

    constructor(key: Name, usedAt: Date) {
        super(USED)
        this.key = key;
        this.usedAt = usedAt;
    }

}

export const USAGE_DATA_MAPPER: DataMapper<Usage, usage> = {
    toData: Usage.toData,
    fromData: Usage.fromData
}