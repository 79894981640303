import React from "react";
import {PageState} from "../../api/model/Page";
import {FormSize} from "../form/FormSize";
import StringOptionsInput from "./StringOptionsInput";

export interface Props {
    id: string,
    value: PageState,
    disabled: boolean,
    size: FormSize;
    setValue: (value: PageState) => void
}

const PageStateInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <StringOptionsInput
            id={id}
            value={value}
            options={Object.keys(PageState).map(state => {
                return {value: state, label: state}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: string) => setValue(value as PageState)}
        />
    )

};

export default PageStateInput;
