import Typography from "@mui/material/Typography";
import React, {createContext, useContext, useEffect, useState} from "react";
import {EntityManager} from "../api/EntityManager";
import {AFFILIATE_DEFINITION, AffiliateManager} from "../api/model/Affiliate";
import {AFFILIATE_CODE_DEFINITION, AffiliateCodeManager} from "../api/model/AffiliateCode";
import {BUNDLE_DEFINITION, BundleManager} from "../api/model/Bundle";
import {CUSTOMER_DEFINITION, CustomerManager} from "../api/model/Customer";
import {EVENT_DEFINITION, EventManager} from "../api/model/Event";
import {FLAG_DEFINITION, FlagManager} from "../api/model/Flag";
import {IMAGE_DEFINITION, ImageManager} from "../api/model/Image";
import {LOCATION_DEFINITION, LocationManager} from "../api/model/Location";
import {PAGE_DEFINITION, PageManager} from "../api/model/Page";
import {GUEST_DEFINITION, GuestManager} from "../api/model/Guest";
import {PARTNER_DEFINITION, PartnerManager} from "../api/model/Partner";
import {PURCHASE_DEFINITION, PurchaseManager} from "../api/model/Purchase";
import {Region, REGION_DEFINITION, RegionManager} from "../api/model/Region";
import {TAG_DEFINITION, TagManager} from "../api/model/Tag";
import {Optional} from "../api/model/util/Optional";
import {VENUE_DEFINITION, VenueManager} from "../api/model/Venue";
import {VOUCHER_DEFINITION, VoucherManager} from "../api/model/Voucher";
import {VOUCHER_CODE_DEFINITION, VoucherCodeManager} from "../api/model/VoucherCode";
import {sortByName} from "../api/util";
import {database} from "../firebase/firebase";

export type Props = {}

export type Project = {
    regions: ReadonlyArray<Region>,
    selectedRegion: Region,
    setSelectedRegion: (region: Region) => void,
    pageManager: PageManager,
    flagManager: FlagManager,
    tagManager: TagManager,
    locationManager: LocationManager,
    imageManager: ImageManager,
    venueManager: VenueManager,
    eventManager: EventManager,
    guestManager: GuestManager,
    purchaseManager: PurchaseManager,
    voucherManager: VoucherManager,
    voucherCodeManager: VoucherCodeManager,
    partnerManager: PartnerManager,
    affiliateManager: AffiliateManager,
    affiliateCodeManager: AffiliateCodeManager,
    customerManager: CustomerManager,
    bundleManager: BundleManager
}

const ProjectContext = createContext<Project>(null!);

const ProjectProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const [regionManager] = useState<RegionManager>(new EntityManager(database, REGION_DEFINITION));
    const [regions, setRegions] = useState<ReadonlyArray<Region>>([]);
    const [selectedRegion, setSelectedRegion] = useState<Optional<Region>>(null);
    const [pageManager] = useState<PageManager>(new EntityManager(database, PAGE_DEFINITION));
    const [flagManager] = useState<FlagManager>(new EntityManager(database, FLAG_DEFINITION));
    const [tagManager] = useState<TagManager>(new EntityManager(database, TAG_DEFINITION));
    const [locationManager] = useState<LocationManager>(new EntityManager(database, LOCATION_DEFINITION));
    const [imageManager] = useState<ImageManager>(new EntityManager(database, IMAGE_DEFINITION));
    const [venueManager] = useState<VenueManager>(new EntityManager(database, VENUE_DEFINITION));
    const [eventManager] = useState<EventManager>(new EntityManager(database, EVENT_DEFINITION));
    const [guestManager] = useState<GuestManager>(new EntityManager(database, GUEST_DEFINITION));
    const [purchaseManager] = useState<PurchaseManager>(new EntityManager(database, PURCHASE_DEFINITION));
    const [voucherManager] = useState<VoucherManager>(new EntityManager(database, VOUCHER_DEFINITION));
    const [voucherCodeManager] = useState<VoucherCodeManager>(new EntityManager(database, VOUCHER_CODE_DEFINITION));
    const [partnerManager] = useState<PartnerManager>(new EntityManager(database, PARTNER_DEFINITION));
    const [affiliateManager] = useState<AffiliateManager>(new EntityManager(database, AFFILIATE_DEFINITION));
    const [affiliateCodeManager] = useState<AffiliateCodeManager>(new EntityManager(database, AFFILIATE_CODE_DEFINITION));
    const [customerManager] = useState<CustomerManager>(new EntityManager(database, CUSTOMER_DEFINITION));
    const [bundleManager] = useState<BundleManager>(new EntityManager(database, BUNDLE_DEFINITION));

    useEffect(() => {

        const loadRegions = async () => {

            console.log("loading regions")
            const persistedRegions = await regionManager.getAllAsArray({});
            const sortedPersistedRegions = sortByName(persistedRegions);
            setRegions(sortedPersistedRegions);
            setSelectedRegion(sortedPersistedRegions.length !== 0 ? sortedPersistedRegions[0] : null);
            console.log("loaded regions", sortedPersistedRegions)

        }

        loadRegions().catch(console.error);

    }, []);

    if (selectedRegion !== null) {

        const value: Project = {
            regions,
            selectedRegion,
            setSelectedRegion,
            pageManager,
            flagManager,
            tagManager,
            locationManager,
            imageManager,
            venueManager,
            eventManager,
            guestManager,
            purchaseManager,
            voucherManager,
            voucherCodeManager,
            partnerManager,
            affiliateManager,
            affiliateCodeManager,
            customerManager,
            bundleManager
        }

        return (
            <ProjectContext.Provider value={value}>
                {children}
            </ProjectContext.Provider>
        );

    } else {

        return (
            <>
                <Typography variant="h3">
                    No regions created yet!
                </Typography>
            </>
        );

    }

};

export function useProject(): Project {
    return useContext(ProjectContext);
}

export default ProjectProvider;