import React, {MutableRefObject, useEffect, useState} from "react";
import {Optional} from "../../api/model/util/Optional";
import {Contacts} from "../../api/model/value/Contacts";
import {FormSize} from "../form/FormSize";
import ContactsInput from "./ContactsInput";

export interface Props {
    id: string,
    value: Contacts,
    disabled: boolean,
    size: FormSize;
    getValue: MutableRefObject<Optional<() => Contacts>>,
    reset: number
}

const ContactsInputCompound: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, getValue, reset} = props

    const [currentItems, setCurrentItems] = useState<Contacts>(new Contacts([...value.items]));

    useEffect(() => {
        setCurrentItems(new Contacts([...value.items]))
    }, [reset]);

    getValue.current = () => currentItems;

    return (
        <>
            <ContactsInput
                id={id}
                value={currentItems}
                disabled={disabled}
                size={size}
                setValue={setCurrentItems}
            />
        </>
    )

};

export default ContactsInputCompound;
