import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {Name} from "./Name";
import {stringCompare} from "./String";

export class UsageState {

    public readonly name: Name;

    public readonly code: Code;

    constructor(name: Name, code: Code) {
        this.name = name;
        this.code = code;
    }

    public static toData(value: UsageState): string {
        return value.code.value;
    }

    public static fromData(data: string): UsageState {
        return UsageState.fromCode(new Code(data));
    }

    public static fromCode(usageStateCode: Code): UsageState {
        const usageState: Maybe<UsageState> = USAGE_STATES.find(usageState => usageState.code.value === usageStateCode.value);
        if (usageState === undefined) {
            throw new Error("Unknown usage state: " + JSON.stringify(usageStateCode));
        }
        return usageState;
    }

}

export const UNUSED: UsageState = new UsageState(
    new Name("Unused"),
    new Code("UNUSED")
);

export const USED: UsageState = new UsageState(
    new Name("Used"),
    new Code("USED")
);

export const USAGE_STATES: ReadonlyArray<UsageState> = [
    UNUSED,
    USED
] as const;

export const USAGE_STATE_DATA_MAPPER: DataMapper<UsageState, string> = {
    toData: UsageState.toData,
    fromData: UsageState.fromData
}

export function usageStateEquals(left: UsageState, right: UsageState): boolean {
    return left.code.value === right.code.value;
}

export function usageStateCompare(left: UsageState, right: UsageState): number {
    return stringCompare(left.name.value, right.name.value)
}
