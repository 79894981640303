import {DataMapper} from "../Entity";
import {ContactType} from "./ContactType";
import {emailAddresses, EmailAddresses} from "./EmailAddresses";
import {LegalEntityType} from "./LegalEntityType";
import {Name} from "./Name";
import {phoneNumbers, PhoneNumbers} from "./PhoneNumbers";

export type contact = {
    type: string,
    name: string,
    emailAddresses: emailAddresses,
    phoneNumbers: phoneNumbers
}

export class Contact {

    public readonly type: ContactType;

    public readonly name: Name;

    public readonly emailAddresses: EmailAddresses;

    public readonly phoneNumbers: PhoneNumbers;

    constructor(type: LegalEntityType, name: Name, emailAddresses: EmailAddresses, phoneNumbers: PhoneNumbers) {
        this.type = type;
        this.name = name;
        this.emailAddresses = emailAddresses;
        this.phoneNumbers = phoneNumbers;
    }

    public withType(type: ContactType): Contact {
        return new Contact(type, this.name, this.emailAddresses, this.phoneNumbers)
    }

    public withName(name: Name): Contact {
        return new Contact(this.type, name, this.emailAddresses, this.phoneNumbers)
    }

    public withEmailAddresses(emailAddresses: EmailAddresses): Contact {
        return new Contact(this.type, this.name, emailAddresses, this.phoneNumbers)
    }

    public withPhoneNumbers(phoneNumbers: PhoneNumbers): Contact {
        return new Contact(this.type, this.name, this.emailAddresses, phoneNumbers)
    }

    public static toData(value: Contact): contact {
        return {
            type: LegalEntityType.toData(value.type),
            name: Name.toData(value.name),
            emailAddresses: EmailAddresses.toData(value.emailAddresses),
            phoneNumbers: PhoneNumbers.toData(value.phoneNumbers)
        }
    }

    public static fromData(data: contact): Contact {
        return new Contact(
            ContactType.fromData(data.type),
            Name.fromData(data.name),
            EmailAddresses.fromData(data.emailAddresses),
            PhoneNumbers.fromData(data.phoneNumbers)
        )
    }

}

export const CONTACT_DATA_MAPPER: DataMapper<Contact, contact> = {
    toData: Contact.toData,
    fromData: Contact.fromData
}



