import {collection, CollectionReference, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {CUSTOMER_ID_DATA_MAPPER, CustomerId, customerIdCompare} from "./value/CustomerId";
import {dateCompare} from "./value/Date";
import {EMAIL_ADDRESS_DATA_MAPPER, EmailAddress, emailAddressCompare, emailAddressContains} from "./value/EmailAddress";
import {EntityType} from "./value/EntityType";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {stringCompare, stringEquals} from "./value/String";

export enum CustomerState {

    ENABLED = "ENABLED"

}

export type CustomerPayload = {
    state: CustomerState,
    customerId: CustomerId,
    name: Name,
    emailAddress: EmailAddress
}

export type CustomerAnchor = EntityAnchor<EntityType.CUSTOMER> & {};

export type PreparedCustomer = PreparedEntity<EntityType.CUSTOMER> & CustomerPayload;

export type Customer = Entity<EntityType.CUSTOMER> & CustomerPayload;

export type PersistableCustomer = PreparedCustomer | Customer;

export type CustomerManager = EntityManager<EntityType.CUSTOMER, CustomerAnchor, PersistableCustomer, Customer>;

export const CUSTOMER_STATE_DATA_MAPPER: DataMapper<CustomerState, string> = {
    toData: (value: CustomerState) => value,
    fromData: (data: string) => data as CustomerState
}

export const CUSTOMER_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableCustomer, CustomerState, string>(
        "state",
        e => e.state,
        CUSTOMER_STATE_DATA_MAPPER,
        CustomerState.ENABLED
    );

export const CUSTOMER_CUSTOMER_ID_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableCustomer, CustomerId, string>(
        "customerId",
        e => e.customerId,
        CUSTOMER_ID_DATA_MAPPER,
        null
    );

export const CUSTOMER_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableCustomer, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        null
    );

export const CUSTOMER_EMAIL_ADDRESS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableCustomer, EmailAddress, string>(
        "emailAddress",
        e => e.emailAddress,
        EMAIL_ADDRESS_DATA_MAPPER,
        null
    );

export type CustomerFilterColumn = EntityFilterColumn | "STATE" | "NAME" | "EMAIL_ADDRESS";

export type CustomerFilterOperation<V> = FilterOperation<EntityType.CUSTOMER, Customer, CustomerFilterColumn, V>;

export type CustomerFilter<V> = Filter<EntityType.CUSTOMER, Customer, CustomerFilterColumn, V>;

export const CUSTOMER_STATE_EQUALS_FILTER: CustomerFilterOperation<CustomerState> = {
    column: "STATE",
    apply: (customer: Customer, comparisonValue: CustomerState) => stringEquals(customer.state, comparisonValue)
}

export const CUSTOMER_NAME_CONTAINS_FILTER: CustomerFilterOperation<string> = {
    column: "NAME",
    apply: (customer: Customer, comparisonValue: string) => nameContains(customer.name, comparisonValue)
}

export const CUSTOMER_EMAIL_ADDRESS_CONTAINS_FILTER: CustomerFilterOperation<string> = {
    column: "NAME",
    apply: (customer: Customer, comparisonValue: string) => emailAddressContains(customer.emailAddress, comparisonValue)
}

export type CustomerSortColumn = EntitySortColumn |  "CREATED_AT" |"STATE" | "CUSTOMER_ID" | "NAME" | "EMAIL_ADDRESS";

export type CustomerSortOrder = SortOrder<EntityType.CUSTOMER, Customer, CustomerSortColumn>;

export const CUSTOMER_CREATED_AT_SORT_ORDER: CustomerSortOrder = {
    column: "CREATED_AT",
    apply: (left: Customer, right: Customer) => dateCompare(left.createdAt, right.createdAt)
}

export const CUSTOMER_STATE_SORT_ORDER: CustomerSortOrder = {
    column: "STATE",
    apply: (left: Customer, right: Customer) => stringCompare(left.state, right.state)
}

export const CUSTOMER_CUSTOMER_ID_SORT_ORDER: CustomerSortOrder = {
    column: "CUSTOMER_ID",
    apply: (left: Customer, right: Customer) => customerIdCompare(left.customerId, right.customerId)
}

export const CUSTOMER_NAME_SORT_ORDER: CustomerSortOrder = {
    column: "NAME",
    apply: (left: Customer, right: Customer) => nameCompare(left.name, right.name)
}


export const CUSTOMER_EMAIL_ADDRESS_SORT_ORDER: CustomerSortOrder = {
    column: "EMAIL_ADDRESS",
    apply: (left: Customer, right: Customer) => emailAddressCompare(left.emailAddress, right.emailAddress)
}

class CustomerDefinition extends EntityDefinition<EntityType.CUSTOMER, CustomerAnchor, PersistableCustomer, Customer> {

    constructor() {
        super(
            EntityType.CUSTOMER,
            [
                CUSTOMER_STATE_ATTRIBUTE_DEFINITION,
                CUSTOMER_CUSTOMER_ID_ATTRIBUTE_DEFINITION,
                CUSTOMER_NAME_ATTRIBUTE_DEFINITION,
                CUSTOMER_EMAIL_ADDRESS_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableCustomer): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableCustomer): CustomerAnchor {
        return {};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.CUSTOMER>): CustomerAnchor {
        return {};
    }

    public anchorToColRef(database: Firestore, anchor: CustomerAnchor): CollectionReference {
        return collection(database, "adminManager", "data", EntityType.CUSTOMER.toLowerCase());
    }

}

export const CUSTOMER_DEFINITION = new CustomerDefinition();