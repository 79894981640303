import {collection, CollectionReference, doc, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    IdRefsAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {benefits, Benefits, BENEFITS_DATA_MAPPER} from "./value/Benefits";
import {BOOLEAN_DATA_MAPPER} from "./value/Boolean";
import {categories, Categories, CATEGORIES_DATA_MAPPER} from "./value/Categories";
import {CONTACT_OPTIONS_DATA_MAPPER, contactOptions, ContactOptions} from "./value/ContactOptions";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {label, Label, LABEL_DATA_MAPPER} from "./value/Label";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {NOMINAL_DATE_DATA_MAPPER, nominalDate, NominalDate} from "./value/NominalDate";
import {NON_NEGATIVE_INTEGER_DATA_MAPPER, NonNegativeInteger} from "./value/NonNegativeInteger";
import {SOCIAL_MEDIA_PROFILES_DATA_MAPPER, socialMediaProfiles, SocialMediaProfiles} from "./value/SocialMediaProfiles";
import {stringCompare, stringEquals} from "./value/String";
import {websites, Websites, WEBSITES_DATA_MAPPER} from "./value/Websites";

export enum VenueState {

    ENABLED = "ENABLED",

    DISABLED = "DISABLED"

}

export type VenuePayload = {
    state: VenueState,
    name: Name,
    subtitle: Name,
    partner: IdRef<EntityType.PARTNER>,
    location: IdRef<EntityType.LOCATION>,
    hidden: boolean,
    description: Label,
    categories: Categories,
    tags: ReadonlyArray<IdRef<EntityType.TAG>>,
    images: ReadonlyArray<IdRef<EntityType.IMAGE>>,
    announcementDays: NonNegativeInteger,
    openFrom: NominalDate,
    openUntil: NominalDate,
    openingHours: Label,
    websites: Websites,
    socialMediaProfiles: SocialMediaProfiles,
    contactOptions: ContactOptions,
    benefits: Benefits,
    valuation: NonNegativeInteger,
    flags: ReadonlyArray<IdRef<EntityType.FLAG>>,
    region: IdRef<EntityType.REGION>
}

export type VenueAnchor = EntityAnchor<EntityType.VENUE> & {
    region: IdAlike<EntityType.REGION>
}

export type PreparedVenue = PreparedEntity<EntityType.VENUE> & VenuePayload;

export type Venue = Entity<EntityType.VENUE> & VenuePayload;

export type PersistableVenue = PreparedVenue | Venue;

export type VenueManager = EntityManager<EntityType.VENUE, VenueAnchor, PersistableVenue, Venue>;

export const VENUE_STATE_DATA_MAPPER: DataMapper<VenueState, string> = {
    toData: (value: VenueState) => value,
    fromData: (data: string) => data as VenueState
}

export const VENUE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, VenueState, string>(
        "state",
        e => e.state,
        VENUE_STATE_DATA_MAPPER,
        VenueState.ENABLED
    );

export const VENUE_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Venue")
    );

export const VENUE_SUBTITLE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, Name, string>(
        "subtitle",
        e => e.subtitle,
        NAME_DATA_MAPPER,
        new Name("")
    );

export const VENUE_PARTNER_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.PARTNER, PersistableVenue>(
        EntityType.PARTNER,
        "partner",
        e => e.partner
    );

export const VENUE_LOCATION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.LOCATION, PersistableVenue>(
        EntityType.LOCATION,
        "location",
        e => e.location
    );

export const VENUE_HIDDEN_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, boolean, boolean>(
        "hidden",
        e => e.hidden,
        BOOLEAN_DATA_MAPPER,
        false
    );

export const VENUE_DESCRIPTION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, Label, label>(
        "description",
        e => e.description,
        LABEL_DATA_MAPPER,
        new Label("")
    );

export const VENUE_CATEGORIES_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, Categories, categories>(
        "categories",
        e => e.categories,
        CATEGORIES_DATA_MAPPER,
        new Categories([])
    );

export const VENUE_TAGS_ATTRIBUTE_DEFINITION =
    new IdRefsAttributeDefinition<EntityType.TAG, PersistableVenue>(
        EntityType.TAG,
        "tags",
        e => e.tags
    );

export const VENUE_IMAGES_ATTRIBUTE_DEFINITION =
    new IdRefsAttributeDefinition<EntityType.IMAGE, PersistableVenue>(
        EntityType.IMAGE,
        "images",
        e => e.images
    );

export const VENUE_ANNOUNCEMENT_DAYS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, NonNegativeInteger, number>(
        "announcementDays",
        e => e.announcementDays,
        NON_NEGATIVE_INTEGER_DATA_MAPPER,
        new NonNegativeInteger(0)
    );

export const VENUE_OPEN_FROM_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, NominalDate, nominalDate>(
        "openFrom",
        e => e.openFrom,
        NOMINAL_DATE_DATA_MAPPER,
        new NominalDate(2022, 1, 1)
    );

export const VENUE_OPEN_UNTIL_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, NominalDate, nominalDate>(
        "openUntil",
        e => e.openUntil,
        NOMINAL_DATE_DATA_MAPPER,
        new NominalDate(2022, 12, 31)
    );

export const VENUE_OPENING_HOURS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, Label, label>(
        "openingHours",
        e => e.openingHours,
        LABEL_DATA_MAPPER,
        new Label("")
    );

export const VENUE_WEBSITES_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, Websites, websites>(
        "websites",
        e => e.websites,
        WEBSITES_DATA_MAPPER,
        new Websites(null)
    );

export const VENUE_SOCIAL_MEDIA_PROFILES_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, SocialMediaProfiles, socialMediaProfiles>(
        "socialMediaProfiles",
        e => e.socialMediaProfiles,
        SOCIAL_MEDIA_PROFILES_DATA_MAPPER,
        new SocialMediaProfiles(null, null)
    );

export const VENUE_CONTACT_OPTIONS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, ContactOptions, contactOptions>(
        "contactOptions",
        e => e.contactOptions,
        CONTACT_OPTIONS_DATA_MAPPER,
        new ContactOptions(null, null, null)
    );

export const VENUE_BENEFITS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, Benefits, benefits>(
        "benefits",
        e => e.benefits,
        BENEFITS_DATA_MAPPER,
        new Benefits([])
    );

export const VENUE_VALUATION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVenue, NonNegativeInteger, number>(
        "valuation",
        e => e.valuation,
        NON_NEGATIVE_INTEGER_DATA_MAPPER,
        new NonNegativeInteger(0)
    );

export const VENUE_FLAGS_ATTRIBUTE_DEFINITION =
    new IdRefsAttributeDefinition<EntityType.FLAG, PersistableVenue>(
        EntityType.FLAG,
        "flags",
        e => e.flags
    );

export const VENUE_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableVenue>(
        EntityType.REGION,
        "region",
        e => e.region
    );


export type VenueFilterColumn = EntityFilterColumn | "STATE" | "NAME";

export type VenueFilterOperation<V> = FilterOperation<EntityType.VENUE, Venue, VenueFilterColumn, V>;

export type VenueFilter<V> = Filter<EntityType.VENUE, Venue, VenueFilterColumn, V>;

export const VENUE_STATE_EQUALS_FILTER: VenueFilterOperation<VenueState> = {
    column: "STATE",
    apply: (venue: Venue, comparisonValue: VenueState) => stringEquals(venue.state, comparisonValue)
}

export const VENUE_NAME_CONTAINS_FILTER: VenueFilterOperation<string> = {
    column: "NAME",
    apply: (venue: Venue, comparisonValue: string) => nameContains(venue.name, comparisonValue)
}

export type VenueSortColumn = EntitySortColumn | "STATE" | "NAME" | "SUBTITLE";

export type VenueSortOrder = SortOrder<EntityType.VENUE, Venue, VenueSortColumn>;

export const VENUE_STATE_SORT_ORDER: VenueSortOrder = {
    column: "STATE",
    apply: (left: Venue, right: Venue) => stringCompare(left.state, right.state)
}

export const VENUE_NAME_SORT_ORDER: VenueSortOrder = {
    column: "NAME",
    apply: (left: Venue, right: Venue) => nameCompare(left.name, right.name)
}

export const VENUE_SUBTITLE_SORT_ORDER: VenueSortOrder = {
    column: "SUBTITLE",
    apply: (left: Venue, right: Venue) => nameCompare(left.subtitle, right.subtitle)
}

class VenueDefinition extends EntityDefinition<EntityType.VENUE, VenueAnchor, PersistableVenue, Venue> {

    constructor() {
        super(
            EntityType.VENUE,
            [
                VENUE_STATE_ATTRIBUTE_DEFINITION,
                VENUE_NAME_ATTRIBUTE_DEFINITION,
                VENUE_SUBTITLE_ATTRIBUTE_DEFINITION,
                VENUE_PARTNER_ATTRIBUTE_DEFINITION,
                VENUE_LOCATION_ATTRIBUTE_DEFINITION,
                VENUE_HIDDEN_ATTRIBUTE_DEFINITION,
                VENUE_DESCRIPTION_ATTRIBUTE_DEFINITION,
                VENUE_CATEGORIES_ATTRIBUTE_DEFINITION,
                VENUE_TAGS_ATTRIBUTE_DEFINITION,
                VENUE_IMAGES_ATTRIBUTE_DEFINITION,
                VENUE_ANNOUNCEMENT_DAYS_ATTRIBUTE_DEFINITION,
                VENUE_OPEN_FROM_ATTRIBUTE_DEFINITION,
                VENUE_OPEN_UNTIL_ATTRIBUTE_DEFINITION,
                VENUE_OPENING_HOURS_ATTRIBUTE_DEFINITION,
                VENUE_WEBSITES_ATTRIBUTE_DEFINITION,
                VENUE_SOCIAL_MEDIA_PROFILES_ATTRIBUTE_DEFINITION,
                VENUE_CONTACT_OPTIONS_ATTRIBUTE_DEFINITION,
                VENUE_BENEFITS_ATTRIBUTE_DEFINITION,
                VENUE_VALUATION_ATTRIBUTE_DEFINITION,
                VENUE_FLAGS_ATTRIBUTE_DEFINITION,
                VENUE_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableVenue): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableVenue): VenueAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.VENUE>): VenueAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: VenueAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.VENUE.toLowerCase());
    }

}

export const VENUE_DEFINITION = new VenueDefinition();