import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from "react";
import {Bundle, BUNDLE_STATE_EQUALS_FILTER, BundleFilter, BundleState} from "../../../api/model/Bundle";
import {ENTITY_STATE_EQUALS_FILTER, EntityState} from "../../../api/model/Entity";
import {Optional} from "../../../api/model/util/Optional";
import BundleList from "./BundleList";
import BundleCreateForm from "./BundlePublishForm";
import {useBundleSlicer} from "./BundleSlicerProvider";
import BundleUpdatePage from "./BundleUpdatePage";

type TabDefinition = {
    id: string,
    label: string,
    filter: ReadonlyArray<BundleFilter<any>>
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "published",
        label: "Published",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: BUNDLE_STATE_EQUALS_FILTER,
                comparisonValue: BundleState.PUBLISHED
            }
        ]
    }
]

const INITIAL_TAB = 0;

export interface Props {
}

const BundlesPage: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    const bundleSlicer = useBundleSlicer();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [selectedBundle, setSelectedBundle] = useState<Optional<"NEW" | Bundle>>(null);

    useEffect(() => {
        bundleSlicer.setStaticFilters(TABS[selectedTab].filter);
    }, [selectedTab]);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const handleSelect = (bundle: Bundle) => {
        setSelectedBundle(bundle);
    }

    const handleCreate = () => {
        setSelectedBundle("NEW");
    }

    const handleClose = () => {
        setSelectedBundle(null);
    }

    if (selectedBundle === "NEW") {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Publish new Bundle
                </Typography>
                <BundleCreateForm onClose={handleClose}/>
            </>
        )
    }  else if (selectedBundle!== null) {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Flag: {selectedBundle.name.value}
                </Typography>
                <BundleUpdatePage bundle={selectedBundle} onClose={handleClose}/>
            </>
        )
    }else {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Bundles
                </Typography>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={handleTabSelection}>
                        {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                    </Tabs>
                </Box>
                <BundleList onSelect={handleSelect}/>
                <Stack direction={"row"} spacing={1}>
                    <Button variant={"contained"} onClick={handleCreate}>
                        Publish
                    </Button>
                </Stack>
            </>
        )
    }

};

export default BundlesPage;
