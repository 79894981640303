import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import React from "react";
import {Optional} from "../../api/model/util/Optional";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: Optional<string>,
    options: ReadonlyArray<{ value: string, label: string }>,
    disabled: boolean,
    size: FormSize;
    setValue: (value: Optional<string>) => void
}

const StringOptionsInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, options, disabled, size, setValue} = props

    const handleChange = (event: SelectChangeEvent) => {
        setValue((event.target.value || "").length === 0 ? null : event.target.value);
    }

    return (
        <Select
            id={id}
            value={value || ""}
            disabled={disabled}
            size={size}
            onChange={handleChange}
        >
            <MenuItem value="">
                <em>Any</em>
            </MenuItem>
            {options.map(option =>
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            )}
        </Select>
    )

};

export default StringOptionsInput;
