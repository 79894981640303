import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Partner, PartnerPayload, PartnerState} from "../../../../api/model/Partner";
import {Optional} from "../../../../api/model/util/Optional";
import {Name} from "../../../../api/model/value/Name";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import NameInput2 from "../../../input/NameInput2";
import PartnerStateInput2 from "../../../input/PartnerStateInput2";
import StringOutput from "../../../output/StringOutput";

export type PartnerCore = Pick<PartnerPayload, "state" | "name">;

export interface Props {
    partner?: Partner,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => PartnerCore>>,
    reset: number
}

const PartnerCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {partner, disabled, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => PartnerState>> = useRef<Optional<() => PartnerState>>(null)
    const getNameRef: MutableRefObject<Optional<() => Name>> = useRef<Optional<() => Name>>(null)

    getValue.current = () => ({
        state: getStateRef.current!(),
        name: getNameRef.current!()
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {partner?.id && <FormRow label="Id">
                    <StringOutput
                        value={partner.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <PartnerStateInput2
                        id="state"
                        value={partner?.state || PartnerState.ENABLED}
                        disabled={disabled}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                {partner && <FormRow label="Partner ID">
                    <StringOutput
                        value={partner.partnerId.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="Name">
                    <NameInput2
                        id="name"
                        value={partner?.name || new Name("")}
                        disabled={disabled}
                        size={size}
                        getValue={getNameRef}
                        reset={reset}
                    />
                </FormRow>
            </Grid>
        </>
    );

};

export default PartnerCoreInput;
