import React from 'react';

function HomeRoute() {

    return (
        <div>
            Home
        </div>
    );

}

export default HomeRoute;
