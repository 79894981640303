import React, {useEffect, useState} from "react";
import {Affiliate} from "../../../api/model/Affiliate";
import {Project, useProject} from "../../../context/ProjectProvider";
import AffiliateTable from "./AffiliateTable";

export interface Props {
    onSelect?: (affiliate: Affiliate) => void
}

const AffiliateList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [affiliates, setAffiliates] = useState<ReadonlyArray<Affiliate>>([]);

    useEffect(() => {

        const loadAffiliates = async () => {

            const affiliates = await project.affiliateManager.getAllAsArray({});
            setAffiliates(affiliates);

        }

        loadAffiliates().catch(console.error);

    }, [project]);

    return (
        <AffiliateTable
            affiliates={affiliates}
            onSelect={onSelect}
        />
    );

};

export default AffiliateList;
