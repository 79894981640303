import TextField from "@mui/material/TextField";
import React, {MutableRefObject, useEffect, useState} from "react";
import {Optional} from "../../api/model/util/Optional";
import {Name} from "../../api/model/value/Name";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: Name,
    disabled: boolean,
    size: FormSize;
    getValue: MutableRefObject<Optional<() => Name>>,
    reset: number
}

const NameInput2: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, getValue, reset} = props

    const [currentValue, setCurrentValue] = useState<string>(value.value);

    useEffect(() => {
        setCurrentValue(value.value)
    }, [reset]);

    const handleCurrentValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCurrentValue(event.target.value);
    }

    getValue.current = () => new Name(currentValue);

    return (
        <TextField
            id={id}
            value={currentValue}
            disabled={disabled}
            size={size}
            onChange={handleCurrentValueChange}
        />
    )

};

export default NameInput2;
