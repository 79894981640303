import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import React from "react";

export interface Props {
    onClear: () => void,
    onClose: () => void
}

const FilterFormButtons: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {

    const {onClear, onClose} = props;

    return (
        <>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <Button variant={"outlined"} onClick={onClear}>Clear all</Button>
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth>
                    <Button variant={"contained"} onClick={onClose}>Apply</Button>
                </FormControl>
            </Grid>
        </>
    )

};

export default FilterFormButtons;
