import {DataMapper} from "../Entity";
import {Url} from "./Url";
import {HOMEPAGE, WebsiteType} from "./WebsiteType";

export type website = {
    type: string,
    url: string
}

export abstract class Website {

    public readonly type: WebsiteType;

    public readonly url: Url;

    protected constructor(type: WebsiteType, url: Url) {
        this.type = type;
        this.url = url;
    }

    public static toData(value: Website): website {
        return {
            type: WebsiteType.toData(value.type),
            url: Url.toData(value.url)
        }
    }

    public static fromData(data: website): Website {
        const type = WebsiteType.fromData(data.type);
        switch (type.code.value) {
            case "HOMEPAGE":
                return new Homepage(Url.fromData(data.url));
        }
        throw new Error("Unknown website type: " + type.code.value);
    }

}

export class Homepage extends Website {

    constructor(url: Url) {
        super(HOMEPAGE, url);
    }
}

export const WEBSITE_DATA_MAPPER: DataMapper<Website, website> = {
    toData: Website.toData,
    fromData: Website.fromData
}