import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Optional} from "../../../../api/model/util/Optional";
import {Unused, Used} from "../../../../api/model/value/Usage";
import {USED} from "../../../../api/model/value/UsageState";
import {VoucherCodePayload, VoucherCodeState} from "../../../../api/model/VoucherCode";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import VoucherCodeStateInput2 from "../../../input/VoucherCodeStateInput2";
import StringOutput from "../../../output/StringOutput";

export type CodeCore = Pick<VoucherCodePayload, "state">;

export interface Props {
    code?: VoucherCodePayload,
    disabled: boolean,
    created: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => CodeCore>>,
    reset: number
}

const VoucherCodeCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {code, disabled, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => VoucherCodeState>> = useRef<Optional<() => VoucherCodeState>>(null)

    getValue.current = () => ({
        state: getStateRef.current!()
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                <FormRow label="State">
                    <VoucherCodeStateInput2
                        id="state"
                        value={code?.state || VoucherCodeState.ENABLED}
                        disabled={disabled}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                {code && <FormRow label="Usage">
                    <StringOutput
                        value={code?.usage.state.name.value}
                        size={size}
                    />
                </FormRow>}
                {code && <FormRow label="Code">
                    <StringOutput
                        value={code.codeId.value}
                        size={size}
                    />
                </FormRow>}
                {code && code.usage.state.code.value === USED.code.value && <FormRow label="Usage Key">
                    <StringOutput
                        value={(code.usage as Used).key.value}
                        size={size}
                    />
                </FormRow>}
                {code && code.usage.state.code.value === USED.code.value && <FormRow label="Used At">
                    <StringOutput
                        value={(code.usage as Used).usedAt.toISOString()}
                        size={size}
                    />
                </FormRow>}
            </Grid>
        </>
    );

};

export default VoucherCodeCoreInput;
