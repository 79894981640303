import TextField from "@mui/material/TextField";
import React from "react";
import {AffiliateCodePayload, affiliateCodeUrl} from "../../api/model/AffiliateCode";
import {FormSize} from "../form/FormSize";

export interface Props {
    value: Pick<AffiliateCodePayload, "codeId" | "target">,
    size: FormSize
}

const AffiliateCodeUrlOutput: React.FunctionComponent<Props> = (props: Props) => {

    const {value, size} = props

    const url = affiliateCodeUrl(value);

    return (
        <TextField
            value={url}
            disabled={true}
            size={size}
        />
    )

};

export default AffiliateCodeUrlOutput;
