import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, {MutableRefObject, useRef, useState} from "react";
import {EntityState} from "../../../api/model/Entity";
import {Flag} from "../../../api/model/Flag";
import {Optional} from "../../../api/model/util/Optional";
import {useProject} from "../../../context/ProjectProvider";
import RemoveButton from "../../form/RemoveButton";
import RestoreButton from "../../form/RestoreButton";
import FlagCoreInput, {FlagCore} from "./input/FlagCoreInput";

type TabDefinition = {
    id: string,
    label: string
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core"
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    flag: Flag,
    onClose?: () => void
}

const FlagUpdatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {flag: flag, onClose} = props

    const [latestFlag, setLatestFlag] = useState<Flag>(flag)
    const [currentFlag, setCurrentFlag] = useState<Flag>(flag)
    const [reset, setReset] = useState<number>(0);

    const project = useProject();
    const flagManager = project.flagManager;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getFlagCoreRef: MutableRefObject<Optional<() => FlagCore>> = useRef<Optional<() => FlagCore>>(null)

    const handlePersist = async () => {
        setDisabled(true);
        const flag: Flag = {
            ...latestFlag,
            ...getFlagCoreRef.current!()
        }
        const updatedFlag = await flagManager.persist(flag);
        setLatestFlag(updatedFlag);
        setCurrentFlag(updatedFlag);
        setDisabled(false);
    }

    const handleReset = async () => {
        setDisabled(true);
        setCurrentFlag(latestFlag);
        setReset(reset + 1);
        setDisabled(false);
    }

    const handleRemove = async () => {
        const updatedFlag = await flagManager.removeById(latestFlag);
        setLatestFlag(updatedFlag);
        setCurrentFlag(updatedFlag);
        setReset(reset + 1);
    }

    const handleRestore = async () => {
        const updatedFlag = await flagManager.restoreById(latestFlag);
        setLatestFlag(updatedFlag);
        setCurrentFlag(updatedFlag);
        setReset(reset + 1);
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <FlagCoreInput
                    flag={currentFlag}
                    disabled={disabled}
                    size="small" getValue={getFlagCoreRef}
                    reset={reset}
                />
            </TabPanel>
            <Stack direction={"row"} spacing={1}>
                {currentFlag.entityState === EntityState.CREATED &&
                    <Button variant={"contained"} onClick={handlePersist}>
                        Update
                    </Button>}
                {currentFlag.entityState === EntityState.CREATED &&
                    <Button variant={"outlined"} onClick={handleReset}>
                        Reset
                    </Button>}
                {currentFlag.entityState === EntityState.REMOVED &&
                    <RestoreButton disabled={false} onRestore={handleRestore}/>}
                {currentFlag.entityState === EntityState.CREATED &&
                    <RemoveButton disabled={false} onRemove={handleRemove}/>}
                {onClose && <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>}
            </Stack>
        </Stack>
    );

};

export default FlagUpdatePage;
