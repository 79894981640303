import React from 'react';
import TagSlicerProvider from "../component/entity/tag/TagSlicerProvider";
import TagsPage from "../component/entity/tag/TagsPage";

function TagRoute() {

    return (
        <TagSlicerProvider>
            <TagsPage/>
        </TagSlicerProvider>
    );

}

export default TagRoute;
