import React from 'react';
import PartnerSlicerProvider from "../component/entity/partner/PartnerSlicerProvider";
import PartnersPage from "../component/entity/partner/PartnersPage";

function PartnerRoute() {

    return (
        <PartnerSlicerProvider>
            <PartnersPage/>
        </PartnerSlicerProvider>
    );

}

export default PartnerRoute;
