import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Purchase, PurchasePayload, PurchaseState} from "../../../../api/model/Purchase";
import {Optional} from "../../../../api/model/util/Optional";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import PurchaseStateInput2 from "../../../input/PuchaseStateInput2";
import StringOutput from "../../../output/StringOutput";

export type PurchaseCore = Pick<PurchasePayload, "state">;

export interface Props {
    purchase?: Purchase,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => PurchaseCore>>,
    reset: number
}

const PurchaseCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {purchase, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => PurchaseState>> = useRef<Optional<() => PurchaseState>>(null)

    getValue.current = () => ({
        state: getStateRef.current!(),
    });

    console.log("purchase: ", purchase);

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {purchase?.id && <FormRow label="Id">
                    <StringOutput
                        value={purchase.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <PurchaseStateInput2
                        id="state"
                        value={purchase?.state || PurchaseState.APPROVED}
                        disabled={true}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                {purchase && <FormRow label="Purchase Id">
                    <StringOutput
                        value={purchase.purchaseId.value}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Approved At">
                    <StringOutput
                        value={purchase.approvedAt.toLocaleString()}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Activated At">
                    <StringOutput
                        value={purchase.activatedAt ? purchase.activatedAt.toLocaleString() : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Active Until">
                    <StringOutput
                        value={purchase.activeUntil ? purchase.activeUntil.toLocaleString() : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Expired At">
                    <StringOutput
                        value={purchase.expiredAt ? purchase.expiredAt.toLocaleString() : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Cancelable Until">
                    <StringOutput
                        value={ purchase.cancellableUntil.toLocaleString()}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Cancelled At">
                    <StringOutput
                        value={purchase.cancelledAt ? purchase.cancelledAt.toLocaleString() : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Payment Type">
                    <StringOutput
                        value={purchase.paymentType.name.value}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Payment ID">
                    <StringOutput
                        value={purchase.paymentId ? purchase.paymentId.value : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Payment Status">
                    <StringOutput
                        value={purchase.paymentStatus ? purchase.paymentStatus.value : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Payment State">
                    <StringOutput
                        value={purchase.paymentState.name.value}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Price">
                    <StringOutput
                        value={purchase.price ? (purchase.price.value / 100).toFixed(2) : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Currency">
                    <StringOutput
                        value={purchase.currency ? purchase.currency.code.value : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Eligible From">
                    <StringOutput
                        value={purchase.eligibleFrom.toLocaleString()}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliated At">
                    <StringOutput
                        value={purchase.affiliatedAt ? purchase.affiliatedAt.toLocaleString() : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliate Code ID">
                    <StringOutput
                        value={purchase.affiliateCodeId ? purchase.affiliateCodeId.value : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliate Code Name">
                    <StringOutput
                        value={purchase.affiliateCodeName ? purchase.affiliateCodeName.value : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliate Code State">
                    <StringOutput
                        value={purchase.affiliateCodeState ? purchase.affiliateCodeState : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliate Code Entity State">
                    <StringOutput
                        value={purchase.affiliateCodeEntityState ? purchase.affiliateCodeEntityState : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliate ID">
                    <StringOutput
                        value={purchase.affiliateId ? purchase.affiliateId.value : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliate Name">
                    <StringOutput
                        value={purchase.affiliateName ? purchase.affiliateName.value : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliate State">
                    <StringOutput
                        value={purchase.affiliateState ? purchase.affiliateState : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliate Entity State">
                    <StringOutput
                        value={purchase.affiliateEntityState ? purchase.affiliateEntityState : ""}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Affiliation State">
                    <StringOutput
                        value={purchase.affiliationState.name.value}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Customer ID">
                    <StringOutput
                        value={purchase.customerId.value}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Customer Name">
                    <StringOutput
                        value={purchase.customerName.value}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Customer Email Address">
                    <StringOutput
                        value={purchase.customerEmailAddress.value}
                        size={size}
                    />
                </FormRow>}
                {purchase && <FormRow label="Duration">
                    <StringOutput
                        value={purchase.duration.name.value}
                        size={size}
                    />
                </FormRow>}
            </Grid>
        </>
    );

};

export default PurchaseCoreInput;
