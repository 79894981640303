import {EditOutlined} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from "@mui/material/TablePagination";
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import {ENTITY_ID_CONTAINS_FILTER, SortDirection} from "../../../api/model/Entity";
import {
    Partner,
    PARTNER_NAME_CONTAINS_FILTER,
    PARTNER_NAME_SORT_ORDER,
    PARTNER_PARTNER_ID_SORT_ORDER,
    PARTNER_STATE_SORT_ORDER,
    PartnerFilter,
    PartnerSortOrder
} from "../../../api/model/Partner";
import FilterDialogButton from "../../form/FilterDialogButton";
import FilterFormButtons from "../../form/FilterFormButtons";
import FilterFormRow from "../../form/FilterFormRow";
import SortOrderButton from "../../form/SortOrderButton";
import StringInput from "../../input/StringInput";
import {usePartnerSlicer} from "./PartnerSlicerProvider";

interface FilterDialogProps {
    open: boolean;
    onClose: (filter: InternalFilter) => void;
}

const FilterDialog: React.FunctionComponent<FilterDialogProps> = (props: FilterDialogProps) => {

    const {open, onClose} = props;

    const [id, setId] = useState<string>("");
    const [name, setName] = useState<string>("");

    const handleClear = () => {
        setId("");
        setName("");
    };

    const handleClose = () => {
        onClose({
            id: id.length === 0 ? undefined : id,
            name: name.length === 0 ? undefined : name
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Filter</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FilterFormRow label="ID" operation="contains">
                    <StringInput
                        id="id"
                        value={id}
                        disabled={false}
                        size="small"
                        setValue={setId}
                    />
                </FilterFormRow>
                <FilterFormRow label="Name" operation="contains">
                    <StringInput
                        id="name"
                        value={name}
                        disabled={false}
                        size="small"
                        setValue={setName}
                    />
                </FilterFormRow>
                <FilterFormButtons onClear={handleClear} onClose={handleClose}/>
            </Grid>
        </Dialog>
    );
}

interface RowProps {
    partner: Partner,
    onSelect: (partner: Partner) => void
}

const Row: React.FunctionComponent<RowProps> = ({partner, onSelect}: RowProps) => {
   return (
        <TableRow>
            <TableCell padding={"checkbox"}>
                <IconButton size="small" onClick={() => onSelect(partner)}>
                    <EditOutlined/>
                </IconButton>
            </TableCell>
            <TableCell>{partner.name.value}</TableCell>
            <TableCell>{partner.partnerId.value}</TableCell>
            <TableCell>{partner.state}</TableCell>
        </TableRow>
    );
}

export interface Props {
    partners: ReadonlyArray<Partner>,
    onSelect?: (partner: Partner) => void
}

type InternalFilter = {
    id?: string,
    name?: string
}

const PartnerTable: React.FunctionComponent<Props> = ({partners, onSelect}: Props) => {

    const partnerSlicer = usePartnerSlicer();

    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const [internalFilter, setInternalFilter] = useState<InternalFilter>({});

    const handleFilterDialogOpen = () => {
        setFilterDialogOpen(true);
    };

    const handleFilterDialogClose = (filter: InternalFilter) => {
        setFilterDialogOpen(false);
        setInternalFilter(filter)
    };

    useEffect(() => {

        const dynamicFilters = new Array<PartnerFilter<any>>();

        if (internalFilter.id) {
            dynamicFilters.push({
                operation: ENTITY_ID_CONTAINS_FILTER,
                comparisonValue: internalFilter.id
            });
        }
        if (internalFilter.name) {
            dynamicFilters.push({
                operation: PARTNER_NAME_CONTAINS_FILTER,
                comparisonValue: internalFilter.name
            });
        }

        partnerSlicer.setDynamicFilters(dynamicFilters);
        partnerSlicer.setPaginationPage(0);

    }, [internalFilter]);

    function handleSortChange(sortOrder: PartnerSortOrder, direction: SortDirection) {
        partnerSlicer.setSortOrder(sortOrder);
        partnerSlicer.setSortDirection(direction);
        partnerSlicer.setPaginationPage(0);
    }

    const handlePaginationPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        partnerSlicer.setPaginationPage(newPage);
    };

    const handlePaginationRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        partnerSlicer.setPaginationRowsPerPage(newRowsPerPage);
        partnerSlicer.setPaginationPage(0);
    };

    const handleSelectFactory = (partner: Partner) => {
        return () => {
            onSelect && onSelect(partner)
        }
    }

    const [partnerSlice, partnerSliceCount] = partnerSlicer.slice(partners);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <FilterDialogButton
                                    filterCount={partnerSlicer.dynamicFilters.length}
                                    onClick={handleFilterDialogOpen}
                                />
                                <FilterDialog
                                    open={filterDialogOpen}
                                    onClose={handleFilterDialogClose}
                                />
                            </TableCell>
                            <TableCell>
                                Name
                                <SortOrderButton
                                    sortOrder={PARTNER_NAME_SORT_ORDER}
                                    currentSortOrder={partnerSlicer.sortOrder}
                                    currentSortDirection={partnerSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Partner ID
                                <SortOrderButton
                                    sortOrder={PARTNER_PARTNER_ID_SORT_ORDER}
                                    currentSortOrder={partnerSlicer.sortOrder}
                                    currentSortDirection={partnerSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                State
                                <SortOrderButton
                                    sortOrder={PARTNER_STATE_SORT_ORDER}
                                    currentSortOrder={partnerSlicer.sortOrder}
                                    currentSortDirection={partnerSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {partnerSlice.map((partner: Partner) => (
                            <Row key={partner.id.value} partner={partner} onSelect={handleSelectFactory(partner)}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={partnerSlicer.paginationRowsPerPageOptions}
                component="div"
                count={partnerSliceCount}
                rowsPerPage={partnerSlicer.paginationRowsPerPage}
                page={partnerSlicer.paginationPage}
                onRowsPerPageChange={handlePaginationRowsPerPageChange}
                onPageChange={handlePaginationPageChange}
            />
        </>
    );

}

export default PartnerTable;

