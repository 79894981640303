import {randomNumericalString} from "../../util";
import {DataMapper} from "../Entity";
import {AffiliateId} from "./AffiliateId";
import {stringCompare, stringContains} from "./String";

export class AffiliateCodeId {

    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public static toData(value: AffiliateCodeId): string {
        return value.value;
    }

    public static fromData(data: string): AffiliateCodeId {
        return new AffiliateCodeId(data);
    }

    public static random(affiliateId: AffiliateId): AffiliateCodeId {
        return new AffiliateCodeId("C-" + affiliateId.value.substring(2) + "-" + randomNumericalString(8));
    }

    static empty() {
        return new AffiliateCodeId("C-0000-00000000-00000000");
    }

}

export const AFFILIATE_CODE_ID_DATA_MAPPER: DataMapper<AffiliateCodeId, string> = {
    toData: AffiliateCodeId.toData,
    fromData: AffiliateCodeId.fromData
}

export function affiliateCodeIdContains(haystack: AffiliateCodeId, needle: string): boolean {
    return stringContains(haystack.value, needle);
}

export function affiliateCodeIdCompare(left: AffiliateCodeId, right: AffiliateCodeId): number {
    return stringCompare(left.value, right.value)
}

