import {collection, CollectionReference, doc, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {NOMINAL_DATE_DATA_MAPPER, nominalDate, NominalDate, nominalDateCompare} from "./value/NominalDate";
import {PASS_DURATION_DATA_MAPPER, PassDuration, passDurationCompare, SEVEN_DAYS} from "./value/PassDuration";
import {POSITIVE_INTEGER_DATA_MAPPER, PositiveInteger, positiveIntegerCompare} from "./value/PositiveInteger";
import {stringCompare, stringEquals} from "./value/String";

export enum VoucherState {

    DISABLED = "DISABLED",

    ENABLED = "ENABLED"

}

export type VoucherPayload = {
    state: VoucherState,
    name: Name,
    amount: PositiveInteger,
    passDuration: PassDuration,
    validUntil: NominalDate,
    region: IdRef<EntityType.REGION>
}

export type VoucherAnchor = EntityAnchor<EntityType.VOUCHER> & {
    region: IdAlike<EntityType.REGION>
};

export type PreparedVoucher = PreparedEntity<EntityType.VOUCHER> & VoucherPayload;

export type Voucher = Entity<EntityType.VOUCHER> & VoucherPayload;

export type PersistableVoucher = PreparedVoucher | Voucher;

export type VoucherManager = EntityManager<EntityType.VOUCHER, VoucherAnchor, PersistableVoucher, Voucher>;

export const VOUCHER_STATE_DATA_MAPPER: DataMapper<VoucherState, string> = {
    toData: (value: VoucherState) => value,
    fromData: (data: string) => data as VoucherState
}

export const VOUCHER_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVoucher, VoucherState, string>(
        "state",
        e => e.state,
        VOUCHER_STATE_DATA_MAPPER,
        VoucherState.ENABLED
    );

export const VOUCHER_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVoucher, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Voucher")
    );

export const VOUCHER_AMOUNT_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVoucher, PositiveInteger, number>(
        "amount",
        e => e.amount,
        POSITIVE_INTEGER_DATA_MAPPER,
        null
    );

export const VOUCHER_PASS_DURATION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVoucher, PassDuration, string>(
        "passDuration",
        e => e.passDuration,
        PASS_DURATION_DATA_MAPPER,
        SEVEN_DAYS
    );

export const VOUCHER_VALID_UNTIL_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVoucher, NominalDate, nominalDate>(
        "validUntil",
        e => e.validUntil,
        NOMINAL_DATE_DATA_MAPPER,
        null
    );

export const VOUCHER_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableVoucher>(
        EntityType.REGION,
        "region",
        e => e.region
    );

export type VoucherFilterColumn = EntityFilterColumn | "STATE" | "NAME";

export type VoucherFilterOperation<V> = FilterOperation<EntityType.VOUCHER, Voucher, VoucherFilterColumn, V>;

export type VoucherFilter<V> = Filter<EntityType.VOUCHER, Voucher, VoucherFilterColumn, V>;

export const VOUCHER_STATE_EQUALS_FILTER: VoucherFilterOperation<VoucherState> = {
    column: "STATE",
    apply: (voucher: Voucher, comparisonValue: VoucherState) => stringEquals(voucher.state, comparisonValue)
}

export const VOUCHER_NAME_CONTAINS_FILTER: VoucherFilterOperation<string> = {
    column: "NAME",
    apply: (voucher: Voucher, comparisonValue: string) => nameContains(voucher.name, comparisonValue)
}

export type VoucherSortColumn = EntitySortColumn | "STATE" | "NAME" | "AMOUNT" | "PASS_DURATION" | "VALID_UNTIL";

export type VoucherSortOrder = SortOrder<EntityType.VOUCHER, Voucher, VoucherSortColumn>;

export const VOUCHER_STATE_SORT_ORDER: VoucherSortOrder = {
    column: "STATE",
    apply: (left: Voucher, right: Voucher) => stringCompare(left.state, right.state)
}

export const VOUCHER_NAME_SORT_ORDER: VoucherSortOrder = {
    column: "NAME",
    apply: (left: Voucher, right: Voucher) => nameCompare(left.name, right.name)
}

export const VOUCHER_AMOUNT_SORT_ORDER: VoucherSortOrder = {
    column: "AMOUNT",
    apply: (left: Voucher, right: Voucher) => positiveIntegerCompare(left.amount, right.amount)
}

export const VOUCHER_PASS_DURATION_SORT_ORDER: VoucherSortOrder = {
    column: "PASS_DURATION",
    apply: (left: Voucher, right: Voucher) => passDurationCompare(left.passDuration, right.passDuration)
}

export const VOUCHER_VALID_UNTIL_SORT_ORDER: VoucherSortOrder = {
    column: "VALID_UNTIL",
    apply: (left: Voucher, right: Voucher) => nominalDateCompare(left.validUntil, right.validUntil)
}

class VoucherDefinition extends EntityDefinition<EntityType.VOUCHER, VoucherAnchor, PersistableVoucher, Voucher> {

    constructor() {
        super(
            EntityType.VOUCHER,
            [
                VOUCHER_STATE_ATTRIBUTE_DEFINITION,
                VOUCHER_NAME_ATTRIBUTE_DEFINITION,
                VOUCHER_AMOUNT_ATTRIBUTE_DEFINITION,
                VOUCHER_PASS_DURATION_ATTRIBUTE_DEFINITION,
                VOUCHER_VALID_UNTIL_ATTRIBUTE_DEFINITION,
                VOUCHER_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableVoucher): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableVoucher): VoucherAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.VOUCHER>): VoucherAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: VoucherAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.VOUCHER.toLowerCase());
    }

}

export const VOUCHER_DEFINITION = new VoucherDefinition();