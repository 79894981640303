import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";

export class WebsiteType {

    public readonly code: Code;

    constructor(code: Code) {
        this.code = code;
    }

    public static toData(value: WebsiteType): string {
        return value.code.value;
    }

    public static fromData(data: string): WebsiteType {
        return WebsiteType.fromCode(new Code(data));
    }

    public static fromCode(websiteTypeCode: Code): WebsiteType {
        const websiteType: Maybe<WebsiteType> = WEBSITE_TYPES.find(websiteType => websiteType.code.value === websiteTypeCode.value);
        if (websiteType === undefined) {
            throw new Error("Unknown website type: " + JSON.stringify(websiteTypeCode));
        }
        return websiteType;
    }

}

export const HOMEPAGE: WebsiteType = new WebsiteType(
    new Code("HOMEPAGE")
);

export const WEBSITE_TYPES: ReadonlyArray<WebsiteType> = [
    HOMEPAGE
] as const;

export const WEBSITE_TYPE_DATA_MAPPER: DataMapper<WebsiteType, string> = {
    toData: WebsiteType.toData,
    fromData: WebsiteType.fromData
}

