import {DataMapper} from "../Entity";
import {nominalDate, NominalDate} from "./NominalDate";
import {nominalTime, NominalTime} from "./NominalTime";
import {CHECK_IN, GUEST_LIST, ParticipationType} from "./ParticipationType";
import {stringCompare} from "./String";

export type checkIn = {
    type: string,
}

export type guestList = {
    type: string,
    closingDate: nominalDate,
    closingTime: nominalTime,
}

export type participation = checkIn | guestList;

export abstract class Participation {

    public readonly type: ParticipationType;

    protected constructor(type: ParticipationType) {
        this.type = type;
    }

    public static toData(value: Participation): participation {
        if (value.type.code.value === CHECK_IN.code.value) {
            const checkInValue = value as CheckIn;
            return {
                type: ParticipationType.toData(checkInValue.type),
            }
        } else {
            const guestListValue = value as GuestList;
            return {
                type: ParticipationType.toData(guestListValue.type),
                closingDate: NominalDate.toData(guestListValue.closingDate),
                closingTime: NominalTime.toData(guestListValue.closingTime)
            }
        }
    }

    public static fromData(data: participation): Participation {
        const type = ParticipationType.fromData(data.type);
        switch (type.code.value) {
            case "CHECK_IN":
                return new CheckIn();
            case "GUEST_LIST":
                const guestListData = data as guestList;
                return new GuestList(
                    NominalDate.fromData(guestListData.closingDate),
                    NominalTime.fromData(guestListData.closingTime)
                );
        }
        throw new Error("Unknown participation type: " + type.code.value);
    }

}

export class CheckIn extends Participation {

    constructor() {
        super(CHECK_IN)
    }

}

export class GuestList extends Participation {

    public readonly closingDate: NominalDate;

    public readonly closingTime: NominalTime;

    constructor(closingDate: NominalDate, closingTime: NominalTime) {
        super(GUEST_LIST)
        this.closingDate = closingDate;
        this.closingTime = closingTime;
    }

    public withClosingDate(closingDate: NominalDate): GuestList {
        return new GuestList(closingDate, this.closingTime);
    }

    public withClosingTime(closingTime: NominalTime): GuestList {
        return new GuestList(this.closingDate, closingTime);
    }

}

export const PARTICIPATION_DATA_MAPPER: DataMapper<Participation, participation> = {
    toData: Participation.toData,
    fromData: Participation.fromData
}

export function participationCompare(left: Participation, right: Participation): number {
    return stringCompare(left.type.name.value, right.type.name.value);
}
