import {doc, getDoc} from "@firebase/firestore";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {httpsCallable, HttpsCallable} from "firebase/functions";
import React, {useEffect, useState} from "react";
import {EntityScope, EntityState} from "../../../api/model/Entity";
import {Event} from "../../../api/model/Event";
import {Flag} from "../../../api/model/Flag";
import {Location} from "../../../api/model/Location";
import {Partner} from "../../../api/model/Partner";
import {Tag} from "../../../api/model/Tag";
import {Optional} from "../../../api/model/util/Optional";
import {Name} from "../../../api/model/value/Name";
import {GUEST_LIST} from "../../../api/model/value/ParticipationType";
import {stringCompare} from "../../../api/model/value/String";
import {csvEscape} from "../../../api/util";
import {useProject} from "../../../context/ProjectProvider";
import {database, functions} from "../../../firebase/firebase";
import DeleteButton from "../../form/DeleteButton";
import RemoveButton from "../../form/RemoveButton";
import RestoreButton from "../../form/RestoreButton";
import EventInput, {EventInputResult} from "./EventInput";


export interface Props {
    event: Event,
    onDelete: () => void
    onClose?: () => void
}

const EventUpdateForm: React.FunctionComponent<Props> = (props: Props) => {

    const {event: initialEvent, onDelete, onClose} = props

    const project = useProject();
    const eventManager = useProject().eventManager;
    const [editing, setEditing] = useState<boolean>(false);
    const [latestEvent, setLatestEvent] = useState<Event>(initialEvent)
    const [currentEvent, setCurrentEvent] = useState<Event>(initialEvent)
    const [partners, setPartners] = useState<ReadonlyArray<Partner>>([]);
    const [locations, setLocations] = useState<ReadonlyArray<Location>>([]);
    const [tags, setTags] = useState<ReadonlyArray<Tag>>([]);
    const [flags, setFlags] = useState<Optional<ReadonlyArray<Flag>>>(null);

    useEffect(() => {

        const loadPartners = async () => {
            setPartners(await project.partnerManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadPartners().catch(console.error)

    }, []);


    useEffect(() => {

        const loadLocations = async () => {
            setLocations(await project.locationManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadLocations().catch(console.error)

    }, []);

    useEffect(() => {

        const loadTags = async () => {
            setTags(await project.tagManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadTags().catch(console.error)

    }, []);

    useEffect(() => {

        const loadFlags = async () => {
            setFlags(await project.flagManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadFlags().catch(console.error)

    }, []);

    const handleEdit = () => {
        setEditing(true);
        setCurrentEvent({...latestEvent});
    }

    const handleDownloadGuestList = async () => {

        const docRef = doc(database, "guestlist", currentEvent.id.value);
        const docSnap = await getDoc(docRef);

        const guests: Array<string> = []
        if (docSnap.exists()) {
            const guestlist = docSnap.data();
            const users = guestlist.user;
            if (Array.isArray(users)) {
                users.forEach(user => {
                    const firstName = user.firstName || "";
                    const lastName = user.lastName || "";
                    guests.push(lastName + ", " + firstName);
                });
            }
        }

        const rows = guests
            .sort((left: string, right: string) => stringCompare(left, right))
            .map(fullName => {
                return [
                    csvEscape(fullName)
                ]
            });

        const csv = rows.map((row: string[]) => row.join(",")).join("\r\n");
        const uri = "data:text/csv;charset=utf-8," + csv;
        const encodedUri = encodeURI(uri);
        const filename = currentEvent.name.value.trim() + ".csv"

        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        link.click()

    }
    const handleSendGuestList = async () => {

        console.log("SENDING GUEST LIST")

        const sendGuestList: HttpsCallable = httpsCallable(functions, 'sendGuestListOnCall');
        const response = await sendGuestList({
            region: project.selectedRegion.id.value,
            event: currentEvent.id.value,
            mode: "manual"
        });

        console.log("RESPONSE", response)

    }

    const handleClone = async () => {
        const clonedEvent = await eventManager.persist({
            ...latestEvent,
            entityScope: EntityScope.PREPARED,
            name: new Name(latestEvent.name.value + " (Clone)")
        });
        setLatestEvent(clonedEvent);
        setCurrentEvent(clonedEvent);
    }

    const handleCancel = () => {
        setEditing(false);
        setCurrentEvent(latestEvent);
    }

    const handlePersist = async (eventInputResult: EventInputResult) => {
        setEditing(false);
        const updatedEvent = await eventManager.persist({...latestEvent, ...eventInputResult});
        setLatestEvent(updatedEvent);
        setCurrentEvent(updatedEvent);
    }

    const handleRemove = async () => {
        const updatedEvent = await eventManager.removeById(latestEvent);
        setLatestEvent(updatedEvent);
        setCurrentEvent(updatedEvent);
    }

    const handleRestore = async () => {
        const updatedEvent = await eventManager.restoreById(latestEvent);
        setLatestEvent(updatedEvent);
        setCurrentEvent(updatedEvent);
    }

    const handleDelete = async () => {
        await eventManager.deleteById(latestEvent);
        onDelete();
    }

    const handleClose = () => {
        onClose && onClose();
    }

    const hasGuestList = currentEvent.participation.type.code.value === GUEST_LIST.code.value;

    return (
        <Stack sx={{p: 1}} spacing={3}>
            {partners.length === 0 || locations.length === 0 || tags.length === 0 || flags === null ?
                <Typography variant={"h6"}>
                    No partners or locations or no tags added yet!
                </Typography> :
                <EventInput
                    event={currentEvent}
                    disabled={!editing}
                    partners={partners}
                    locations={locations}
                    tags={tags}
                    flags={flags}
                    size="small"
                    onPersist={currentEvent.entityState === EntityState.CREATED ? handlePersist : undefined}
                />}
            <Stack direction={"row"} spacing={1}>
                {editing && <>
                    <Button variant={"outlined"} onClick={handleCancel}>
                        Cancel
                    </Button>
                </>}
                {!editing && <>
                    {currentEvent.entityState === EntityState.CREATED &&
                        <Button variant={"contained"} onClick={handleEdit}>Edit</Button>}
                    {currentEvent.entityState === EntityState.CREATED &&
                        <Button
                            variant={"outlined"}
                            onClick={handleDownloadGuestList}
                            disabled={!hasGuestList}>
                            Download Guest List
                        </Button>}
                    {currentEvent.entityState === EntityState.CREATED &&
                        <Button
                            variant={"outlined"}
                            onClick={handleSendGuestList}
                            disabled={!hasGuestList}>
                            Send Guest List
                        </Button>}
                    {currentEvent.entityState === EntityState.CREATED &&
                        <Button variant={"outlined"} onClick={handleClone}>Clone</Button>}
                    {currentEvent.entityState === EntityState.REMOVED &&
                        <DeleteButton disabled={false} onDelete={handleDelete}/>}
                    {currentEvent.entityState === EntityState.REMOVED &&
                        <RestoreButton disabled={false} onRestore={handleRestore}/>}
                    {currentEvent.entityState === EntityState.CREATED &&
                        <RemoveButton disabled={false} onRemove={handleRemove}/>}
                    {onClose && <Button variant={"outlined"} onClick={handleClose}>
                        Close
                    </Button>}
                </>}
            </Stack>
        </Stack>
    );

};

export default EventUpdateForm;
