import React from 'react';
import FlagSlicerProvider from "../component/entity/flag/FlagSlicerProvider";
import FlagsPage from "../component/entity/flag/FlagsPage";

function FlagRoute() {

    return (
        <FlagSlicerProvider>
            <FlagsPage/>
        </FlagSlicerProvider>
    );

}

export default FlagRoute;
