import {collection, CollectionReference, doc, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {Category, CATEGORY_DATA_MAPPER, categoryCompare, categoryEquals} from "./value/Category";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {label, Label, LABEL_DATA_MAPPER} from "./value/Label";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {stringCompare, stringEquals} from "./value/String";

export enum TagState {

    ENABLED = "ENABLED",

    DISABLED = "DISABLED"

}

export type TagPayload = {
    state: TagState,
    name: Name,
    description: Label,
    category: Category,
    region: IdRef<EntityType.REGION>
}

export type TagAnchor = EntityAnchor<EntityType.TAG> & {
    region: IdAlike<EntityType.REGION>
}

export type PreparedTag = PreparedEntity<EntityType.TAG> & TagPayload;

export type Tag = Entity<EntityType.TAG> & TagPayload;

export type PersistableTag = PreparedTag | Tag;

export type TagManager = EntityManager<EntityType.TAG, TagAnchor, PersistableTag, Tag>;

export const TAG_STATE_DATA_MAPPER: DataMapper<TagState, string> = {
    toData: (value: TagState) => value,
    fromData: (data: string) => data as TagState
}

export const TAG_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableTag, TagState, string>(
        "state",
        e => e.state,
        TAG_STATE_DATA_MAPPER,
        TagState.ENABLED
    );

export const TAG_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableTag, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Tag")
    );

export const TAG_DESCRIPTION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableTag, Label, label>(
        "description",
        e => e.description,
        LABEL_DATA_MAPPER,
        new Label("")
    );

export const TAG_CATEGORY_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableTag, Category, string>(
        "category",
        e => e.category,
        CATEGORY_DATA_MAPPER,
        null
    );

export const TAG_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableTag>(
        EntityType.REGION,
        "region",
        e => e.region
    );

export type TagFilterColumn = EntityFilterColumn | "STATE" | "NAME" | "CATEGORY";

export type TagFilterOperation<V> = FilterOperation<EntityType.TAG, Tag, TagFilterColumn, V>;

export type TagFilter<V> = Filter<EntityType.TAG, Tag, TagFilterColumn, V>;

export const TAG_STATE_EQUALS_FILTER: TagFilterOperation<TagState> = {
    column: "STATE",
    apply: (tag: Tag, comparisonValue: TagState) => stringEquals(tag.state, comparisonValue)
}

export const TAG_NAME_CONTAINS_FILTER: TagFilterOperation<string> = {
    column: "NAME",
    apply: (tag: Tag, comparisonValue: string) => nameContains(tag.name, comparisonValue)
}

export const TAG_CATEGORY_EQUALS_FILTER: TagFilterOperation<Category> = {
    column: "CATEGORY",
    apply: (tag: Tag, comparisonValue: Category) => categoryEquals(tag.category, comparisonValue)
}

export type TagSortColumn = EntitySortColumn | "STATE" | "NAME" | "CATEGORY";

export type TagSortOrder = SortOrder<EntityType.TAG, Tag, TagSortColumn>;

export const TAG_STATE_SORT_ORDER: TagSortOrder = {
    column: "STATE",
    apply: (left: Tag, right: Tag) => stringCompare(left.state, right.state)
}

export const TAG_NAME_SORT_ORDER: TagSortOrder = {
    column: "NAME",
    apply: (left: Tag, right: Tag) => nameCompare(left.name, right.name)
}

export const TAG_CATEGORY_SORT_ORDER: TagSortOrder = {
    column: "CATEGORY",
    apply: (left: Tag, right: Tag) => categoryCompare(left.category, right.category)
}

class TagDefinition extends EntityDefinition<EntityType.TAG, TagAnchor, PersistableTag, Tag> {

    constructor() {
        super(
            EntityType.TAG,
            [
                TAG_STATE_ATTRIBUTE_DEFINITION,
                TAG_NAME_ATTRIBUTE_DEFINITION,
                TAG_DESCRIPTION_ATTRIBUTE_DEFINITION,
                TAG_CATEGORY_ATTRIBUTE_DEFINITION,
                TAG_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableTag): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableTag): TagAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.TAG>): TagAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: TagAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.TAG.toLowerCase());
    }

}

export const TAG_DEFINITION = new TagDefinition();