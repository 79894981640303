import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import React from "react";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: ReadonlyArray<string>,
    options: ReadonlyArray<{ value: string, label: string }>,
    disabled: boolean,
    size: FormSize;
    setValue: (value: ReadonlyArray<string>) => void
}

const StringOptionsInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, options, disabled, size, setValue} = props

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value;
        setValue(typeof value === 'string' ? value.split(',') : value,);
    };

    const vs = value.filter(v => options.findIndex(option => option.value === v) !== -1);

    return (
        <Select
            id={id}
            value={vs}
            multiple
            disabled={disabled}
            size={size}
            onChange={handleChange}
            input={<OutlinedInput/>}
            renderValue={(selected: string[]) => selected.map(s => options.find(t => t.value === s)!.label).join(', ')}
        >
            {options.map(option =>
                <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={value.indexOf(option.value) > -1}/>
                    <ListItemText primary={option.label}/>
                </MenuItem>
            )}
        </Select>
    )

};

export default StringOptionsInput;
