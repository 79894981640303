import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {CountryCode} from "./CountryCode";
import {Name} from "./Name";
import {stringCompare} from "./String";

export class PaymentType {

    public readonly name: Name;

    public readonly code: CountryCode;

    constructor(name: Name, code: Code) {
        this.name = name;
        this.code = code;
    }

    public static toData(value: PaymentType): string {
        return value.code.value;
    }

    public static fromData(data: string): PaymentType {
        return PaymentType.fromCode(new Code(data));
    }

    public static fromCode(paymentTypeCode: Code): PaymentType {
        const paymentType: Maybe<PaymentType> = PAYMENT_TYPES.find(paymentType => paymentType.code.value === paymentTypeCode.value);
        if (paymentType === undefined) {
            throw new Error("Unknown payment type: " + JSON.stringify(paymentTypeCode));
        }
        return paymentType;
    }

}

export const UNKNOWN: PaymentType = new PaymentType(
    new Name("Unknown"),
    new Code("UNKNOWN")
);

export const VOUCHER: PaymentType = new PaymentType(
    new Name("Voucher"),
    new Code("VOUCHER")
);

export const PAYPAL: PaymentType = new PaymentType(
    new Name("PayPal"),
    new Code("PAYPAL")
);

export const PAYMENT_TYPES: ReadonlyArray<PaymentType> = [
    UNKNOWN,
    VOUCHER,
    PAYPAL,
] as const;

export const PAYMENT_TYPE_DATA_MAPPER: DataMapper<PaymentType, string> = {
    toData: PaymentType.toData,
    fromData: PaymentType.fromData
}

export function paymentTypeEquals(left: PaymentType, right: PaymentType): boolean {
    return left.code.value === right.code.value;
}

export function paymentTypeCompare(left: PaymentType, right: PaymentType): number {
    return stringCompare(left.name.value, right.name.value)
}
