import React, {useEffect, useState} from "react";
import {Tag} from "../../../api/model/Tag";
import {Project, useProject} from "../../../context/ProjectProvider";
import TagTable from "./TagTable";

export interface Props {
    onSelect?: (tag: Tag) => void
}

const TagList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [tags, setTags] = useState<ReadonlyArray<Tag>>([]);

    useEffect(() => {

        const loadTags = async () => {

            const tags = await project.tagManager.getAllAsArray({region: project.selectedRegion});
            setTags(tags);

        }

        loadTags().catch(console.error);

    }, [project]);

    return (
        <TagTable
            tags={tags}
            onSelect={onSelect}
        />
    );

};

export default TagList;
