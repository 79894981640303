import {leftPadWithZero} from "../../util";
import {DataMapper} from "../Entity";
import {dateAfter, dateCompare} from "./Date";
import {Name} from "./Name";
import {stringContains} from "./String";

export type nominalDate = {
    year: number;
    month: number;
    day: number;
}

export class NominalDate {

    public readonly year: number;

    public readonly month: number;

    public readonly day: number;

    constructor(year: number, month: number, day: number) {
        if (year < 2000 || year > 2100) {
            throw new Error("Invalid year: " + year);
        }
        if (month < 1 || month > 12) {
            throw new Error("Invalid month: " + month);
        }
        if (day < 1 || day > 31) {
            throw new Error("Invalid day: " + day);
        }
        this.year = year;
        this.month = month;
        this.day = day;
    }

    public withYear(year: number): NominalDate {
        return new NominalDate(year, this.month, this.day);
    }

    public withMonth(month: number): NominalDate {
        return new NominalDate(this.year, month, this.day);
    }

    public withDay(day: number): NominalDate {
        return new NominalDate(this.year, this.month, day);
    }

    public toDate(): Date {
        return new Date(this.year, this.month - 1, this.day);
    }

    public toUTCTime(): number {
        return Date.UTC(this.year, this.month - 1, this.day);
    }

    public toString(): string {
        return this.year + "-" + leftPadWithZero(this.month, 2) + "-" + leftPadWithZero(this.day, 2);
    }

    public static toData(value: NominalDate): nominalDate {
        return {
            year: value.year,
            month: value.month,
            day: value.day
        };
    }

    static fromData(data: nominalDate): NominalDate {
        return new NominalDate(
            data.year,
            data.month,
            data.day
        );
    }

    static fromLocalDate(localDate: Date) {
        return new NominalDate(localDate.getFullYear(), localDate.getMonth() + 1, localDate.getDate())
    }

    static fromUTCDate(localDate: Date) {
        return new NominalDate(localDate.getUTCFullYear(), localDate.getUTCMonth() + 1, localDate.getUTCDate())
    }

}

export const NOMINAL_DATE_DATA_MAPPER: DataMapper<NominalDate, nominalDate> = {
    toData: NominalDate.toData,
    fromData: NominalDate.fromData
}


export function nominalDateAfter(haystack: NominalDate, needle: Date): boolean {
    return dateAfter(haystack.toDate(), needle);
}

export function nominalDateCompare(left: NominalDate, right: NominalDate): number {
    return dateCompare(left.toDate(), right.toDate());
}

