import {DataMapper} from "../Entity";

export enum EntityType {

    AFFILIATE = "AFFILIATE",

    AFFILIATE_CODE = "AFFILIATE_CODE",

    BUNDLE = "BUNDLE",

    CUSTOMER = "CUSTOMER",

    EVENT = "EVENT",

    FLAG = "FLAG",

    GUEST = "GUEST",

    IMAGE = "IMAGE",

    LOCATION = "LOCATION",

    PAGE = "PAGE",

    PARTNER = "PARTNER",

    PRODUCT = "PRODUCT",

    PURCHASE = "PURCHASE",

    REGION = "REGION",

    TAG = "TAG",

    USER = "USER",

    VENUE = "VENUE",

    VOUCHER = "VOUCHER",

    VOUCHER_CODE = "VOUCHER_CODE"

}

export const ENTITY_TYPE_DATA_MAPPER: DataMapper<EntityType, string> = {
    toData: (value: EntityType) => value,
    fromData: (data: string) => data as EntityType
}