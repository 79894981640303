import {randomNumericalString} from "../../util";
import {DataMapper} from "../Entity";
import {stringCompare} from "./String";

export class AffiliateId {

    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public static toData(value: AffiliateId): string {
        return value.value;
    }

    public static fromData(data: string): AffiliateId {
        return new AffiliateId(data);
    }

    public static random(): AffiliateId {
        return new AffiliateId("A-" + new Date().getFullYear() + "-" + randomNumericalString(8));
    }

    static empty() {
        return new AffiliateId("A-0000-00000000");
    }

}

export const AFFILIATE_ID_DATA_MAPPER: DataMapper<AffiliateId, string> = {
    toData: AffiliateId.toData,
    fromData: AffiliateId.fromData
}

export function affiliateIdCompare(left: AffiliateId, right: AffiliateId): number {
    return stringCompare(left.value, right.value)
}

