import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";
import {Page, PagePayload, PageState} from "../../../api/model/Page";
import {Label} from "../../../api/model/value/Label";
import {Name} from "../../../api/model/value/Name";
import {Slot, SLOTS} from "../../../api/model/value/Slot";
import FormRow from "../../form/FormRow";
import {FormSize} from "../../form/FormSize";
import LabelInput, {LabelType} from "../../input/LabelInput";
import NameInput from "../../input/NameInput";
import PageStateInput from "../../input/PageStateInput";
import SlotInput from "../../input/SlotInput";
import StringOutput from "../../output/StringOutput";

export type PageInputResult = Omit<PagePayload, "region">;

export interface Props {
    page?: Page,
    disabled: boolean,
    size: FormSize,
    onPersist?: (partialPage: PageInputResult) => void
}

const PageInput: React.FunctionComponent<Props> = (props: Props) => {

    const {page, disabled, size, onPersist} = props

    const [state, setState] = useState<PageState>(PageState.ENABLED);
    const [slot, setSlot] = useState<Slot>(SLOTS[0]);
    const [name, setName] = useState<Name>(new Name(""));
    const [description, setDescription] = useState<Label>(new Label(""));

    useEffect(() => {
        if (page) {
            setState(page.state);
            setSlot(page.slot);
            setName(page.name);
            setDescription(page.description);
        }
    }, [page]);

    const handlePersist = () => {

        const pageInputResult: PageInputResult = {
            state,
            slot,
            name,
            description
        };

        onPersist && onPersist(pageInputResult);

    }

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {page?.id && <FormRow label="Id">
                    <StringOutput
                        value={page.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <PageStateInput
                        id="state"
                        value={state}
                        disabled={disabled}
                        size={size}
                        setValue={setState}
                    />
                </FormRow>
                <FormRow label="Slot">
                    <SlotInput
                        id="slot"
                        value={slot}
                        disabled={disabled}
                        size={size}
                        setValue={setSlot}
                    />
                </FormRow>
                <FormRow label="Name">
                    <NameInput
                        id="name"
                        value={name}
                        disabled={disabled}
                        size={size}
                        setValue={setName}
                    />
                </FormRow>
                <FormRow label="Description">
                    <LabelInput
                        id="description"
                        value={description}
                        type={LabelType.HTML}
                        disabled={disabled}
                        size={size}
                        allowLinks={true}
                        setValue={setDescription}
                    />
                </FormRow>
            </Grid>
            {onPersist && <Stack direction={"row"} spacing={1}>
                <Button
                    variant={"contained"}
                    disabled={disabled}
                    onClick={handlePersist}
                >
                    Save
                </Button>
            </Stack>}
        </>
    );

};

export default PageInput;
