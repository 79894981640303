import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {AffiliateCodePayload, AffiliateCodeState} from "../../../../api/model/AffiliateCode";
import {Optional} from "../../../../api/model/util/Optional";
import {Name} from "../../../../api/model/value/Name";
import {PositiveInteger} from "../../../../api/model/value/PositiveInteger";
import {Target, WEBAPP} from "../../../../api/model/value/Target";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import AffiliateCodeStateInput2 from "../../../input/AffiliateCodeStateInput2";
import NameInput2 from "../../../input/NameInput2";
import PositiveIntegerInput2 from "../../../input/PositiveIntegerInput2";
import TargetInput2 from "../../../input/TargetInput2";
import AffiliateCodeQrCodeOutput from "../../../output/AffiliateCodeQrCodeOutput";
import AffiliateCodeUrlOutput from "../../../output/AffiliateCodeUrlOutput";
import StringOutput from "../../../output/StringOutput";

export type CodeCore = Pick<AffiliateCodePayload, "state" | "name" | "target" | "eligibleDays">;

export interface Props {
    code?: AffiliateCodePayload,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => CodeCore>>,
    reset: number
}

const AffiliateCodeCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {code, disabled, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => AffiliateCodeState>> = useRef<Optional<() => AffiliateCodeState>>(null)
    const getNameRef: MutableRefObject<Optional<() => Name>> = useRef<Optional<() => Name>>(null)
    const getTargetRef: MutableRefObject<Optional<() => Target>> = useRef<Optional<() => Target>>(null)
    const getEligibleDaysRef: MutableRefObject<Optional<() => PositiveInteger>> = useRef<Optional<() => PositiveInteger>>(null)

    getValue.current = () => ({
        state: getStateRef.current!(),
        name: getNameRef.current!(),
        target: getTargetRef.current!(),
        eligibleDays: getEligibleDaysRef.current!(),
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                <FormRow label="State">
                    <AffiliateCodeStateInput2
                        id="state"
                        value={code?.state || AffiliateCodeState.ENABLED}
                        disabled={disabled}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                {code && <FormRow label="Code">
                    <StringOutput
                        value={code.codeId.value}
                        size={size}
                    />
                </FormRow>}
                {code &&
                    <FormRow label="Code URL">
                        <AffiliateCodeUrlOutput
                            value={code}
                            size={size}
                        />
                    </FormRow>}
                {code &&
                    <FormRow label="AffiliateCode URL">
                        <AffiliateCodeQrCodeOutput
                            value={code}
                            size={size}
                        />
                    </FormRow>}
                <FormRow label="Name">
                    <NameInput2
                        id="name"
                        value={code?.name || new Name("")}
                        disabled={disabled}
                        size={size}
                        getValue={getNameRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Target">
                    <TargetInput2
                        id="target"
                        value={code?.target || WEBAPP}
                        disabled={disabled}
                        size={size}
                        getValue={getTargetRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Eligible Days">
                    <PositiveIntegerInput2
                        id="eligible-days"
                        value={code?.eligibleDays || new PositiveInteger(14)}
                        max={new PositiveInteger(365)}
                        adornmentEnd={"days"}
                        disabled={disabled}
                        size={size}
                        getValue={getEligibleDaysRef}
                        reset={reset}
                    />
                </FormRow>
            </Grid>
        </>
    );

};

export default AffiliateCodeCoreInput;
