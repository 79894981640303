import {DataMapper} from "../Entity";
import {Country} from "./Country";
import {stringCompare, stringContains} from "./String";

export type address = {
    street: string,
    streetNumber: string,
    city: string,
    zip: string,
    country: string,
}

export class Address {

    public readonly street: string;

    public readonly streetNumber: string;

    public readonly city: string;

    public readonly zip: string;

    public readonly country: Country;

    constructor(street: string, streetNumber: string, city: string, zip: string, country: Country) {
        this.street = street;
        this.streetNumber = streetNumber;
        this.city = city;
        this.zip = zip;
        this.country = country;
    }

    public withStreet(street: string): Address {
        return new Address(street, this.streetNumber, this.city, this.zip, this.country)
    }

    public withStreetNumber(streetNumber: string): Address {
        return new Address(this.street, streetNumber, this.city, this.zip, this.country)
    }

    public withCity(city: string): Address {
        return new Address(this.street, this.streetNumber, city, this.zip, this.country)
    }

    public withZip(zip: string): Address {
        return new Address(this.street, this.streetNumber, this.city, zip, this.country)
    }

    public withCountry(country: Country): Address {
        return new Address(this.street, this.streetNumber, this.city, this.zip, country)
    }

    public toAddressString(singleLine: boolean): string {
        const delimiter = singleLine ? ", " : "\n";
        return this.street + " " + this.streetNumber + delimiter + this.zip + " " + this.city + delimiter + this.country.name.value;
    }

    public static toData(value: Address): address {
        return {
            street: value.street,
            streetNumber: value.streetNumber,
            city: value.city,
            zip: value.zip,
            country: Country.toData(value.country),
        }
    }

    public static fromData(data: address): Address {
        return new Address(
            data.street,
            data.streetNumber,
            data.city,
            data.zip,
            Country.fromData(data.country)
        )
    }

}

export const ADDRESS_DATA_MAPPER: DataMapper<Address, address> = {
    toData: Address.toData,
    fromData: Address.fromData
}

export function addressContains(haystack: Address, needle: string): boolean {
    return stringContains(haystack.toAddressString(true), needle);
}

export function addressCompare(left: Address, right: Address): number {
    return stringCompare(left.toAddressString(true), right.toAddressString(true));
}



