import React, {createContext, useContext, useEffect, useState} from "react";
import {SortDirection} from "../../../api/model/Entity";
import {Flag, FLAG_NAME_SORT_ORDER, FlagFilter, FlagSortOrder} from "../../../api/model/Flag";

export type Props = {}

export type FlagSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: FlagSortOrder,
    setSortOrder: (sortOrder: FlagSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<FlagFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<FlagFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<FlagFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<FlagFilter<any>>) => void,
    slice: (flags: ReadonlyArray<Flag>) => [ReadonlyArray<Flag>, number]
}

const FlagSlicerContext = createContext<FlagSlicer>(null!);

const FlagSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<FlagSortOrder>(FLAG_NAME_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<FlagFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<FlagFilter<any>>>([]);

    useEffect(() => {

        console.log("PAGE", paginationRowsPerPage, paginationPage);

    }, [paginationRowsPerPageOptions, paginationRowsPerPage, paginationPage]);


    const slice = (flags: ReadonlyArray<Flag>): [ReadonlyArray<Flag>, number] => {

        let filteredFlags = flags;
        staticFilters.forEach(filter => {
            filteredFlags = filteredFlags.filter(flag => filter.operation.apply(flag, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredFlags = filteredFlags.filter(flag => filter.operation.apply(flag, filter.comparisonValue));
        });

        const sortedFlags = [...filteredFlags].sort((left: Flag, right: Flag) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedFlags.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedFlags = sortedFlags.slice(start, end);

        return [paginatedFlags, filteredFlags.length];

    }

    const value: FlagSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <FlagSlicerContext.Provider value={value}>
            {children}
        </FlagSlicerContext.Provider>
    );

};

export function useFlagSlicer(): FlagSlicer {
    return useContext(FlagSlicerContext);
}

export default FlagSlicerProvider;