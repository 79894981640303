import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {Name} from "./Name";
import {stringCompare} from "./String";

export class Slot {

    public readonly name: Name;

    public readonly code: Code;

    constructor(name: Name, code: Code) {
        this.name = name;
        this.code = code;
    }

    public static toData(value: Slot): string {
        return value.code.value;
    }

    public static fromData(data: string): Slot {
        return Slot.fromCode(new Code(data));
    }

    public static fromCode(slotCode: Code): Slot {
        const slot: Maybe<Slot> = SLOTS.find(slot => slot.code.value === slotCode.value);
        if (slot === undefined) {
            throw new Error("Unknown slot: " + JSON.stringify(slotCode));
        }
        return slot;
    }

}

export const LEGAL_DATA: Slot = new Slot(
    new Name("Legal Data"),
    new Code("LEGAL_DATA")
);

export const PRIVACY_POLICY: Slot = new Slot(
    new Name("Privacy Policy"),
    new Code("PRIVACY_POLICY")
);

export const TERMS_AND_CONDITIONS: Slot = new Slot(
    new Name("Terms and Conditions"),
    new Code("TERMS_AND_CONDITIONS")
);

export const WAIVER_OF_CANCELLATION_RIGHT: Slot = new Slot(
    new Name("Waiver of Cancellation Right"),
    new Code("WAIVER_OF_CANCELLATION_RIGHT")
);

export const SLOTS: ReadonlyArray<Slot> = [
    LEGAL_DATA,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS,
    WAIVER_OF_CANCELLATION_RIGHT
] as const;

export const SLOT_DATA_MAPPER: DataMapper<Slot, string> = {
    toData: Slot.toData,
    fromData: Slot.fromData
}

export function slotEquals(left: Slot, right: Slot): boolean {
    return left.code.value === right.code.value;
}

export function slotCompare(left: Slot, right: Slot): number {
    return stringCompare(left.code.value, right.code.value)
}