import {
    getDownloadURL,
    ref,
    StorageError,
    uploadBytesResumable,
    UploadTask,
    UploadTaskSnapshot
} from "@firebase/storage";
import {useEffect, useState} from "react";
import {v4} from 'uuid';
import {Optional} from "../api/model/util/Optional";
import {ImageFormat} from "../api/model/value/ImageFormat";
import {StorageData} from "../api/model/value/StorageData";
import {StoredImage} from "../api/model/value/StoredImage";
import {Url} from "../api/model/value/Url";
import {storage} from "../firebase/firebase";

const useImageStore = (file: Optional<File>) => {

    const [progress, setProgress] = useState<number>(0);
    const [error, setError] = useState<Optional<StorageError>>(null);
    const [image, setImage] = useState<Optional<StoredImage>>(null);

    useEffect(() => {

        if (file) {

            const format = ImageFormat.fromMimeType(file.type);
            const iconRef = ref(storage, "image/" + v4() + "/original" + format.extension);
            const uploadTask: UploadTask = uploadBytesResumable(iconRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot: UploadTaskSnapshot) => {
                    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error: StorageError) => {
                    setError(error);
                },
                async () => {

                    const bucket = uploadTask.snapshot.metadata.bucket;
                    const fullPath = uploadTask.snapshot.metadata.fullPath;
                    const name = uploadTask.snapshot.metadata.name;
                    const size = uploadTask.snapshot.metadata.size;

                    const downloadUrl: string = await getDownloadURL(uploadTask.snapshot.ref)

                    setProgress(100);
                    setImage(new StoredImage(
                        format,
                        new StorageData(bucket, fullPath, name, size),
                        new Url(downloadUrl)
                    ));

                }
            );

        } else {
            setProgress(0);
            setError(null);
            setImage(null);
        }

    }, [file]);

    return {progress, error, image}

}

export default useImageStore;