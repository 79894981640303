import {Optional} from "../util/Optional";
import {EntityType} from "./EntityType";

export class Id<T extends EntityType> {

    public readonly type: T;

    public readonly path: string;

    public readonly value: string;

    constructor(type: T, path: string) {
        this.type = type;
        this.path = path;
        this.value = path.substring(path.lastIndexOf("/") + 1);
    }

    public parentPath(): Optional<string> {
        const parentColPos = this.path.lastIndexOf("/");
        if (parentColPos !== -1) {
            const parentColPath = this.path.substring(0, parentColPos)
            const parentDocPos = parentColPath.lastIndexOf("/");
            if (parentDocPos !== -1) {
                const parentDocPath = parentColPath.substring(0, parentDocPos)
                return parentDocPath;
            }
        }
        return null;
    }
}

