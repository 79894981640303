import EditOutlined from '@mui/icons-material/EditOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from "@mui/material/TablePagination";
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import {ENTITY_ID_CONTAINS_FILTER, SortDirection} from "../../../api/model/Entity";
import {
    Location,
    LOCATION_ADDRESS_CITY_SORT_ORDER,
    LOCATION_ADDRESS_CONTAINS_FILTER,
    LOCATION_ADDRESS_STREET_NUMBER_SORT_ORDER,
    LOCATION_ADDRESS_STREET_SORT_ORDER,
    LOCATION_ADDRESS_ZIP_SORT_ORDER,
    LOCATION_NAME_CONTAINS_FILTER,
    LOCATION_NAME_SORT_ORDER,
    LOCATION_STATE_SORT_ORDER,
    LocationFilter,
    LocationSortOrder
} from "../../../api/model/Location";
import FilterDialogButton from "../../form/FilterDialogButton";
import FilterFormButtons from "../../form/FilterFormButtons";
import FilterFormRow from "../../form/FilterFormRow";
import SortOrderButton from "../../form/SortOrderButton";
import StringInput from "../../input/StringInput";
import {useLocationSlicer} from "./LocationSlicerProvider";

interface FilterDialogProps {
    open: boolean;
    onClose: (filter: InternalFilter) => void;
}

const FilterDialog: React.FunctionComponent<FilterDialogProps> = (props: FilterDialogProps) => {

    const {open, onClose} = props;

    const [id, setId] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [address, setAddress] = useState<string>("");

    const handleClear = () => {
        setId("");
        setName("");
        setAddress("");
    };

    const handleClose = () => {
        onClose({
            id: id.length === 0 ? undefined : id,
            name: name.length === 0 ? undefined : name,
            address: address.length === 0 ? undefined : address
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Filter</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FilterFormRow label="ID" operation="contains">
                    <StringInput
                        id="id"
                        value={id}
                        disabled={false}
                        size="small"
                        setValue={setId}
                    />
                </FilterFormRow>
                <FilterFormRow label="Name" operation="contains">
                    <StringInput
                        id="name"
                        value={name}
                        disabled={false}
                        size="small"
                        setValue={setName}
                    />
                </FilterFormRow>
                <FilterFormRow label="Address" operation="contains">
                    <StringInput
                        id="address"
                        value={address}
                        disabled={false}
                        size="small"
                        setValue={setAddress}
                    />
                </FilterFormRow>
                <FilterFormButtons onClear={handleClear} onClose={handleClose}/>
            </Grid>
        </Dialog>
    );
}

interface RowProps {
    location: Location,
    onSelect: (location: Location) => void
}

const Row: React.FunctionComponent<RowProps> = ({location, onSelect}: RowProps) => {
    return (
        <TableRow>
            <TableCell padding={"checkbox"}>
                <IconButton size="small" onClick={() => onSelect(location)}>
                    <EditOutlined/>
                </IconButton>
            </TableCell>
            <TableCell>{location.name.value}</TableCell>
            <TableCell>{location.address.street}</TableCell>
            <TableCell>{location.address.streetNumber}</TableCell>
            <TableCell>{location.address.zip}</TableCell>
            <TableCell>{location.address.city}</TableCell>
            <TableCell>{location.state}</TableCell>
        </TableRow>
    );
}

export interface Props {
    locations: ReadonlyArray<Location>,
    onSelect?: (location: Location) => void
}

type InternalFilter = {
    id?: string,
    name?: string,
    address?: string
}

const LocationTable: React.FunctionComponent<Props> = ({locations, onSelect}: Props) => {

    const locationSlicer = useLocationSlicer();

    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const [internalFilter, setInternalFilter] = useState<InternalFilter>({});

    const handleFilterDialogOpen = () => {
        setFilterDialogOpen(true);
    };

    const handleFilterDialogClose = (filter: InternalFilter) => {
        setFilterDialogOpen(false);
        setInternalFilter(filter)
    };

    useEffect(() => {

        const dynamicFilters = new Array<LocationFilter<any>>();

        if (internalFilter.id) {
            dynamicFilters.push({
                operation: ENTITY_ID_CONTAINS_FILTER,
                comparisonValue: internalFilter.id
            });
        }
        if (internalFilter.name) {
            dynamicFilters.push({
                operation: LOCATION_NAME_CONTAINS_FILTER,
                comparisonValue: internalFilter.name
            });
        }
        if (internalFilter.address) {
            dynamicFilters.push({
                operation: LOCATION_ADDRESS_CONTAINS_FILTER,
                comparisonValue: internalFilter.address
            });
        }

        locationSlicer.setDynamicFilters(dynamicFilters);
        locationSlicer.setPaginationPage(0);

    }, [internalFilter]);

    function handleSortChange(sortOrder: LocationSortOrder, direction: SortDirection) {
        locationSlicer.setSortOrder(sortOrder);
        locationSlicer.setSortDirection(direction);
        locationSlicer.setPaginationPage(0);
    }

    const handlePaginationPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        locationSlicer.setPaginationPage(newPage);
    };

    const handlePaginationRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        locationSlicer.setPaginationRowsPerPage(newRowsPerPage);
        locationSlicer.setPaginationPage(0);
    };

    const handleSelectFactory = (location: Location) => {
        return () => {
            onSelect && onSelect(location)
        }
    }

    const [locationSlice, locationSliceCount] = locationSlicer.slice(locations);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <FilterDialogButton
                                    filterCount={locationSlicer.dynamicFilters.length}
                                    onClick={handleFilterDialogOpen}
                                />
                                <FilterDialog
                                    open={filterDialogOpen}
                                    onClose={handleFilterDialogClose}
                                />
                            </TableCell>
                            <TableCell>
                                Name
                                <SortOrderButton
                                    sortOrder={LOCATION_NAME_SORT_ORDER}
                                    currentSortOrder={locationSlicer.sortOrder}
                                    currentSortDirection={locationSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Street
                                <SortOrderButton
                                    sortOrder={LOCATION_ADDRESS_STREET_SORT_ORDER}
                                    currentSortOrder={locationSlicer.sortOrder}
                                    currentSortDirection={locationSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                No.
                                <SortOrderButton
                                    sortOrder={LOCATION_ADDRESS_STREET_NUMBER_SORT_ORDER}
                                    currentSortOrder={locationSlicer.sortOrder}
                                    currentSortDirection={locationSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Zip
                                <SortOrderButton
                                    sortOrder={LOCATION_ADDRESS_ZIP_SORT_ORDER}
                                    currentSortOrder={locationSlicer.sortOrder}
                                    currentSortDirection={locationSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                City
                                <SortOrderButton
                                    sortOrder={LOCATION_ADDRESS_CITY_SORT_ORDER}
                                    currentSortOrder={locationSlicer.sortOrder}
                                    currentSortDirection={locationSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                State
                                <SortOrderButton
                                    sortOrder={LOCATION_STATE_SORT_ORDER}
                                    currentSortOrder={locationSlicer.sortOrder}
                                    currentSortDirection={locationSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {locationSlice.map((location: Location) => (
                            <Row key={location.id.value} location={location} onSelect={handleSelectFactory(location)}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={locationSlicer.paginationRowsPerPageOptions}
                component="div"
                count={locationSliceCount}
                rowsPerPage={locationSlicer.paginationRowsPerPage}
                page={locationSlicer.paginationPage}
                onRowsPerPageChange={handlePaginationRowsPerPageChange}
                onPageChange={handlePaginationPageChange}
            />
        </>
    );

}

export default LocationTable;

