import React, {MutableRefObject, useEffect, useState} from "react";
import {Optional} from "../../api/model/util/Optional";
import {EmailAddresses} from "../../api/model/value/EmailAddresses";
import {FormSize} from "../form/FormSize";
import EmailAddressesInput from "./EmailAddressesInput";

export interface Props {
    id: string,
    value: EmailAddresses,
    disabled: boolean,
    size: FormSize;
    getValue: MutableRefObject<Optional<() => EmailAddresses>>,
    reset: number
}

const EmailAddressesInputCompound: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, getValue, reset} = props

    const [currentItems, setCurrentItems] = useState<EmailAddresses>(new EmailAddresses([...value.items]));

    useEffect(() => {
        setCurrentItems(new EmailAddresses([...value.items]))
    }, [reset]);

    getValue.current = () => currentItems;

    return (
        <>
            <EmailAddressesInput
                id={id}
                value={currentItems}
                disabled={disabled}
                size={size}
                setValue={setCurrentItems}
            />
        </>
    )

};

export default EmailAddressesInputCompound;
