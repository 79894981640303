import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/List";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";
import {v4} from "uuid";
import {Optional} from "../../../api/model/util/Optional";
import {Comment} from "../../../api/model/value/Comment";
import {Name} from "../../../api/model/value/Name";
import {useProject} from "../../../context/ProjectProvider";
import {database} from "../../../firebase/firebase";
import useBundler from "../../../hook/useBundler";
import CommentInput from "../../input/CommentInput";

export interface Props {
    onClose: () => void
}

const BundleUpdateForm: React.FunctionComponent<Props> = (props: Props) => {

    const {onClose} = props;

    const project = useProject();
    const [started, setStarted] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const [comment, setComment] = useState<Comment>(new Comment(""));

    const [request, setRequest] = useState<Optional<[Name, Comment]>>(null);
    const {steps, progress, result} = useBundler(database, project, request);

    useEffect(() => {
        if (result !== null) {
            setFinished(true);
        }
    }, [result]);

    const handleCreate = () => {
        setStarted(true);
        setRequest([new Name(v4()), comment]);
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Stack maxWidth={1000}>
                Comment
                <CommentInput
                    id="comment"
                    value={comment}
                    disabled={started}
                    size={"small"}
                    setValue={setComment}
                />
            </Stack>
            {steps.length !== 0 && (
                <List dense>
                    {steps.map((step, position) => <>
                        {position !== 0 && <Divider key={position + "-divider"}/>}
                        <ListItem key={position}>
                            <ListItemText primary={step[1] ? <strong>{step[0]}</strong> : step[0]}/>
                        </ListItem></>)}
                </List>
            )}
            {result && !result?.success && (
                <Alert severity="error">{result.message}</Alert>
            )}
            <Stack direction={"row"} spacing={1}>
                {!started && <>
                    <Button variant={"contained"} disabled={started} onClick={handleCreate}>
                        Publish
                    </Button>
                    <Button variant={"outlined"} disabled={started} onClick={handleClose}>
                        Cancel
                    </Button>
                </>}
                {started && <>
                    <Button variant={"outlined"} disabled={!finished} onClick={handleClose}>
                        Close
                    </Button>
                </>}
            </Stack>
        </Stack>
    );

};

export default BundleUpdateForm;
