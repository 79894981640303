import React, {createContext, useContext, useState} from "react";
import {Affiliate, AFFILIATE_NAME_SORT_ORDER, AffiliateFilter, AffiliateSortOrder} from "../../../api/model/Affiliate";
import {SortDirection} from "../../../api/model/Entity";

export type Props = {}

export type AffiliateSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: AffiliateSortOrder,
    setSortOrder: (sortOrder: AffiliateSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<AffiliateFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<AffiliateFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<AffiliateFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<AffiliateFilter<any>>) => void,
    slice: (affiliates: ReadonlyArray<Affiliate>) => [ReadonlyArray<Affiliate>, number]
}

const AffiliateSlicerContext = createContext<AffiliateSlicer>(null!);

const AffiliateSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<AffiliateSortOrder>(AFFILIATE_NAME_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<AffiliateFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<AffiliateFilter<any>>>([]);

    const slice = (affiliates: ReadonlyArray<Affiliate>): [ReadonlyArray<Affiliate>, number] => {

        let filteredAffiliates = affiliates;
        staticFilters.forEach(filter => {
            filteredAffiliates = filteredAffiliates.filter(affiliate => filter.operation.apply(affiliate, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredAffiliates = filteredAffiliates.filter(affiliate => filter.operation.apply(affiliate, filter.comparisonValue));
        });

        const sortedAffiliates = [...filteredAffiliates].sort((left: Affiliate, right: Affiliate) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedAffiliates.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedAffiliates = sortedAffiliates.slice(start, end);

        return [paginatedAffiliates, filteredAffiliates.length];

    }

    const value: AffiliateSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <AffiliateSlicerContext.Provider value={value}>
            {children}
        </AffiliateSlicerContext.Provider>
    );

};

export function useAffiliateSlicer(): AffiliateSlicer {
    return useContext(AffiliateSlicerContext);
}

export default AffiliateSlicerProvider;