import React, {MutableRefObject, useRef} from "react";
import {Partner, PartnerPayload} from "../../../../api/model/Partner";
import {Optional} from "../../../../api/model/util/Optional";
import {Address} from "../../../../api/model/value/Address";
import {DEFAULT_COUNTRY} from "../../../../api/model/value/Country";
import {LegalEntity} from "../../../../api/model/value/LegalEntity";
import {CORPORATE} from "../../../../api/model/value/LegalEntityType";
import {Name} from "../../../../api/model/value/Name";
import {FormSize} from "../../../form/FormSize";
import LegalEntityInput2 from "../../../input/LegalEntityInput2";

export type PartnerLegalEntity = Pick<PartnerPayload, "legalEntity">;

export interface Props {
    partner?: Partner,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => PartnerLegalEntity>>,
    reset: number
}

const PartnerLegalEntityInput: React.FunctionComponent<Props> = (props: Props) => {

    const {partner, disabled, size, getValue, reset} = props

    const getLegalEntityRef: MutableRefObject<Optional<() => LegalEntity>> = useRef<Optional<() => LegalEntity>>(null)

    getValue.current = () => ({
        legalEntity: getLegalEntityRef.current!(),
    });

    return (
        <>
            <LegalEntityInput2
                id="legal-entity"
                value={partner?.legalEntity || new LegalEntity(CORPORATE, new Name(""), new Address("", "", "", "", DEFAULT_COUNTRY))}
                disabled={disabled}
                size={size}
                getValue={getLegalEntityRef}
                reset={reset}
            />
        </>
    );

};

export default PartnerLegalEntityInput;
