import {collection, CollectionReference, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {Address} from "./value/Address";
import {contacts, Contacts, CONTACTS_DATA_MAPPER} from "./value/Contacts";
import {DEFAULT_COUNTRY} from "./value/Country";
import {EMAIL_ADDRESSES_DATA_MAPPER, emailAddresses, EmailAddresses} from "./value/EmailAddresses";
import {EntityType} from "./value/EntityType";
import {LEGAL_ENTITY_DATA_MAPPER, legalEntity, LegalEntity} from "./value/LegalEntity";
import {CORPORATE} from "./value/LegalEntityType";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {PARTNER_ID_DATA_MAPPER, PartnerId, partnerIdCompare} from "./value/PartnerId";
import {stringCompare, stringEquals} from "./value/String";

export enum PartnerState {

    DISABLED = "DISABLED",

    ENABLED = "ENABLED"

}

export type PartnerPayload = {
    state: PartnerState,
    partnerId: PartnerId,
    name: Name,
    legalEntity: LegalEntity,
    contacts: Contacts,
    notificationEmailAddresses: EmailAddresses
}

export type PartnerAnchor = EntityAnchor<EntityType.PARTNER> & {};

export type PreparedPartner = PreparedEntity<EntityType.PARTNER> & PartnerPayload;

export type Partner = Entity<EntityType.PARTNER> & PartnerPayload;

export type PersistablePartner = PreparedPartner | Partner;

export type PartnerManager = EntityManager<EntityType.PARTNER, PartnerAnchor, PersistablePartner, Partner>;

export const PARTNER_STATE_DATA_MAPPER: DataMapper<PartnerState, string> = {
    toData: (value: PartnerState) => value,
    fromData: (data: string) => data as PartnerState
}

export const PARTNER_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePartner, PartnerState, string>(
        "state",
        e => e.state,
        PARTNER_STATE_DATA_MAPPER,
        PartnerState.ENABLED
    );

export const PARTNER_PARTNER_ID_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePartner, PartnerId, string>(
        "partnerId",
        e => e.partnerId,
        PARTNER_ID_DATA_MAPPER,
        null
    );

export const PARTNER_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePartner, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Partner")
    );

export const PARTNER_LEGAL_ENTITY_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePartner, LegalEntity, legalEntity>(
        "legalEntity",
        e => e.legalEntity,
        LEGAL_ENTITY_DATA_MAPPER,
        new LegalEntity(CORPORATE, new Name(""), new Address("", "", "", "", DEFAULT_COUNTRY))
    );

export const PARTNER_CONTACTS_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePartner, Contacts, contacts>(
        "contacts",
        e => e.contacts,
        CONTACTS_DATA_MAPPER,
        new Contacts([])
    );

export const PARTNER_NOTIFICATION_EMAIL_ADDRESSES_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePartner, EmailAddresses, emailAddresses>(
        "notificationEmailAddresses",
        e => e.notificationEmailAddresses,
        EMAIL_ADDRESSES_DATA_MAPPER,
        new EmailAddresses([])
    );

export type PartnerFilterColumn = EntityFilterColumn | "STATE" | "NAME";

export type PartnerFilterOperation<V> = FilterOperation<EntityType.PARTNER, Partner, PartnerFilterColumn, V>;

export type PartnerFilter<V> = Filter<EntityType.PARTNER, Partner, PartnerFilterColumn, V>;

export const PARTNER_STATE_EQUALS_FILTER: PartnerFilterOperation<PartnerState> = {
    column: "STATE",
    apply: (partner: Partner, comparisonValue: PartnerState) => stringEquals(partner.state, comparisonValue)
}

export const PARTNER_NAME_CONTAINS_FILTER: PartnerFilterOperation<string> = {
    column: "NAME",
    apply: (partner: Partner, comparisonValue: string) => nameContains(partner.name, comparisonValue)
}

export type PartnerSortColumn = EntitySortColumn | "STATE" | "PARTNER_ID" | "NAME";

export type PartnerSortOrder = SortOrder<EntityType.PARTNER, Partner, PartnerSortColumn>;

export const PARTNER_STATE_SORT_ORDER: PartnerSortOrder = {
    column: "STATE",
    apply: (left: Partner, right: Partner) => stringCompare(left.state, right.state)
}

export const PARTNER_PARTNER_ID_SORT_ORDER: PartnerSortOrder = {
    column: "PARTNER_ID",
    apply: (left: Partner, right: Partner) => partnerIdCompare(left.partnerId, right.partnerId)
}

export const PARTNER_NAME_SORT_ORDER: PartnerSortOrder = {
    column: "NAME",
    apply: (left: Partner, right: Partner) => nameCompare(left.name, right.name)
}

class PartnerDefinition extends EntityDefinition<EntityType.PARTNER, PartnerAnchor, PersistablePartner, Partner> {

    constructor() {
        super(
            EntityType.PARTNER,
            [
                PARTNER_STATE_ATTRIBUTE_DEFINITION,
                PARTNER_PARTNER_ID_ATTRIBUTE_DEFINITION,
                PARTNER_NAME_ATTRIBUTE_DEFINITION,
                PARTNER_LEGAL_ENTITY_ATTRIBUTE_DEFINITION,
                PARTNER_CONTACTS_ATTRIBUTE_DEFINITION,
                PARTNER_NOTIFICATION_EMAIL_ADDRESSES_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistablePartner): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistablePartner): PartnerAnchor {
        return {};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.PARTNER>): PartnerAnchor {
        return {};
    }

    public anchorToColRef(database: Firestore, anchor: PartnerAnchor): CollectionReference {
        return collection(database, "adminManager", "data", EntityType.PARTNER.toLowerCase());
    }

}

export const PARTNER_DEFINITION = new PartnerDefinition();