import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import React from "react";

export interface Props {
    onReset: () => void;
    onStore: () => void;
}

const DialogFormButtons: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {

    const {onReset, onStore} = props;

    return (
        <>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <Button variant={"outlined"} onClick={onReset}>Reset</Button>
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth>
                    <Button variant={"contained"} onClick={onStore}>Store</Button>
                </FormControl>
            </Grid>
        </>
    )

};

export default DialogFormButtons;
