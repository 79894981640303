import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";

export class LegalEntityType {

    public readonly code: Code;

    constructor(code: Code) {
        this.code = code;
    }

    public static toData(value: LegalEntityType): string {
        return value.code.value;
    }

    public static fromData(data: string): LegalEntityType {
        return LegalEntityType.fromCode(new Code(data));
    }

    public static fromCode(code: Code): LegalEntityType {
        const legalEntityType : Maybe<LegalEntityType> = LEGAL_ENTITY_TYPES.find(legalEntityType => legalEntityType.code.value === code.value);
        if (legalEntityType === undefined) {
            throw new Error("Unknown legal entity type: " + JSON.stringify(code));
        }
        return legalEntityType;
    }

}

export const CORPORATE: LegalEntityType = new LegalEntityType(
    new Code("CORPORATE")
);

export const PERSONAL: LegalEntityType = new LegalEntityType(
    new Code("PERSONAL")
);

export const LEGAL_ENTITY_TYPES: ReadonlyArray<LegalEntityType> = [
    CORPORATE,
    PERSONAL
] as const;

export const LEGAL_ENTITY_TYPE_DATA_MAPPER: DataMapper<LegalEntityType, string> = {
    toData: LegalEntityType.toData,
    fromData: LegalEntityType.fromData
}

