import {collection, CollectionReference, doc, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {stringCompare, stringEquals} from "./value/String";

export enum FlagState {

    ENABLED = "ENABLED",

    DISABLED = "DISABLED"

}

export type FlagPayload = {
    state: FlagState,
    name: Name,
    region: IdRef<EntityType.REGION>
}

export type FlagAnchor = EntityAnchor<EntityType.FLAG> & {
    region: IdAlike<EntityType.REGION>
}

export type PreparedFlag = PreparedEntity<EntityType.FLAG> & FlagPayload;

export type Flag = Entity<EntityType.FLAG> & FlagPayload;

export type PersistableFlag = PreparedFlag | Flag;

export type FlagManager = EntityManager<EntityType.FLAG, FlagAnchor, PersistableFlag, Flag>;

export const FLAG_STATE_DATA_MAPPER: DataMapper<FlagState, string> = {
    toData: (value: FlagState) => value,
    fromData: (data: string) => data as FlagState
}

export const FLAG_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableFlag, FlagState, string>(
        "state",
        e => e.state,
        FLAG_STATE_DATA_MAPPER,
        FlagState.ENABLED
    );

export const FLAG_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableFlag, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Flag")
    );

export const FLAG_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableFlag>(
        EntityType.REGION,
        "region",
        e => e.region
    );

export type FlagFilterColumn = EntityFilterColumn | "STATE" | "NAME";

export type FlagFilterOperation<V> = FilterOperation<EntityType.FLAG, Flag, FlagFilterColumn, V>;

export type FlagFilter<V> = Filter<EntityType.FLAG, Flag, FlagFilterColumn, V>;

export const FLAG_STATE_EQUALS_FILTER: FlagFilterOperation<FlagState> = {
    column: "STATE",
    apply: (flag: Flag, comparisonValue: FlagState) => stringEquals(flag.state, comparisonValue)
}

export const FLAG_NAME_CONTAINS_FILTER: FlagFilterOperation<string> = {
    column: "NAME",
    apply: (flag: Flag, comparisonValue: string) => nameContains(flag.name, comparisonValue)
}

export type FlagSortColumn = EntitySortColumn | "STATE" | "NAME";

export type FlagSortOrder = SortOrder<EntityType.FLAG, Flag, FlagSortColumn>;

export const FLAG_STATE_SORT_ORDER: FlagSortOrder = {
    column: "STATE",
    apply: (left: Flag, right: Flag) => stringCompare(left.state, right.state)
}

export const FLAG_NAME_SORT_ORDER: FlagSortOrder = {
    column: "NAME",
    apply: (left: Flag, right: Flag) => nameCompare(left.name, right.name)
}


class FlagDefinition extends EntityDefinition<EntityType.FLAG, FlagAnchor, PersistableFlag, Flag> {

    constructor() {
        super(
            EntityType.FLAG,
            [
                FLAG_STATE_ATTRIBUTE_DEFINITION,
                FLAG_NAME_ATTRIBUTE_DEFINITION,
                FLAG_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableFlag): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableFlag): FlagAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.FLAG>): FlagAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: FlagAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.FLAG.toLowerCase());
    }

}

export const FLAG_DEFINITION = new FlagDefinition();