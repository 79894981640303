import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {EntityState} from "../../../api/model/Entity";
import {Flag} from "../../../api/model/Flag";
import {Location} from "../../../api/model/Location";
import {Partner} from "../../../api/model/Partner";
import {Tag} from "../../../api/model/Tag";
import {Optional} from "../../../api/model/util/Optional";
import {Venue} from "../../../api/model/Venue";
import {useProject} from "../../../context/ProjectProvider";
import DeleteButton from "../../form/DeleteButton";
import RemoveButton from "../../form/RemoveButton";
import RestoreButton from "../../form/RestoreButton";
import VenueInput, {VenueInputResult} from "./VenueInput";

export interface Props {
    venue: Venue,
    onDelete: () => void
    onClose?: () => void
}

const VenueUpdateForm: React.FunctionComponent<Props> = (props: Props) => {

    const {venue: initialVenue, onDelete, onClose} = props

    const project = useProject();
    const venueManager = useProject().venueManager;
    const [editing, setEditing] = useState<boolean>(false);
    const [latestVenue, setLatestVenue] = useState<Venue>(initialVenue)
    const [currentVenue, setCurrentVenue] = useState<Venue>(initialVenue)
    const [partners, setPartners] = useState<ReadonlyArray<Partner>>([]);
    const [locations, setLocations] = useState<ReadonlyArray<Location>>([]);
    const [tags, setTags] = useState<ReadonlyArray<Tag>>([]);
    const [flags, setFlags] = useState<Optional<ReadonlyArray<Flag>>>(null);

    useEffect(() => {

        const loadPartners = async () => {
            setPartners(await project.partnerManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadPartners().catch(console.error)

    }, []);

    useEffect(() => {

        const loadLocations = async () => {
            setLocations(await project.locationManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadLocations().catch(console.error)

    }, []);

    useEffect(() => {

        const loadTags = async () => {
            setTags(await project.tagManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadTags().catch(console.error)

    }, []);

    useEffect(() => {

        const loadFlags = async () => {
            setFlags(await project.flagManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadFlags().catch(console.error)

    }, []);

    const handleEdit = () => {
        setEditing(true);
        setCurrentVenue({...latestVenue});
    }

    const handleCancel = () => {
        setEditing(false);
        setCurrentVenue(latestVenue);
    }

    const handlePersist = async (venueInputResult: VenueInputResult) => {
        setEditing(false);
        const updatedVenue = await venueManager.persist({...latestVenue, ...venueInputResult});
        setLatestVenue(updatedVenue);
        setCurrentVenue(updatedVenue);
    }

    const handleRemove = async () => {
        const updatedVenue = await venueManager.removeById(latestVenue);
        setLatestVenue(updatedVenue);
        setCurrentVenue(updatedVenue);
    }

    const handleRestore = async () => {
        const updatedVenue = await venueManager.restoreById(latestVenue);
        setLatestVenue(updatedVenue);
        setCurrentVenue(updatedVenue);
    }

    const handleDelete = async () => {
        await venueManager.deleteById(latestVenue);
        onDelete();
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            {partners.length === 0 || locations.length === 0 || tags.length === 0 || flags === null ?
                <Typography variant={"h6"}>
                    No partners or locations or no tags added yet!
                </Typography> :
                <VenueInput
                    venue={currentVenue}
                    disabled={!editing}
                    partners={partners}
                    locations={locations}
                    tags={tags}
                    flags={flags}
                    size="small"
                    onPersist={currentVenue.entityState === EntityState.CREATED ? handlePersist : undefined}
                />}
            <Stack direction={"row"} spacing={1}>
                {editing && <>
                    <Button variant={"outlined"} onClick={handleCancel}>
                        Cancel
                    </Button>
                </>}
                {!editing && <>
                    {currentVenue.entityState === EntityState.CREATED &&
                        <Button variant={"contained"} onClick={handleEdit}>
                            Edit
                        </Button>}
                    {currentVenue.entityState === EntityState.REMOVED &&
                        <DeleteButton disabled={false} onDelete={handleDelete}/>}
                    {currentVenue.entityState === EntityState.REMOVED &&
                        <RestoreButton disabled={false} onRestore={handleRestore}/>}
                    {currentVenue.entityState === EntityState.CREATED &&
                        <RemoveButton disabled={false} onRemove={handleRemove}/>}
                    {onClose && <Button variant={"outlined"} onClick={handleClose}>
                        Close
                    </Button>}
                </>}
            </Stack>
        </Stack>
    );

};

export default VenueUpdateForm;
