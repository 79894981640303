import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React, {useState} from "react";
import {EntityScope} from "../../../api/model/Entity";
import {EntityType} from "../../../api/model/value/EntityType";
import {IdRef} from "../../../api/model/value/IdRef";
import {useProject} from "../../../context/ProjectProvider";
import LocationInput, {LocationInputResult} from "./LocationInput";

export interface Props {
    onClose: () => void
}

const LocationUpdateForm: React.FunctionComponent<Props> = (props: Props) => {

    const {onClose} = props;

    const project = useProject();
    const locationManager = useProject().locationManager;
    const [disabled, setDisabled] = useState<boolean>(false);

    const handlePersist = async (locationInputResult: LocationInputResult) => {
        setDisabled(true);
        await locationManager.persist({
            entityType: EntityType.LOCATION,
            entityScope: EntityScope.PREPARED,
            ...locationInputResult,
            region: new IdRef<EntityType.REGION>(project.selectedRegion.id)
        });
        onClose();
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <LocationInput disabled={disabled} size="small" onPersist={handlePersist}/>
            <Stack direction={"row"} spacing={1}>
                <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>
            </Stack>
        </Stack>
    );

};

export default LocationUpdateForm;
