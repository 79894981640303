import React, {createContext, useContext, useState} from "react";
import {SortDirection} from "../../../api/model/Entity";
import {Page, PAGE_NAME_SORT_ORDER, PageFilter, PageSortOrder} from "../../../api/model/Page";

export type Props = {}

export type PageSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: PageSortOrder,
    setSortOrder: (sortOrder: PageSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<PageFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<PageFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<PageFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<PageFilter<any>>) => void,
    slice: (pages: ReadonlyArray<Page>) => [ReadonlyArray<Page>, number]
}

const PageSlicerContext = createContext<PageSlicer>(null!);

const PageSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<PageSortOrder>(PAGE_NAME_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<PageFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<PageFilter<any>>>([]);

    const slice = (pages: ReadonlyArray<Page>): [ReadonlyArray<Page>, number] => {

        let filteredPages = pages;
        staticFilters.forEach(filter => {
            filteredPages = filteredPages.filter(page => filter.operation.apply(page, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredPages = filteredPages.filter(page => filter.operation.apply(page, filter.comparisonValue));
        });

        const sortedPages = [...filteredPages].sort((left: Page, right: Page) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedPages.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedPages = sortedPages.slice(start, end);

        return [paginatedPages, filteredPages.length];

    }

    const value: PageSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <PageSlicerContext.Provider value={value}>
            {children}
        </PageSlicerContext.Provider>
    );

};

export function usePageSlicer(): PageSlicer {
    return useContext(PageSlicerContext);
}

export default PageSlicerProvider;