import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";

export class ImageScale {

    public readonly code: Code;

    constructor(code: Code) {
        this.code = code;
    }

    public static toData(value: ImageScale): string {
        return value.code.value;
    }

    public static fromData(data: string): ImageScale {
        return ImageScale.fromCode(new Code(data));
    }

    public static fromCode(imageTypeCode: Code): ImageScale {
        const imageScale: Maybe<ImageScale> = IMAGE_TYPES.find(imageType => imageType.code.value === imageTypeCode.value);
        if (imageScale === undefined) {
            throw new Error("Unknown image type: " + JSON.stringify(imageTypeCode));
        }
        return imageScale;
    }

}

export const SMALL: ImageScale = new ImageScale(
    new Code("SM")
);

export const MEDIUM: ImageScale = new ImageScale(
    new Code("MD")
);

export const LARGE: ImageScale = new ImageScale(
    new Code("LG")
);

export const EXTRA_LARGE: ImageScale = new ImageScale(
    new Code("XL")
);

export const EXTRA_EXTRA_LARGE: ImageScale = new ImageScale(
    new Code("XXL")
);

export const IMAGE_TYPES: ReadonlyArray<ImageScale> = [
    SMALL,
    MEDIUM,
    LARGE,
    EXTRA_LARGE,
    EXTRA_EXTRA_LARGE
] as const;

export const IMAGE_SCALE_DATA_MAPPER: DataMapper<ImageScale, string> = {
    toData: ImageScale.toData,
    fromData: ImageScale.fromData
}

