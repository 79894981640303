import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Country, GERMANY, SPAIN} from "./Country";
import {Name} from "./Name";
import {ZoneId} from "./ZoneId";

export class TimeZone {

    public readonly name: Name;

    public readonly country: Country;

    public readonly zoneId: ZoneId;

    constructor(name: Name, country: Country, zoneId: ZoneId) {
        this.name = name;
        this.country = country;
        this.zoneId = zoneId;
    }

    public static toData(value: TimeZone): string {
        return value.zoneId.value;
    }

    public static fromData(data: string): TimeZone {
        return TimeZone.fromZoneId(new ZoneId(data));
    }

    public static fromZoneId(zoneId: ZoneId): TimeZone {
        const timeZone: Maybe<TimeZone> = TIME_ZONES.find(timeZone => timeZone.zoneId.value === zoneId.value);
        if (timeZone === undefined) {
            throw new Error("Unknown time zone: " + zoneId);
        }
        return timeZone;
    }
}

export const EUROPA_BERLIN = new TimeZone(
    new Name("Europe/Berlin"),
    GERMANY,
    new ZoneId("Europe/Berlin")
);


export const EUROPA_MADRID = new TimeZone(
    new Name("Europe/Madrid"),
    SPAIN,
    new ZoneId("Europe/Madrid")
);

export const TIME_ZONES: ReadonlyArray<TimeZone> = [
    EUROPA_BERLIN,
    EUROPA_MADRID
] as const;

export const DEFAULT_TIME_ZONE: TimeZone = EUROPA_MADRID;

export const TIME_ZONE_DATA_MAPPER: DataMapper<TimeZone, string> = {
    toData: TimeZone.toData,
    fromData: TimeZone.fromData
}
