import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Tag, TagPayload, TagState} from "../../../../api/model/Tag";
import {Optional} from "../../../../api/model/util/Optional";
import {Category} from "../../../../api/model/value/Category";
import {Name} from "../../../../api/model/value/Name";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import {CategoryCompound} from "../../../input/CategoryInput";
import NameInput2 from "../../../input/NameInput2";
import TagStateInput2 from "../../../input/TagStateInput2";
import StringOutput from "../../../output/StringOutput";

export type TagCore = Pick<TagPayload, "state" | "name" | "category">;

export interface Props {
    tag?: Tag,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => TagCore>>,
    reset: number
}

const TagCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {tag, disabled, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => TagState>> = useRef<Optional<() => TagState>>(null)
    const getNameRef: MutableRefObject<Optional<() => Name>> = useRef<Optional<() => Name>>(null)
    const getCategoryRef: MutableRefObject<Optional<() => Optional<Category>>> = useRef<Optional<() => Optional<Category>>>(null)

    getValue.current = () => ({
        state: getStateRef.current!(),
        name: getNameRef.current!(),
        category: getCategoryRef.current!()!
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {tag?.id && <FormRow label="Id">
                    <StringOutput
                        value={tag.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <TagStateInput2
                        id="state"
                        value={tag?.state || TagState.ENABLED}
                        disabled={disabled}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Name">
                    <NameInput2
                        id="name"
                        value={tag?.name || new Name("")}
                        disabled={disabled}
                        size={size}
                        getValue={getNameRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Category">
                    <CategoryCompound
                        id="category"
                        value={tag?.category || null}
                        disabled={disabled}
                        showEmpty={[false]}
                        size={size}
                        getValue={getCategoryRef}
                        reset={reset}
                    />
                </FormRow>
            </Grid>
        </>
    );

};

export default TagCoreInput;
