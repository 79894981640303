import {DataMapper} from "../Entity";

export class Url {

    public readonly value: string;

    constructor(value: string) {
        this.value = value;
    }

    public static toData(value: Url): string {
        return value.value;
    }

    public static fromData(data: string): Url {
        return new Url(data);
    }
}

export const URL_DATA_MAPPER: DataMapper<Url, string> = {
    toData: Url.toData,
    fromData: Url.fromData
}
