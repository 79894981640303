import React from 'react';
import CustomerSlicerProvider from "../component/entity/customer/CustomerSlicerProvider";
import CustomersPage from "../component/entity/customer/CustomersPage";

function CustomerRoute() {

    return (
        <CustomerSlicerProvider>
            <CustomersPage/>
        </CustomerSlicerProvider>
    );

}

export default CustomerRoute;
