import {EditOutlined} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from "@mui/material/TablePagination";
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import {ENTITY_ID_CONTAINS_FILTER, SortDirection} from "../../../api/model/Entity";
import {
    Venue,
    VENUE_NAME_CONTAINS_FILTER,
    VENUE_NAME_SORT_ORDER,
    VENUE_STATE_SORT_ORDER, VENUE_SUBTITLE_SORT_ORDER,
    VenueFilter,
    VenueSortOrder
} from "../../../api/model/Venue";
import FilterDialogButton from "../../form/FilterDialogButton";
import FilterFormButtons from "../../form/FilterFormButtons";
import FilterFormRow from "../../form/FilterFormRow";
import SortOrderButton from "../../form/SortOrderButton";
import StringInput from "../../input/StringInput";
import {useVenueSlicer} from "./VenueSlicerProvider";

interface FilterDialogProps {
    open: boolean;
    onClose: (filter: InternalFilter) => void;
}

const FilterDialog: React.FunctionComponent<FilterDialogProps> = (props: FilterDialogProps) => {

    const {open, onClose} = props;

    const [id, setId] = useState<string>("");
    const [name, setName] = useState<string>("");

    const handleClear = () => {
        setId("");
        setName("");
    };

    const handleClose = () => {
        onClose({
            id: id.length === 0 ? undefined : id,
            name: name.length === 0 ? undefined : name
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Filter</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FilterFormRow label="ID" operation="contains">
                    <StringInput
                        id="id"
                        value={id}
                        disabled={false}
                        size="small"
                        setValue={setId}
                    />
                </FilterFormRow>
                <FilterFormRow label="Name" operation="contains">
                    <StringInput
                        id="name"
                        value={name}
                        disabled={false}
                        size="small"
                        setValue={setName}
                    />
                </FilterFormRow>
                <FilterFormButtons onClear={handleClear} onClose={handleClose}/>
            </Grid>
        </Dialog>
    );
}

interface RowProps {
    venue: Venue,
    onSelect: (venue: Venue) => void
}

const Row: React.FunctionComponent<RowProps> = ({venue, onSelect}: RowProps) => {
    return (
        <TableRow>
            <TableCell padding={"checkbox"}>
                <IconButton size="small" onClick={() => onSelect(venue)}>
                    <EditOutlined/>
                </IconButton>
            </TableCell>
            <TableCell>{venue.name.value}</TableCell>
            <TableCell>{venue.subtitle.value}</TableCell>
            <TableCell>{venue.state}</TableCell>
        </TableRow>
    );
}

export interface Props {
    venues: ReadonlyArray<Venue>,
    onSelect?: (venue: Venue) => void
}

type InternalFilter = {
    id?: string,
    name?: string
}

const VenueTable: React.FunctionComponent<Props> = ({venues, onSelect}: Props) => {

    const venueSlicer = useVenueSlicer();

    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const [internalFilter, setInternalFilter] = useState<InternalFilter>({});

    const handleFilterDialogOpen = () => {
        setFilterDialogOpen(true);
    };

    const handleFilterDialogClose = (filter: InternalFilter) => {
        setFilterDialogOpen(false);
        setInternalFilter(filter)
    };

    useEffect(() => {

        const dynamicFilters = new Array<VenueFilter<any>>();

        if (internalFilter.id) {
            dynamicFilters.push({
                operation: ENTITY_ID_CONTAINS_FILTER,
                comparisonValue: internalFilter.id
            });
        }
        if (internalFilter.name) {
            dynamicFilters.push({
                operation: VENUE_NAME_CONTAINS_FILTER,
                comparisonValue: internalFilter.name
            });
        }

        venueSlicer.setDynamicFilters(dynamicFilters);
        venueSlicer.setPaginationPage(0);

    }, [internalFilter]);

    function handleSortChange(sortOrder: VenueSortOrder, direction: SortDirection) {
        venueSlicer.setSortOrder(sortOrder);
        venueSlicer.setSortDirection(direction);
        venueSlicer.setPaginationPage(0);
    }

    const handlePaginationPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        venueSlicer.setPaginationPage(newPage);
    };

    const handlePaginationRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        venueSlicer.setPaginationRowsPerPage(newRowsPerPage);
        venueSlicer.setPaginationPage(0);
    };

    const handleSelectFactory = (venue: Venue) => {
        return () => {
            onSelect && onSelect(venue)
        }
    }

    const [venueSlice, venueSliceCount] = venueSlicer.slice(venues);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <FilterDialogButton
                                    filterCount={venueSlicer.dynamicFilters.length}
                                    onClick={handleFilterDialogOpen}
                                />
                                <FilterDialog
                                    open={filterDialogOpen}
                                    onClose={handleFilterDialogClose}
                                />
                            </TableCell>
                            <TableCell>
                                Name
                                <SortOrderButton
                                    sortOrder={VENUE_NAME_SORT_ORDER}
                                    currentSortOrder={venueSlicer.sortOrder}
                                    currentSortDirection={venueSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Subtitle
                                <SortOrderButton
                                    sortOrder={VENUE_SUBTITLE_SORT_ORDER}
                                    currentSortOrder={venueSlicer.sortOrder}
                                    currentSortDirection={venueSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                State
                                <SortOrderButton
                                    sortOrder={VENUE_STATE_SORT_ORDER}
                                    currentSortOrder={venueSlicer.sortOrder}
                                    currentSortDirection={venueSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {venueSlice.map((venue: Venue) => (
                            <Row key={venue.id.value} venue={venue} onSelect={handleSelectFactory(venue)}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={venueSlicer.paginationRowsPerPageOptions}
                component="div"
                count={venueSliceCount}
                rowsPerPage={venueSlicer.paginationRowsPerPage}
                page={venueSlicer.paginationPage}
                onRowsPerPageChange={handlePaginationRowsPerPageChange}
                onPageChange={handlePaginationPageChange}
            />
        </>
    );

}

export default VenueTable;

