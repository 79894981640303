import {DataMapper} from "../Entity";
import {BenefitType} from "./BenefitType";

export type benefitTypes = string[];

export class BenefitTypes {

    public readonly items: ReadonlyArray<BenefitType>;

    constructor(benefitTypes: ReadonlyArray<BenefitType>) {
        this.items = benefitTypes;
    }

    public static toData(value: BenefitTypes): benefitTypes {
        return value.items.map(image => BenefitType.toData(image));
    }

    static fromData(data: benefitTypes): BenefitTypes {
        return new BenefitTypes(
            data.map(datum => BenefitType.fromData(datum))
        );
    }

}

export const BENEFIT_TYPES_DATA_MAPPER: DataMapper<BenefitTypes, benefitTypes> = {
    toData: BenefitTypes.toData,
    fromData: BenefitTypes.fromData
}
