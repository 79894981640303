import {DataMapper} from "../Entity";

export class NonNegativeInteger {

    public readonly value: number;

    constructor(value: number) {
        if (!isFinite(value) || !Number.isInteger(value) || value < 0) {
            throw new Error("Invalid amount: " + value);
        }
        this.value = value;
    }

    public static toData(value: NonNegativeInteger): number {
        return value.value;
    }

    public static fromData(data: number): NonNegativeInteger {
        return new NonNegativeInteger(data);
    }
}

export const NON_NEGATIVE_INTEGER_DATA_MAPPER: DataMapper<NonNegativeInteger, number> = {
    toData: NonNegativeInteger.toData,
    fromData: NonNegativeInteger.fromData
}

export function nonNegativeIntegerCompare(left: NonNegativeInteger, right: NonNegativeInteger): number {
    return left.value - right.value;
}