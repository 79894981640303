import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";

export class ContactOptionType {

    public readonly code: Code;

    constructor(code: Code) {
        this.code = code;
    }

    public static toData(value: ContactOptionType): string {
        return value.code.value;
    }

    public static fromData(data: string): ContactOptionType {
        return ContactOptionType.fromCode(new Code(data));
    }

    public static fromCode(contactOptionTypeCode: Code): ContactOptionType {
        const contactOptionType: Maybe<ContactOptionType> = CONTACT_OPTION_TYPES.find(contactType => contactType.code.value === contactOptionTypeCode.value);
        if (contactOptionType === undefined) {
            throw new Error("Unknown contact option type: " + JSON.stringify(contactOptionTypeCode));
        }
        return contactOptionType;
    }

}

export const EMAIL: ContactOptionType = new ContactOptionType(
    new Code("EMAIL"),
);

export const PHONE: ContactOptionType = new ContactOptionType(
    new Code("PHONE"),
);

export const WHATSAPP: ContactOptionType = new ContactOptionType(
    new Code("WHATSAPP"),
);

export const CONTACT_OPTION_TYPES: ReadonlyArray<ContactOptionType> = [
    EMAIL,
    PHONE,
    WHATSAPP
] as const;

export const CONTACT_OPTION_TYPE_DATA_MAPPER: DataMapper<ContactOptionType, string> = {
    toData: ContactOptionType.toData,
    fromData: ContactOptionType.fromData
}

