import {EntityType} from "./EntityType";
import {Id} from "./Id";

export class IdRef<T extends EntityType> {

    public readonly type: T;

    public readonly value: string;

    public readonly id: Id<T>;

    constructor(id: Id<T>) {
        this.type = id.type;
        this.value = id.value;
        this.id = id;
    }

}
