import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, {useEffect, useState} from "react";
import {Tag, TAG_NAME_SORT_ORDER} from "../../api/model/Tag";
import {Categories} from "../../api/model/value/Categories";
import {CATEGORIES} from "../../api/model/value/Category";
import {arrayDown, arrayRemove, arrayUp, findByIdValue} from "../../api/util";
import DialogFormButtons from "../form/DialogFormButtons";
import FormRow from "../form/FormRow";
import {FormSize} from "../form/FormSize";
import StringsOptionsInput from "./StringsOptionsInput";

interface TagOrderDialogProps {
    open: boolean;
    value: ReadonlyArray<Tag>;
    onClose: (value: ReadonlyArray<Tag>) => void;
}

type TagMap = {
    [key: string]: ReadonlyArray<Tag>;
}

function map(array: ReadonlyArray<Tag>): TagMap {
    const map: { [key: string]: Array<Tag>; } = {};
    CATEGORIES.forEach(category => {
        map[category.code.value] = new Array<Tag>()
    });
    array.forEach((tag: Tag) => {
        map[tag.category.code.value].push(tag);
    });
    return map;
}

function unmap(map: TagMap): ReadonlyArray<Tag> {
    const array = new Array<Tag>();
    Object.values(map).forEach((tags: ReadonlyArray<Tag>) => {
        array.push(...tags);
    });
    return array;
}

const TagOrderDialog: React.FunctionComponent<TagOrderDialogProps> = (props: TagOrderDialogProps) => {

    const {open, value, onClose} = props;

    const [currentValue, setCurrentValue] = useState<TagMap>(map(value));

    useEffect(() => {
        setCurrentValue(map(value));
    }, [value]);

    const handleReset = () => {
        setCurrentValue(map(value));
    }

    const handleStore = () => {
        onClose(unmap(currentValue))
    };

    const handleClose = () => {
        onClose(value)
    };

    const handleMoveUpFactory = (key: string, position: number) => {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleMoveUp(key, position);
        }
    }

    const handleMoveUp = (key: string, position: number) => {
        setCurrentValue({...currentValue, [key]: arrayUp(currentValue[key]!, position)});
    };

    const handleMoveDownFactory = (key: string, position: number) => {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleMoveDown(key, position);
        }
    }

    const handleMoveDown = (key: string, position: number) => {
        setCurrentValue({...currentValue, [key]: arrayDown(currentValue[key]!, position)});
    };

    const handleDeleteFactory = (key: string, position: number) => {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleDelete(key, position);
        }
    }

    const handleDelete = (key: string, position: number) => {
        setCurrentValue({...currentValue, [key]: arrayRemove(currentValue[key]!, position)});
    };

    const categories = CATEGORIES.filter(category => currentValue[category.code.value].length !== 0);

    return (
        <Dialog maxWidth={"md"} fullWidth={true} onClose={handleClose} open={open}>
            <DialogTitle>Tags</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                {categories.map(category => {
                        const key = category.code.value;
                        return (<FormRow label={category.name.value}>
                            <TableContainer sx={{mb: 3}}>
                                <Table sx={{minWidth: 650}} size={"small"} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tag</TableCell>
                                            <TableCell/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentValue[key].map((tag: Tag, position: number) => (
                                            <TableRow key={position}>
                                                <TableCell>
                                                    {tag.name.value}
                                                </TableCell>
                                                <TableCell align={"right"}>
                                                    <IconButton
                                                        size={"small"}
                                                        disabled={false}
                                                        onClick={handleMoveUpFactory(key, position)}>
                                                        <KeyboardArrowUp/>
                                                    </IconButton>
                                                    <IconButton
                                                        size={"small"}
                                                        disabled={false}
                                                        onClick={handleMoveDownFactory(key, position)}>
                                                        <KeyboardArrowDown/>
                                                    </IconButton>
                                                    <IconButton
                                                        size={"small"}
                                                        disabled={false}
                                                        onClick={handleDeleteFactory(key, position)}>
                                                        <Delete/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </FormRow>);
                    }
                )}
                <DialogFormButtons onReset={handleReset} onStore={handleStore}/>
            </Grid>
        </Dialog>
    );
}


export interface Props {
    id: string,
    value: ReadonlyArray<Tag>,
    categories: Categories
    tags: ReadonlyArray<Tag>,
    disabled: boolean,
    size: FormSize,
    setValue: (value: ReadonlyArray<Tag>) => void
}

const TagsInput: React.FunctionComponent<Props> = (props: Props) => {

    const {
        id,
        value: selectedTags,
        categories: selectedCategories,
        tags,
        disabled,
        size,
        setValue: setSelectedTags
    } = props

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectableTags, setSelectableTags] = useState<ReadonlyArray<Tag>>(tags);

    useEffect(() => {

        const selectableTags = tags.filter(selectableTag => {
            return selectedCategories.items.findIndex(category => category.code.value === selectableTag.category.code.value) !== -1;
        })

        const stillSelectedTags = selectedTags.filter(selectedTag => {
            return selectableTags.findIndex(selectableTag => selectableTag.id.value === selectedTag.id.value) !== -1;
        })

        setSelectableTags([...selectableTags].sort((left: Tag, right: Tag) => TAG_NAME_SORT_ORDER.apply(left, right)));
        if (stillSelectedTags.length !== selectedTags.length) {
            setSelectedTags(stillSelectedTags);
        }

    }, [selectedCategories, tags]);


    const handleOrder = () => {
        setDialogOpen(true);
    };

    const handleClose = (tags: ReadonlyArray<Tag>) => {
        setSelectedTags(tags);
        setDialogOpen(false);
    };

    return (
        <>
            <StringsOptionsInput
                id={id}
                value={selectedTags.map(tag => tag.id.value)}
                options={selectableTags.map(tag => {
                    return {value: tag.id.value, label: tag.name.value}
                })}
                disabled={disabled}
                size={size}
                setValue={(tagIds: ReadonlyArray<string>) => setSelectedTags(tagIds.map(tagId => findByIdValue(tags, tagId)!))}
            />
            <Stack direction="row">
                <FormControl sx={{pt: 1}}>
                    <Button onClick={handleOrder} variant={"outlined"} disabled={disabled}>Order</Button>
                </FormControl>
            </Stack>
            <TagOrderDialog open={dialogOpen} value={selectedTags} onClose={handleClose}/>
        </>
    )

};

export default TagsInput;
