import {collection, CollectionReference, doc, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike, valueFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {POSITIVE_INTEGER_DATA_MAPPER, PositiveInteger, positiveIntegerCompare} from "./value/PositiveInteger";
import {stringCompare, stringEquals} from "./value/String";
import {usage, Usage, USAGE_DATA_MAPPER} from "./value/Usage";
import {UsageState, usageStateCompare, usageStateEquals} from "./value/UsageState";
import {
    VOUCHER_CODE_ID_DATA_MAPPER,
    VoucherCodeId,
    voucherCodeIdCompare,
    voucherCodeIdContains
} from "./value/VoucherCodeId";

export enum VoucherCodeState {

    ENABLED = "ENABLED",

    DISABLED = "DISABLED"

}

export type VoucherCodePayload = {
    state: VoucherCodeState,
    number: PositiveInteger,
    codeId: VoucherCodeId,
    usage: Usage,
    voucher: IdRef<EntityType.VOUCHER>,
    region: IdRef<EntityType.REGION>
}
export type VoucherCodeAnchor = EntityAnchor<EntityType.VOUCHER_CODE> & {
    region: IdAlike<EntityType.REGION>
}

export type PreparedVoucherCode = PreparedEntity<EntityType.VOUCHER_CODE> & VoucherCodePayload;

export type VoucherCode = Entity<EntityType.VOUCHER_CODE> & VoucherCodePayload;

export type PersistableVoucherCode = PreparedVoucherCode | VoucherCode;

export type VoucherCodeManager = EntityManager<EntityType.VOUCHER_CODE, VoucherCodeAnchor, PersistableVoucherCode, VoucherCode>;

export const VOUCHER_CODE_STATE_DATA_MAPPER: DataMapper<VoucherCodeState, string> = {
    toData: (value: VoucherCodeState) => value,
    fromData: (data: string) => data as VoucherCodeState
}

export const VOUCHER_CODE_NUMBER_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVoucherCode, PositiveInteger, number>(
        "number",
        e => e.number,
        POSITIVE_INTEGER_DATA_MAPPER,
        null
    );

export const VOUCHER_CODE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVoucherCode, VoucherCodeState, string>(
        "state",
        e => e.state,
        VOUCHER_CODE_STATE_DATA_MAPPER,
        null
    );

export const VOUCHER_CODE_CODE_ID_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVoucherCode, VoucherCodeId, string>(
        "codeId",
        e => e.codeId,
        VOUCHER_CODE_ID_DATA_MAPPER,
        null
    );

export const VOUCHER_CODE_USAGE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableVoucherCode, Usage, usage>(
        "usage",
        e => e.usage,
        USAGE_DATA_MAPPER,
        null
    );

export const VOUCHER_CODE_VOUCHER_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.VOUCHER, PersistableVoucherCode>(
        EntityType.VOUCHER,
        "voucher",
        e => e.voucher
    );

export const VOUCHER_CODE_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableVoucherCode>(
        EntityType.REGION,
        "region",
        e => e.region
    );

export type VoucherCodeFilterColumn = EntityFilterColumn | "STATE" | "USAGE_STATE" | "CODE_ID" | "VOUCHER";

export type VoucherCodeFilterOperation<V> = FilterOperation<EntityType.VOUCHER_CODE, VoucherCode, VoucherCodeFilterColumn, V>;

export type VoucherCodeFilter<V> = Filter<EntityType.VOUCHER_CODE, VoucherCode, VoucherCodeFilterColumn, V>;

export const VOUCHER_CODE_STATE_EQUALS_FILTER: VoucherCodeFilterOperation<VoucherCodeState> = {
    column: "STATE",
    apply: (code: VoucherCode, comparisonValue: VoucherCodeState) => stringEquals(code.state, comparisonValue)
}

export const VOUCHER_CODE_CODE_ID_CONTAINS_FILTER: VoucherCodeFilterOperation<string> = {
    column: "CODE_ID",
    apply: (code: VoucherCode, comparisonValue: string) => voucherCodeIdContains(code.codeId, comparisonValue)
}

export const VOUCHER_CODE_USAGE_STATE_EQUALS_FILTER: VoucherCodeFilterOperation<UsageState> = {
    column: "USAGE_STATE",
    apply: (code: VoucherCode, comparisonValue: UsageState) => usageStateEquals(code.usage.state, comparisonValue)
}

export const VOUCHER_CODE_VOUCHER_EQUALS_FILTER: VoucherCodeFilterOperation<IdAlike<EntityType.VOUCHER>> = {
    column: "VOUCHER",
    apply: (code: VoucherCode, comparisonValue: IdAlike<EntityType.VOUCHER>) => stringEquals(valueFromIdAlike(code.voucher), valueFromIdAlike(comparisonValue))
}

export type VoucherCodeSortColumn = EntitySortColumn | "STATE" | "NUMBER" | "USAGE" | "CODE_ID";

export type VoucherCodeSortOrder = SortOrder<EntityType.VOUCHER_CODE, VoucherCode, VoucherCodeSortColumn>;

export const VOUCHER_CODE_STATE_SORT_ORDER: VoucherCodeSortOrder = {
    column: "STATE",
    apply: (left: VoucherCode, right: VoucherCode) => stringCompare(left.state, right.state)
}

export const VOUCHER_CODE_NUMBER_SORT_ORDER: VoucherCodeSortOrder = {
    column: "NUMBER",
    apply: (left: VoucherCode, right: VoucherCode) => positiveIntegerCompare(left.number, right.number)
}

export const VOUCHER_CODE_USAGE_SORT_ORDER: VoucherCodeSortOrder = {
    column: "USAGE",
    apply: (left: VoucherCode, right: VoucherCode) => usageStateCompare(left.usage.state, right.usage.state)
}


export const VOUCHER_CODE_CODE_ID_SORT_ORDER: VoucherCodeSortOrder = {
    column: "CODE_ID",
    apply: (left: VoucherCode, right: VoucherCode) => voucherCodeIdCompare(left.codeId, right.codeId)
}


class VoucherCodeDefinition extends EntityDefinition<EntityType.VOUCHER_CODE, VoucherCodeAnchor, PersistableVoucherCode, VoucherCode> {

    constructor() {
        super(
            EntityType.VOUCHER_CODE,
            [
                VOUCHER_CODE_STATE_ATTRIBUTE_DEFINITION,
                VOUCHER_CODE_NUMBER_ATTRIBUTE_DEFINITION,
                VOUCHER_CODE_CODE_ID_ATTRIBUTE_DEFINITION,
                VOUCHER_CODE_USAGE_ATTRIBUTE_DEFINITION,
                VOUCHER_CODE_VOUCHER_ATTRIBUTE_DEFINITION,
                VOUCHER_CODE_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableVoucherCode): Optional<string> {
        return entity.codeId.value;
    }

    public preparedEntityToAnchor(entity: PersistableVoucherCode): VoucherCodeAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.VOUCHER_CODE>): VoucherCodeAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: VoucherCodeAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.VOUCHER_CODE.toLowerCase());
    }

}

export const VOUCHER_CODE_DEFINITION = new VoucherCodeDefinition();
