import {DataMapper} from "../Entity";
import {Optional} from "../util/Optional";
import {
    FacebookProfile,
    InstagramProfile,
    socialMediaProfile,
    SocialMediaProfile
} from "./SocialMediaProfile";
import {FACEBOOK, INSTAGRAM} from "./SocialMediaProfileType";

export type socialMediaProfiles = socialMediaProfile[];

export class SocialMediaProfiles {

    public readonly facebook: Optional<FacebookProfile>;

    public readonly instagram: Optional<InstagramProfile>;

    constructor(facebook: Optional<SocialMediaProfile>, instagram: Optional<SocialMediaProfile>) {
        this.facebook = facebook;
        this.instagram = instagram;
    }

    public withFacebook(facebook: Optional<FacebookProfile>): SocialMediaProfiles {
        return new SocialMediaProfiles(facebook, this.instagram);
    }

    public withInstagram(instagram: Optional<InstagramProfile>): SocialMediaProfiles {
        return new SocialMediaProfiles(this.facebook, instagram);
    }

    public static toData(value: SocialMediaProfiles): socialMediaProfiles {
        const socialMediaProfiles = new Array<socialMediaProfile>();
        if (value.facebook !== null) {
            socialMediaProfiles.push(SocialMediaProfile.toData(value.facebook));
        }
        if (value.instagram !== null) {
            socialMediaProfiles.push(SocialMediaProfile.toData(value.instagram));
        }
        return socialMediaProfiles;
    }

    public static fromData(data: socialMediaProfiles): SocialMediaProfiles {
        let facebook: Optional<SocialMediaProfile> = null;
        let instagram: Optional<SocialMediaProfile> = null;
        if (data) {
            data.forEach(datum => {
                const socialMediaProfile = SocialMediaProfile.fromData(datum);
                if (socialMediaProfile.type.code.value === FACEBOOK.code.value) {
                    if (facebook !== null) {
                        throw new Error("Multiple instances of the same social media profile are not yet supported");
                    }
                    facebook = socialMediaProfile as FacebookProfile;
                } else if (socialMediaProfile.type.code.value === INSTAGRAM.code.value) {
                    if (instagram !== null) {
                        throw new Error("Multiple instances of the same social media profile are not yet supported");
                    }
                    instagram = socialMediaProfile as InstagramProfile;
                }
            });
        }
        return new SocialMediaProfiles(facebook, instagram);
    }


}

export const SOCIAL_MEDIA_PROFILES_DATA_MAPPER: DataMapper<SocialMediaProfiles, socialMediaProfiles> = {
    toData: SocialMediaProfiles.toData,
    fromData: SocialMediaProfiles.fromData
}