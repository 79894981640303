import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Customer, CustomerPayload, CustomerState} from "../../../../api/model/Customer";
import {Optional} from "../../../../api/model/util/Optional";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import CustomerStateInput2 from "../../../input/CustomerStateInput2";
import StringOutput from "../../../output/StringOutput";

export type CustomerCore = Pick<CustomerPayload, "state">;

export interface Props {
    customer?: Customer,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => CustomerCore>>,
    reset: number
}

const CustomerCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {customer, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => CustomerState>> = useRef<Optional<() => CustomerState>>(null)

    getValue.current = () => ({
        state: getStateRef.current!(),
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {customer?.id && <FormRow label="Id">
                    <StringOutput
                        value={customer.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <CustomerStateInput2
                        id="state"
                        value={customer?.state || CustomerState.ENABLED}
                        disabled={true}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                {customer && <FormRow label="Name">
                    <StringOutput
                        value={customer.name.value}
                        size={size}
                    />
                </FormRow>}
                {customer && <FormRow label="Customer ID">
                    <StringOutput
                        value={customer.customerId.value}
                        size={size}
                    />
                </FormRow>}
                {customer && <FormRow label="Email Address">
                    <StringOutput
                        value={customer.emailAddress.value}
                        size={size}
                    />
                </FormRow>}
            </Grid>
        </>
    );

};

export default CustomerCoreInput;
