import {DataMapper} from "../Entity";

export class Code {

    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public static toData(value: Code): string {
        return value.value;
    }

    public static fromData(data: string): Code {
        return new Code(data);
    }

}

export const CODE_DATA_MAPPER: DataMapper<Code, string> = {
    toData: Code.toData,
    fromData: Code.fromData
}
