import {DataMapper} from "../Entity";
import {phoneNumber, PhoneNumber} from "./PhoneNumber";

export type phoneNumbers = phoneNumber[];

export class PhoneNumbers {

    public readonly items: ReadonlyArray<PhoneNumber>;

    constructor(items: ReadonlyArray<PhoneNumber>) {
        this.items = items;
    }

    public static toData(value: PhoneNumbers): phoneNumbers {
        return value.items.map(item => PhoneNumber.toData(item));
    }

    static fromData(data: phoneNumbers): PhoneNumbers {
        return new PhoneNumbers(
            data.map(datum => PhoneNumber.fromData(datum))
        );
    }

}

export const PHONE_NUMBERS_DATA_MAPPER: DataMapper<PhoneNumbers, phoneNumbers> = {
    toData: PhoneNumbers.toData,
    fromData: PhoneNumbers.fromData
}
