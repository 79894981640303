import React, {createContext, useContext, useState} from "react";
import {Bundle, BUNDLE_DATE_SORT_ORDER, BundleFilter, BundleSortOrder} from "../../../api/model/Bundle";
import {SortDirection} from "../../../api/model/Entity";

export type Props = {}

export type BundleSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: BundleSortOrder,
    setSortOrder: (sortOrder: BundleSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<BundleFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<BundleFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<BundleFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<BundleFilter<any>>) => void,
    slice: (bundles: ReadonlyArray<Bundle>) => [ReadonlyArray<Bundle>, number]
}

const BundleSlicerContext = createContext<BundleSlicer>(null!);

const BundleSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<BundleSortOrder>(BUNDLE_DATE_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<BundleFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<BundleFilter<any>>>([]);

    const slice = (bundles: ReadonlyArray<Bundle>): [ReadonlyArray<Bundle>, number] => {

        let filteredBundles = bundles;
        staticFilters.forEach(filter => {
            filteredBundles = filteredBundles.filter(bundle => filter.operation.apply(bundle, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredBundles = filteredBundles.filter(bundle => filter.operation.apply(bundle, filter.comparisonValue));
        });

        const sortedBundles = [...filteredBundles].sort((left: Bundle, right: Bundle) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedBundles.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedBundles = sortedBundles.slice(start, end);

        return [paginatedBundles, filteredBundles.length];

    }

    const value: BundleSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <BundleSlicerContext.Provider value={value}>
            {children}
        </BundleSlicerContext.Provider>
    );

};

export function useBundleSlicer(): BundleSlicer {
    return useContext(BundleSlicerContext);
}

export default BundleSlicerProvider;