import React from "react";
import {CONTACT_TYPES, ContactType} from "../../api/model/value/ContactType";
import {FormSize} from "../form/FormSize";
import StringOptionsInput from "./StringOptionsInput";

export interface Props {
    id: string,
    value: ContactType,
    disabled: boolean,
    size: FormSize,
    setValue: (value: ContactType) => void
}

const ContactTypeInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <StringOptionsInput
            id={id}
            value={value.code.value}
            options={CONTACT_TYPES.map(contactType => {
                return {value: contactType.code.value, label: contactType.code.value}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: string) => setValue(ContactType.fromData(value))}
        />
    )

};

export default ContactTypeInput;
