import React, {useEffect, useState} from "react";
import {Event} from "../../../api/model/Event";
import {Project, useProject} from "../../../context/ProjectProvider";
import EventTable from "./EventTable";

export interface Props {
    onSelect?: (event: Event) => void
}

const EventList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [events, setEvents] = useState<ReadonlyArray<Event>>([]);

    useEffect(() => {

        const loadEvents = async () => {

            const events = await project.eventManager.getAllAsArray({region: project.selectedRegion});
            setEvents(events);

        }

        loadEvents().catch(console.error);

    }, [project]);

    return (
        <EventTable
            events={events}
            onSelect={onSelect}
        />
    );

};

export default EventList;
