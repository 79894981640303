import React from "react";
import {BENEFIT_TYPES} from "../../api/model/value/BenefitType";
import {benefitTypes, BenefitTypes} from "../../api/model/value/BenfitTypes";
import {FormSize} from "../form/FormSize";
import StringsOptionsInput from "./StringsOptionsInput";

export interface Props {
    id: string,
    value: BenefitTypes,
    disabled: boolean,
    size: FormSize,
    setValue: (value: BenefitTypes) => void
}

const BenefitTypesInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <StringsOptionsInput
            id={id}
            value={value.items.map(benefitType => benefitType.code.value)}
            options={BENEFIT_TYPES.map(benefitType => {
                return {value: benefitType.code.value, label: benefitType.code.value}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: ReadonlyArray<string>) => setValue(BenefitTypes.fromData(value as benefitTypes))}
        />
    )

};

export default BenefitTypesInput;
