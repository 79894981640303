import {DataMapper} from "../Entity";

export class PositiveInteger {

    public readonly value: number;

    constructor(value: number) {
        if (!isFinite(value) || !Number.isInteger(value) || value < 1) {
            throw new Error("Invalid amount: " + value);
        }
        this.value = value;
    }

    public static toData(value: PositiveInteger): number {
        return value.value;
    }

    public static fromData(data: number): PositiveInteger {
        return new PositiveInteger(data);
    }
}

export const POSITIVE_INTEGER_DATA_MAPPER: DataMapper<PositiveInteger, number> = {
    toData: PositiveInteger.toData,
    fromData: PositiveInteger.fromData
}

export function positiveIntegerCompare(left: PositiveInteger, right: PositiveInteger): number {
    return left.value - right.value;
}
