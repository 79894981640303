import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";
import {NominalTime} from "../../api/model/value/NominalTime";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: NominalTime,
    disabled: boolean,
    size: FormSize;
    setValue: (value: NominalTime) => void
}

const NominalTimeInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleHourChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rawValue = event.target.value;
        if (rawValue.length === 0) {
            setValue(value.withHour(0));
        } else {
            const integerValue = Math.max(0, Math.min(23, parseInt(rawValue)));
            if (isFinite(integerValue)) {
                setValue(value.withHour(integerValue));
            }
        }
    }

    const handleMinuteChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rawValue = event.target.value;
        if (rawValue.length === 0) {
            setValue(value.withMinute(0));
        } else {
            const integerValue = Math.max(0, Math.min(59, parseInt(event.target.value)));
            if (isFinite(integerValue)) {
                setValue(value.withMinute(integerValue));
            }
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Stack direction="column">
                    Hour
                    <TextField
                        id={id + "-hour"}
                        type="number"
                        value={value.hour}
                        InputProps={{inputProps: {min: 0, max: 23}}}
                        disabled={disabled}
                        size={size}
                        onChange={handleHourChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column">
                    Minute
                    <TextField
                        id={id + "-month"}
                        type="number"
                        value={value.minute}
                        InputProps={{inputProps: {min: 0, max: 59}}}
                        disabled={disabled}
                        size={size}
                        onChange={handleMinuteChange}
                    />
                </Stack>
            </Grid>
        </Grid>
    )

};

export default NominalTimeInput;
