export function stringContains(haystack: string, needle: string): boolean {
    return haystack.toLowerCase().includes(needle.toLowerCase());
}

export function stringEquals(left: string, right: string): boolean {
    return left === right;
}

export function stringCompare(left: string, right: string): number {
    return left.localeCompare(right);
}
