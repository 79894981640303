import {DataMapper} from "../Entity";
import {EmailAddress} from "./EmailAddress";

export type emailAddresses = string[];

export class EmailAddresses {

    public readonly items: ReadonlyArray<EmailAddress>;

    constructor(items: ReadonlyArray<EmailAddress>) {
        this.items = items;
    }

    public static toData(value: EmailAddresses): emailAddresses {
        return value.items.map(item => EmailAddress.toData(item));
    }

    static fromData(data: emailAddresses): EmailAddresses {
        return new EmailAddresses(
            data.map(datum => EmailAddress.fromData(datum))
        );
    }

}

export const EMAIL_ADDRESSES_DATA_MAPPER: DataMapper<EmailAddresses, string[]> = {
    toData: EmailAddresses.toData,
    fromData: EmailAddresses.fromData
}
