import React, {MutableRefObject, useRef} from "react";
import {BundleState} from "../../api/model/Bundle";
import {Optional} from "../../api/model/util/Optional";
import {FormSize} from "../form/FormSize";
import StringOptionsInput2 from "./StringOptionsInput2";

export interface Props {
    id: string,
    value: BundleState,
    disabled: boolean,
    size: FormSize;
    getValue: MutableRefObject<Optional<() => BundleState>>,
    reset: number
}

const BundleStateInput2: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, getValue, reset} = props

    const getStringRef: MutableRefObject<Optional<() => string>> = useRef<Optional<() => string>>(null);

    getValue.current = () => getStringRef.current!() as BundleState;

    return (
        <StringOptionsInput2
            id={id}
            value={value}
            options={Object.keys(BundleState).map(state => {
                return {value: state, label: state}
            })}
            disabled={disabled}
            size={size}
            getValue={getStringRef}
            reset={reset}
        />
    )

};

export default BundleStateInput2;
