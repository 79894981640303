import React from "react";
import {CATEGORIES, Category} from "../../api/model/value/Category";
import {
    SingleSelectCompound,
    SingleSelectCompoundProps,
    SingleSelectInput,
    SingleSelectInputProps
} from "./SingleSelectInput";

const TO_LABEL = (value: Category) => value.name.value;
const TO_VALUE = (value: Category) => value.code.value;
const FROM_DATA = (value: string) => Category.fromData(value);

export type CategoryCompoundProps = Omit<SingleSelectCompoundProps<Category>, "options" | "toLabel" | "toValue" | "fromValue">

export type CategoryInputProps = Omit<SingleSelectInputProps<Category>, "options" | "toLabel" | "toValue" | "fromValue">

export function CategoryCompound(props: CategoryCompoundProps) {

    return (
        <SingleSelectCompound<Category>
            {...props}
            options={CATEGORIES}
            toLabel={TO_LABEL}
            toValue={TO_VALUE}
            fromValue={FROM_DATA}
        />
    )

};

export function CategoryInput(props: CategoryInputProps) {

    return (
        <SingleSelectInput<Category>
            {...props}
            options={CATEGORIES}
            toLabel={TO_LABEL}
            toValue={TO_VALUE}
            fromValue={FROM_DATA}
        />
    )

};