import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import InitProvider from "../api/InitProvider";
import AuthProvider from "../context/AuthProvider";
import LayoutProvider from "../context/LayoutProvider";
import ProjectProvider from "../context/ProjectProvider";
import Bar from "./Bar";
import Footer from "./Footer";
import Header from "./Header";
import Nav from "./Nav";

export interface Props {
}

const Layout: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {
    return (
        <>
            <LayoutProvider>
                <AuthProvider>
                    <InitProvider>
                        <ProjectProvider>
                            <header>
                                <Bar/>
                            </header>
                            <Grid container spacing={3}>
                                <Grid item xs={2}>
                                    <Box sx={{my: 3, ml: 3}}>
                                        <Paper>
                                            <nav>
                                                <Nav/>
                                            </nav>
                                        </Paper>
                                    </Box>
                                </Grid>
                                <Grid item xs={10}>
                                    <Box sx={{my: 3, mr: 3}}>
                                        <Paper>
                                            <div>
                                                <header>
                                                    <Header/>
                                                </header>
                                                <main>
                                                    <Box sx={{p: 2}}>
                                                        {children}
                                                    </Box>
                                                </main>
                                                <footer>
                                                    <Footer/>
                                                </footer>
                                            </div>
                                        </Paper>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ProjectProvider>
                    </InitProvider>
                </AuthProvider>
            </LayoutProvider>
        </>
    );
};

export default Layout;
