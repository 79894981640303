import React from 'react';
import AffiliateSlicerProvider from "../component/entity/affiliate/AffiliateSlicerProvider";
import AffiliatesPage from "../component/entity/affiliate/AffiliatesPage";

function AffiliateRoute() {

    return (
        <AffiliateSlicerProvider>
            <AffiliatesPage/>
        </AffiliateSlicerProvider>
    );

}

export default AffiliateRoute;
