import {collection, CollectionReference, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike} from "../util";
import {DataMapper, Entity, EntityAnchor, EntityDefinition, PreparedEntity, SimpleAttributeDefinition} from "./Entity";
import {Optional} from "./util/Optional";
import {Code, CODE_DATA_MAPPER} from "./value/Code";
import {Country, COUNTRY_DATA_MAPPER} from "./value/Country";
import {EntityType} from "./value/EntityType";
import {GEO_AREA_DATA_MAPPER, geoArea, GeoArea} from "./value/GeoArea";
import {GeoPoint} from "./value/GeoPoint";
import {Language, LANGUAGE_DATA_MAPPER} from "./value/Language";
import {Latitude} from "./value/Latitude";
import {Longitude} from "./value/Longitude";
import {Name, NAME_DATA_MAPPER} from "./value/Name";
import {TIME_ZONE_DATA_MAPPER, TimeZone} from "./value/TimeZone";

export enum RegionState {

    ENABLED = "ENABLED"

}

export type RegionPayload = {
    state: RegionState,
    name: Name,
    code: Code,
    country: Country,
    language: Language,
    timeZone: TimeZone,
    area: GeoArea
}
export type RegionAnchor = EntityAnchor<EntityType.REGION> & {};

export type PreparedRegion = PreparedEntity<EntityType.REGION> & RegionPayload;

export type Region = Entity<EntityType.REGION> & RegionPayload;

export type PersistableRegion = PreparedRegion | Region;

export type RegionManager = EntityManager<EntityType.REGION, RegionAnchor, PersistableRegion, Region>;

export const REGION_STATE_DATA_MAPPER: DataMapper<RegionState, string> = {
    toData: (value: RegionState) => value,
    fromData: (data: string) => data as RegionState
}

export const REGION_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableRegion, RegionState, string>(
        "state",
        e => e.state,
        REGION_STATE_DATA_MAPPER,
        RegionState.ENABLED
    );

export const REGION_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableRegion, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Region")
    );

export const REGION_CODE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableRegion, Code, string>(
        "code",
        e => e.code,
        CODE_DATA_MAPPER,
        new Code("REGION")
    );

export const REGION_COUNTRY_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableRegion, Country, string>(
        "country",
        e => e.country,
        COUNTRY_DATA_MAPPER,
        null
    );


export const REGION_LANGUAGE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableRegion, Language, string>(
        "language",
        e => e.language,
        LANGUAGE_DATA_MAPPER,
        null
    );


export const REGION_TIME_ZONE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableRegion, TimeZone, string>(
        "timeZone",
        e => e.timeZone,
        TIME_ZONE_DATA_MAPPER,
        null
    );

export const REGION_AREA_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableRegion, GeoArea, geoArea>(
        "area",
        e => e.area,
        GEO_AREA_DATA_MAPPER,
        new GeoArea(new GeoPoint(new Latitude(0), new Longitude(0)), new GeoPoint(new Latitude(0), new Longitude(0)))
    );

class RegionDefinition extends EntityDefinition<EntityType.REGION, RegionAnchor, PersistableRegion, Region> {

    constructor() {
        super(
            EntityType.REGION,
            [
                REGION_STATE_ATTRIBUTE_DEFINITION,
                REGION_NAME_ATTRIBUTE_DEFINITION,
                REGION_CODE_ATTRIBUTE_DEFINITION,
                REGION_COUNTRY_ATTRIBUTE_DEFINITION,
                REGION_LANGUAGE_ATTRIBUTE_DEFINITION,
                REGION_TIME_ZONE_ATTRIBUTE_DEFINITION,
                REGION_AREA_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableRegion): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableRegion): RegionAnchor {
        return {};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.REGION>): RegionAnchor {
        return {};
    }

    public anchorToColRef(database: Firestore, anchor: RegionAnchor): CollectionReference {
        return collection(database, "adminManager", "data", EntityType.REGION.toLowerCase());
    }

}

export const REGION_DEFINITION = new RegionDefinition();