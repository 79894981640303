import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Affiliate, AffiliatePayload} from "../../../../api/model/Affiliate";
import {Optional} from "../../../../api/model/util/Optional";
import {Contacts} from "../../../../api/model/value/Contacts";
import {EmailAddresses} from "../../../../api/model/value/EmailAddresses";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import ContactsInputCompound from "../../../input/ContactsInputCompound";
import EmailAddressesInputCompound from "../../../input/EmailAddressesInputCompound";

export type AffiliateContact = Pick<AffiliatePayload, "contacts" | "notificationEmailAddresses">;

export interface Props {
    affiliate?: Affiliate,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => AffiliateContact>>,
    reset: number
}

const AffiliateContactInput: React.FunctionComponent<Props> = (props: Props) => {

    const {affiliate, disabled, size, getValue, reset} = props

    const getContactsRef: MutableRefObject<Optional<() => Contacts>> = useRef<Optional<() => Contacts>>(null)
    const getNotificationEmailAddressesRef: MutableRefObject<Optional<() => EmailAddresses>> = useRef<Optional<() => EmailAddresses>>(null)

    getValue.current = () => ({
        contacts: getContactsRef.current!(),
        notificationEmailAddresses: getNotificationEmailAddressesRef.current!()
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                <FormRow label="Contacts">
                    <ContactsInputCompound
                        id="contacts"
                        value={affiliate?.contacts || new Contacts([])}
                        disabled={disabled}
                        size={size}
                        getValue={getContactsRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Notification Email Addresses">
                    <EmailAddressesInputCompound
                        id="notification-email-addresses"
                        value={affiliate?.notificationEmailAddresses || new EmailAddresses([])}
                        disabled={disabled}
                        size={size}
                        getValue={getNotificationEmailAddressesRef}
                        reset={reset}
                    />
                </FormRow>
            </Grid>
        </>
    );

};

export default AffiliateContactInput;
