import React, {useEffect, useState} from "react";
import {Page} from "../../../api/model/Page";
import {Project, useProject} from "../../../context/ProjectProvider";
import PageTable from "./PageTable";

export interface Props {
    onSelect?: (page: Page) => void
}

const PageList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [pages, setPages] = useState<ReadonlyArray<Page>>([]);

    useEffect(() => {

        const loadPages = async () => {

            const pages = await project.pageManager.getAllAsArray({region: project.selectedRegion});
            setPages(pages);

        }

        loadPages().catch(console.error);

    }, [project]);

    return (
        <PageTable
            pages={pages}
            onSelect={onSelect}
        />
    );

};

export default PageList;
