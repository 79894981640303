import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from "react";
import {ENTITY_STATE_EQUALS_FILTER, EntityState} from "../../../api/model/Entity";
import {Optional} from "../../../api/model/util/Optional";
import {Venue, VENUE_STATE_EQUALS_FILTER, VenueFilter, VenueState} from "../../../api/model/Venue";
import VenueCreateForm from "./VenueCreateForm";
import VenueList from "./VenueList";
import {useVenueSlicer} from "./VenueSlicerProvider";
import VenueUpdateForm from "./VenueUpdateForm";

type TabDefinition = {
    id: string,
    label: string,
    filter: ReadonlyArray<VenueFilter<any>>
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "disabled",
        label: "Disabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: VENUE_STATE_EQUALS_FILTER,
                comparisonValue: VenueState.DISABLED
            }
        ]
    },
    {
        id: "enabled",
        label: "Enabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: VENUE_STATE_EQUALS_FILTER,
                comparisonValue: VenueState.ENABLED
            }
        ]
    },
    {
        id: "removed",
        label: "Removed",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.REMOVED
            }
        ]
    }
]

const INITIAL_TAB = 1;

export interface Props {
}

const VenuesPage: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    const VenueSlicer = useVenueSlicer();
    const [selectedTab, setSelectedTab] = useState<number>(INITIAL_TAB);
    const [selectedVenue, setSelectedVenue] = useState<Optional<"NEW" | Venue>>(null);

    useEffect(() => {
        VenueSlicer.setStaticFilters(TABS[selectedTab].filter);
    }, [selectedTab]);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const handleSelect = (Venue: Venue) => {
        setSelectedVenue(Venue);
    }

    const handleCreate = () => {
        setSelectedVenue("NEW");
    }

    const handleClose = () => {
        setSelectedVenue(null);
    }

    if (selectedVenue === "NEW") {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    New Venue
                </Typography>
                <VenueCreateForm onClose={handleClose}/>
            </>
        )
    } else if (selectedVenue !== null) {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Venue: {selectedVenue.name.value}
                </Typography>
                <VenueUpdateForm venue={selectedVenue} onDelete={handleClose} onClose={handleClose}/>
            </>
        )
    } else {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Venues
                </Typography>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={handleTabSelection}>
                        {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                    </Tabs>
                </Box>
                <VenueList onSelect={handleSelect}/>
                <Stack direction={"row"} spacing={1}>
                    <Button variant={"contained"} onClick={handleCreate}>
                        Create
                    </Button>
                </Stack>
            </>
        )
    }

};

export default VenuesPage;
