import {DataMapper} from "../Entity";

export class Comment {

    public readonly value: string;

    constructor(value: string) {
        this.value = value;
    }

    public static toData(value: Comment): string {
        return value.value;
    }

    public static fromData(data: string): Comment {
        return new Comment(data);
    }

}

export const COMMENT_DATA_MAPPER: DataMapper<Comment, string> = {
    toData: Comment.toData,
    fromData: Comment.fromData
}
