import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from "react";
import {ENTITY_STATE_EQUALS_FILTER, EntityState} from "../../../api/model/Entity";
import {Page, PAGE_STATE_EQUALS_FILTER, PageFilter, PageState} from "../../../api/model/Page";
import {Optional} from "../../../api/model/util/Optional";
import PageCreateForm from "./PageCreateForm";
import PageList from "./PageList";
import {usePageSlicer} from "./PageSlicerProvider";
import PageUpdateForm from "./PageUpdateForm";

type TabDefinition = {
    id: string,
    label: string,
    filter: ReadonlyArray<PageFilter<any>>
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "disabled",
        label: "Disabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PAGE_STATE_EQUALS_FILTER,
                comparisonValue: PageState.DISABLED
            }
        ]
    },
    {
        id: "enabled",
        label: "Enabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PAGE_STATE_EQUALS_FILTER,
                comparisonValue: PageState.ENABLED
            }
        ]
    },
    {
        id: "removed",
        label: "Removed",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.REMOVED
            }
        ]
    }
]

const INITIAL_TAB = 1;

export interface Props {
}

const PagesPage: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    const pageSlicer = usePageSlicer();
    const [selectedTab, setSelectedTab] = useState<number>(INITIAL_TAB);
    const [selectedPage, setSelectedPage] = useState<Optional<"NEW" | Page>>(null);

    useEffect(() => {
        pageSlicer.setStaticFilters(TABS[selectedTab].filter);
    }, [selectedTab]);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const handleSelect = (page: Page) => {
        setSelectedPage(page);
    }

    const handleCreate = () => {
        setSelectedPage("NEW");
    }

    const handleClose = () => {
        setSelectedPage(null);
    }

    if (selectedPage === "NEW") {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    New Page
                </Typography>
                <PageCreateForm onClose={handleClose}/>
            </>
        )
    } else if (selectedPage !== null) {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Page: {selectedPage.name.value}
                </Typography>
                <PageUpdateForm page={selectedPage} onDelete={handleClose} onClose={handleClose}/>
            </>
        )
    } else {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Pages
                </Typography>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={handleTabSelection}>
                        {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                    </Tabs>
                </Box>
                <PageList onSelect={handleSelect}/>
                <Stack direction={"row"} spacing={1}>
                    <Button variant={"contained"} onClick={handleCreate}>
                        Create
                    </Button>
                </Stack>
            </>
        )
    }

};

export default PagesPage;
