import {DataMapper} from "../Entity";
import {geoPoint, GeoPoint} from "./GeoPoint";
import {Latitude} from "./Latitude";
import {Longitude} from "./Longitude";

export type geoArea = {
    northEast: geoPoint;
    southWest: geoPoint;
}

export class GeoArea {


    public readonly northEast: GeoPoint;

    public readonly southWest: GeoPoint;

    constructor(northEast: GeoPoint, southWest: GeoPoint) {
        if (northEast.latitude < southWest.latitude) {
            throw new Error("Invalid latitudes: north " + northEast.latitude.value + " vs. south " + southWest.latitude.value);
        }
        if (northEast.longitude < southWest.longitude) {
            throw new Error("Invalid longitudes: east " + northEast.longitude.value + " vs. west " + southWest.longitude.value);
        }
        this.northEast = northEast;
        this.southWest = southWest;
    }

    public withNorthEast(northEast: GeoPoint): GeoArea {
        return new GeoArea(northEast, this.southWest)
    }

    public withSouthWest(southWest: GeoPoint): GeoArea {
        return new GeoArea(this.northEast, southWest)
    }

    public center(): GeoPoint {
        return new GeoPoint(
            new Latitude(GeoArea.mean(this.northEast.latitude.value, this.southWest.latitude.value)),
            new Longitude(GeoArea.mean(this.northEast.longitude.value, this.southWest.longitude.value))
        )
    }

    private static mean(left: number, right: number): number {
        return (left + right) / 2;
    }

    public static toData(value: GeoArea): geoArea {
        return {
            northEast: GeoPoint.toData(value.northEast),
            southWest: GeoPoint.toData(value.southWest)
        };
    }

    static fromData(data: geoArea): GeoArea {
        return new GeoArea(
            GeoPoint.fromData(data.northEast),
            GeoPoint.fromData(data.southWest)
        );
    }

    static fromGeopoints(point1: GeoPoint, point2: GeoPoint): GeoArea {
        const north: Latitude = Latitude.max(point1.latitude, point2.latitude)
        const east: Longitude = Longitude.max(point1.longitude, point2.longitude)
        const south: Latitude = Latitude.min(point1.latitude, point2.latitude)
        const west: Longitude = Longitude.min(point1.longitude, point2.longitude)
        return new GeoArea(new GeoPoint(north, east), new GeoPoint(south, west))
    }

}

export const WORLD = new GeoArea(
    new GeoPoint(new Latitude(90), new Longitude(180)),
    new GeoPoint(new Latitude(-90), new Longitude(-180))
)

export const GEO_AREA_DATA_MAPPER: DataMapper<GeoArea, geoArea> = {
    toData: GeoArea.toData,
    fromData: GeoArea.fromData
}
