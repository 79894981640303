import React from 'react';
import EventSlicerProvider from "../component/entity/event/EventSlicerProvider";
import EventsPage from "../component/entity/event/EventsPage";

function EventRoute() {

    return (
        <EventSlicerProvider>
            <EventsPage/>
        </EventSlicerProvider>
    );

}

export default EventRoute;
