import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, {useEffect, useState} from "react";
import {Optional} from "../../api/model/util/Optional";
import {Contact} from "../../api/model/value/Contact";
import {Contacts} from "../../api/model/value/Contacts";
import {CEO, ContactType} from "../../api/model/value/ContactType";
import {EmailAddresses} from "../../api/model/value/EmailAddresses";
import {Name} from "../../api/model/value/Name";
import {PhoneNumbers} from "../../api/model/value/PhoneNumbers";
import {arrayAdd, arrayDown, arrayRemove, arrayUp, arrayUpdate} from "../../api/util";
import DialogFormButtons from "../form/DialogFormButtons";
import FormRow from "../form/FormRow";
import {FormSize} from "../form/FormSize";
import ContactTypeInput from "./ContactTypeInput";
import EmailAddressesInput from "./EmailAddressesInput";
import NameInput from "./NameInput";
import PhoneNumbersInput from "./PhoneNumbersInput";


interface ContactDialogProps {
    open: boolean;
    value: Contact;
    onClose: (value: Optional<Contact>) => void;
}

const ContactDialog: React.FunctionComponent<ContactDialogProps> = (props: ContactDialogProps) => {

    const {open, value, onClose} = props;
    const [currentValue, setCurrentValue] = useState<Contact>(value);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleReset = () => {
        setCurrentValue(value);
    }

    const handleStore = () => {
        if (currentValue.name.value.length === 0 && currentValue.emailAddresses.items.length === 0 && currentValue.phoneNumbers.items.length === 0) {
            onClose(null)
        } else {
            onClose(currentValue)
        }
    };

    const handleClose = () => {
        if (value.name.value.length === 0 && value.emailAddresses.items.length === 0 && value.phoneNumbers.items.length === 0) {
            onClose(null)
        } else {
            onClose(value)
        }
    };

    return (
        <Dialog maxWidth={"lg"} fullWidth={true} onClose={handleClose} open={open}>
            <DialogTitle>Contact</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FormRow label="Type">
                    <ContactTypeInput
                        id={"type"}
                        value={currentValue.type}
                        disabled={false}
                        size={"small"}
                        setValue={(type: ContactType) => setCurrentValue(currentValue.withType(type))}
                    />
                </FormRow>
                <FormRow label="Name">
                    <NameInput
                        id={"name"}
                        value={currentValue.name}
                        disabled={false}
                        size={"small"}
                        setValue={(name: Name) => setCurrentValue(currentValue.withName(name))}
                    />
                </FormRow>
                <FormRow label="Email Addresses">
                    <EmailAddressesInput
                        id={"email-addresses"}
                        value={currentValue.emailAddresses}
                        disabled={false}
                        size={"small"}
                        setValue={(emailAddresses: EmailAddresses) => setCurrentValue(currentValue.withEmailAddresses(emailAddresses))}
                    />
                </FormRow>
                <FormRow label="Phone Numbers">
                    <PhoneNumbersInput
                        id={"phone-numbers"}
                        value={currentValue.phoneNumbers}
                        disabled={false}
                        size={"small"}
                        setValue={(phoneNumbers: PhoneNumbers) => setCurrentValue(currentValue.withPhoneNumbers(phoneNumbers))}
                    />
                </FormRow>
                <DialogFormButtons onReset={handleReset} onStore={handleStore}/>
            </Grid>
        </Dialog>
    );
}


export interface Props {
    id: string,
    value: Contacts,
    disabled: boolean,
    size: FormSize;
    setValue: (value: Contacts) => void;
}

const ContactsInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedPosition, setSelectedPosition] = useState<Optional<number>>(null);
    const [item, setItem] = useState<Contact>(new Contact(CEO, new Name(""), new EmailAddresses([]), new PhoneNumbers([])));


    const handleAdd = () => {
        setSelectedPosition(null);
        setItem(new Contact(CEO, new Name(""), new EmailAddresses([]), new PhoneNumbers([])));
        setDialogOpen(true);
    };

    const handleSelect = (position: number) => {
        setSelectedPosition(position);
        setItem(value.items[position]);
        setDialogOpen(true);
    };

    const handleClose = (contact: Optional<Contact>) => {
        if (selectedPosition !== null) {
            if (contact !== null) {
                setValue(new Contacts(arrayUpdate(value.items, selectedPosition, contact)));
            } else {
                handleDelete(selectedPosition);
            }
        } else {
            if (contact !== null) {
                setValue(new Contacts(arrayAdd(value.items, contact)));
            }
        }
        setDialogOpen(false);
    };

    const handleMoveUpFactory = (position: number) => {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleMoveUp(position);
        }
    }

    const handleMoveUp = (position: number) => {
        setValue(new Contacts(arrayUp(value.items, position!)));
        handleReset();
    };

    const handleMoveDownFactory = (position: number) => {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleMoveDown(position);
        }
    }

    const handleMoveDown = (position: number) => {
        setValue(new Contacts(arrayDown(value.items, position!)));
        handleReset();
    };

    const handleDeleteFactory = (position: number) => {
        return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleDelete(position);
        }
    }

    const handleDelete = (position: number) => {
        setValue(new Contacts(arrayRemove(value.items, position!)));
        handleReset();
    };

    function handleReset() {
        setSelectedPosition(null);
        setItem(new Contact(CEO, new Name(""), new EmailAddresses([]), new PhoneNumbers([])));
    }

    return (
        <>
            <TableContainer sx={{mb: 3}}>
                <Table sx={{minWidth: 650}} size={"small"} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email Addresses</TableCell>
                            <TableCell>Phone Numbers</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {value.items.map((item: Contact, position: number) => (
                            <TableRow key={position} hover onClick={() => handleSelect(position)}>
                                <TableCell>
                                    {item.type.code.value}
                                </TableCell>
                                <TableCell>
                                    {item.name.value}
                                </TableCell>
                                <TableCell>
                                    <Stack direction={"column"}>
                                        {item.emailAddresses.items.map((emailAddress, position) =>
                                            <span key={position}>
                                                {emailAddress.value}
                                            </span>)}
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Stack direction={"column"}>
                                        {item.phoneNumbers.items.map((phoneNumber, position) =>
                                            <span key={position}>
                                                {phoneNumber.countryCode}-{phoneNumber.areaCode}-{phoneNumber.localNumber}
                                            </span>)}
                                    </Stack>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <IconButton
                                        size={"small"}
                                        disabled={disabled}
                                        onClick={handleMoveUpFactory(position)}>
                                        <KeyboardArrowUp/>
                                    </IconButton>
                                    <IconButton
                                        size={"small"}
                                        disabled={disabled}
                                        onClick={handleMoveDownFactory(position)}>
                                        <KeyboardArrowDown/>
                                    </IconButton>
                                    <IconButton
                                        size={"small"}
                                        disabled={disabled}
                                        onClick={handleDeleteFactory(position)}>
                                        <Delete/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ContactDialog open={dialogOpen} value={item} onClose={handleClose}/>
            <Stack direction="row">
                <FormControl sx={{pt: 1}}>
                    <Button onClick={handleAdd} variant={"outlined"} disabled={disabled}>Add</Button>
                </FormControl>
            </Stack>
        </>
    )

};

export default ContactsInput;
