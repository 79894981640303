import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {Name} from "./Name";
import {stringCompare} from "./String";

export class PassDuration {

    public readonly name: Name;

    public readonly code: Code;

    constructor(name: Name, code: Code) {
        this.name = name;
        this.code = code;
    }

    public static toData(value: PassDuration): string {
        return value.code.value;
    }

    public static fromData(data: string): PassDuration {
        return PassDuration.fromCode(new Code(data));
    }

    public static fromCode(passDurationCode: Code): PassDuration {
        const passDuration: Maybe<PassDuration> = PASS_DURATIONS.find(passDuration => passDuration.code.value === passDurationCode.value);
        if (passDuration === undefined) {
            throw new Error("Unknown usage state: " + JSON.stringify(passDurationCode));
        }
        return passDuration;
    }

}

export const THREE_DAYS: PassDuration = new PassDuration(
    new Name("3 Days"),
    new Code("THREE_DAYS")
);

export const SEVEN_DAYS: PassDuration = new PassDuration(
    new Name("7 Days"),
    new Code("SEVEN_DAYS")
);

export const PASS_DURATIONS: ReadonlyArray<PassDuration> = [
    THREE_DAYS,
    SEVEN_DAYS
] as const;

export const PASS_DURATION_DATA_MAPPER: DataMapper<PassDuration, string> = {
    toData: PassDuration.toData,
    fromData: PassDuration.fromData
}

export function passDurationEquals(left: PassDuration, right: PassDuration): boolean {
    return left.code.value === right.code.value;
}

export function passDurationCompare(left: PassDuration, right: PassDuration): number {
    return stringCompare(left.name.value, right.name.value)
}
