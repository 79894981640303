import {randomNumericalString} from "../../util";
import {DataMapper} from "../Entity";
import {stringCompare} from "./String";

export class PartnerId {

    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public static toData(value: PartnerId): string {
        return value.value;
    }

    public static fromData(data: string): PartnerId {
        return new PartnerId(data);
    }

    public static random(): PartnerId {
        return new PartnerId("P-" + new Date().getFullYear() + "-" + randomNumericalString(8));
    }

    static empty() {
        return new PartnerId("P-0000-00000000");
    }

}

export const PARTNER_ID_DATA_MAPPER: DataMapper<PartnerId, string> = {
    toData: PartnerId.toData,
    fromData: PartnerId.fromData
}

export function partnerIdCompare(left: PartnerId, right: PartnerId): number {
    return stringCompare(left.value, right.value)
}


