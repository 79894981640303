import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {Optional} from "../api/model/util/Optional";
import {useLoggedOut} from "../context/AuthProvider";

export interface Props {
}

const LogInPage: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    const loggedOut = useLoggedOut();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<Optional<string>>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleLogin = async (): Promise<void> => {
        try {
            setError(null);
            setLoading(true);
            await loggedOut.signIn(email, password);
        } catch {
            setError("Failed to login")
            setLoading(false);
        }
    }

    const catchReturn = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
        if (e.key === 'Enter') {
            if (email.length !== 0 && password.length !== 0) {
                await handleLogin();
            }
        }
    }

    return (
        <>
            <Paper sx={{minWidth: "400px", maxWidth: "600px", margin: "100px auto"}} elevation={5}>
                <AppBar position="static" elevation={0}>
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            Loco Login
                        </Typography>
                    </Toolbar>
                </AppBar>
                {error && <Alert severity="error">{error}</Alert>}
                <Grid container spacing={3} maxWidth={1000} sx={{p: 3}}>
                    <Grid item xs={2}>
                        Email
                    </Grid>
                    <Grid item xs={10}>
                        <FormControl fullWidth>
                            <TextField
                                id="email"
                                value={email}
                                size={"small"}
                                required
                                disabled={loading}
                                onChange={event => setEmail(event.target.value)}
                                onKeyPress={catchReturn}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        Password
                    </Grid>
                    <Grid item xs={10}>
                        <FormControl fullWidth>
                            <TextField
                                id="password"
                                value={password}
                                type="password"
                                size={"small"}
                                required
                                disabled={loading}
                                onChange={event => setPassword(event.target.value)}
                                onKeyPress={catchReturn}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={10}>
                        <FormControl fullWidth>
                            <Button
                                variant="contained"
                                size={"large"}
                                disabled={loading}
                                onClick={handleLogin}
                            >
                                Login
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )

};

export default LogInPage;
