import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {EntityScope} from "../../../api/model/Entity";
import {Flag} from "../../../api/model/Flag";
import {Location} from "../../../api/model/Location";
import {Partner} from "../../../api/model/Partner";
import {Tag} from "../../../api/model/Tag";
import {Optional} from "../../../api/model/util/Optional";
import {EntityType} from "../../../api/model/value/EntityType";
import {IdRef} from "../../../api/model/value/IdRef";
import {useProject} from "../../../context/ProjectProvider";
import EventInput, {EventInputResult} from "./EventInput";

export interface Props {
    onClose: () => void
}

const EventUpdateForm: React.FunctionComponent<Props> = (props: Props) => {

    const {onClose} = props;

    const project = useProject();
    const eventManager = useProject().eventManager;
    const [disabled, setDisabled] = useState<boolean>(false);
    const [partners, setPartners] = useState<ReadonlyArray<Partner>>([]);
    const [locations, setLocations] = useState<ReadonlyArray<Location>>([]);
    const [tags, setTags] = useState<ReadonlyArray<Tag>>([]);
    const [flags, setFlags] = useState<Optional<ReadonlyArray<Flag>>>(null);

    useEffect(() => {

        const loadPartners = async () => {
            setPartners(await project.partnerManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadPartners().catch(console.error)

    }, []);


    useEffect(() => {

        const loadLocations = async () => {
            setLocations(await project.locationManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadLocations().catch(console.error)

    }, []);

    useEffect(() => {

        const loadTags = async () => {
            setTags(await project.tagManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadTags().catch(console.error)

    }, []);

    useEffect(() => {

        const loadFlags = async () => {
            setFlags(await project.flagManager.getAllAsArray({region: project.selectedRegion}))
        }

        loadFlags().catch(console.error)

    }, []);

    const handlePersist = async (eventInputResult: EventInputResult) => {
        setDisabled(true);
        await eventManager.persist({
            entityType: EntityType.EVENT,
            entityScope: EntityScope.PREPARED,
            ...eventInputResult,
            region: new IdRef<EntityType.REGION>(project.selectedRegion.id)
        });
        onClose();
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            {partners.length === 0 || locations.length === 0 || tags.length === 0 || flags === null ?
                <Typography variant={"h6"}>
                    No partners or locations or no tags added yet!
                </Typography> :
                <EventInput
                    disabled={disabled}
                    partners={partners}
                    locations={locations}
                    tags={tags}
                    flags={flags}
                    size="small"
                    onPersist={handlePersist}
                />}
            <Stack direction={"row"} spacing={1}>
                <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>
            </Stack>
        </Stack>
    );

};

export default EventUpdateForm;
