import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, {MutableRefObject, useRef, useState} from "react";
import {EntityState} from "../../../api/model/Entity";
import {Partner} from "../../../api/model/Partner";
import {Optional} from "../../../api/model/util/Optional";
import {useProject} from "../../../context/ProjectProvider";
import RemoveButton from "../../form/RemoveButton";
import RestoreButton from "../../form/RestoreButton";
import PartnerContactInput, {PartnerContact} from "./input/PartnerContactInput";
import PartnerCoreInput, {PartnerCore} from "./input/PartnerCoreInput";
import PartnerLegalEntityInput, {PartnerLegalEntity} from "./input/PartnerLegalEntityInput";

type TabDefinition = {
    id: string,
    label: string
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core"
    },
    {
        id: "legal-entity",
        label: "Legal Entity"
    },
    {
        id: "contact",
        label: "Contact"
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    partner: Partner,
    onClose?: () => void
}

const PartnerUpdatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {partner: partner, onClose} = props

    const [latestPartner, setLatestPartner] = useState<Partner>(partner)
    const [currentPartner, setCurrentPartner] = useState<Partner>(partner)
    const [reset, setReset] = useState<number>(0);

    const project = useProject();
    const partnerManager = project.partnerManager;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getPartnerCoreRef: MutableRefObject<Optional<() => PartnerCore>> = useRef<Optional<() => PartnerCore>>(null)
    const getPartnerLegalEntityRef: MutableRefObject<Optional<() => PartnerLegalEntity>> = useRef<Optional<() => PartnerLegalEntity>>(null)
    const getPartnerContactRef: MutableRefObject<Optional<() => PartnerContact>> = useRef<Optional<() => PartnerContact>>(null)

    const handlePersist = async () => {
        setDisabled(true);
        const partner: Partner = {
            ...latestPartner,
            ...getPartnerCoreRef.current!(),
            ...getPartnerLegalEntityRef.current!(),
            ...getPartnerContactRef.current!(),
        }
        const updatedPartner = await partnerManager.persist(partner);
        setLatestPartner(updatedPartner);
        setCurrentPartner(updatedPartner);
        setDisabled(false);
    }

    const handleReset = async () => {
        setDisabled(true);
        setCurrentPartner(latestPartner);
        setReset(reset + 1);
        setDisabled(false);
    }

    const handleRemove = async () => {
        const updatedPartner = await partnerManager.removeById(latestPartner);
        setLatestPartner(updatedPartner);
        setCurrentPartner(updatedPartner);
        setReset(reset + 1);
    }

    const handleRestore = async () => {
        const updatedPartner = await partnerManager.restoreById(latestPartner);
        setLatestPartner(updatedPartner);
        setCurrentPartner(updatedPartner);
        setReset(reset + 1);
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <PartnerCoreInput
                    partner={currentPartner}
                    disabled={disabled}
                    size="small" getValue={getPartnerCoreRef}
                    reset={reset}
                />
            </TabPanel>
            <TabPanel index={1} selectedTab={selectedTab}>
                <PartnerLegalEntityInput
                    partner={currentPartner}
                    disabled={disabled}
                    size="small"
                    getValue={getPartnerLegalEntityRef}
                    reset={reset}
                />
            </TabPanel>
            <TabPanel index={2} selectedTab={selectedTab}>
                <PartnerContactInput
                    partner={currentPartner}
                    disabled={disabled}
                    size="small"
                    getValue={getPartnerContactRef}
                    reset={reset}
                />
            </TabPanel>
            <Stack direction={"row"} spacing={1}>
                {currentPartner.entityState === EntityState.CREATED &&
                    <Button variant={"contained"} onClick={handlePersist}>
                        Update
                    </Button>}
                {currentPartner.entityState === EntityState.CREATED &&
                    <Button variant={"outlined"} onClick={handleReset}>
                        Reset
                    </Button>}
                {currentPartner.entityState === EntityState.REMOVED &&
                    <RestoreButton disabled={false} onRestore={handleRestore}/>}
                {currentPartner.entityState === EntityState.CREATED &&
                    <RemoveButton disabled={false} onRemove={handleRemove}/>}
                {onClose && <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>}
            </Stack>
        </Stack>
    );

};

export default PartnerUpdatePage;
