import Grid from '@mui/material/Grid';
import React, {MutableRefObject, useRef} from "react";
import {Flag, FlagPayload, FlagState} from "../../../../api/model/Flag";
import {Optional} from "../../../../api/model/util/Optional";
import {Name} from "../../../../api/model/value/Name";
import FormRow from "../../../form/FormRow";
import {FormSize} from "../../../form/FormSize";
import NameInput2 from "../../../input/NameInput2";
import FlagStateInput2 from "../../../input/FlagStateInput2";
import StringOutput from "../../../output/StringOutput";

export type FlagCore = Pick<FlagPayload, "state" | "name">;

export interface Props {
    flag?: Flag,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => FlagCore>>,
    reset: number
}

const FlagCoreInput: React.FunctionComponent<Props> = (props: Props) => {

    const {flag, disabled, size, getValue, reset} = props

    const getStateRef: MutableRefObject<Optional<() => FlagState>> = useRef<Optional<() => FlagState>>(null)
    const getNameRef: MutableRefObject<Optional<() => Name>> = useRef<Optional<() => Name>>(null)

    getValue.current = () => ({
        state: getStateRef.current!(),
        name: getNameRef.current!()
    });

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {flag?.id && <FormRow label="Id">
                    <StringOutput
                        value={flag.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <FlagStateInput2
                        id="state"
                        value={flag?.state || FlagState.ENABLED}
                        disabled={disabled}
                        size={size}
                        getValue={getStateRef}
                        reset={reset}
                    />
                </FormRow>
                <FormRow label="Name">
                    <NameInput2
                        id="name"
                        value={flag?.name || new Name("")}
                        disabled={disabled}
                        size={size}
                        getValue={getNameRef}
                        reset={reset}
                    />
                </FormRow>
            </Grid>
        </>
    );

};

export default FlagCoreInput;
