import React, {createContext, useContext, useState} from "react";
import {SortDirection} from "../../../api/model/Entity";
import {Location, LOCATION_NAME_SORT_ORDER, LocationFilter, LocationSortOrder} from "../../../api/model/Location";

export type Props = {}

export type LocationSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: LocationSortOrder,
    setSortOrder: (sortOrder: LocationSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<LocationFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<LocationFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<LocationFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<LocationFilter<any>>) => void,
    slice: (locations: ReadonlyArray<Location>) => [ReadonlyArray<Location>, number]
}

const LocationSlicerContext = createContext<LocationSlicer>(null!);

const LocationSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<LocationSortOrder>(LOCATION_NAME_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<LocationFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<LocationFilter<any>>>([]);

    const slice = (locations: ReadonlyArray<Location>): [ReadonlyArray<Location>, number] => {

        let filteredLocations = locations;
        staticFilters.forEach(filter => {
            filteredLocations = filteredLocations.filter(location => filter.operation.apply(location, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredLocations = filteredLocations.filter(location => filter.operation.apply(location, filter.comparisonValue));
        });

        const sortedLocations = [...filteredLocations].sort((left: Location, right: Location) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedLocations.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedLocations = sortedLocations.slice(start, end);

        return [paginatedLocations, filteredLocations.length];

    }

    const value: LocationSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <LocationSlicerContext.Provider value={value}>
            {children}
        </LocationSlicerContext.Provider>
    );

};

export function useLocationSlicer(): LocationSlicer {
    return useContext(LocationSlicerContext);
}

export default LocationSlicerProvider;