import React from "react";
import {Optional} from "../../api/model/util/Optional";
import {Slot, SLOTS} from "../../api/model/value/Slot";
import {FormSize} from "../form/FormSize";
import OptionalStringOptionsInput from "./OptionalStringOptionsInput";

export interface Props {
    id: string,
    value: Optional<Slot>,
    disabled: boolean,
    size: FormSize,
    setValue: (value: Optional<Slot>) => void
}

const SlotInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <OptionalStringOptionsInput
            id={id}
            value={value ? value.code.value : ""}
            options={SLOTS.map(slot => {
                return {value: slot.code.value, label: slot.name.value}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: Optional<string>) => setValue(value ? Slot.fromData(value) : null)}
        />
    )

};

export default SlotInput;
