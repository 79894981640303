import React from 'react';
import VoucherSlicerProvider from "../component/entity/voucher/VoucherSlicerProvider";
import VouchersPage from "../component/entity/voucher/VouchersPage";

function VoucherRoute() {

    return (
        <VoucherSlicerProvider>
            <VouchersPage/>
        </VoucherSlicerProvider>
    );

}

export default VoucherRoute;
