import FilterList from '@mui/icons-material/FilterList';
import Badge from '@mui/material/Badge';
import IconButton from "@mui/material/IconButton";
import React from "react";

export interface Props {
    filterCount: number,
    onClick: () => void
}

const FilterDialogButton: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {

    const {filterCount, onClick} = props;

    return (
        <>
            <IconButton size="small" color={filterCount === 0 ? "default" : "primary"} onClick={onClick}>
                {filterCount === 0 ?
                    <FilterList/>
                    :
                    <Badge color="primary" badgeContent={filterCount}>
                        <FilterList/>
                    </Badge>}
            </IconButton>
        </>
    )

};

export default FilterDialogButton;
