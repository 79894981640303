export class CountryCode {

    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

}

