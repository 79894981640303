import React from "react";
import {categories, Categories} from "../../api/model/value/Categories";
import {CATEGORIES} from "../../api/model/value/Category";
import {CategoryTarget} from "../../api/model/value/CategoryTarget";
import {FormSize} from "../form/FormSize";
import StringsOptionsInput from "./StringsOptionsInput";

export interface Props {
    id: string,
    value: Categories,
    targets: ReadonlyArray<CategoryTarget>,
    disabled: boolean,
    size: FormSize,
    setValue: (value: Categories) => void
}

const CategoriesInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, targets, disabled, size, setValue} = props

    const targetedCategories = CATEGORIES.filter(category => category.targets.some(target => targets.includes(target)))

    return (
        <StringsOptionsInput
            id={id}
            value={value.items.map(category => category.code.value)}
            options={targetedCategories.map(category => {
                return {value: category.code.value, label: category.name.value}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: ReadonlyArray<string>) => setValue(Categories.fromData(value as categories))}
        />
    )

};

export default CategoriesInput;
