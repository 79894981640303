import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from "react";
import {Affiliate} from "../../../api/model/Affiliate";
import {
    AffiliateCode,
    AFFILIATE_CODE_AFFILIATE_EQUALS_FILTER,
    AFFILIATE_CODE_STATE_EQUALS_FILTER,
    AffiliateCodeFilter,
    AffiliateCodeState
} from "../../../api/model/AffiliateCode";
import {ENTITY_STATE_EQUALS_FILTER, EntityState} from "../../../api/model/Entity";
import {Optional} from "../../../api/model/util/Optional";
import AffiliateCodeCreatePage from "./AffiliateCodeCreatePage";
import AffiliateCodeList from "./AffiliateCodeList";
import {useAffiliateCodeSlicer} from "./AffiliateCodeSlicerProvider";
import AffiliateCodeUpdatePage from "./AffiliateCodeUpdatePage";

type TabDefinition = {
    id: string,
    label: string,
    filter: ReadonlyArray<AffiliateCodeFilter<any>>
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "disabled",
        label: "Disabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: AFFILIATE_CODE_STATE_EQUALS_FILTER,
                comparisonValue: AffiliateCodeState.DISABLED
            }
        ]
    },
    {
        id: "enabled",
        label: "Enabled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: AFFILIATE_CODE_STATE_EQUALS_FILTER,
                comparisonValue: AffiliateCodeState.ENABLED
            }
        ]
    },
    {
        id: "removed",
        label: "Removed",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.REMOVED
            }
        ]
    }
]

const INITIAL_TAB = 1;

export interface Props {
    affiliate: Affiliate
}

const AffiliateCodesPage: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {

    const {affiliate} = props;

    const codeSlicer = useAffiliateCodeSlicer();
    const [selectedTab, setSelectedTab] = useState<number>(INITIAL_TAB);
    const [selectedCode, setSelectedCode] = useState<Optional<"NEW" | AffiliateCode>>(null);

    useEffect(() => {
        codeSlicer.setStaticFilters([
            ...TABS[selectedTab].filter,
            {
                operation: AFFILIATE_CODE_AFFILIATE_EQUALS_FILTER,
                comparisonValue: affiliate.id
            }
        ]);
    }, [selectedTab]);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const handleSelect = (code: AffiliateCode) => {
        setSelectedCode(code);
    }

    const handleCreate = () => {
        setSelectedCode("NEW");
    }

    const handleClose = () => {
        setSelectedCode(null);
    }

    if (selectedCode === "NEW") {
        return (
            <>
                <Typography variant="h5" gutterBottom>
                    New Code
                </Typography>
                <AffiliateCodeCreatePage affiliate={affiliate} onClose={handleClose}/>
            </>
        )
    } else if (selectedCode !== null) {
        return (
            <>
                <Typography variant="h5" gutterBottom>
                    Code: {selectedCode.name.value}
                </Typography>
                <AffiliateCodeUpdatePage code={selectedCode} onClose={handleClose}/>
            </>
        )
    } else {
        return (
            <>
                <Typography variant="h5" gutterBottom>
                    Codes
                </Typography>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={handleTabSelection}>
                        {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                    </Tabs>
                </Box>
                <AffiliateCodeList onSelect={handleSelect}/>
                <Stack direction={"row"} spacing={1}>
                    <Button variant={"contained"} onClick={handleCreate}>
                        Create
                    </Button>
                </Stack>
            </>
        )
    }

};

export default AffiliateCodesPage;
