import {EditOutlined} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from "@mui/material/TablePagination";
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import {ENTITY_ID_CONTAINS_FILTER, SortDirection} from "../../../api/model/Entity";
import {
    Event,
    EVENT_EVENT_DATE_SORT_ORDER,
    EVENT_NAME_CONTAINS_FILTER,
    EVENT_NAME_SORT_ORDER,
    EVENT_PARTICIPATION_SORT_ORDER,
    EVENT_STATE_SORT_ORDER, EVENT_SUBTITLE_SORT_ORDER,
    EventFilter,
    EventSortOrder
} from "../../../api/model/Event";
import FilterDialogButton from "../../form/FilterDialogButton";
import FilterFormButtons from "../../form/FilterFormButtons";
import FilterFormRow from "../../form/FilterFormRow";
import SortOrderButton from "../../form/SortOrderButton";
import StringInput from "../../input/StringInput";
import {useEventSlicer} from "./EventSlicerProvider";

interface FilterDialogProps {
    open: boolean;
    onClose: (filter: InternalFilter) => void;
}

const FilterDialog: React.FunctionComponent<FilterDialogProps> = (props: FilterDialogProps) => {

    const {open, onClose} = props;

    const [id, setId] = useState<string>("");
    const [name, setName] = useState<string>("");

    const handleClear = () => {
        setId("");
        setName("");
    };

    const handleClose = () => {
        onClose({
            id: id.length === 0 ? undefined : id,
            name: name.length === 0 ? undefined : name
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Filter</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FilterFormRow label="ID" operation="contains">
                    <StringInput
                        id="id"
                        value={id}
                        disabled={false}
                        size="small"
                        setValue={setId}
                    />
                </FilterFormRow>
                <FilterFormRow label="Name" operation="contains">
                    <StringInput
                        id="name"
                        value={name}
                        disabled={false}
                        size="small"
                        setValue={setName}
                    />
                </FilterFormRow>
                <FilterFormButtons onClear={handleClear} onClose={handleClose}/>
            </Grid>
        </Dialog>
    );
}

interface RowProps {
    event: Event,
    onSelect: (event: Event) => void
}

const Row: React.FunctionComponent<RowProps> = ({event, onSelect}: RowProps) => {
    return (
        <TableRow>
            <TableCell padding={"checkbox"}>
                <IconButton size="small" onClick={() => onSelect(event)}>
                    <EditOutlined/>
                </IconButton>
            </TableCell>
            <TableCell>{event.name.value}</TableCell>
            <TableCell>{event.subtitle.value}</TableCell>
            <TableCell>{event.eventDate.toString()}</TableCell>
            <TableCell>{event.participation.type.name.value}</TableCell>
            <TableCell>{event.state}</TableCell>
        </TableRow>
    );
}

export interface Props {
    events: ReadonlyArray<Event>,
    onSelect?: (event: Event) => void
}

type InternalFilter = {
    id?: string,
    name?: string
}

const EventTable: React.FunctionComponent<Props> = ({events, onSelect}: Props) => {

    const eventSlicer = useEventSlicer();

    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const [internalFilter, setInternalFilter] = useState<InternalFilter>({});

    const handleFilterDialogOpen = () => {
        setFilterDialogOpen(true);
    };

    const handleFilterDialogClose = (filter: InternalFilter) => {
        setFilterDialogOpen(false);
        setInternalFilter(filter)
    };

    useEffect(() => {

        const dynamicFilters = new Array<EventFilter<any>>();

        if (internalFilter.id) {
            dynamicFilters.push({
                operation: ENTITY_ID_CONTAINS_FILTER,
                comparisonValue: internalFilter.id
            });
        }
        if (internalFilter.name) {
            dynamicFilters.push({
                operation: EVENT_NAME_CONTAINS_FILTER,
                comparisonValue: internalFilter.name
            });
        }

        eventSlicer.setDynamicFilters(dynamicFilters);
        eventSlicer.setPaginationPage(0);

    }, [internalFilter]);

    function handleSortChange(sortOrder: EventSortOrder, direction: SortDirection) {
        eventSlicer.setSortOrder(sortOrder);
        eventSlicer.setSortDirection(direction);
        eventSlicer.setPaginationPage(0);
    }

    const handlePaginationPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        eventSlicer.setPaginationPage(newPage);
    };

    const handlePaginationRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        eventSlicer.setPaginationRowsPerPage(newRowsPerPage);
        eventSlicer.setPaginationPage(0);
    };

    const handleSelectFactory = (event: Event) => {
        return () => {
            onSelect && onSelect(event)
        }
    }

    const [eventSlice, eventSliceCount] = eventSlicer.slice(events);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <FilterDialogButton
                                    filterCount={eventSlicer.dynamicFilters.length}
                                    onClick={handleFilterDialogOpen}
                                />
                                <FilterDialog
                                    open={filterDialogOpen}
                                    onClose={handleFilterDialogClose}
                                />
                            </TableCell>
                            <TableCell>
                                Name
                                <SortOrderButton
                                    sortOrder={EVENT_NAME_SORT_ORDER}
                                    currentSortOrder={eventSlicer.sortOrder}
                                    currentSortDirection={eventSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Subtitle
                                <SortOrderButton
                                    sortOrder={EVENT_SUBTITLE_SORT_ORDER}
                                    currentSortOrder={eventSlicer.sortOrder}
                                    currentSortDirection={eventSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Date
                                <SortOrderButton
                                    sortOrder={EVENT_EVENT_DATE_SORT_ORDER}
                                    currentSortOrder={eventSlicer.sortOrder}
                                    currentSortDirection={eventSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Participation
                                <SortOrderButton
                                    sortOrder={EVENT_PARTICIPATION_SORT_ORDER}
                                    currentSortOrder={eventSlicer.sortOrder}
                                    currentSortDirection={eventSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                State
                                <SortOrderButton
                                    sortOrder={EVENT_STATE_SORT_ORDER}
                                    currentSortOrder={eventSlicer.sortOrder}
                                    currentSortDirection={eventSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventSlice.map((event: Event) => (
                            <Row key={event.id.value} event={event} onSelect={handleSelectFactory(event)}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={eventSlicer.paginationRowsPerPageOptions}
                component="div"
                count={eventSliceCount}
                rowsPerPage={eventSlicer.paginationRowsPerPage}
                page={eventSlicer.paginationPage}
                onRowsPerPageChange={handlePaginationRowsPerPageChange}
                onPageChange={handlePaginationPageChange}
            />
        </>
    );

}

export default EventTable;

