import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";
import {LocationPayload, LocationState, Location} from "../../../api/model/Location";
import {Address} from "../../../api/model/value/Address";
import {GeoPoint} from "../../../api/model/value/GeoPoint";
import {Name} from "../../../api/model/value/Name";
import {useProject} from "../../../context/ProjectProvider";
import FormRow from "../../form/FormRow";
import {FormSize} from "../../form/FormSize";
import AddressInput from "../../input/AddressInput";
import GeoPointInput from "../../input/GeoPointInput";
import LocationStateInput from "../../input/LocationStateInput";
import NameInput from "../../input/NameInput";
import StringOutput from "../../output/StringOutput";

export type LocationInputResult = Omit<LocationPayload, "region">;

export interface Props {
    location?: Location,
    disabled: boolean,
    size: FormSize,
    onPersist?: (partialLocation: LocationInputResult) => void
}

const LocationInput: React.FunctionComponent<Props> = (props: Props) => {

    const {location, disabled, size, onPersist} = props

    const region = useProject().selectedRegion;
    const [state, setState] = useState<LocationState>(LocationState.ENABLED);
    const [name, setName] = useState<Name>(new Name(""));
    const [address, setAddress] = useState<Address>(new Address("", "", "", "", region.country));
    const [geoLocation, setGeoLocation] = useState<GeoPoint>(region.area.center());

    useEffect(() => {
        if (location) {
            setState(location.state);
            setName(location.name);
            setAddress(location.address);
            setGeoLocation(location.geoLocation);
        }
    }, [location]);

    const handlePersist = () => {

        const locationInputResult: LocationInputResult = {
            state,
            name,
            address,
            geoLocation
        };

        onPersist && onPersist(locationInputResult);

    }

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {location?.id && <FormRow label="Id">
                    <StringOutput
                        value={location.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <LocationStateInput
                        id="state"
                        value={state}
                        disabled={disabled}
                        size={size}
                        setValue={setState}
                    />
                </FormRow>
                <FormRow label="Name">
                    <NameInput
                        id="name"
                        value={name}
                        disabled={disabled}
                        size={size}
                        setValue={setName}
                    />
                </FormRow>
                <FormRow label="Address">
                    <AddressInput
                        id="address"
                        value={address}
                        disabled={disabled}
                        size={size}
                        setValue={setAddress}
                    />
                </FormRow>
                <FormRow label="Geo Location">
                    <GeoPointInput
                        id="geo-location"
                        value={geoLocation}
                        disabled={disabled}
                        size={size}
                        geoArea={region.area}
                        setValue={setGeoLocation}
                    />
                </FormRow>
            </Grid>
            {onPersist && <Stack direction={"row"} spacing={1}>
                <Button
                    variant={"contained"}
                    disabled={disabled}
                    onClick={handlePersist}
                >
                    Save
                </Button>
            </Stack>}
        </>
    );

};

export default LocationInput;
