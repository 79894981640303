import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, {MutableRefObject, useRef, useState} from "react";
import {EntityState} from "../../../api/model/Entity";
import {Optional} from "../../../api/model/util/Optional";
import {Voucher} from "../../../api/model/Voucher";
import {useProject} from "../../../context/ProjectProvider";
import RemoveButton from "../../form/RemoveButton";
import RestoreButton from "../../form/RestoreButton";
import VoucherCodeSlicerProvider from "../voucherCode/VoucherCodeSlicerProvider";
import VoucherCodesPage from "../voucherCode/VoucherCodesPage";
import VoucherCoreInput, {VoucherCore} from "./input/VoucherCoreInput";

type TabDefinition = {
    id: string,
    label: string,
    buttons: boolean
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core",
        buttons: true
    },
    {
        id: "codes",
        label: "Codes",
        buttons: false
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    voucher: Voucher,
    onClose?: () => void
}

const VoucherUpdatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {voucher: voucher, onClose} = props

    const [latestVoucher, setLatestVoucher] = useState<Voucher>(voucher)
    const [currentVoucher, setCurrentVoucher] = useState<Voucher>(voucher)
    const [reset, setReset] = useState<number>(0);

    const project = useProject();
    const voucherManager = project.voucherManager;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getVoucherCoreRef: MutableRefObject<Optional<() => VoucherCore>> = useRef<Optional<() => VoucherCore>>(null)

    const handlePersist = async () => {
        setDisabled(true);
        const voucher: Voucher = {
            ...latestVoucher,
            ...getVoucherCoreRef.current!(),
        }
        const updatedVoucher = await voucherManager.persist(voucher);
        setLatestVoucher(updatedVoucher);
        setCurrentVoucher(updatedVoucher);
        setDisabled(false);
    }

    const handleReset = async () => {
        setDisabled(true);
        setCurrentVoucher(latestVoucher);
        setReset(reset + 1);
        setDisabled(false);
    }

    const handleRemove = async () => {
        const updatedVoucher = await voucherManager.removeById(latestVoucher);
        setLatestVoucher(updatedVoucher);
        setCurrentVoucher(updatedVoucher);
        setReset(reset + 1);
    }

    const handleRestore = async () => {
        const updatedVoucher = await voucherManager.restoreById(latestVoucher);
        setLatestVoucher(updatedVoucher);
        setCurrentVoucher(updatedVoucher);
        setReset(reset + 1);
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <VoucherCoreInput
                    voucher={currentVoucher}
                    created={true}
                    disabled={disabled}
                    size="small" getValue={getVoucherCoreRef}
                    reset={reset}
                />
            </TabPanel>
            <TabPanel index={1} selectedTab={selectedTab}>
                <VoucherCodeSlicerProvider>
                    <VoucherCodesPage voucher={currentVoucher}/>
                </VoucherCodeSlicerProvider>
            </TabPanel>
            {TABS[selectedTab].buttons && <Stack direction={"row"} spacing={1}>
                {currentVoucher.entityState === EntityState.CREATED &&
                    <Button variant={"contained"} onClick={handlePersist}>
                        Update
                    </Button>}
                {currentVoucher.entityState === EntityState.CREATED &&
                    <Button variant={"outlined"} onClick={handleReset}>
                        Reset
                    </Button>}
                {currentVoucher.entityState === EntityState.REMOVED &&
                    <RestoreButton disabled={false} onRestore={handleRestore}/>}
                {currentVoucher.entityState === EntityState.CREATED &&
                    <RemoveButton disabled={false} onRemove={handleRemove}/>}
                {onClose && <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>}
            </Stack>}
        </Stack>
    );

};

export default VoucherUpdatePage;
