import {collection, CollectionReference, doc, Firestore, Timestamp} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SimpleOptionalAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {DATE_DATA_MAPPER} from "./value/Date";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {Name, NAME_DATA_MAPPER} from "./value/Name";
import {stringCompare, stringEquals} from "./value/String";

export enum GuestState {

    ENTERED = "ENTERED",

    LEFT = "LEFT"

}

export type GuestPayload = {
    state: GuestState,
    firstName: Name,
    lastName: Name,
    enteredAt: Optional<Date>,
    leftAt: Optional<Date>,
    customer: IdRef<EntityType.CUSTOMER>,
    event: IdRef<EntityType.EVENT>,
    region: IdRef<EntityType.REGION>
}

export type GuestAnchor = EntityAnchor<EntityType.GUEST> & {
    event: IdAlike<EntityType.EVENT>,
    region: IdAlike<EntityType.REGION>
}

export type PreparedGuest = PreparedEntity<EntityType.GUEST> & GuestPayload;

export type Guest = Entity<EntityType.GUEST> & GuestPayload;

export type PersistableGuest = PreparedGuest | Guest;

export type GuestManager = EntityManager<EntityType.GUEST, GuestAnchor, PersistableGuest, Guest>;

export const GUEST_STATE_DATA_MAPPER: DataMapper<GuestState, string> = {
    toData: (value: GuestState) => value,
    fromData: (data: string) => data as GuestState
}

export const GUEST_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableGuest, GuestState, string>(
        "state",
        e => e.state,
        GUEST_STATE_DATA_MAPPER,
        null
    );

export const GUEST_FIRST_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableGuest, Name, string>(
        "firstName",
        e => e.firstName,
        NAME_DATA_MAPPER,
        null
    );

export const GUEST_LAST_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableGuest, Name, string>(
        "lastName",
        e => e.lastName,
        NAME_DATA_MAPPER,
        null
    );

export const GUEST_ENTERED_AT_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistableGuest, Date, Timestamp>(
        "enteredAt",
        e => e.enteredAt,
        DATE_DATA_MAPPER
    );

export const GUEST_LEFT_AT_ATTRIBUTE_DEFINITION =
    new SimpleOptionalAttributeDefinition<PersistableGuest, Date, Timestamp>(
        "leftAt",
        e => e.leftAt,
        DATE_DATA_MAPPER
    );

export const GUEST_CUSTOMER_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.CUSTOMER, PersistableGuest>(
        EntityType.CUSTOMER,
        "customer",
        e => e.customer
    );

export const GUEST_EVENT_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.EVENT, PersistableGuest>(
        EntityType.EVENT,
        "event",
        e => e.event
    );

export const GUEST_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableGuest>(
        EntityType.REGION,
        "region",
        e => e.region
    );

export type GuestFilterColumn = EntityFilterColumn | "STATE";

export type GuestFilterOperation<V> = FilterOperation<EntityType.GUEST, Guest, GuestFilterColumn, V>;

export type GuestFilter<V> = Filter<EntityType.GUEST, Guest, GuestFilterColumn, V>;

export const GUEST_STATE_EQUALS_FILTER: GuestFilterOperation<GuestState> = {
    column: "STATE",
    apply: (guest: Guest, comparisonValue: GuestState) => stringEquals(guest.state, comparisonValue)
}

export type GuestSortColumn = EntitySortColumn | "STATE";

export type GuestSortOrder = SortOrder<EntityType.GUEST, Guest, GuestSortColumn>;

export const GUEST_STATE_SORT_ORDER: GuestSortOrder = {
    column: "STATE",
    apply: (left: Guest, right: Guest) => stringCompare(left.state, right.state)
}

class GuestDefinition extends EntityDefinition<EntityType.GUEST, GuestAnchor, PersistableGuest, Guest> {

    constructor() {
        super(
            EntityType.GUEST,
            [
                GUEST_STATE_ATTRIBUTE_DEFINITION,
                GUEST_FIRST_NAME_ATTRIBUTE_DEFINITION,
                GUEST_LAST_NAME_ATTRIBUTE_DEFINITION,
                GUEST_ENTERED_AT_ATTRIBUTE_DEFINITION,
                GUEST_LEFT_AT_ATTRIBUTE_DEFINITION,
                GUEST_CUSTOMER_ATTRIBUTE_DEFINITION,
                GUEST_EVENT_ATTRIBUTE_DEFINITION,
                GUEST_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableGuest): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableGuest): GuestAnchor {
        return {event: entity.event, region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.GUEST>): GuestAnchor {
        const event = new Id(EntityType.EVENT, idFromIdAlike(idAlike).parentPath()!)
        const region = new Id(EntityType.REGION, idFromIdAlike(event).parentPath()!)
        return {event, region};
    }

    public anchorToColRef(database: Firestore, anchor: GuestAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.event).path), EntityType.GUEST.toLowerCase(),);
    }

}

export const GUEST_DEFINITION = new GuestDefinition();