import Delete from "@mui/icons-material/Delete";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";
import {EntityManager} from "../../api/EntityManager";
import {EntityScope} from "../../api/model/Entity";
import {IMAGE_DEFINITION, ImageState, PreparedImage} from "../../api/model/Image";
import {Optional} from "../../api/model/util/Optional";
import {EntityType} from "../../api/model/value/EntityType";
import {IdRef} from "../../api/model/value/IdRef";
import {ScaledImages} from "../../api/model/value/ScaledImages";
import {StoredImage} from "../../api/model/value/StoredImage";
import {arrayAdd, arrayDown, arrayRemove, arrayUp} from "../../api/util";
import {useProject} from "../../context/ProjectProvider";
import {database} from "../../firebase/firebase";
import useImageStore from "../../hook/useImageStore";
import {FormSize} from "../form/FormSize";
import ImageOutput from "../output/ImageOutput";

export interface Props {
    id: string,
    value: ReadonlyArray<IdRef<EntityType.IMAGE>>,
    disabled: boolean,
    size: FormSize;
    setValue: (value: ReadonlyArray<IdRef<EntityType.IMAGE>>) => void
}

const ImagesInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const region = useProject().selectedRegion;
    const [fileList, setFileList] = useState<Optional<{ list: FileList, index: number }>>(null);
    const [file, setFile] = useState<Optional<File>>(null);
    const {progress, error, image} = useImageStore(file);

    useEffect(() => {

        if (fileList && fileList.index < fileList.list.length) {
            setFile(fileList.list.item(fileList.index));
        } else {
            setFileList(null);
            setFile(null);
        }

    }, [fileList]);

    useEffect(() => {

        const storeImage = async (storedImage: StoredImage) => {

            const preparedImage: PreparedImage = {
                entityType: EntityType.IMAGE,
                entityScope: EntityScope.PREPARED,
                state: ImageState.UPLOADED,
                original: storedImage,
                scaled: new ScaledImages([]),
                region: new IdRef<EntityType.REGION>(region.id)
            }

            const imageManager = new EntityManager(database, IMAGE_DEFINITION);
            const image = await imageManager.persist(preparedImage)

            setValue(arrayAdd(value, new IdRef<EntityType.IMAGE>(image.id)));
            if (fileList) {
                setFileList({...fileList, index: fileList.index + 1});
            }

        }

        if (error) {
            console.error(error);
        }

        if (image) {
            storeImage(image).catch(console.error)
        }

    }, [error, image]);


    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const fileList: Optional<FileList> = event.target.files;
        if (fileList && fileList.length !== 0) {
            setFileList({list: fileList, index: 0});
        }
    }

    const handleMoveLeft = (index: number): void => {
        setValue(arrayUp(value, index));
    }

    const handleMoveRight = (index: number): void => {
        setValue(arrayDown(value, index));
    }

    const handleDelete = (index: number): void => {
        setValue(arrayRemove(value, index));
    }

    const currentlyUploading: boolean = file !== null;

    return (
        <Stack direction="column" spacing={1}>
            <Grid container spacing={3}>
                {value.map((image: IdRef<EntityType.IMAGE>, index: number) =>
                    <Grid key={image.id.value} item xs={3}>
                        <Stack direction="column">
                            <ImageOutput
                                value={image}
                                size={size}
                                disabled={disabled}
                            />
                            <Stack direction="row" sx={{justifyContent: "center"}}>
                                <IconButton
                                    size={size}
                                    disabled={disabled || currentlyUploading}
                                    onClick={() => handleMoveLeft(index)}>
                                    <KeyboardArrowLeft/>
                                </IconButton>
                                <IconButton
                                    size={size}
                                    disabled={disabled || currentlyUploading}
                                    onClick={() => handleDelete(index)}>
                                    <Delete/>
                                </IconButton>
                                <IconButton
                                    size={size}
                                    disabled={disabled || currentlyUploading}
                                    onClick={() => handleMoveRight(index)}>
                                    <KeyboardArrowRight/>
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Grid>)}
            </Grid>
            <label htmlFor={id + "-upload"}>
                <input
                    type="file"
                    id={id + "-upload"}
                    accept="image/jpeg,image/png"
                    disabled={disabled || currentlyUploading}
                    multiple={true}
                    style={{display: 'none'}}
                    onChange={handleUpload}
                />
                <Button
                    variant="contained"
                    component="span"
                    size={size}
                    disabled={disabled || currentlyUploading}
                >
                    Upload
                </Button>
            </label>
            {currentlyUploading && <LinearProgress variant="determinate" value={progress}/>}
        </Stack>
    )

};

export default ImagesInput;
