import {connectFirestoreEmulator, Firestore, getFirestore} from "@firebase/firestore";
import {connectStorageEmulator, FirebaseStorage, getStorage} from "@firebase/storage";
import {FirebaseApp, initializeApp} from "firebase/app";
import {Auth, connectAuthEmulator, getAuth} from "firebase/auth";
import {connectFunctionsEmulator, Functions, getFunctions} from "firebase/functions";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app: FirebaseApp = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app);
export const database: Firestore = getFirestore(app);
export const storage: FirebaseStorage = getStorage(app);
export const functions: Functions = getFunctions(app, "europe-west3");

if (window.location.hostname === "localhost") {
    connectAuthEmulator(auth, "http://localhost:10001");
    connectFirestoreEmulator(database, 'localhost', 10002);
    connectStorageEmulator(storage, 'localhost', 10003);
    connectFunctionsEmulator(functions, 'localhost', 10005);
}