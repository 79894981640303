import {randomNumericalString} from "../../util";
import {DataMapper} from "../Entity";
import {stringCompare} from "./String";

export class CustomerId {

    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public static toData(value: CustomerId): string {
        return value.value;
    }

    public static fromData(data: string): CustomerId {
        return new CustomerId(data);
    }

    public static random(): CustomerId {
        return new CustomerId("C-" + new Date().getFullYear() + "-" + randomNumericalString(8));
    }

    static empty() {
        return new CustomerId("C-0000-00000000");
    }

}

export const CUSTOMER_ID_DATA_MAPPER: DataMapper<CustomerId, string> = {
    toData: CustomerId.toData,
    fromData: CustomerId.fromData
}

export function customerIdCompare(left: CustomerId, right: CustomerId): number {
    return stringCompare(left.value, right.value)
}

