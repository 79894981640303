import Divider from "@mui/material/Divider";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useProject} from "../context/ProjectProvider";

export interface Props {
}

const Nav: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    const location = useLocation();
    const project = useProject();

    const pathname = location.pathname;

    return (
        <>
            <Typography variant={"h6"} sx={{px: 2, pt: 1}}>
                Loco Global
            </Typography>
            <List component="nav" dense>
                <Link to={'/'}>
                    <ListItemButton selected={pathname === "/"}>
                        <ListItemText primary="Home"/>
                    </ListItemButton>
                </Link>
                <Divider/>
                <Link to={'/customer'}>
                    <ListItemButton selected={pathname.startsWith("/customer")}>
                        <ListItemText primary="Customers"/>
                    </ListItemButton>
                </Link> <Divider/>
                <Link to={'/affiliate'}>
                    <ListItemButton selected={pathname.startsWith("/affiliate")}>
                        <ListItemText primary="Affiliates"/>
                    </ListItemButton>
                </Link> <Divider/>
                <Link to={'/partner'}>
                    <ListItemButton selected={pathname.startsWith("/partner")}>
                        <ListItemText primary="Partners"/>
                    </ListItemButton>
                </Link>
            </List>
            <Typography variant={"h6"} sx={{px: 2, pt: 1}}>
                Loco {project.selectedRegion.name.value}
            </Typography>
            <List component="nav" dense>
                <Link to={'/purchase'}>
                    <ListItemButton selected={pathname.startsWith("/purchase")}>
                        <ListItemText primary="Purchases"/>
                    </ListItemButton>
                </Link>
                <Divider/>  <Link to={'/voucher'}>
                <ListItemButton selected={pathname.startsWith("/voucher")}>
                    <ListItemText primary="Vouchers"/>
                </ListItemButton>
            </Link>
                <Divider/>
                <Link to={'/event'}>
                    <ListItemButton selected={pathname.startsWith("/event")}>
                        <ListItemText primary="Events"/>
                    </ListItemButton>
                </Link>
                <Divider/>
                <Link to={'/venue'}>
                    <ListItemButton selected={pathname.startsWith("/venue")}>
                        <ListItemText primary="Venues"/>
                    </ListItemButton>
                </Link>
                <Divider/>
                <Link to={'/location'}>
                    <ListItemButton selected={pathname.startsWith("/location")}>
                        <ListItemText primary="Locations"/>
                    </ListItemButton>
                </Link>
                <Divider/>
                <Link to={'/tag'}>
                    <ListItemButton selected={pathname.startsWith("/tag")}>
                        <ListItemText primary="Tags"/>
                    </ListItemButton>
                </Link>
                <Divider/>
                <Link to={'/flag'}>
                    <ListItemButton selected={pathname.startsWith("/flag")}>
                        <ListItemText primary="Flags"/>
                    </ListItemButton>
                </Link>
                <Divider/>
                <Link to={'/page'}>
                    <ListItemButton selected={pathname.startsWith("/page")}>
                        <ListItemText primary="Pages"/>
                    </ListItemButton>
                </Link>
                <Divider/>
                <Link to={'/bundle'}>
                    <ListItemButton selected={pathname.startsWith("/bundle")}>
                        <ListItemText primary="Bundles"/>
                    </ListItemButton>
                </Link>
            </List>
        </>
    );
};

export default Nav;
