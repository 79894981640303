import React from "react";
import {LocationState} from "../../api/model/Location";
import {FormSize} from "../form/FormSize";
import StringOptionsInput from "./StringOptionsInput";

export interface Props {
    id: string,
    value: LocationState,
    disabled: boolean,
    size: FormSize;
    setValue: (value: LocationState) => void
}

const LocationStateInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <StringOptionsInput
            id={id}
            value={value}
            options={Object.keys(LocationState).map(state => {
                return {value: state, label: state}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: string) => setValue(value as LocationState)}
        />
    )

};

export default LocationStateInput;
