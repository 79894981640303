import React, {createContext, useContext, useState} from "react";
import {
    Customer,
    CUSTOMER_CREATED_AT_SORT_ORDER,
    CUSTOMER_NAME_SORT_ORDER,
    CustomerFilter,
    CustomerSortOrder
} from "../../../api/model/Customer";
import {SortDirection} from "../../../api/model/Entity";

export type Props = {}

export type CustomerSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: CustomerSortOrder,
    setSortOrder: (sortOrder: CustomerSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<CustomerFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<CustomerFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<CustomerFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<CustomerFilter<any>>) => void,
    slice: (customers: ReadonlyArray<Customer>) => [ReadonlyArray<Customer>, number]
}

const CustomerSlicerContext = createContext<CustomerSlicer>(null!);

const CustomerSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<CustomerSortOrder>(CUSTOMER_CREATED_AT_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<CustomerFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<CustomerFilter<any>>>([]);

    const slice = (customers: ReadonlyArray<Customer>): [ReadonlyArray<Customer>, number] => {

        let filteredCustomers = customers;
        staticFilters.forEach(filter => {
            filteredCustomers = filteredCustomers.filter(customer => filter.operation.apply(customer, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredCustomers = filteredCustomers.filter(customer => filter.operation.apply(customer, filter.comparisonValue));
        });

        const sortedCustomers = [...filteredCustomers].sort((left: Customer, right: Customer) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedCustomers.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedCustomers = sortedCustomers.slice(start, end);

        return [paginatedCustomers, filteredCustomers.length];

    }

    const value: CustomerSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <CustomerSlicerContext.Provider value={value}>
            {children}
        </CustomerSlicerContext.Provider>
    );

};

export function useCustomerSlicer(): CustomerSlicer {
    return useContext(CustomerSlicerContext);
}

export default CustomerSlicerProvider;