import React, {useEffect, useState} from "react";
import {Customer} from "../../../api/model/Customer";
import {Project, useProject} from "../../../context/ProjectProvider";
import CustomerTable from "./CustomerTable";

export interface Props {
    onSelect?: (customer: Customer) => void
}

const CustomerList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [customers, setCustomers] = useState<ReadonlyArray<Customer>>([]);

    useEffect(() => {

        const loadCustomers = async () => {

            const customers = await project.customerManager.getAllAsArray({});
            setCustomers(customers);

        }

        loadCustomers().catch(console.error);

    }, [project]);

    return (
        <CustomerTable
            customers={customers}
            onSelect={onSelect}
        />
    );

};

export default CustomerList;
