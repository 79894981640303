import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, {MutableRefObject, useRef, useState} from "react";
import {PreparedAffiliate} from "../../../api/model/Affiliate";
import {EntityScope} from "../../../api/model/Entity";
import {Optional} from "../../../api/model/util/Optional";
import {AffiliateId} from "../../../api/model/value/AffiliateId";
import {EntityType} from "../../../api/model/value/EntityType";
import {useProject} from "../../../context/ProjectProvider";
import AffiliateContactInput, {AffiliateContact} from "./input/AffiliateContactInput";
import AffiliateCoreInput, {AffiliateCore} from "./input/AffiliateCoreInput";
import AffiliateLegalEntityInput, {AffiliateLegalEntity} from "./input/AffiliateLegalEntityInput";

type TabDefinition = {
    id: string,
    label: string
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core"
    },
    {
        id: "legal-entity",
        label: "Legal Entity"
    },
    {
        id: "contact",
        label: "Contact"
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    onClose: () => void
}

const AffiliateCreatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {onClose} = props;

    const project = useProject();
    const affiliateManager = project.affiliateManager;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getAffiliateCoreRef: MutableRefObject<Optional<() => AffiliateCore>> = useRef<Optional<() => AffiliateCore>>(null)
    const getAffiliateLegalEntityRef: MutableRefObject<Optional<() => AffiliateLegalEntity>> = useRef<Optional<() => AffiliateLegalEntity>>(null)
    const getAffiliateContactRef: MutableRefObject<Optional<() => AffiliateContact>> = useRef<Optional<() => AffiliateContact>>(null)

    const handlePersist = async () => {

        setDisabled(true);

        const preparedAffiliate: PreparedAffiliate = {
            entityType: EntityType.AFFILIATE,
            entityScope: EntityScope.PREPARED,
            ...getAffiliateCoreRef.current!(),
            ...getAffiliateLegalEntityRef.current!(),
            ...getAffiliateContactRef.current!(),
            affiliateId: AffiliateId.random()
        }

        await affiliateManager.persist(preparedAffiliate);

        onClose();

    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <AffiliateCoreInput
                    disabled={disabled}
                    size="small"
                    getValue={getAffiliateCoreRef}
                    reset={0}
                />
            </TabPanel>
            <TabPanel index={1} selectedTab={selectedTab}>
                <AffiliateLegalEntityInput
                    disabled={disabled}
                    size="small"
                    getValue={getAffiliateLegalEntityRef}
                    reset={0}
                />
            </TabPanel>
            <TabPanel index={2} selectedTab={selectedTab}>
                <AffiliateContactInput
                    disabled={disabled}
                    size="small"
                    getValue={getAffiliateContactRef}
                    reset={0}
                />
            </TabPanel>
            <Stack direction={"row"} spacing={1}>
                <Button variant={"contained"} onClick={handlePersist}>
                    Save
                </Button>
                <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>
            </Stack>
        </Stack>
    );

};

export default AffiliateCreatePage;
