import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";
import {PhoneNumber} from "../../api/model/value/PhoneNumber";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: PhoneNumber,
    disabled: boolean,
    size: FormSize;
    setValue: (value: PhoneNumber) => void
}

const PhoneNumberInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleCountryCodeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withCountryCode(event.target.value));
    }

    const handleAreaCodeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withAreaCode(event.target.value));
    }

    const handleLocalNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(value.withLocalNumber(event.target.value));
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={2}>
                <Stack direction="column">
                    Country Code
                    <TextField
                        id={id + "-country-affiliateCode"}
                        value={value.countryCode}
                        disabled={disabled}
                        size={size}
                        onChange={handleCountryCodeChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction="column">
                    Area Code
                    <TextField
                        id={id + "-area-affiliateCode"}
                        value={value.areaCode}
                        disabled={disabled}
                        size={size}
                        onChange={handleAreaCodeChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={7}>
                <Stack direction="column">
                    Local Number
                    <TextField
                        id={id + "-local-number"}
                        value={value.localNumber}
                        disabled={disabled}
                        size={size}
                        onChange={handleLocalNumberChange}
                    />
                </Stack>
            </Grid>
        </Grid>
    )

};

export default PhoneNumberInput;
