import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, {MutableRefObject, useRef, useState} from "react";
import {Affiliate} from "../../../api/model/Affiliate";
import {EntityState} from "../../../api/model/Entity";
import {Optional} from "../../../api/model/util/Optional";
import {useProject} from "../../../context/ProjectProvider";
import RemoveButton from "../../form/RemoveButton";
import RestoreButton from "../../form/RestoreButton";
import AffiliateCodeSlicerProvider from "../affiliateCode/AffiliateCodeSlicerProvider";
import AffiliateCodesPage from "../affiliateCode/AffiliateCodesPage";
import AffiliateContactInput, {AffiliateContact} from "./input/AffiliateContactInput";
import AffiliateCoreInput, {AffiliateCore} from "./input/AffiliateCoreInput";
import AffiliateLegalEntityInput, {AffiliateLegalEntity} from "./input/AffiliateLegalEntityInput";

type TabDefinition = {
    id: string,
    label: string,
    buttons: boolean
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core",
        buttons: true
    },
    {
        id: "legal-entity",
        label: "Legal Entity",
        buttons: true
    },
    {
        id: "contact",
        label: "Contact",
        buttons: true
    },
    {
        id: "codes",
        label: "Codes",
        buttons: false
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    affiliate: Affiliate,
    onClose?: () => void
}

const AffiliateUpdatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {affiliate: affiliate, onClose} = props

    const [latestAffiliate, setLatestAffiliate] = useState<Affiliate>(affiliate)
    const [currentAffiliate, setCurrentAffiliate] = useState<Affiliate>(affiliate)
    const [reset, setReset] = useState<number>(0);

    const project = useProject();
    const affiliateManager = project.affiliateManager;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getAffiliateCoreRef: MutableRefObject<Optional<() => AffiliateCore>> = useRef<Optional<() => AffiliateCore>>(null)
    const getAffiliateLegalEntityRef: MutableRefObject<Optional<() => AffiliateLegalEntity>> = useRef<Optional<() => AffiliateLegalEntity>>(null)
    const getAffiliateContactRef: MutableRefObject<Optional<() => AffiliateContact>> = useRef<Optional<() => AffiliateContact>>(null)

    const handlePersist = async () => {
        setDisabled(true);
        const affiliate: Affiliate = {
            ...latestAffiliate,
            ...getAffiliateCoreRef.current!(),
            ...getAffiliateLegalEntityRef.current!(),
            ...getAffiliateContactRef.current!(),
        }
        const updatedAffiliate = await affiliateManager.persist(affiliate);
        setLatestAffiliate(updatedAffiliate);
        setCurrentAffiliate(updatedAffiliate);
        setDisabled(false);
    }

    const handleReset = async () => {
        setDisabled(true);
        setCurrentAffiliate(latestAffiliate);
        setReset(reset + 1);
        setDisabled(false);
    }

    const handleRemove = async () => {
        const updatedAffiliate = await affiliateManager.removeById(latestAffiliate);
        setLatestAffiliate(updatedAffiliate);
        setCurrentAffiliate(updatedAffiliate);
        setReset(reset + 1);
    }

    const handleRestore = async () => {
        const updatedAffiliate = await affiliateManager.restoreById(latestAffiliate);
        setLatestAffiliate(updatedAffiliate);
        setCurrentAffiliate(updatedAffiliate);
        setReset(reset + 1);
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <AffiliateCoreInput
                    affiliate={currentAffiliate}
                    disabled={disabled}
                    size="small" getValue={getAffiliateCoreRef}
                    reset={reset}
                />
            </TabPanel>
            <TabPanel index={1} selectedTab={selectedTab}>
                <AffiliateLegalEntityInput
                    affiliate={currentAffiliate}
                    disabled={disabled}
                    size="small"
                    getValue={getAffiliateLegalEntityRef}
                    reset={reset}
                />
            </TabPanel>
            <TabPanel index={2} selectedTab={selectedTab}>
                <AffiliateContactInput
                    affiliate={currentAffiliate}
                    disabled={disabled}
                    size="small"
                    getValue={getAffiliateContactRef}
                    reset={reset}
                />
            </TabPanel>
            <TabPanel index={3} selectedTab={selectedTab}>
                <AffiliateCodeSlicerProvider>
                    <AffiliateCodesPage affiliate={currentAffiliate}/>
                </AffiliateCodeSlicerProvider>
            </TabPanel>
            {TABS[selectedTab].buttons && <Stack direction={"row"} spacing={1}>
                {currentAffiliate.entityState === EntityState.CREATED &&
                    <Button variant={"contained"} onClick={handlePersist}>
                        Update
                    </Button>}
                {currentAffiliate.entityState === EntityState.CREATED &&
                    <Button variant={"outlined"} onClick={handleReset}>
                        Reset
                    </Button>}
                {currentAffiliate.entityState === EntityState.REMOVED &&
                    <RestoreButton disabled={false} onRestore={handleRestore}/>}
                {currentAffiliate.entityState === EntityState.CREATED &&
                    <RemoveButton disabled={false} onRemove={handleRemove}/>}
                {onClose && <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>}
            </Stack>}
        </Stack>
    );

};

export default AffiliateUpdatePage;
