import {EditOutlined} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from "@mui/material/TablePagination";
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import {ENTITY_ID_CONTAINS_FILTER, SortDirection} from "../../../api/model/Entity";
import {Target} from "../../../api/model/value/Target";
import {
    VOUCHER_CODE_CODE_ID_CONTAINS_FILTER,
    VOUCHER_CODE_CODE_ID_SORT_ORDER,
    VOUCHER_CODE_NUMBER_SORT_ORDER,
    VOUCHER_CODE_STATE_SORT_ORDER,
    VOUCHER_CODE_USAGE_SORT_ORDER,
    VoucherCode,
    VoucherCodeFilter,
    VoucherCodeSortOrder
} from "../../../api/model/VoucherCode";
import FilterDialogButton from "../../form/FilterDialogButton";
import FilterFormButtons from "../../form/FilterFormButtons";
import FilterFormRow from "../../form/FilterFormRow";
import SortOrderButton from "../../form/SortOrderButton";
import StringInput from "../../input/StringInput";
import {useVoucherCodeSlicer} from "./VoucherCodeSlicerProvider";

interface FilterDialogProps {
    open: boolean;
    onClose: (filter: InternalFilter) => void;
}

const FilterDialog: React.FunctionComponent<FilterDialogProps> = (props: FilterDialogProps) => {

    const {open, onClose} = props;

    const [id, setId] = useState<string>("");
    const [codeId, setCodeId] = useState<string>("");

    const handleClear = () => {
        setId("");
        setCodeId("");
    };

    const handleClose = () => {
        onClose({
            id: id.length === 0 ? undefined : id,
            codeId: codeId.length === 0 ? undefined : codeId
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Filter</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FilterFormRow label="ID" operation="contains">
                    <StringInput
                        id="id"
                        value={id}
                        disabled={false}
                        size="small"
                        setValue={setId}
                    />
                </FilterFormRow>
                <FilterFormRow label="Code" operation="contains">
                    <StringInput
                        id="code-id"
                        value={codeId}
                        disabled={false}
                        size="small"
                        setValue={setCodeId}
                    />
                </FilterFormRow>
                <FilterFormButtons onClear={handleClear} onClose={handleClose}/>
            </Grid>
        </Dialog>
    );
}

interface RowProps {
    code: VoucherCode,
    onSelect: (code: VoucherCode) => void
}

const Row: React.FunctionComponent<RowProps> = ({code, onSelect}: RowProps) => {
    return (
        <TableRow>
            <TableCell padding={"checkbox"}>
                <IconButton size="small" onClick={() => onSelect(code)}>
                    <EditOutlined/>
                </IconButton>
            </TableCell>
            <TableCell>{code.number.value}</TableCell>
            <TableCell>{code.codeId.value}</TableCell>
            <TableCell>{code.usage.state.name.value}</TableCell>
            <TableCell>{code.state}</TableCell>
        </TableRow>
    );
}

export interface Props {
    codes: ReadonlyArray<VoucherCode>,
    onSelect?: (code: VoucherCode) => void
}

type InternalFilter = {
    id?: string,
    codeId?: string,
    name?: string,
    target?: Target
}

const VoucherCodeTable: React.FunctionComponent<Props> = ({codes, onSelect}: Props) => {

    const codeSlicer = useVoucherCodeSlicer();

    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const [internalFilter, setInternalFilter] = useState<InternalFilter>({});

    const handleFilterDialogOpen = () => {
        setFilterDialogOpen(true);
    };

    const handleFilterDialogClose = (filter: InternalFilter) => {
        setFilterDialogOpen(false);
        setInternalFilter(filter)
    };

    useEffect(() => {

        const dynamicFilters = new Array<VoucherCodeFilter<any>>();

        if (internalFilter.id) {
            dynamicFilters.push({
                operation: ENTITY_ID_CONTAINS_FILTER,
                comparisonValue: internalFilter.id
            });
        }
        if (internalFilter.codeId) {
            dynamicFilters.push({
                operation: VOUCHER_CODE_CODE_ID_CONTAINS_FILTER,
                comparisonValue: internalFilter.codeId
            });
        }

        codeSlicer.setDynamicFilters(dynamicFilters);
        codeSlicer.setPaginationPage(0);

    }, [internalFilter]);

    function handleSortChange(sortOrder: VoucherCodeSortOrder, direction: SortDirection) {
        codeSlicer.setSortOrder(sortOrder);
        codeSlicer.setSortDirection(direction);
        codeSlicer.setPaginationPage(0);
    }

    const handlePaginationPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        codeSlicer.setPaginationPage(newPage);
    };

    const handlePaginationRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        codeSlicer.setPaginationRowsPerPage(newRowsPerPage);
        codeSlicer.setPaginationPage(0);
    };

    const handleSelectFactory = (code: VoucherCode) => {
        return () => {
            onSelect && onSelect(code)
        }
    }

    const [codeSlice, codeSliceCount] = codeSlicer.slice(codes);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <FilterDialogButton
                                    filterCount={codeSlicer.dynamicFilters.length}
                                    onClick={handleFilterDialogOpen}
                                />
                                <FilterDialog
                                    open={filterDialogOpen}
                                    onClose={handleFilterDialogClose}
                                />
                            </TableCell>
                            <TableCell>
                                Number
                                <SortOrderButton
                                    sortOrder={VOUCHER_CODE_NUMBER_SORT_ORDER}
                                    currentSortOrder={codeSlicer.sortOrder}
                                    currentSortDirection={codeSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Code
                                <SortOrderButton
                                    sortOrder={VOUCHER_CODE_CODE_ID_SORT_ORDER}
                                    currentSortOrder={codeSlicer.sortOrder}
                                    currentSortDirection={codeSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Usage
                                <SortOrderButton
                                    sortOrder={VOUCHER_CODE_USAGE_SORT_ORDER}
                                    currentSortOrder={codeSlicer.sortOrder}
                                    currentSortDirection={codeSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                State
                                <SortOrderButton
                                    sortOrder={VOUCHER_CODE_STATE_SORT_ORDER}
                                    currentSortOrder={codeSlicer.sortOrder}
                                    currentSortDirection={codeSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {codeSlice.map((code: VoucherCode) => (
                            <Row key={code.id.value} code={code} onSelect={handleSelectFactory(code)}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={codeSlicer.paginationRowsPerPageOptions}
                component="div"
                count={codeSliceCount}
                rowsPerPage={codeSlicer.paginationRowsPerPage}
                page={codeSlicer.paginationPage}
                onRowsPerPageChange={handlePaginationRowsPerPageChange}
                onPageChange={handlePaginationPageChange}
            />
        </>
    );

}

export default VoucherCodeTable;

