import React, {useEffect, useState} from "react";
import {AffiliateCode} from "../../../api/model/AffiliateCode";
import {Project, useProject} from "../../../context/ProjectProvider";
import AffiliateCodeTable from "./AffiliateCodeTable";

export interface Props {
    onSelect?: (code: AffiliateCode) => void
}

const AffiliateCodeList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [codes, setCodes] = useState<ReadonlyArray<AffiliateCode>>([]);

    useEffect(() => {

        const loadCodes = async () => {

            const codes = await project.affiliateCodeManager.getAllAsArray({});
            setCodes(codes);

        }

        loadCodes().catch(console.error);

    }, [project]);

    return (
        <AffiliateCodeTable
            codes={codes}
            onSelect={onSelect}
        />
    );

};

export default AffiliateCodeList;
