import React from "react";
import {Optional} from "../../api/model/util/Optional";
import {CATEGORIES, Target} from "../../api/model/value/Target";
import {FormSize} from "../form/FormSize";
import OptionalStringOptionsInput from "./OptionalStringOptionsInput";

export interface Props {
    id: string,
    value: Optional<Target>,
    disabled: boolean,
    size: FormSize,
    setValue: (value: Optional<Target>) => void
}

const TargetInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    return (
        <OptionalStringOptionsInput
            id={id}
            value={value ? value.code.value : ""}
            options={CATEGORIES.map(target => {
                return {value: target.code.value, label: target.name.value}
            })}
            disabled={disabled}
            size={size}
            setValue={(value: Optional<string>) => setValue(value ? Target.fromData(value) : null)}
        />
    )

};

export default TargetInput;
