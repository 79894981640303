import React, {MutableRefObject, useRef} from "react";
import {Optional} from "../../api/model/util/Optional";
import {COUNTRIES, Country} from "../../api/model/value/Country";
import {FormSize} from "../form/FormSize";
import StringOptionsInput2 from "./StringOptionsInput2";

export interface Props {
    id: string,
    value: Country,
    disabled: boolean,
    size: FormSize,
    getValue: MutableRefObject<Optional<() => Country>>
    reset: number
}

const CountryInput2: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, getValue, reset} = props

    const getStringRef: MutableRefObject<Optional<() => string>> = useRef<Optional<() => string>>(null);

    getValue.current = () => Country.fromData(getStringRef.current!());

    return (
        <StringOptionsInput2
            id={id}
            value={value.code.value}
            options={COUNTRIES.map(country => {
                return {value: country.code.value, label: country.name.value}
            })}
            disabled={disabled}
            size={size}
            getValue={getStringRef}
            reset={reset}
        />
    )

};

export default CountryInput2;
