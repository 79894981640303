import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {Name} from "./Name";

export class ParticipationType {

    public readonly name: Name;

    public readonly code: Code;

    constructor(name:Name, code: Code) {
        this.name = name;
        this.code = code;
    }

    public static toData(value: ParticipationType): string {
        return value.code.value;
    }

    public static fromData(data: string): ParticipationType {
        return ParticipationType.fromCode(new Code(data));
    }

    public static fromCode(participationTypeCode: Code): ParticipationType {
        const participationType: Maybe<ParticipationType> = PARTICIPATION_TYPES.find(participationType => participationType.code.value === participationTypeCode.value);
        if (participationType === undefined) {
            throw new Error("Unknown participation type: " + JSON.stringify(participationTypeCode));
        }
        return participationType;
    }

}

export const CHECK_IN: ParticipationType = new ParticipationType(
    new Name("Check In"),
    new Code("CHECK_IN")
);

export const GUEST_LIST: ParticipationType = new ParticipationType(
    new Name("Guest List"),
    new Code("GUEST_LIST")
);

export const PARTICIPATION_TYPES: ReadonlyArray<ParticipationType> = [
    CHECK_IN,
    GUEST_LIST
] as const;

export const PARTICIPATION_TYPE_DATA_MAPPER: DataMapper<ParticipationType, string> = {
    toData: ParticipationType.toData,
    fromData: ParticipationType.fromData
}

