import React from 'react';
import BundleSlicerProvider from "../component/entity/bundle/BundleSlicerProvider";
import BundlesPage from "../component/entity/bundle/BundlesPage";

function BundleRoute() {

    return (
        <BundleSlicerProvider>
            <BundlesPage/>
        </BundleSlicerProvider>
    );

}

export default BundleRoute;
