import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from "react";
import {ENTITY_STATE_EQUALS_FILTER, EntityState} from "../../../api/model/Entity";
import {
    Purchase,
    PURCHASE_AFFILIATION_STATE_ATTRIBUTE_DEFINITION, PURCHASE_AFFILIATION_STATE_EQUALS_FILTER,
    PURCHASE_STATE_EQUALS_FILTER,
    PurchaseFilter,
    PurchaseState
} from "../../../api/model/Purchase";
import {Optional} from "../../../api/model/util/Optional";
import {DISBURSED, ELIGIBLE, INELIGIBLE, PENDING} from "../../../api/model/value/AffiliationState";
import PurchaseList from "./PurchaseList";
import {usePurchaseSlicer} from "./PurchaseSlicerProvider";
import PurchaseUpdatePage from "./PurchaseUpdatePage";

type TabDefinition = {
    id: string,
    label: string,
    filter: ReadonlyArray<PurchaseFilter<any>>
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "all",
        label: "All",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            }
        ]
    },
    {
        id: "approved",
        label: "Approved",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PURCHASE_STATE_EQUALS_FILTER,
                comparisonValue: PurchaseState.APPROVED
            }
        ]
    },
    {
        id: "activated",
        label: "Activated",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PURCHASE_STATE_EQUALS_FILTER,
                comparisonValue: PurchaseState.ACTIVATED
            }
        ]
    },
    {
        id: "expired",
        label: "Expired",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PURCHASE_STATE_EQUALS_FILTER,
                comparisonValue: PurchaseState.EXPIRED
            }
        ]
    },
    {
        id: "cancelled",
        label: "Cancelled",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PURCHASE_STATE_EQUALS_FILTER,
                comparisonValue: PurchaseState.CANCELLED
            }
        ]
    },
    {
        id: "ineligible",
        label: "Ineligible",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PURCHASE_AFFILIATION_STATE_EQUALS_FILTER,
                comparisonValue: INELIGIBLE
            }
        ]
    },
    {
        id: "eligible",
        label: "Eligible",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PURCHASE_AFFILIATION_STATE_EQUALS_FILTER,
                comparisonValue: ELIGIBLE
            }
        ]
    },
    {
        id: "pending",
        label: "Pending",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PURCHASE_AFFILIATION_STATE_EQUALS_FILTER,
                comparisonValue: PENDING
            }
        ]
    },
    {
        id: "disbursed",
        label: "Disbursed",
        filter: [
            {
                operation: ENTITY_STATE_EQUALS_FILTER,
                comparisonValue: EntityState.CREATED
            },
            {
                operation: PURCHASE_AFFILIATION_STATE_EQUALS_FILTER,
                comparisonValue: DISBURSED
            }
        ]
    }
]

const INITIAL_TAB = 0;

export interface Props {
}

const PurchasesPage: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    const purchaseSlicer = usePurchaseSlicer();
    const [selectedTab, setSelectedTab] = useState<number>(INITIAL_TAB);
    const [selectedPurchase, setSelectedPurchase] = useState<Optional<Purchase>>(null);

    useEffect(() => {
        purchaseSlicer.setStaticFilters(TABS[selectedTab].filter);
    }, [selectedTab]);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const handleSelect = (purchase: Purchase) => {
        setSelectedPurchase(purchase);
    }

    const handleClose = () => {
        setSelectedPurchase(null);
    }

    if (selectedPurchase !== null) {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Purchase: {selectedPurchase.purchaseId.value}
                </Typography>
                <PurchaseUpdatePage purchase={selectedPurchase} onClose={handleClose}/>
            </>
        )
    } else {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Purchases
                </Typography>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={handleTabSelection}>
                        {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                    </Tabs>
                </Box>
                <PurchaseList onSelect={handleSelect}/>
                <Stack direction={"row"} spacing={1}>
                </Stack>
            </>
        )
    }

};

export default PurchasesPage;
