import React from 'react';
import PageSlicerProvider from "../component/entity/page/PageSlicerProvider";
import PagesPage from "../component/entity/page/PagesPage";

function PageRoute() {

    return (
        <PageSlicerProvider>
            <PagesPage/>
        </PageSlicerProvider>
    );

}

export default PageRoute;
