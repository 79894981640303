import React, {createContext, useContext, useEffect, useState} from "react";
import {SortDirection} from "../../../api/model/Entity";
import {
    Purchase, PURCHASE_APPROVED_AT_SORT_ORDER,
    PURCHASE_CUSTOMER_NAME_SORT_ORDER,
    PurchaseFilter,
    PurchaseSortOrder
} from "../../../api/model/Purchase";

export type Props = {}

export type PurchaseSlicer = {
    paginationRowsPerPageOptions: Array<number>
    paginationRowsPerPage: number,
    setPaginationRowsPerPage: (paginationRowsPerPage: number) => void,
    paginationPage: number,
    setPaginationPage: (paginationPage: number) => void,
    sortOrder: PurchaseSortOrder,
    setSortOrder: (sortOrder: PurchaseSortOrder) => void,
    sortDirection: SortDirection,
    setSortDirection: (sortDirection: SortDirection) => void,
    staticFilters: ReadonlyArray<PurchaseFilter<any>>,
    setStaticFilters: (staticFilters: ReadonlyArray<PurchaseFilter<any>>) => void,
    dynamicFilters: ReadonlyArray<PurchaseFilter<any>>,
    setDynamicFilters: (dynamicFilters: ReadonlyArray<PurchaseFilter<any>>) => void,
    slice: (purchases: ReadonlyArray<Purchase>) => [ReadonlyArray<Purchase>, number]
}

const PurchaseSlicerContext = createContext<PurchaseSlicer>(null!);

const PurchaseSlicerProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const paginationRowsPerPageOptions = [5, 10, 15, 20, 25];
    const [paginationRowsPerPage, setPaginationRowsPerPage] = useState<number>(10);
    const [paginationPage, setPaginationPage] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<PurchaseSortOrder>(PURCHASE_APPROVED_AT_SORT_ORDER);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC);

    const [staticFilters, setStaticFilters] = React.useState<ReadonlyArray<PurchaseFilter<any>>>([]);
    const [dynamicFilters, setDynamicFilters] = React.useState<ReadonlyArray<PurchaseFilter<any>>>([]);

    const slice = (purchases: ReadonlyArray<Purchase>): [ReadonlyArray<Purchase>, number] => {

        let filteredPurchases = purchases;
        staticFilters.forEach(filter => {
            filteredPurchases = filteredPurchases.filter(purchase => filter.operation.apply(purchase, filter.comparisonValue));
        });
        dynamicFilters.forEach(filter => {
            filteredPurchases = filteredPurchases.filter(purchase => filter.operation.apply(purchase, filter.comparisonValue));
        });

        const sortedPurchases = [...filteredPurchases].sort((left: Purchase, right: Purchase) => sortDirection * sortOrder.apply(left, right));

        const start = paginationRowsPerPage * paginationPage;
        const end = Math.min(sortedPurchases.length, paginationRowsPerPage * (paginationPage + 1));
        const paginatedPurchases = sortedPurchases.slice(start, end);

        return [paginatedPurchases, filteredPurchases.length];

    }

    const value: PurchaseSlicer = {
        paginationRowsPerPageOptions,
        paginationRowsPerPage,
        setPaginationRowsPerPage,
        paginationPage,
        setPaginationPage,
        sortOrder,
        setSortOrder,
        sortDirection,
        setSortDirection,
        staticFilters,
        setStaticFilters,
        dynamicFilters,
        setDynamicFilters,
        slice
    }

    return (
        <PurchaseSlicerContext.Provider value={value}>
            {children}
        </PurchaseSlicerContext.Provider>
    );

};

export function usePurchaseSlicer(): PurchaseSlicer {
    return useContext(PurchaseSlicerContext);
}

export default PurchaseSlicerProvider;