import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, {MutableRefObject, useRef, useState} from "react";
import {Purchase, PurchaseState} from "../../../api/model/Purchase";
import {Optional} from "../../../api/model/util/Optional";
import {INELIGIBLE} from "../../../api/model/value/AffiliationState";
import {useProject} from "../../../context/ProjectProvider";
import CancelButton from "../../form/CancelButton";
import PurchaseCoreInput, {PurchaseCore} from "./input/PurchaseCoreInput";

type TabDefinition = {
    id: string,
    label: string
}

const TABS: ReadonlyArray<TabDefinition> = [
    {
        id: "core",
        label: "Core"
    }
]

interface TabPanelProps {
    index: number;
    selectedTab: number;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const {index, selectedTab, children} = props;
    return (
        <div hidden={index !== selectedTab} id={"tab-" + index}>
            {children}
        </div>
    );
}

export interface Props {
    purchase: Purchase,
    onClose?: () => void
}

const PurchaseUpdatePage: React.FunctionComponent<Props> = (props: Props) => {

    const {purchase: purchase, onClose} = props

    const [latestPurchase, setLatestPurchase] = useState<Purchase>(purchase)
    const [currentPurchase, setCurrentPurchase] = useState<Purchase>(purchase)
    const [reset, setReset] = useState<number>(0);

    const project = useProject();
    const purchaseManager = project.purchaseManager;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleTabSelection = (event: React.SyntheticEvent, value: number) => {
        setSelectedTab(value);
    };

    const getPurchaseCoreRef: MutableRefObject<Optional<() => PurchaseCore>> = useRef<Optional<() => PurchaseCore>>(null)

    const handleCancel = async () => {
        setDisabled(true);
        const purchase: Purchase = {
            ...latestPurchase,
            state: PurchaseState.CANCELLED,
            affiliationState: INELIGIBLE,
            cancelledAt: new Date()
        }
        const updatedPurchase = await purchaseManager.persist(purchase);
        setLatestPurchase(updatedPurchase);
        setCurrentPurchase(updatedPurchase);
        setReset(reset + 1);
        setDisabled(false);
    }


    const handleClose = () => {
        onClose && onClose();
    }

    const cancellable = currentPurchase.state === PurchaseState.APPROVED && currentPurchase.cancellableUntil.getTime() > new Date().getTime();

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={handleTabSelection}>
                    {TABS.map(tab => <Tab key={tab.id} id={tab.id} label={tab.label}/>)}
                </Tabs>
            </Box>
            <TabPanel index={0} selectedTab={selectedTab}>
                <PurchaseCoreInput
                    purchase={currentPurchase}
                    disabled={disabled}
                    size="small" getValue={getPurchaseCoreRef}
                    reset={reset}
                />
            </TabPanel>
            <Stack direction={"row"} spacing={1}>
                <CancelButton disabled={!cancellable} onCancel={handleCancel}/>
                {onClose && <Button variant={"outlined"} onClick={handleClose}>
                    Close
                </Button>}
            </Stack>
        </Stack>
    );

};

export default PurchaseUpdatePage;
