import React from "react";

export interface Props {
}

const Footer: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    return (
        <div>
        </div>
    );

};

export default Footer;
