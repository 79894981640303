import React from 'react';
import VenueSlicerProvider from "../component/entity/venue/VenueSlicerProvider";
import VenuesPage from "../component/entity/venue/VenuesPage";

function VenueRoute() {

    return (
        <VenueSlicerProvider>
            <VenuesPage/>
        </VenueSlicerProvider>
    );

}

export default VenueRoute;
