import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";
import {NominalDate} from "../../api/model/value/NominalDate";
import {NominalTime} from "../../api/model/value/NominalTime";
import {CheckIn, GuestList, Participation} from "../../api/model/value/Participation";
import {CHECK_IN, GUEST_LIST, ParticipationType} from "../../api/model/value/ParticipationType";
import {FormSize} from "../form/FormSize";
import NominalDateInput from "./NominalDateInput";
import NominalTimeInput from "./NominalTimeInput";
import ParticipationTypeInput from "./ParticipationTypeInput";

export interface Props {
    id: string,
    value: Participation,
    disabled: boolean,
    size: FormSize;
    setValue: (value: Participation) => void
}

const ParticipationInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const inAMonth = new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000));
    const [guestList, setGuestList] = useState<GuestList>(new GuestList(NominalDate.fromLocalDate(inAMonth), new NominalTime(18, 0)));

    useEffect(() => {
        if (value.type.code.value === GUEST_LIST.code.value) {
            setGuestList(value as GuestList);
        }
    }, [value]);

    const handleTypeChange = (type: ParticipationType) => {
        if (type.code.value === GUEST_LIST.code.value) {
            setValue(guestList);
        } else {
            setValue(new CheckIn());
        }
    }

    const handleClosingDateChange = (date: NominalDate) => {
        setValue(guestList.withClosingDate(date));

    }

    const handleClosingTimeChange = (time: NominalTime) => {
        setValue(guestList.withClosingTime(time));
    }

    const isGuestList = value.type.code.value === GUEST_LIST.code.value;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="column">
                    Type
                    <ParticipationTypeInput
                        id={id + "-type"}
                        value={value.type}
                        disabled={disabled}
                        size={size}
                        setValue={handleTypeChange}
                    />
                </Stack>
            </Grid>
            {isGuestList && <Grid item xs={12}>
                <Stack direction="column">
                    <NominalDateInput
                        id={id + "-closing-date"}
                        value={guestList.closingDate}
                        disabled={disabled}
                        size={size}
                        setValue={handleClosingDateChange}
                    />
                </Stack>
            </Grid>}
            {isGuestList && <Grid item xs={12}>
                <Stack direction="column">
                    <NominalTimeInput
                        id={id + "-closing-time"}
                        value={guestList.closingTime}
                        disabled={disabled}
                        size={size}
                        setValue={handleClosingTimeChange}
                    />
                </Stack>
            </Grid>}
        </Grid>
    )

};

export default ParticipationInput;
