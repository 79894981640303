import React, {useEffect, useState} from "react";
import {Voucher} from "../../../api/model/Voucher";
import {Project, useProject} from "../../../context/ProjectProvider";
import VoucherTable from "./VoucherTable";

export interface Props {
    onSelect?: (voucher: Voucher) => void
}

const VoucherList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [vouchers, setVouchers] = useState<ReadonlyArray<Voucher>>([]);

    useEffect(() => {

        const loadVouchers = async () => {

            const vouchers = await project.voucherManager.getAllAsArray({region: project.selectedRegion});
            setVouchers(vouchers);

        }

        loadVouchers().catch(console.error);

    }, [project]);

    return (
        <VoucherTable
            vouchers={vouchers}
            onSelect={onSelect}
        />
    );

};

export default VoucherList;
