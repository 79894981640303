import {
    CollectionReference,
    doc,
    DocumentData,
    DocumentReference,
    FieldValue,
    Firestore,
    serverTimestamp,
    Timestamp
} from "@firebase/firestore";
import {IdAlike, idFromIdAlike} from "../util";
import {Optional} from "./util/Optional";
import {ENTITY_TYPE_DATA_MAPPER, EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {stringContains, stringEquals} from "./value/String";

export enum EntityScope {

    PREPARED = "PREPARED",

    PERSISTED = "PERSISTED"

}

export enum EntityState {

    CREATED = "CREATED",

    REMOVED = "REMOVED",

    DELETED = "DELETED"

}

export const ENTITY_STATE_DATA_MAPPER: DataMapper<EntityState, string> = {
    toData: (value: EntityState) => value,
    fromData: (data: string) => data as EntityState
}

export type EntityAnchor<T extends EntityType> = {};

export type PreparedEntity<T extends EntityType> = {
    entityType: T,
    entityScope: EntityScope.PREPARED,
}

export type Entity<T extends EntityType> = {
    id: Id<T>,
    entityType: T,
    entityScope: EntityScope.PERSISTED,
    entityState: EntityState,
    createdAt: Date,
    updatedAt: Date
}

export type PersistableEntity<T extends EntityType> = (PreparedEntity<T> | Entity<T>);

export type Data = boolean | number | string | object | Data[] | Timestamp | DocumentReference;

export type DataMapper<V, D extends Data> = {
    toData: (value: V) => D
    fromData: (data: D) => V
}

export interface AttributeDefinition<O, V, D extends Data> {

    toData(database: Firestore, object: O): Optional<[string, D | FieldValue]>;

    fromData(docRef: DocumentReference, data: DocumentData): Optional<[string, V]>;

}

export class SimpleAttributeDefinition<O, V, D extends Data> implements AttributeDefinition<O, V, D> {

    private readonly path: string;

    private readonly accessor: (object: O) => V;

    private readonly dataMapper: DataMapper<V, D>;

    private readonly fallback: Optional<V>;

    constructor(path: string, accessor: (object: O) => V, dataMapper: DataMapper<V, D>, fallback: Optional<V>) {
        this.path = path;
        this.accessor = accessor;
        this.dataMapper = dataMapper;
        this.fallback = fallback;
    }

    public access(object: O): V {
        return this.accessor(object);
    }

    public toData(database: Firestore, object: O): [string, D] {
        let value = this.accessor(object);
        return [this.path, this.dataMapper.toData(value)];
    }

    public fromData(docRef: DocumentReference, data: DocumentData): [string, V] {
        const value = data[this.path];
        if (value === undefined) {
            if (this.fallback === null) {
                throw new Error("No value for path " + this.path + " and no fallback");
            } else {
                return [this.path, this.fallback]
            }
        } else {
            return [this.path, this.dataMapper.fromData(value)]
        }
    }

}

export class SimpleOptionalAttributeDefinition<O, V, D extends Data> implements AttributeDefinition<O, Optional<V>, D> {

    private readonly path: string;

    private readonly accessor: (object: O) => Optional<V>;

    private readonly dataMapper: DataMapper<V, D>;

    constructor(path: string, accessor: (object: O) => Optional<V>, dataMapper: DataMapper<V, D>) {
        this.path = path;
        this.accessor = accessor;
        this.dataMapper = dataMapper;
    }

    public access(object: O): Optional<V> {
        return this.accessor(object);
    }

    public toData(database: Firestore, object: O): Optional<[string, D]> {
        const value = this.accessor(object);
        if (value === null) {
            return null;
        } else {
            return [this.path, this.dataMapper.toData(value)];
        }
    }

    public fromData(docRef: DocumentReference, data: DocumentData): Optional<[string, Optional<V>]> {
        const value = data[this.path];
        if (value === undefined) {
            return [this.path, null];
        } else {
            return [this.path, this.dataMapper.fromData(value)]
        }
    }

}

export class IdRefAttributeDefinition<T extends EntityType, O> implements AttributeDefinition<O, IdRef<T>, DocumentReference> {

    private readonly type: T;

    private readonly path: string;

    private readonly accessor: (object: O) => IdRef<T>;

    constructor(type: T, path: string, accessor: (object: O) => IdRef<T>) {
        this.type = type;
        this.path = path;
        this.accessor = accessor;
    }

    public access(object: O): IdRef<T> {
        return this.accessor(object);
    }

    public toData(database: Firestore, object: O): [string, DocumentReference] {
        let value = this.accessor(object);
        return [this.path, doc(database, value.id.path)];
    }

    public fromData(docRef: DocumentReference, data: DocumentData): [string, IdRef<T>] {
        let value = data[this.path];
        return [this.path, new IdRef<T>(new Id(this.type, value.path))];
    }

}

export class OptionalIdRefAttributeDefinition<T extends EntityType, O> implements AttributeDefinition<O, IdRef<T>, DocumentReference> {

    private readonly type: T;

    private readonly path: string;

    private readonly accessor: (object: O) => Optional<IdRef<T>>;

    constructor(type: T, path: string, accessor: (object: O) => Optional<IdRef<T>>) {
        this.type = type;
        this.path = path;
        this.accessor = accessor;
    }

    public access(object: O): Optional<IdRef<T>> {
        return this.accessor(object);
    }

    public toData(database: Firestore, object: O): Optional<[string, DocumentReference]> {
        let idRef = this.accessor(object);
        if (idRef === null) {
            return null;
        } else {
            return [this.path, doc(database, idRef.id.path)];
        }
    }

    public fromData(docRef: DocumentReference, data: DocumentData): Optional<[string, IdRef<T>]> {
        let value = data[this.path];
        if (value === undefined) {
            return null;
        } else {
            return [this.path, new IdRef<T>(new Id(this.type, value.path))];
        }
    }

}

export class IdRefsAttributeDefinition<T extends EntityType, O> implements AttributeDefinition<O, ReadonlyArray<IdRef<T>>, ReadonlyArray<DocumentReference>> {

    private readonly type: T;

    private readonly path: string;

    private readonly accessor: (object: O) => ReadonlyArray<IdRef<T>>;

    constructor(type: T, path: string, accessor: (object: O) => ReadonlyArray<IdRef<T>>) {
        this.type = type;
        this.path = path;
        this.accessor = accessor;
    }

    public access(object: O): ReadonlyArray<IdRef<T>> {
        return this.accessor(object);
    }

    public toData(database: Firestore, object: O): [string, ReadonlyArray<DocumentReference>] {
        return [this.path, this.accessor(object).map(item => doc(database, item.id.path))];
    }

    public fromData(docRef: DocumentReference, data: DocumentData): [string, ReadonlyArray<IdRef<T>>] {
        const datum = data[this.path];
        if (datum === undefined) {
            return [this.path, []];
        } else {
            return [this.path, datum.map((item: DocumentReference) => new IdRef<T>(new Id(this.type, item.path)))];
        }
    }

}

export class IdAttributeDefinition<T extends EntityType> implements AttributeDefinition<PersistableEntity<T>, Optional<Id<T>>, string> {

    private readonly type: T;

    constructor(type: T) {
        this.type = type;
    }

    public access(entity: Entity<any>): Optional<Id<T>> {
        if (entity.entityScope === EntityScope.PERSISTED) {
            return entity.id;
        } else {
            return null;
        }
    }

    public toData(database: Firestore, entity: PersistableEntity<T>): null {
        return null;
    }

    public fromData(docRef: DocumentReference, data: DocumentData): [string, Id<T>] {
        return ["id", new Id(this.type, docRef.path)];
    }

}

export class EntityStateAttributeDefinition implements AttributeDefinition<PersistableEntity<any>, EntityState, string> {

    constructor() {
    }

    public access(entity: Entity<any>): EntityState {
        return entity.entityState;
    }

    public toData(database: Firestore, entity: PersistableEntity<any>): [string, string] {
        if (entity.entityScope === EntityScope.PERSISTED) {
            return ["entityState", entity.entityState];
        } else {
            return ["entityState", EntityState.CREATED];
        }
    }

    public fromData(docRef: DocumentReference, data: DocumentData): [string, EntityState] {
        return ["entityState", data.entityState];
    }

}

export class EntityScopeAttributeDefinition implements AttributeDefinition<Entity<any>, EntityScope, string> {

    constructor() {
    }

    public access(entity: Entity<any>): EntityScope {
        return entity.entityScope;
    }

    public toData(database: Firestore, entity: Entity<any>): null {
        return null;
    }

    public fromData(docRef: DocumentReference, data: DocumentData): [string, EntityScope] {
        return ["entityScope", EntityScope.PERSISTED];
    }

}

export class CreatedAtAttributeDefinition implements AttributeDefinition<Entity<any>, Optional<Date>, Timestamp> {

    constructor() {
    }

    public access(entity: Entity<any>): Optional<Date> {
        if (entity.entityScope === EntityScope.PERSISTED) {
            return entity.createdAt;
        } else {
            return null;
        }
    }

    public toData(database: Firestore, entity: Entity<any>): [string, Timestamp | FieldValue] {
        if (entity.entityScope === EntityScope.PERSISTED) {
            return ["createdAt", Timestamp.fromDate(entity.createdAt)];
        } else {
            return ["createdAt", serverTimestamp()];
        }
    }

    public fromData(docRef: DocumentReference, data: DocumentData): [string, Date] {
        return ["createdAt", data.createdAt.toDate()];
    }

}

export class UpdatedAtAttributeDefinition implements AttributeDefinition<Entity<any>, Optional<Date>, Timestamp> {

    constructor() {
    }

    public access(entity: Entity<any>): Optional<Date> {
        if (entity.entityScope === EntityScope.PERSISTED) {
            return entity.updatedAt;
        } else {
            return null;
        }
    }

    public toData(database: Firestore, entity: Entity<any>): [string, FieldValue] {
        return ["updatedAt", serverTimestamp()];
    }

    public fromData(docRef: DocumentReference, data: DocumentData): [string, Date] {
        return ["updatedAt", data.updatedAt.toDate()];
    }

}

export const ENTITY_TYPE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableEntity<any>, EntityType, string>(
        "entityType",
        e => e.entityType,
        ENTITY_TYPE_DATA_MAPPER,
        null
    );

export const ENTITY_STATE_ATTRIBUTE_DEFINITION: AttributeDefinition<PersistableEntity<any>, EntityState, string> =
    new EntityStateAttributeDefinition();

export const ENTITY_SCOPE_ATTRIBUTE_DEFINITION: AttributeDefinition<PersistableEntity<any>, EntityScope, string> =
    new EntityScopeAttributeDefinition();

export const CREATED_AT_ATTRIBUTE_DEFINITION: AttributeDefinition<PersistableEntity<any>, Optional<Date>, Timestamp> =
    new CreatedAtAttributeDefinition();

export const UPDATED_AT_ATTRIBUTE_DEFINITION: AttributeDefinition<PersistableEntity<any>, Optional<Date>, Timestamp> =
    new UpdatedAtAttributeDefinition();

export type EntityFilterColumn = "ID" | "ENTITY_STATE";

export type FilterOperation<T extends EntityType, E extends Entity<T>, C, V> = {
    column: C
    apply: (entity: E, comparisonValue: V) => boolean
}

export type Filter<T extends EntityType, E extends Entity<T>, C, V> = {
    operation: FilterOperation<T, E, C, V>;
    comparisonValue: V
}

export const ENTITY_ID_CONTAINS_FILTER: FilterOperation<any, Entity<any>, EntityFilterColumn, string> = {
    column: "ID",
    apply: (entity: Entity<any>, comparisonValue: string) => stringContains(entity.id.value, comparisonValue)
}

export const ENTITY_STATE_EQUALS_FILTER: FilterOperation<any, Entity<any>, EntityFilterColumn, EntityState> = {
    column: "ENTITY_STATE",
    apply: (entity: Entity<any>, comparisonValue: EntityState) => stringEquals(entity.entityState, comparisonValue)
}

export type EntitySortColumn = void;

export enum SortDirection {

    ASC = 1,

    DESC = -1

}

export type SortOrder<T extends EntityType, E extends Entity<T>, C> = {
    column: C
    apply: (left: E, right: E) => number
}

export abstract class EntityDefinition<T extends EntityType, A extends EntityAnchor<T>, P extends PersistableEntity<T>, E extends Entity<T>> {

    public readonly type: T

    private readonly attributes: ReadonlyArray<AttributeDefinition<P, any, any>>;

    protected constructor(type: T, attributes: ReadonlyArray<AttributeDefinition<P, any, any>>) {
        this.type = type;
        this.attributes = EntityDefinition.extendAttributes(new IdAttributeDefinition(type), attributes);
    }

    private static extendAttributes<T extends EntityType>(
        idAttributeDefinition: IdAttributeDefinition<T>,
        attributes: ReadonlyArray<AttributeDefinition<PersistableEntity<T>, any, any>>
    ): ReadonlyArray<AttributeDefinition<PersistableEntity<T>, any, any>> {
        return [
            idAttributeDefinition,
            ENTITY_TYPE_ATTRIBUTE_DEFINITION,
            ENTITY_STATE_ATTRIBUTE_DEFINITION,
            ENTITY_SCOPE_ATTRIBUTE_DEFINITION,
            CREATED_AT_ATTRIBUTE_DEFINITION,
            UPDATED_AT_ATTRIBUTE_DEFINITION,
            ...attributes
        ];
    }

    public toData(database: Firestore, entity: P): any {
        const result: { [key: string]: any } = {}
        this.attributes.forEach(attribute => {
            const entry = attribute.toData(database, entity);
            if (entry !== null) {
                const [key, value] = entry;
                result[key] = value;
            }
        })
        return result;
    }

    public fromData(id: DocumentReference, data: DocumentData): E {
        const result: { [key: string]: any } = {}
        this.attributes.forEach(attribute => {
            const entry = attribute.fromData(id, data);
            if (entry !== null) {
                const [key, value] = entry;
                result[key] = value;
            }
        })
        return result as E;
    }

    public abstract getDocId(entity: P): Optional<string>;

    public abstract preparedEntityToAnchor(entity: P): A ;

    public abstract idAlikeToAnchor(idAlike: IdAlike<T>): A;

    public idAlikeToDocRef(database: Firestore, idAlike: IdAlike<T>): DocumentReference {
        return doc(database, idFromIdAlike(idAlike).path);
    }

    public idAlikeToColRef(database: Firestore, idAlike: IdAlike<T>): CollectionReference {
        return this.anchorToColRef(database, this.idAlikeToAnchor(idAlike));
    }

    public abstract anchorToColRef(database: Firestore, anchor: A): CollectionReference;


}
