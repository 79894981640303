import {randomNumericalString} from "../../util";
import {DataMapper} from "../Entity";
import {CustomerId} from "./CustomerId";
import {stringCompare, stringContains} from "./String";

export class PurchaseId {

    public readonly value: string;

    public constructor(value: string) {
        this.value = value;
    }

    public static toData(value: PurchaseId): string {
        return value.value;
    }

    public static fromData(data: string): PurchaseId {
        return new PurchaseId(data);
    }

    public static random(customerId: CustomerId): PurchaseId {
        return new PurchaseId("P-" + customerId.value.substring(2) + "-" + randomNumericalString(8));
    }

    static empty() {
        return new PurchaseId("P-0000-00000000-00000000");
    }

}

export const PURCHASE_ID_DATA_MAPPER: DataMapper<PurchaseId, string> = {
    toData: PurchaseId.toData,
    fromData: PurchaseId.fromData
}

export function purchaseIdContains(haystack: PurchaseId, needle: string): boolean {
    return stringContains(haystack.value, needle);
}

export function purchaseIdCompare(left: PurchaseId, right: PurchaseId): number {
    return stringCompare(left.value, right.value)
}

