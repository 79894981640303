import {collection, CollectionReference, doc, Firestore, Timestamp} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {Comment, COMMENT_DATA_MAPPER} from "./value/Comment";
import {DATE_DATA_MAPPER, dateCompare} from "./value/Date";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {stringCompare, stringEquals} from "./value/String";

export enum BundleState {

    PUBLISHED = "PUBLISHED"

}

export type BundlePayload = {
    state: BundleState,
    name: Name,
    date: Date,
    comment: Comment,
    region: IdRef<EntityType.REGION>
}

export type BundleAnchor = EntityAnchor<EntityType.BUNDLE> & {
    region: IdAlike<EntityType.REGION>
}

export type PreparedBundle = PreparedEntity<EntityType.BUNDLE> & BundlePayload;

export type Bundle = Entity<EntityType.BUNDLE> & BundlePayload;

export type PersistableBundle = PreparedBundle | Bundle;

export type BundleManager = EntityManager<EntityType.BUNDLE, BundleAnchor, PersistableBundle, Bundle>;

export const BUNDLE_STATE_DATA_MAPPER: DataMapper<BundleState, string> = {
    toData: (value: BundleState) => value,
    fromData: (data: string) => data as BundleState
}

export const BUNDLE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableBundle, BundleState, string>(
        "state",
        e => e.state,
        BUNDLE_STATE_DATA_MAPPER,
        BundleState.PUBLISHED
    );

export const BUNDLE_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableBundle, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Bundle")
    );

export const BUNDLE_DATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableBundle, Date, Timestamp>(
        "date",
        e => e.date,
        DATE_DATA_MAPPER,
        new Date()
    );

export const BUNDLE_COMMENT_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableBundle, Comment, string>(
        "comment",
        e => e.comment,
        COMMENT_DATA_MAPPER,
        new Comment("")
    );

export const BUNDLE_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableBundle>(
        EntityType.REGION,
        "region",
        e => e.region
    );


export type BundleFilterColumn = EntityFilterColumn | "STATE" | "NAME" | "LABEL" | "SLOT";

export type BundleFilterOperation<V> = FilterOperation<EntityType.BUNDLE, Bundle, BundleFilterColumn, V>;

export type BundleFilter<V> = Filter<EntityType.BUNDLE, Bundle, BundleFilterColumn, V>;

export const BUNDLE_STATE_EQUALS_FILTER: BundleFilterOperation<BundleState> = {
    column: "STATE",
    apply: (bundle: Bundle, comparisonValue: BundleState) => stringEquals(bundle.state, comparisonValue)
}

export const BUNDLE_NAME_CONTAINS_FILTER: BundleFilterOperation<string> = {
    column: "NAME",
    apply: (bundle: Bundle, comparisonValue: string) => nameContains(bundle.name, comparisonValue)
}

export type BundleSortColumn = EntitySortColumn | "STATE" | "NAME" | "DATE";

export type BundleSortOrder = SortOrder<EntityType.BUNDLE, Bundle, BundleSortColumn>;

export const BUNDLE_STATE_SORT_ORDER: BundleSortOrder = {
    column: "STATE",
    apply: (left: Bundle, right: Bundle) => stringCompare(left.state, right.state)
}

export const BUNDLE_NAME_SORT_ORDER: BundleSortOrder = {
    column: "NAME",
    apply: (left: Bundle, right: Bundle) => nameCompare(left.name, right.name)
}

export const BUNDLE_DATE_SORT_ORDER: BundleSortOrder = {
    column: "DATE",
    apply: (left: Bundle, right: Bundle) => dateCompare(left.date, right.date)
}

class BundleDefinition extends EntityDefinition<EntityType.BUNDLE, BundleAnchor, PersistableBundle, Bundle> {

    constructor() {
        super(
            EntityType.BUNDLE,
            [
                BUNDLE_STATE_ATTRIBUTE_DEFINITION,
                BUNDLE_NAME_ATTRIBUTE_DEFINITION,
                BUNDLE_DATE_ATTRIBUTE_DEFINITION,
                BUNDLE_COMMENT_ATTRIBUTE_DEFINITION,
                BUNDLE_REGION_ATTRIBUTE_DEFINITION
            ]
        )
    }

    public getDocId(entity: PersistableBundle): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableBundle): BundleAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.BUNDLE>): BundleAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: BundleAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.BUNDLE.toLowerCase());
    }

}

export const BUNDLE_DEFINITION = new BundleDefinition();