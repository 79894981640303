import Typography from "@mui/material/Typography";
import React, {createContext, useEffect, useState} from "react";
import {database} from "../firebase/firebase";
import {EntityRepo} from "./EntityRepo";
import {EntityScope} from "./model/Entity";
import {LOCATION_DEFINITION, LocationState, PreparedLocation} from "./model/Location";
import {PreparedRegion, Region, REGION_DEFINITION, RegionState} from "./model/Region";
import {PreparedTag, TAG_DEFINITION, TagState} from "./model/Tag";
import {Address} from "./model/value/Address";
import {ACTIVITY, FOOD_DRINKS} from "./model/value/Category";
import {Code} from "./model/value/Code";
import {GERMANY, SPAIN} from "./model/value/Country";
import {EntityType} from "./model/value/EntityType";
import {GeoArea} from "./model/value/GeoArea";
import {GeoPoint} from "./model/value/GeoPoint";
import {IdRef} from "./model/value/IdRef";
import {Label} from "./model/value/Label";
import {GERMAN, SPANISH} from "./model/value/Language";
import {Latitude} from "./model/value/Latitude";
import {Longitude} from "./model/value/Longitude";
import {Name} from "./model/value/Name";
import {EUROPA_BERLIN, EUROPA_MADRID} from "./model/value/TimeZone";

export type Props = {}

export type Init = {}

const SetupContext = createContext<Init>(null!);

export function preparedRegions(): ReadonlyArray<PreparedRegion> {
    return [
        {
            entityType: EntityType.REGION,
            entityScope: EntityScope.PREPARED,
            state: RegionState.ENABLED,
            name: new Name("Ibiza"),
            code: new Code("IBIZA"),
            country: SPAIN,
            language: SPANISH,
            timeZone: EUROPA_MADRID,
            area: new GeoArea(
                new GeoPoint(
                    new Latitude(39.16299395978177),
                    new Longitude(1.7634931941821617)
                ),
                new GeoPoint(
                    new Latitude(38.599617409111644),
                    new Longitude(1.1139265472976283)
                )
            )
        },
        {
            entityType: EntityType.REGION,
            entityScope: EntityScope.PREPARED,
            state: RegionState.ENABLED,
            name: new Name("Kiel"),
            code: new Code("KIEL"),
            country: GERMANY,
            language: GERMAN,
            timeZone: EUROPA_BERLIN,
            area: new GeoArea(
                new GeoPoint(
                    new Latitude(54.43990253246118),
                    new Longitude(10.317976217619902)
                ),
                new GeoPoint(
                    new Latitude(54.25886921781025),
                    new Longitude(10.018160901767713)
                )
            )
        }
    ];
}

export function preparedTags(): ReadonlyArray<Omit<PreparedTag, "region"> & { region: string }> {
    return []
}


export function preparedLocations(): ReadonlyArray<Omit<PreparedLocation, "region"> & { region: string }> {
    return []
}

const InitProvider: React.FunctionComponent<Props> = ({children}: React.PropsWithChildren<Props>) => {

    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {

        const regionRepo = new EntityRepo(database, REGION_DEFINITION);
        const tagRepo = new EntityRepo(database, TAG_DEFINITION);
        const locationRepo = new EntityRepo(database, LOCATION_DEFINITION);

        const initializeSystem = async () => {

            console.log("initializing regions");
            const persistedRegionIds: ReadonlyArray<IdRef<EntityType.REGION>> = await Promise.all(
                preparedRegions().map(
                    async (preparedRegion) => {
                        return await regionRepo.persist({
                            ...preparedRegion
                        })
                    }
                )
            );
            console.log("initialized regions", persistedRegionIds);

            const persistedRegions: ReadonlyArray<Region> = await regionRepo.getAll({});
            const getRegionIdRef = (region: string): IdRef<EntityType.REGION> => {
                return new IdRef<EntityType.REGION>(persistedRegions.find(r => r.code.value === region)!.id);
            }

            for (let i = 0; i < 10; i++) {
                console.log("initializing tags");
                const persistedTagIds: ReadonlyArray<IdRef<EntityType.TAG>> = await Promise.all(
                    preparedTags().map(
                        async (preparedTag) => {
                            return await tagRepo.persist({
                                ...preparedTag,
                                name: new Name(preparedTag.name.value + "" + (i + 1)),
                                region: getRegionIdRef(preparedTag.region)
                            })
                        }
                    )
                );
                console.log("initialized tags", persistedTagIds);
            }

            console.log("initializing locations");
            const persistedLocationsIds: ReadonlyArray<IdRef<EntityType.LOCATION>> = await Promise.all(
                preparedLocations().map(
                    async (preparedLocation) => {
                        return await locationRepo.persist({
                            ...preparedLocation,
                            region: getRegionIdRef(preparedLocation.region)
                        })
                    }
                )
            );
            console.log("initialized locations", persistedLocationsIds);

        }

        const initSystem = async () => {

            console.log("setting up system");
            const regions = await regionRepo.getAll({});

            if (regions.length === 0 && process.env.REACT_APP_INIT === "true") {

                console.log("initializing system");
                await initializeSystem();

            }

            setInitialized(true);

        }

        initSystem().catch(console.error)

    }, []);

    if (initialized) {
        return (
            <SetupContext.Provider value={{}}>
                {children}
            </SetupContext.Provider>
        )
    } else {
        return (
            <Typography variant="h3">
                System not yet initialized!
            </Typography>
        );
    }

};

export default InitProvider;