import React, {useEffect, useState} from "react";
import {Partner} from "../../../api/model/Partner";
import {Project, useProject} from "../../../context/ProjectProvider";
import PartnerTable from "./PartnerTable";

export interface Props {
    onSelect?: (partner: Partner) => void
}

const PartnerList: React.FunctionComponent<Props> = ({onSelect}: React.PropsWithChildren<Props>) => {

    const project: Project = useProject();
    const [partners, setPartners] = useState<ReadonlyArray<Partner>>([]);

    useEffect(() => {

        const loadPartners = async () => {

            const partners = await project.partnerManager.getAllAsArray({});
            setPartners(partners);

        }

        loadPartners().catch(console.error);

    }, [project]);

    return (
        <PartnerTable
            partners={partners}
            onSelect={onSelect}
        />
    );

};

export default PartnerList;
