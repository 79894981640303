import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";

export class ImageFormat {

    public readonly code: Code;

    public readonly mimeType: string;

    public readonly extension: string;

    constructor(code: Code, mimeType: string, extension: string) {
        this.code = code;
        this.mimeType = mimeType;
        this.extension = extension;
    }

    public static toData(value: ImageFormat): string {
        return value.code.value;
    }

    public static fromData(data: string): ImageFormat {
        return ImageFormat.fromCode(new Code(data));
    }

    public static fromCode(imageTypeCode: Code): ImageFormat {
        const imageFormat: Maybe<ImageFormat> = IMAGE_TYPES.find(imageType => imageType.code.value === imageTypeCode.value);
        if (imageFormat === undefined) {
            throw new Error("Unknown image format: " + JSON.stringify(imageTypeCode));
        }
        return imageFormat;
    }

    public static fromMimeType(mimeType: string): ImageFormat {
        const imageFormat: Maybe<ImageFormat> = IMAGE_TYPES.find(imageType => imageType.mimeType === mimeType);
        if (imageFormat === undefined) {
            throw new Error("Unknown image mime type: " + JSON.stringify(mimeType));
        }
        return imageFormat;
    }

}

export const JPEG: ImageFormat = new ImageFormat(
    new Code("JPEG"),
    "image/jpeg",
    ".jpeg"
);

export const PNG: ImageFormat = new ImageFormat(
    new Code("PNG"),
    "image/png",
    ".png"
);

export const IMAGE_TYPES: ReadonlyArray<ImageFormat> = [
    JPEG,
    PNG
] as const;

export const IMAGE_FORMAT_DATA_MAPPER: DataMapper<ImageFormat, string> = {
    toData: ImageFormat.toData,
    fromData: ImageFormat.fromData
}

