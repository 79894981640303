import {DataMapper} from "../Entity";
import {Latitude} from "./Latitude";
import {Longitude} from "./Longitude";

export type geoPoint = {
    latitude: number;
    longitude: number;
}

export class GeoPoint {

    public readonly latitude: Latitude;

    public readonly longitude: Longitude;

    constructor(latitude: Latitude, longitude: Longitude) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    public withLatitude(latitude: Latitude): GeoPoint {
        return new GeoPoint(latitude, this.longitude);
    }

    public withLongitude(longitude: Longitude): GeoPoint {
        return new GeoPoint(this.latitude, longitude);
    }

    public static toData(value: GeoPoint): geoPoint {
        return {
            latitude: Latitude.toData(value.latitude),
            longitude: Longitude.toData(value.longitude)
        };
    }

    static fromData(data: geoPoint): GeoPoint {
        return new GeoPoint(
            Latitude.fromData(data.latitude),
            Longitude.fromData(data.longitude)
        );
    }

}

export const GEO_POINT_DATA_MAPPER: DataMapper<GeoPoint, geoPoint> = {
    toData: GeoPoint.toData,
    fromData: GeoPoint.fromData
}

