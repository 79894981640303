import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import React from "react";
import {FormSize} from "../form/FormSize";

export type Option = { value: string, label: string };

export type Options = ReadonlyArray<Option>;

export interface Props {
    id: string,
    value: string,
    options: Options,
    disabled: boolean,
    size: FormSize;
    setValue: (value: string) => void
}

const StringOptionsInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, options, disabled, size, setValue} = props

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value);
    }

    return (
        <Select
            id={id}
            value={value}
            disabled={disabled}
            size={size}
            onChange={handleChange}
        >
            {options.map(option =>
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            )}
        </Select>
    )

};

export default StringOptionsInput;

export function sortOptions(options: Options): Options {
    return [...options].sort((left: Option, right: Option) => left.label.localeCompare(right.label))
}
