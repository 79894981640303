import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {CategoryTarget} from "./CategoryTarget";
import {Code} from "./Code";
import {Name} from "./Name";
import {stringCompare} from "./String";

export class Category {

    public readonly name: Name;

    public readonly code: Code;

    public readonly targets: ReadonlyArray<CategoryTarget>;

    constructor(name: Name, code: Code, targets: ReadonlyArray<CategoryTarget>) {
        this.name = name;
        this.code = code;
        this.targets = targets;
    }

    public static toData(value: Category): string {
        return value.code.value;
    }

    public static fromData(data: string): Category {
        return Category.fromCode(new Code(data));
    }

    public static fromCode(categoryCode: Code): Category {
        const category: Maybe<Category> = CATEGORIES.find(category => category.code.value === categoryCode.value);
        if (category === undefined) {
            throw new Error("Unknown category: " + JSON.stringify(categoryCode));
        }
        return category;
    }

}

export const ACTIVITY: Category = new Category(
    new Name("Activity"),
    new Code("ACTIVITY"),
    [CategoryTarget.VENUE]
);

export const EXPLORATION: Category = new Category(
    new Name("Explore"),
    new Code("EXPLORATION"),
    [CategoryTarget.VENUE]
);

export const FOOD_DRINKS: Category = new Category(
    new Name("Food & Drinks"),
    new Code("FOOD_DRINKS"),
    [CategoryTarget.VENUE]
);

export const EVENT: Category = new Category(
    new Name("Event"),
    new Code("EVENT"),
    [CategoryTarget.EVENT, CategoryTarget.VENUE]
);

export const CATEGORIES: ReadonlyArray<Category> = [
    ACTIVITY,
    FOOD_DRINKS,
    EXPLORATION,
    EVENT
] as const;

export const CATEGORY_DATA_MAPPER: DataMapper<Category, string> = {
    toData: Category.toData,
    fromData: Category.fromData
}

export function categoryEquals(left: Category, right: Category): boolean {
    return left.code.value === right.code.value;
}

export function categoryCompare(left: Category, right: Category): number {
    return stringCompare(left.code.value, right.code.value)
}