import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import React from "react";

export interface Props {
    label: string,
}

const FormRow: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {

    const {label, children} = props;

    return (
        <>
            <Grid item xs={2}>
                {label}
            </Grid>
            <Grid item xs={10}>
                <FormControl fullWidth>
                    {children}
                </FormControl>
            </Grid>
        </>
    )

};

export default FormRow;
