import {EditOutlined} from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from "@mui/material/TablePagination";
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import {ENTITY_ID_CONTAINS_FILTER, SortDirection} from "../../../api/model/Entity";
import {
    Purchase,
    PURCHASE_AFFILIATE_CODE_ID_SORT_ORDER, PURCHASE_APPROVED_AT_SORT_ORDER,
    PURCHASE_CURRENCY_SORT_ORDER,
    PURCHASE_CUSTOMER_NAME_SORT_ORDER,
    PURCHASE_DURATION_SORT_ORDER,
    PURCHASE_PAYMENT_ID_SORT_ORDER,
    PURCHASE_PAYMENT_TYPE_SORT_ORDER,
    PURCHASE_PRICE_SORT_ORDER,
    PURCHASE_STATE_SORT_ORDER,
    PurchaseFilter,
    PurchaseSortOrder
} from "../../../api/model/Purchase";
import FilterDialogButton from "../../form/FilterDialogButton";
import FilterFormButtons from "../../form/FilterFormButtons";
import FilterFormRow from "../../form/FilterFormRow";
import SortOrderButton from "../../form/SortOrderButton";
import StringInput from "../../input/StringInput";
import {usePurchaseSlicer} from "./PurchaseSlicerProvider";

interface FilterDialogProps {
    open: boolean;
    onClose: (filter: InternalFilter) => void;
}

const FilterDialog: React.FunctionComponent<FilterDialogProps> = (props: FilterDialogProps) => {

    const {open, onClose} = props;

    const [id, setId] = useState<string>("");

    const handleClear = () => {
        setId("");
    };

    const handleClose = () => {
        onClose({
            id: id.length === 0 ? undefined : id,
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Filter</DialogTitle>
            <Grid container spacing={3} sx={{p: 3}}>
                <FilterFormRow label="ID" operation="contains">
                    <StringInput
                        id="id"
                        value={id}
                        disabled={false}
                        size="small"
                        setValue={setId}
                    />
                </FilterFormRow>
                <FilterFormButtons onClear={handleClear} onClose={handleClose}/>
            </Grid>
        </Dialog>
    );
}

interface RowProps {
    purchase: Purchase,
    onSelect: (purchase: Purchase) => void
}

const Row: React.FunctionComponent<RowProps> = ({purchase, onSelect}: RowProps) => {
    return (
        <TableRow>
            <TableCell padding={"checkbox"}>
                <IconButton size="small" onClick={() => onSelect(purchase)}>
                    <EditOutlined/>
                </IconButton>
            </TableCell>
            <TableCell>{purchase.customerName.value}</TableCell>
            <TableCell>{purchase.approvedAt.toLocaleString()}</TableCell>
            <TableCell>{purchase.paymentType.name.value}</TableCell>
            <TableCell>{purchase.paymentId ? purchase.paymentId.value : ""}</TableCell>
            <TableCell>{purchase.price ? (purchase.price.value / 100).toFixed(2) : ""}</TableCell>
            <TableCell>{purchase.duration.name.value}</TableCell>
            <TableCell>{purchase.affiliateCodeId ? purchase.affiliateCodeId.value : ""}</TableCell>
            <TableCell>{purchase.state}</TableCell>
        </TableRow>
    );
}

export interface Props {
    purchases: ReadonlyArray<Purchase>,
    onSelect?: (purchase: Purchase) => void
}

type InternalFilter = {
    id?: string,
}

const PurchaseTable: React.FunctionComponent<Props> = ({purchases, onSelect}: Props) => {

    const purchaseSlicer = usePurchaseSlicer();

    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const [internalFilter, setInternalFilter] = useState<InternalFilter>({});

    const handleFilterDialogOpen = () => {
        setFilterDialogOpen(true);
    };

    const handleFilterDialogClose = (filter: InternalFilter) => {
        setFilterDialogOpen(false);
        setInternalFilter(filter)
    };

    useEffect(() => {

        const dynamicFilters = new Array<PurchaseFilter<any>>();

        if (internalFilter.id) {
            dynamicFilters.push({
                operation: ENTITY_ID_CONTAINS_FILTER,
                comparisonValue: internalFilter.id
            });
        }

        purchaseSlicer.setDynamicFilters(dynamicFilters);
        purchaseSlicer.setPaginationPage(0);

    }, [internalFilter]);

    function handleSortChange(sortOrder: PurchaseSortOrder, direction: SortDirection) {
        purchaseSlicer.setSortOrder(sortOrder);
        purchaseSlicer.setSortDirection(direction);
        purchaseSlicer.setPaginationPage(0);
    }

    const handlePaginationPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        purchaseSlicer.setPaginationPage(newPage);
    };

    const handlePaginationRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        purchaseSlicer.setPaginationRowsPerPage(newRowsPerPage);
        purchaseSlicer.setPaginationPage(0);
    };

    const handleSelectFactory = (purchase: Purchase) => {
        return () => {
            onSelect && onSelect(purchase)
        }
    }

    const [purchaseSlice, purchaseSliceCount] = purchaseSlicer.slice(purchases);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <FilterDialogButton
                                    filterCount={purchaseSlicer.dynamicFilters.length}
                                    onClick={handleFilterDialogOpen}
                                />
                                <FilterDialog
                                    open={filterDialogOpen}
                                    onClose={handleFilterDialogClose}
                                />
                            </TableCell>
                            <TableCell>
                                Customer Name
                                <SortOrderButton
                                    sortOrder={PURCHASE_CUSTOMER_NAME_SORT_ORDER}
                                    currentSortOrder={purchaseSlicer.sortOrder}
                                    currentSortDirection={purchaseSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Approved At
                                <SortOrderButton
                                    sortOrder={PURCHASE_APPROVED_AT_SORT_ORDER}
                                    currentSortOrder={purchaseSlicer.sortOrder}
                                    currentSortDirection={purchaseSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Payment Type
                                <SortOrderButton
                                    sortOrder={PURCHASE_PAYMENT_TYPE_SORT_ORDER}
                                    currentSortOrder={purchaseSlicer.sortOrder}
                                    currentSortDirection={purchaseSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Payment ID
                                <SortOrderButton
                                    sortOrder={PURCHASE_PAYMENT_ID_SORT_ORDER}
                                    currentSortOrder={purchaseSlicer.sortOrder}
                                    currentSortDirection={purchaseSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Price
                                <SortOrderButton
                                    sortOrder={PURCHASE_PRICE_SORT_ORDER}
                                    currentSortOrder={purchaseSlicer.sortOrder}
                                    currentSortDirection={purchaseSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Duration
                                <SortOrderButton
                                    sortOrder={PURCHASE_DURATION_SORT_ORDER}
                                    currentSortOrder={purchaseSlicer.sortOrder}
                                    currentSortDirection={purchaseSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                Affiliate Code ID
                                <SortOrderButton
                                    sortOrder={PURCHASE_AFFILIATE_CODE_ID_SORT_ORDER}
                                    currentSortOrder={purchaseSlicer.sortOrder}
                                    currentSortDirection={purchaseSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                            <TableCell>
                                State
                                <SortOrderButton
                                    sortOrder={PURCHASE_STATE_SORT_ORDER}
                                    currentSortOrder={purchaseSlicer.sortOrder}
                                    currentSortDirection={purchaseSlicer.sortDirection}
                                    handleSortChange={handleSortChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseSlice.map((purchase: Purchase) => (
                            <Row key={purchase.id.value} purchase={purchase} onSelect={handleSelectFactory(purchase)}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={purchaseSlicer.paginationRowsPerPageOptions}
                component="div"
                count={purchaseSliceCount}
                rowsPerPage={purchaseSlicer.paginationRowsPerPage}
                page={purchaseSlicer.paginationPage}
                onRowsPerPageChange={handlePaginationRowsPerPageChange}
                onPageChange={handlePaginationPageChange}
            />
        </>
    );

}

export default PurchaseTable;

