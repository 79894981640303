import {DataMapper} from "../Entity";
import {ContactOptionType, EMAIL, PHONE, WHATSAPP} from "./ContactOptionType";
import {EmailAddress} from "./EmailAddress";
import {PhoneNumber, phoneNumber} from "./PhoneNumber";

export type email = {
    type: string,
    address: string
}

export type phone = {
    type: string,
    number: phoneNumber
}

export type whatsapp = {
    type: string,
    number: phoneNumber
}

export type contactOption = email | phone | whatsapp;

export abstract class ContactOption {

    public readonly type: ContactOptionType;

    protected constructor(type: ContactOptionType) {
        this.type = type;
    }

    public static toData(value: ContactOption): contactOption {
        if (value.type.code.value === EMAIL.code.value) {
            const emailValue = value as Email;
            return {
                type: ContactOptionType.toData(emailValue.type),
                address: EmailAddress.toData(emailValue.address)
            }
        } else  if (value.type.code.value === PHONE.code.value) {
            const phoneValue = value as Phone;
            return {
                type: ContactOptionType.toData(phoneValue.type),
                number: PhoneNumber.toData(phoneValue.number)
            }
        } else {
            const whatsappValue = value as Whatsapp;
            return {
                type: ContactOptionType.toData(whatsappValue.type),
                number: PhoneNumber.toData(whatsappValue.number)
            }
        }
    }

    public static fromData(data: contactOption): ContactOption {
        const type = ContactOptionType.fromData(data.type);
        switch (type.code.value) {
            case "EMAIL":
                const emailData = data as email;
                return new Email(
                    EmailAddress.fromData(emailData.address)
                );
            case "PHONE":
                const phoneData = data as phone;
                return new Phone(
                    PhoneNumber.fromData(phoneData.number)
                );
            case "WHATSAPP":
                const whatsappData = data as whatsapp;
                return new Whatsapp(
                    PhoneNumber.fromData(whatsappData.number)
                );
        }
        throw new Error("Unknown contact option type: " + type.code.value);
    }

}

export class Email extends ContactOption {

    public readonly address: EmailAddress;

    constructor(emailAddress: EmailAddress) {
        super(EMAIL)
        this.address = emailAddress;
    }

}

export class Phone extends ContactOption {

    public readonly number: PhoneNumber;

    constructor(number: PhoneNumber) {
        super(PHONE)
        this.number = number;
    }

}


export class Whatsapp extends ContactOption {

    public readonly number: PhoneNumber;

    constructor(number: PhoneNumber) {
        super(WHATSAPP)
        this.number = number;
    }

}

export const CONTACT_OPTION_DATA_MAPPER: DataMapper<ContactOption, contactOption> = {
    toData: ContactOption.toData,
    fromData: ContactOption.fromData
}