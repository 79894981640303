import {DataMapper} from "../Entity";
import {Optional} from "../util/Optional";
import {Homepage, website, Website} from "./Website";
import {HOMEPAGE} from "./WebsiteType";

export type websites = website[];

export class Websites {

    public readonly homepage: Optional<Homepage>;

    constructor(homepage: Optional<Homepage>) {
        this.homepage = homepage;
    }

    public withHomepage(homepage: Optional<Homepage>): Websites {
        return new Websites(homepage);
    }

    public static toData(value: Websites): websites {
        const websites = new Array<website>();
        if (value.homepage !== null) {
            websites.push(Website.toData(value.homepage));
        }
        return websites;
    }

    public static fromData(data: websites): Websites {
        let homepage: Optional<Website> = null;
        if(data) {
            data.forEach(datum => {
                const website = Website.fromData(datum);
                if (website.type.code.value === HOMEPAGE.code.value) {
                    if (homepage !== null) {
                        throw new Error("Multiple instances of the same website are not yet supported");
                    }
                    homepage = website as Homepage;
                }
            });
        }
        return new Websites(homepage);
    }

}

export const WEBSITES_DATA_MAPPER: DataMapper<Websites, websites> = {
    toData: Websites.toData,
    fromData: Websites.fromData
}