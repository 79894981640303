import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useState} from "react";

export interface Props {
    disabled: boolean,
    onDelete: () => void
}

const DeleteButton: React.FunctionComponent<Props> = (props: Props) => {

    const {disabled, onDelete} = props
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleNo = () => {
        setOpen(false);
    };

    const handleYes = () => {
        setOpen(false);
        onDelete();
    };

    return (
        <>
            <Button disabled={disabled} color={"error"} variant="contained" onClick={handleOpen}>Delete</Button>
            <Dialog open={open} onClose={handleNo} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">
                    {"Really?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNo} color={"error"} variant={"outlined"}>No</Button>
                    <Button onClick={handleYes} color={"error"} variant={"contained"}>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    );

};

export default DeleteButton;
