import React, {useEffect, useState} from "react";
import {Location} from "../../api/model/Location";
import {findByIdValue} from "../../api/util";
import {FormSize} from "../form/FormSize";
import StringOptionsInput, {Option, Options, sortOptions} from "./StringOptionsInput";

export interface Props {
    id: string,
    value: Location,
    disabled: boolean,
    locations: ReadonlyArray<Location>,
    size: FormSize;
    setValue: (value: Location) => void
}

const LocationInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, locations, size, setValue} = props

    const [options, setOptions] = useState<Options>([]);

    useEffect(() => {
        setOptions(sortOptions(locations.map(location => {
            return {value: location.id.value, label: location.name.value}
        })));
    }, [locations]);

    return (
        <StringOptionsInput
            id={id}
            value={value.id.value}
            options={options}
            disabled={disabled}
            size={size}
            setValue={(value: string) => setValue(findByIdValue(locations, value)!)}
        />
    )

};

export default LocationInput;
