import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import FormControl from "@mui/material/FormControl";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from "@mui/material/Select";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from "react";
import {useNavigate} from "react-router-dom";
import {findByIdValue, sortByName} from "../api/util";
import {useLoggedIn} from "../context/AuthProvider";
import {useProject} from "../context/ProjectProvider";

export interface Props {
}

const Bar: React.FunctionComponent<Props> = ({}: React.PropsWithChildren<Props>) => {

    const loggedIn = useLoggedIn();
    const project = useProject();
    const navigate = useNavigate();

    const [profileMenuAnchor, setProfileMenuAnchor] = React.useState<null | HTMLElement>(null);

    const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenuAnchor(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuAnchor(null);
    };

    const handleProfile = () => {
        setProfileMenuAnchor(null);
        navigate('/profile', {replace: true});
    };

    const handleLogout = async () => {
        await loggedIn.signOut();
    };

    return (
        <AppBar position="static" elevation={0}>
            <Toolbar>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    Loco Manager
                </Typography>
                <FormControl size="small" sx={{minWidth: 200}}>
                    <Select
                        value={project.selectedRegion.id.value}
                        onChange={(event) => project.setSelectedRegion(findByIdValue(project.regions, event.target.value)!)}
                    >
                        {sortByName(project.regions).map(region =>
                            <MenuItem
                                key={region.id.value}
                                value={region.id.value}>
                                {region.name.value}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
                <div>
                    <IconButton size="large" onClick={handleProfileMenu} color="inherit">
                        <AccountCircle/>
                    </IconButton>
                    <Menu
                        id="bar-menu-profile"
                        anchorEl={profileMenuAnchor}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        open={profileMenuAnchor !== null}
                        onClose={handleProfileMenuClose}
                    >
                        <MenuItem onClick={handleProfile}>Profile</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );

};

export default Bar;
