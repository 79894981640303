import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Flag} from "./Flag";
import {LanguageCode} from "./LanguageCode";
import {Name} from "./Name";

export class Language {

    public readonly name: Name;

    public readonly flag: Flag;

    public readonly code: LanguageCode;

    constructor(name: Name, flag: Flag, code: LanguageCode) {
        this.name = name;
        this.flag = flag;
        this.code = code;
    }

    public static toData(value: Language): string {
        return value.code.value;
    }

    public static fromData(data: string): Language {
        return Language.fromCode(new LanguageCode(data));
    }

    public static fromCode(languageCode: LanguageCode): Language {
        const language: Maybe<Language> = LANGUAGES.find(language => language.code.value === languageCode.value);
        if (language === undefined) {
            throw new Error("Unknown language: " + languageCode);
        }
        return language;
    }

}

export const GERMAN: Language = new Language(
    new Name("German"),
    new Flag('🇩🇪'),
    new LanguageCode("DE")
);

export const ENGLISH: Language = new Language(
    new Name("English"),
    new Flag('🇬🇧'),
    new LanguageCode("EN")
);

export const SPANISH: Language = new Language(
    new Name("Spanish"),
    new Flag('🇪🇸'),
    new LanguageCode("ES")
);

export const LANGUAGES: ReadonlyArray<Language> = [
    GERMAN,
    ENGLISH,
    SPANISH
] as const;

export const DEFAULT_LANGUAGE: Language = ENGLISH;

export const LANGUAGE_DATA_MAPPER: DataMapper<Language, string> = {
    toData: Language.toData,
    fromData: Language.fromData
}
