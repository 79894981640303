import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import React from "react";

export interface Props {
    label: string,
    operation: string,
}

const FilterFormRow: React.FunctionComponent<Props> = (props: React.PropsWithChildren<Props>) => {

    const {label, operation, children} = props;

    return (
        <>
            <Grid item xs={2}>
                {label}
            </Grid>
            <Grid item xs={2}>
                {operation}
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth>
                    {children}
                </FormControl>
            </Grid>
        </>
    )

};

export default FilterFormRow;
