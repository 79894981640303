import {Download} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import QRCode from 'qrcode'
import React, {useEffect, useState} from "react";
import {AffiliateCodePayload, affiliateCodeUrl} from "../../api/model/AffiliateCode";
import {FormSize} from "../form/FormSize";

export interface Props {
    value: Pick<AffiliateCodePayload, "codeId" | "target">,
    size: FormSize
}

const AffiliateCodeQrCodeOutput: React.FunctionComponent<Props> = (props: Props) => {

    const {value, size} = props

    const [svgDataUrl, setSvgDataUrl] = useState<string>("");
    const [pngDataUrl, setPngDataUrl] = useState<string>("");

    useEffect(() => {

        const url = affiliateCodeUrl(value);

        const svgOptions = {
            errorCorrectionLevel: 'H',
            type: 'svg',
            margin: 2
        }

        // @ts-ignore
        QRCode.toString(url, svgOptions, (error, xmlString) => {
            setSvgDataUrl("data:image/svg+xml;base64," + window.btoa(xmlString));
        });

        const pngOptions = {
            errorCorrectionLevel: 'H',
            type: 'image/png',
            width: "1000",
            quality: 1,
            margin: 2
        }

        // @ts-ignore
        QRCode.toDataURL(url, pngOptions, (error, dataUrl) => {
            setPngDataUrl(dataUrl);
        })

    }, []);


    const handleDownloadSvg = () => {
        const svgLink = document.createElement("a");
        svgLink.href = svgDataUrl;
        svgLink.download = value.codeId.value + ".svg";
        svgLink.click();
    }

    const handleDownloadPng = () => {
        const pngLink = document.createElement("a");
        pngLink.href = pngDataUrl;
        pngLink.download = value.codeId.value + ".png";
        pngLink.click();
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Stack direction="column">
                    <img src={svgDataUrl} onClick={handleDownloadSvg}/>
                    <Stack direction="row" sx={{justifyContent: "center"}}>
                        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap',}}>
                            SVG
                            <IconButton
                                sx={{ml: 1}}
                                size={size}
                                disabled={false}
                                onClick={() => handleDownloadSvg()}>
                                <Download/>
                            </IconButton>
                        </div>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack direction="column">
                    <img src={pngDataUrl}/>
                    <Stack direction="row" sx={{justifyContent: "center"}}>
                        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap',}}>
                            PNG
                            <IconButton
                                sx={{ml: 1}}
                                size={size}
                                disabled={false}
                                onClick={() => handleDownloadPng()}>
                                <Download/>
                            </IconButton>
                        </div>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )

};

export default AffiliateCodeQrCodeOutput;
