import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import React, {MutableRefObject, useEffect, useState} from "react";
import {Optional} from "../../api/model/util/Optional";
import {FormSize} from "../form/FormSize";

const EMPTY = "";

export type SingleSelectProps<T> = {
    id: string,
    value: Optional<T>,
    options: ReadonlyArray<T>,
    disabled: boolean,
    showEmpty: [false] | [true, string],
    size: FormSize,
    toLabel: (value: T) => string,
    toValue: (value: T) => string,
    fromValue: (value: string) => T
};

export type SingleSelectCompoundProps<T> = SingleSelectProps<T> & {
    getValue: MutableRefObject<Optional<() => Optional<T>>>,
    reset: number
};

export type SingleSelectInputProps<T> = SingleSelectProps<T> & {
    setValue: (value: Optional<T>) => void
};

export function SingleSelectCompound<T>(props: SingleSelectCompoundProps<T>) {

    const {id, value, options, disabled, showEmpty, size, toLabel, toValue, fromValue, getValue, reset} = props

    const [currentValue, setCurrentValue] = useState<Optional<T>>(value);

    useEffect(() => {
        setCurrentValue(value)
    }, [reset]);

    getValue.current = () => currentValue

    return (
        <SingleSelectInput<T>
            id={id}
            value={currentValue}
            options={options}
            disabled={disabled}
            showEmpty={showEmpty}
            size={size}
            toLabel={toLabel}
            toValue={toValue}
            fromValue={fromValue}
            setValue={setCurrentValue}
        />
    )

};

export function SingleSelectInput<T>(props: SingleSelectInputProps<T>) {

    const {id, value, options, disabled, showEmpty, size, toLabel, toValue, fromValue, setValue} = props

    const [items, setItems] = useState<ReadonlyArray<{ value: string, label: string }>>([]);

    useEffect(() => {
        setItems(options.map(option => ({
            value: toValue(option),
            label: toLabel(option)
        })));
    }, [options]);

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        if (value === EMPTY) {
            setValue(null);
        } else {
            setValue(fromValue(value));
        }
    }

    return (
        <Select
            id={id}
            value={value !== null ? toValue(value) : EMPTY}
            disabled={disabled}
            displayEmpty={true}
            size={size}
            onChange={handleChange}
        >
            {showEmpty[0] &&
                <MenuItem value={EMPTY}>
                    <em>{showEmpty[1]}</em>
                </MenuItem>
            }
            {items.map(item =>
                <MenuItem key={item.value} value={item.value}>
                    {item.label}
                </MenuItem>
            )}
        </Select>
    )

};
