import {Switch} from "@mui/material";
import React from "react";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: boolean,
    disabled: boolean,
    size: FormSize;
    setValue: (value: boolean) => void
}

const NonNegativeIntegerInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleChange = () => {
        setValue(!value);
    }

    return (
        <Switch
            id={id}
            checked={value}
            disabled={disabled}
            size={size}
            onClick={handleChange}
        />
    )

};

export default NonNegativeIntegerInput;
