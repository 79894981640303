import {DataMapper} from "../Entity";
import {address, Address} from "./Address";
import {LegalEntityType} from "./LegalEntityType";
import {Name} from "./Name";

export type legalEntity = {
    type: string,
    name: string,
    address: address
}

export class LegalEntity {

    public readonly type: LegalEntityType;

    public readonly name: Name;

    public readonly address: Address;

    constructor(type: LegalEntityType, name: Name, address: Address) {
        this.type = type;
        this.name = name;
        this.address = address;
    }

    public withType(type: LegalEntityType): LegalEntity {
        return new LegalEntity(type, this.name, this.address)
    }

    public withName(name: Name): LegalEntity {
        return new LegalEntity(this.type, name, this.address)
    }

    public withAddress(address: Address): LegalEntity {
        return new LegalEntity(this.type, this.name, address)
    }

    public static toData(value: LegalEntity): legalEntity {
        return {
            type: LegalEntityType.toData(value.type),
            name: Name.toData(value.name),
            address: Address.toData(value.address)
        }
    }

    public static fromData(data: legalEntity): LegalEntity {
        return new LegalEntity(
            LegalEntityType.fromData(data.type),
            Name.fromData(data.name),
            Address.fromData(data.address)
        )
    }

}

export const LEGAL_ENTITY_DATA_MAPPER: DataMapper<LegalEntity, legalEntity> = {
    toData: LegalEntity.toData,
    fromData: LegalEntity.fromData
}



