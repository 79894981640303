import {DataMapper} from "../Entity";
import {ImageScale} from "./ImageScale";
import {storedImage, StoredImage} from "./StoredImage";

export type scaledImage = {
    scale: string;
    image: storedImage;
}

export class ScaledImage {

    public readonly scale: ImageScale;

    public readonly image: StoredImage;

    constructor(scale: ImageScale, image: StoredImage) {
        this.scale = scale;
        this.image = image;
    }

    public static toData(value: ScaledImage): scaledImage {
        return {
            scale: ImageScale.toData(value.scale),
            image: StoredImage.toData(value.image)
        };
    }

    static fromData(data: scaledImage): ScaledImage {
        return new ScaledImage(
            ImageScale.fromData(data.scale),
            StoredImage.fromData(data.image)
        );
    }

}

export const SCALED_IMAGE_DATA_MAPPER: DataMapper<ScaledImage, scaledImage> = {
    toData: ScaledImage.toData,
    fromData: ScaledImage.fromData
}

