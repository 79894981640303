import {DataMapper} from "../Entity";
import {stringCompare, stringContains} from "./String";

export class EmailAddress {

    public readonly value: string;

    constructor(value: string) {
        this.value = value;
    }

    public static toData(value: EmailAddress): string {
        return value.value;
    }

    public static fromData(data: string): EmailAddress {
        return new EmailAddress(data);
    }
}

export const EMAIL_ADDRESS_DATA_MAPPER: DataMapper<EmailAddress, string> = {
    toData: EmailAddress.toData,
    fromData: EmailAddress.fromData
}

export function emailAddressContains(haystack: EmailAddress, needle: string): boolean {
    return stringContains(haystack.value, needle);
}

export function emailAddressCompare(left: EmailAddress, right: EmailAddress): number {
    return stringCompare(left.value, right.value)
}