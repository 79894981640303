import {DataMapper} from "../Entity";
import {Maybe} from "../util/Maybe";
import {Code} from "./Code";
import {CountryCode} from "./CountryCode";
import {Name} from "./Name";
import {stringCompare} from "./String";

export class Currency {

    public readonly name: Name;

    public readonly code: CountryCode;

    constructor(name: Name, code: Code) {
        this.name = name;
        this.code = code;
    }

    public static toData(value: Currency): string {
        return value.code.value;
    }

    public static fromData(data: string): Currency {
        return Currency.fromCode(new Code(data));
    }

    public static fromCode(currencyCode: Code): Currency {
        const currency: Maybe<Currency> = CURRENCIES.find(currency => currency.code.value === currencyCode.value);
        if (currency === undefined) {
            throw new Error("Unknown currency: " + JSON.stringify(currencyCode));
        }
        return currency;
    }

}

export const EURO: Currency = new Currency(
    new Name("Euro"),
    new Code("EUR")
);

export const CURRENCIES: ReadonlyArray<Currency> = [
    EURO
] as const;

export const CURRENCY_DATA_MAPPER: DataMapper<Currency, string> = {
    toData: Currency.toData,
    fromData: Currency.fromData
}

export function currencyEquals(left: Currency, right: Currency): boolean {
    return left.code.value === right.code.value;
}

export function currencyCompare(left: Currency, right: Currency): number {
    return stringCompare(left.name.value, right.name.value)
}
