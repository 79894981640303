import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";
import {EventPayload, EventState, Event} from "../../../api/model/Event";
import {Flag} from "../../../api/model/Flag";
import {Location} from "../../../api/model/Location";
import {Partner} from "../../../api/model/Partner";
import {Tag} from "../../../api/model/Tag";
import {Benefit} from "../../../api/model/value/Benefit";
import {Benefits} from "../../../api/model/value/Benefits";
import {BenefitTypes} from "../../../api/model/value/BenfitTypes";
import {Categories} from "../../../api/model/value/Categories";
import {EVENT} from "../../../api/model/value/Category";
import {ContactOptions} from "../../../api/model/value/ContactOptions";
import {EntityType} from "../../../api/model/value/EntityType";
import {IdRef} from "../../../api/model/value/IdRef";
import {Label} from "../../../api/model/value/Label";
import {Name} from "../../../api/model/value/Name";
import {NominalDate} from "../../../api/model/value/NominalDate";
import {NominalTime} from "../../../api/model/value/NominalTime";
import {NonNegativeInteger} from "../../../api/model/value/NonNegativeInteger";
import {CheckIn, Participation} from "../../../api/model/value/Participation";
import {PositiveInteger} from "../../../api/model/value/PositiveInteger";
import {SocialMediaProfiles} from "../../../api/model/value/SocialMediaProfiles";
import {Websites} from "../../../api/model/value/Websites";
import {findByIdValue} from "../../../api/util";
import FormRow from "../../form/FormRow";
import {FormSize} from "../../form/FormSize";
import BenefitInput from "../../input/BenefitInput";
import ContactOptionsEmailInput from "../../input/ContactOptionsEmailInput";
import ContactOptionsPhoneInput from "../../input/ContactOptionsPhoneInput";
import ContactOptionsWhatsappInput from "../../input/ContactOptionsWhatsappInput";
import EventStateInput from "../../input/EventStateInput";
import FlagsInput from "../../input/FlagsInput";
import ImagesInput from "../../input/ImagesInput";
import LabelInput, {LabelType} from "../../input/LabelInput";
import LocationInput from "../../input/LocationInput";
import NameInput from "../../input/NameInput";
import NominalDateInput from "../../input/NominalDateInput";
import NominalTimeInput from "../../input/NominalTimeInput";
import NonNegativeIntegerInput from "../../input/NonNegativeIntegerInput";
import ParticipationInput from "../../input/ParticipationInput";
import PartnerInput from "../../input/PartnerInput";
import PositiveIntegerInput from "../../input/PositiveIntegerInput";
import SocialMediaProfilesInput from "../../input/SocialMediaProfilesInput";
import TagsInput from "../../input/TagsInput";
import WebsitesHomepageInput from "../../input/WebsitesHomepageInput";
import StringOutput from "../../output/StringOutput";

export type EventInputResult = Omit<EventPayload, "region">;

export interface Props {
    event?: Event,
    partners: ReadonlyArray<Partner>,
    locations: ReadonlyArray<Location>,
    tags: ReadonlyArray<Tag>,
    flags: ReadonlyArray<Flag>,
    disabled: boolean,
    size: FormSize,
    onPersist?: (partialEvent: EventInputResult) => void
}

const EventInput: React.FunctionComponent<Props> = (props: Props) => {

    const {
        event,
        partners: availablePartners,
        locations: availableLocations,
        tags: availableTags,
        flags: availableFlags,
        disabled,
        size,
        onPersist
    } = props

    const inAMonth = new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000));
    const [state, setState] = useState<EventState>(EventState.ENABLED);
    const [name, setName] = useState<Name>(new Name(""));
    const [subtitle, setSubtitle] = useState<Name>(new Name(""));
    const [partner, setPartner] = useState<Partner>(availablePartners[0]);
    const [location, setLocation] = useState<Location>(availableLocations[0]);
    const [description, setDescription] = useState<Label>(new Label(""));
    const [categories, setCategories] = useState<Categories>(new Categories([EVENT]));
    const [tags, setTags] = useState<ReadonlyArray<Tag>>([]);
    const [images, setImages] = useState<ReadonlyArray<IdRef<EntityType.IMAGE>>>([]);
    const [announcementDays, setAnnouncementDays] = useState<NonNegativeInteger>(new NonNegativeInteger(11));
    const [participation, setParticipation] = useState<Participation>(new CheckIn());
    const [eventDate, setEventDate] = useState<NominalDate>(NominalDate.fromLocalDate(inAMonth));
    const [eventTime, setEventTime] = useState<NominalTime>(new NominalTime(18, 30));
    const [durationHours, setDurationHours] = useState<NonNegativeInteger>(new NonNegativeInteger(4));
    const [admittance, setAdmittance] = useState<Label>(new Label(""));
    const [websites, setWebsites] = useState<Websites>(new Websites(null));
    const [socialMediaProfiles, setSocialMediaProfiles] = useState<SocialMediaProfiles>(new SocialMediaProfiles(null, null));
    const [contactOptions, setContactOptions] = useState<ContactOptions>(new ContactOptions(null, null, null));
    const [benefit, setBenefit] = useState<Benefit>(new Benefit(new BenefitTypes([]), new Label("")));
    const [valuation, setValuation] = useState<NonNegativeInteger>(new NonNegativeInteger(0));
    const [flags, setFlags] = useState<ReadonlyArray<Flag>>([]);

    useEffect(() => {
        if (event) {
            setState(event.state);
            setName(event.name);
            setSubtitle(event.subtitle);
            setPartner(findByIdValue(availablePartners, event.partner.value)!);
            setLocation(findByIdValue(availableLocations, event.location.value)!);
            setDescription(event.description);
            setCategories(event.categories);
            setTags(event.tags.map(eventTagValue => findByIdValue(availableTags, eventTagValue)!));
            setImages(event.images);
            setAnnouncementDays(event.announcementDays);
            setParticipation(event.participation);
            setEventDate(event.eventDate);
            setEventTime(event.eventTime);
            setDurationHours(event.durationHours);
            setAdmittance(event.admittance);
            setWebsites(event.websites);
            setSocialMediaProfiles(event.socialMediaProfiles);
            setContactOptions(event.contactOptions);
            setBenefit(event.benefits.items.length !== 0 ? event.benefits.items[0] : new Benefit(new BenefitTypes([]), new Label("")));
            setValuation(event.valuation);
            setFlags(event.flags.map(eventFlagValue => findByIdValue(availableFlags, eventFlagValue)!));
        }
    }, [event]);

    const handlePersist = () => {

        const eventInputResult: EventInputResult = {
            state,
            name,
            subtitle,
            partner: new IdRef<EntityType.PARTNER>(partner.id),
            location: new IdRef<EntityType.LOCATION>(location.id),
            description,
            categories,
            tags: tags.map(tag => new IdRef<EntityType.TAG>(tag.id)),
            images,
            announcementDays,
            participation,
            eventTime,
            eventDate,
            durationHours,
            admittance,
            websites,
            socialMediaProfiles,
            contactOptions,
            benefits: new Benefits([benefit]),
            valuation,
            flags: flags.map(flag => new IdRef<EntityType.FLAG>(flag.id))
        };

        onPersist && onPersist(eventInputResult);

    }

    return (
        <>
            <Grid container spacing={3} maxWidth={1000}>
                {event?.id && <FormRow label="Id">
                    <StringOutput
                        value={event.id.value}
                        size={size}
                    />
                </FormRow>}
                <FormRow label="State">
                    <EventStateInput
                        id="state"
                        value={state}
                        disabled={disabled}
                        size={size}
                        setValue={setState}
                    />
                </FormRow>
                <FormRow label="Name">
                    <NameInput
                        id="name"
                        value={name}
                        disabled={disabled}
                        size={size}
                        setValue={setName}
                    />
                </FormRow>
                <FormRow label="Subtitle">
                    <NameInput
                        id="subtitle"
                        value={subtitle}
                        disabled={disabled}
                        size={size}
                        setValue={setSubtitle}
                    />
                </FormRow>
                <FormRow label="Partner">
                    <PartnerInput
                        id="partner"
                        value={partner}
                        partners={availablePartners}
                        disabled={disabled}
                        size={size}
                        setValue={setPartner}
                    />
                </FormRow>
                <FormRow label="Location">
                    <LocationInput
                        id="location"
                        value={location}
                        locations={availableLocations}
                        disabled={disabled}
                        size={size}
                        setValue={setLocation}
                    />
                </FormRow>
                <FormRow label="Description">
                    <LabelInput
                        id="description"
                        value={description}
                        disabled={disabled}
                        type={LabelType.HTML}
                        size={size}
                        setValue={setDescription}
                    />
                </FormRow>
                <FormRow label="Tags">
                    <TagsInput
                        id="tags"
                        value={tags}
                        categories={categories}
                        tags={availableTags}
                        disabled={disabled}
                        size={size}
                        setValue={setTags}
                    />
                </FormRow>
                <FormRow label="Images">
                    <ImagesInput
                        id="images"
                        value={images}
                        disabled={disabled}
                        size={size}
                        setValue={setImages}
                    />
                </FormRow>
                <FormRow label="Announcement">
                    <PositiveIntegerInput
                        id="announcement"
                        value={announcementDays}
                        max={new PositiveInteger(31)}
                        adornmentEnd={"Days"}
                        disabled={disabled}
                        size={size}
                        setValue={setAnnouncementDays}
                    />
                </FormRow>
                <FormRow label="Participation">
                    <ParticipationInput
                        id="participation"
                        value={participation}
                        disabled={disabled}
                        size={size}
                        setValue={setParticipation}
                    />
                </FormRow>
                <FormRow label="Event date">
                    <NominalDateInput
                        id="event-date"
                        value={eventDate}
                        disabled={disabled}
                        size={size}
                        setValue={setEventDate}
                    />
                </FormRow>
                <FormRow label="Event time">
                    <NominalTimeInput
                        id="event-time"
                        value={eventTime}
                        disabled={disabled}
                        size={size}
                        setValue={setEventTime}
                    />
                </FormRow>
                <FormRow label="Duration">
                    <PositiveIntegerInput
                        id="duration"
                        value={durationHours}
                        max={new PositiveInteger(24)}
                        adornmentEnd={"Hours"}
                        disabled={disabled}
                        size={size}
                        setValue={setDurationHours}
                    />
                </FormRow>

                <FormRow label="Admittance">
                    <LabelInput
                        id="admittance"
                        value={admittance}
                        disabled={disabled}
                        type={LabelType.HTML}
                        size={size}
                        setValue={setAdmittance}
                    />
                </FormRow>
                <FormRow label="Homepage">
                    <WebsitesHomepageInput
                        id="websites-homepage"
                        value={websites}
                        disabled={disabled}
                        size={size}
                        setValue={setWebsites}
                    />
                </FormRow>
                <FormRow label="Social Media">
                    <SocialMediaProfilesInput
                        id="social-media-profiles"
                        value={socialMediaProfiles}
                        disabled={disabled}
                        size={size}
                        setValue={setSocialMediaProfiles}
                    />
                </FormRow>
                <FormRow label="Email">
                    <ContactOptionsEmailInput
                        id="contact-options-email"
                        value={contactOptions}
                        disabled={disabled}
                        size={size}
                        setValue={setContactOptions}
                    />
                </FormRow>
                <FormRow label="Phone">
                    <ContactOptionsPhoneInput
                        id="contact-options-phone"
                        value={contactOptions}
                        disabled={disabled}
                        size={size}
                        setValue={setContactOptions}
                    />
                </FormRow>
                <FormRow label="Whatsapp">
                    <ContactOptionsWhatsappInput
                        id="contact-options-whatsapp"
                        value={contactOptions}
                        disabled={disabled}
                        size={size}
                        setValue={setContactOptions}
                    />
                </FormRow>
                <FormRow label="Benefit">
                    <BenefitInput
                        id="benefit"
                        value={benefit}
                        disabled={disabled}
                        size={size}
                        setValue={setBenefit}
                    />
                </FormRow>
                <FormRow label="Valuation">
                    <NonNegativeIntegerInput
                        id="valuation"
                        value={valuation}
                        max={new NonNegativeInteger(10)}
                        adornmentEnd={"internet points"}
                        disabled={disabled}
                        size={size}
                        setValue={setValuation}
                    />
                </FormRow>
                <FormRow label="Flags">
                    <FlagsInput
                        id="flags"
                        value={flags}
                        flags={availableFlags}
                        disabled={disabled}
                        size={size}
                        setValue={setFlags}
                    />
                </FormRow>
            </Grid>
            {onPersist && <Stack direction={"row"} spacing={1}>
                <Button
                    variant={"contained"}
                    disabled={disabled}
                    onClick={handlePersist}
                >
                    Save
                </Button>
            </Stack>}
        </>
    );

};

export default EventInput;
