import {collection, CollectionReference, doc, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {SCALED_IMAGES_DATA_MAPPER, scaledImages, ScaledImages} from "./value/ScaledImages";
import {STORED_IMAGE_DATA_MAPPER, storedImage, StoredImage} from "./value/StoredImage";
import {stringEquals} from "./value/String";

export enum ImageState {

    UPLOADED = "UPLOADED",

    SCALED_V1 = "SCALED_V1",

    FAILED = "FAILED"

}

export type ImagePayload = {
    state: ImageState,
    original: StoredImage;
    scaled: ScaledImages
    region: IdRef<EntityType.REGION>
}

export type ImageAnchor = EntityAnchor<EntityType.IMAGE> & {
    region: IdAlike<EntityType.REGION>
}

export type PreparedImage = PreparedEntity<EntityType.IMAGE> & ImagePayload;

export type Image = Entity<EntityType.IMAGE> & ImagePayload;

export type PersistableImage = PreparedImage | Image;

export type ImageManager = EntityManager<EntityType.IMAGE, ImageAnchor, PersistableImage, Image>;

export const IMAGE_STATE_DATA_MAPPER: DataMapper<ImageState, string> = {
    toData: (value: ImageState) => value,
    fromData: (data: string) => data as ImageState
}

export const IMAGE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableImage, ImageState, string>(
        "state",
        e => e.state,
        IMAGE_STATE_DATA_MAPPER,
        ImageState.UPLOADED
    );

export const IMAGE_ORIGINAL_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableImage, StoredImage, storedImage>(
        "original",
        e => e.original,
        STORED_IMAGE_DATA_MAPPER,
        null
    );

export const IMAGE_SCALED_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistableImage, ScaledImages, scaledImages>(
        "scaled",
        e => e.scaled,
        SCALED_IMAGES_DATA_MAPPER,
        new ScaledImages([])
    );

export const IMAGE_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistableImage>(
        EntityType.REGION,
        "region",
        e => e.region
    );


export type ImageFilterColumn = EntityFilterColumn | "STATE";

export type ImageFilterOperation<V> = FilterOperation<EntityType.IMAGE, Image, ImageFilterColumn, V>;

export type ImageFilter<V> = Filter<EntityType.IMAGE, Image, ImageFilterColumn, V>;

export const IMAGE_STATE_EQUALS_FILTER: ImageFilterOperation<ImageState> = {
    column: "STATE",
    apply: (image: Image, comparisonValue: ImageState) => stringEquals(image.state, comparisonValue)
}

export type ImageSortColumn = EntitySortColumn | "STATE";

export type ImageSortOrder = SortOrder<EntityType.IMAGE, Image, ImageSortColumn>;

class ImageDefinition extends EntityDefinition<EntityType.IMAGE, ImageAnchor, PersistableImage, Image> {

    constructor() {
        super(
            EntityType.IMAGE,
            [
                IMAGE_STATE_ATTRIBUTE_DEFINITION,
                IMAGE_ORIGINAL_ATTRIBUTE_DEFINITION,
                IMAGE_SCALED_ATTRIBUTE_DEFINITION,
                IMAGE_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistableImage): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistableImage): ImageAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.IMAGE>): ImageAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: ImageAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.IMAGE.toLowerCase());
    }

}

export const IMAGE_DEFINITION = new ImageDefinition();