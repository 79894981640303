import {collection, CollectionReference, doc, Firestore} from "@firebase/firestore";
import {EntityManager} from "../EntityManager";
import {IdAlike, idFromIdAlike} from "../util";
import {
    DataMapper,
    Entity,
    EntityAnchor,
    EntityDefinition,
    EntityFilterColumn,
    EntitySortColumn,
    Filter,
    FilterOperation,
    IdRefAttributeDefinition,
    PreparedEntity,
    SimpleAttributeDefinition,
    SortOrder
} from "./Entity";
import {Optional} from "./util/Optional";
import {EntityType} from "./value/EntityType";
import {Id} from "./value/Id";
import {IdRef} from "./value/IdRef";
import {label, Label, LABEL_DATA_MAPPER} from "./value/Label";
import {Name, NAME_DATA_MAPPER, nameCompare, nameContains} from "./value/Name";
import {Slot, SLOT_DATA_MAPPER, slotCompare, slotEquals} from "./value/Slot";
import {stringCompare, stringEquals} from "./value/String";

export enum PageState {

    ENABLED = "ENABLED",

    DISABLED = "DISABLED"

}

export type PagePayload = {
    state: PageState,
    slot: Slot,
    name: Name,
    description: Label,
    region: IdRef<EntityType.REGION>
}

export type PageAnchor = EntityAnchor<EntityType.PAGE> & {
    region: IdAlike<EntityType.REGION>
}

export type PreparedPage = PreparedEntity<EntityType.PAGE> & PagePayload;

export type Page = Entity<EntityType.PAGE> & PagePayload;

export type PersistablePage = PreparedPage | Page;

export type PageManager = EntityManager<EntityType.PAGE, PageAnchor, PersistablePage, Page>;

export const PAGE_STATE_DATA_MAPPER: DataMapper<PageState, string> = {
    toData: (value: PageState) => value,
    fromData: (data: string) => data as PageState
}

export const PAGE_STATE_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePage, PageState, string>(
        "state",
        e => e.state,
        PAGE_STATE_DATA_MAPPER,
        PageState.ENABLED
    );

export const PAGE_SLOT_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePage, Slot, string>(
        "slot",
        e => e.slot,
        SLOT_DATA_MAPPER,
        null
    );

export const PAGE_NAME_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePage, Name, string>(
        "name",
        e => e.name,
        NAME_DATA_MAPPER,
        new Name("Page")
    );

export const PAGE_DESCRIPTION_ATTRIBUTE_DEFINITION =
    new SimpleAttributeDefinition<PersistablePage, Label, label>(
        "description",
        e => e.description,
        LABEL_DATA_MAPPER,
        new Label("")
    );


export const PAGE_REGION_ATTRIBUTE_DEFINITION =
    new IdRefAttributeDefinition<EntityType.REGION, PersistablePage>(
        EntityType.REGION,
        "region",
        e => e.region
    );


export type PageFilterColumn = EntityFilterColumn | "STATE" | "SLOT" | "NAME";

export type PageFilterOperation<V> = FilterOperation<EntityType.PAGE, Page, PageFilterColumn, V>;

export type PageFilter<V> = Filter<EntityType.PAGE, Page, PageFilterColumn, V>;

export const PAGE_SLOT_EQUALS_FILTER: PageFilterOperation<Slot> = {
    column: "SLOT",
    apply: (page: Page, comparisonValue: Slot) => slotEquals(page.slot, comparisonValue)
}

export const PAGE_STATE_EQUALS_FILTER: PageFilterOperation<PageState> = {
    column: "STATE",
    apply: (page: Page, comparisonValue: PageState) => stringEquals(page.state, comparisonValue)
}

export const PAGE_NAME_CONTAINS_FILTER: PageFilterOperation<string> = {
    column: "NAME",
    apply: (page: Page, comparisonValue: string) => nameContains(page.name, comparisonValue)
}

export type PageSortColumn = EntitySortColumn | "STATE" | "SLOT" | "NAME";

export type PageSortOrder = SortOrder<EntityType.PAGE, Page, PageSortColumn>;

export const PAGE_SLOT_SORT_ORDER: PageSortOrder = {
    column: "SLOT",
    apply: (left: Page, right: Page) => slotCompare(left.slot, right.slot)
}

export const PAGE_STATE_SORT_ORDER: PageSortOrder = {
    column: "STATE",
    apply: (left: Page, right: Page) => stringCompare(left.state, right.state)
}

export const PAGE_NAME_SORT_ORDER: PageSortOrder = {
    column: "NAME",
    apply: (left: Page, right: Page) => nameCompare(left.name, right.name)
}

class PageDefinition extends EntityDefinition<EntityType.PAGE, PageAnchor, PersistablePage, Page> {

    constructor() {
        super(
            EntityType.PAGE,
            [
                PAGE_STATE_ATTRIBUTE_DEFINITION,
                PAGE_SLOT_ATTRIBUTE_DEFINITION,
                PAGE_NAME_ATTRIBUTE_DEFINITION,
                PAGE_DESCRIPTION_ATTRIBUTE_DEFINITION,
                PAGE_REGION_ATTRIBUTE_DEFINITION
            ]
        );
    }

    public getDocId(entity: PersistablePage): Optional<string> {
        return null;
    }

    public preparedEntityToAnchor(entity: PersistablePage): PageAnchor {
        return {region: entity.region};
    }

    public idAlikeToAnchor(idAlike: IdAlike<EntityType.PAGE>): PageAnchor {
        return {region: new Id(EntityType.REGION, idFromIdAlike(idAlike).parentPath()!)};
    }

    public anchorToColRef(database: Firestore, anchor: PageAnchor): CollectionReference {
        return collection(doc(database, idFromIdAlike(anchor.region).path), EntityType.PAGE.toLowerCase());
    }

}

export const PAGE_DEFINITION = new PageDefinition();