import {Firestore} from "@firebase/firestore";
import {EntityRepo} from "./EntityRepo";
import {Entity, EntityAnchor, EntityDefinition, EntityState, PersistableEntity} from "./model/Entity";
import {Maybe} from "./model/util/Maybe";
import {Optional} from "./model/util/Optional";
import {EntityType} from "./model/value/EntityType";
import {IdAlike, valueFromIdAlike} from "./util";

export class EntityManager<T extends EntityType, A extends EntityAnchor<T>, P extends PersistableEntity<T>, E extends Entity<T>> {

    private readonly database: Firestore;

    private readonly repo: EntityRepo<T, A, P, E>;

    private readonly entityDefinition: EntityDefinition<T, A, P, E>

    private readonly cache: Map<string, [Date, Map<string, E>]>;

    constructor(database: Firestore, entityDefinition: EntityDefinition<T, A, P, E>) {
        this.database = database;
        this.repo = new EntityRepo(database, entityDefinition);
        this.entityDefinition = entityDefinition;
        this.cache = new Map();
    }

    public async persist(entity: P): Promise<E> {
        const idRef = await this.repo.persist(entity);
        return this.getById(idRef);
    }

    public async getById(idAlike: IdAlike<T>): Promise<E> {
        const entity = await this.optById(idAlike);
        if (entity === null) {
            throw new Error("Unable to load entity " + JSON.stringify(idAlike));
        } else {
            return entity;
        }
    }

    public async optById(idAlike: IdAlike<T>): Promise<Optional<E>> {
        const cachedEntities = await this.updateCache(this.entityDefinition.idAlikeToAnchor(idAlike));
        return cachedEntities.get(valueFromIdAlike(idAlike)) ?? null;
    }

    public async getAllAsMap(anchor: Optional<A>): Promise<ReadonlyMap<string, E>> {
        const cachedEntities = await this.updateCache(anchor);
        return cachedEntities;
    }

    public async getAllAsArray(anchor: Optional<A>): Promise<ReadonlyArray<E>> {
        const cachedEntities = await this.updateCache(anchor);
        return Array.from(cachedEntities.values());
    }

    private async updateCache(anchor: Optional<A>) {
        const path = anchor === null ? "ALL" : this.entityDefinition.anchorToColRef(this.database, anchor).path;
        const cacheEntry: Maybe<[Date, Map<string, E>]> = this.cache.get(path);

        if (cacheEntry === undefined) {

            let maxUpdatedAt = new Date(0);
            let entities = new Map<string, E>();

            const remoteEntities = await this.repo.getAll(anchor);

            remoteEntities.forEach(remoteEntity => {

                if (remoteEntity.updatedAt > maxUpdatedAt) {
                    maxUpdatedAt = remoteEntity.updatedAt;
                }
                if (remoteEntity.entityState !== EntityState.DELETED) {
                    entities.set(remoteEntity.id.value, remoteEntity);
                }

            });

            this.cache.set(path, [maxUpdatedAt, entities]);
            return entities;


        } else {

            let maxUpdatedAt = cacheEntry[0];
            let entities = cacheEntry[1];

            const remoteEntities = await this.repo.getAllSince(maxUpdatedAt, anchor);

            remoteEntities.forEach(remoteEntity => {

                if (remoteEntity.updatedAt > maxUpdatedAt) {
                    maxUpdatedAt = remoteEntity.updatedAt;
                }

                if (remoteEntity.entityState !== EntityState.DELETED) {
                    entities.set(remoteEntity.id.value, remoteEntity);
                } else {
                    entities.delete(remoteEntity.id.value);
                }

            });

            this.cache.set(path, [maxUpdatedAt, entities]);
            return entities;

        }
    }

    public async removeById(idAlike: IdAlike<T>): Promise<E> {
        await this.repo.removeById(idAlike);
        return this.getById(idAlike);
    }

    public async restoreById(idAlike: IdAlike<T>): Promise<E> {
        await this.repo.restoreById(idAlike);
        return this.getById(idAlike);
    }

    public async deleteById(idAlike: IdAlike<T>): Promise<Optional<E>> {
        await this.repo.deleteById(idAlike);
        return this.optById(idAlike);
    }

}


