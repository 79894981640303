import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";
import {NominalDate} from "../../api/model/value/NominalDate";
import {FormSize} from "../form/FormSize";

export interface Props {
    id: string,
    value: NominalDate,
    disabled: boolean,
    size: FormSize;
    setValue: (value: NominalDate) => void
}

const NominalDateInput: React.FunctionComponent<Props> = (props: Props) => {

    const {id, value, disabled, size, setValue} = props

    const handleDayChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rawValue = event.target.value;
        if (rawValue.length === 0) {
            setValue(value.withDay(1));
        } else {
            const integerValue = Math.max(1, Math.min(31, parseInt(rawValue)));
            if (isFinite(integerValue)) {
                setValue(value.withDay(integerValue));
            }
        }
    }

    const handleMonthChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rawValue = event.target.value;
        if (rawValue.length === 0) {
            setValue(value.withMonth(1));
        } else {
            const integerValue = Math.max(1, Math.min(12, parseInt(rawValue)));
            if (isFinite(integerValue)) {
                setValue(value.withMonth(integerValue));
            }
        }
    }

    const handleYearChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rawValue = event.target.value;
        if (rawValue.length === 0) {
            setValue(value.withYear(2000));
        } else {
            const integerValue = Math.max(2000, Math.min(2100, parseInt(rawValue)));
            if (isFinite(integerValue)) {
                setValue(value.withYear(integerValue));
            }
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Stack direction="column">
                    Day
                    <TextField
                        id={id + "-day"}
                        type="number"
                        value={value.day}
                        InputProps={{inputProps: {min: 1, max: 31}}}
                        disabled={disabled}
                        size={size}
                        onChange={handleDayChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack direction="column">
                    Month
                    <TextField
                        id={id + "-month"}
                        type="number"
                        value={value.month}
                        InputProps={{inputProps: {min: 1, max: 12}}}
                        disabled={disabled}
                        size={size}
                        onChange={handleMonthChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack direction="column">
                    Year
                    <TextField
                        id={id + "-year"}
                        type="number"
                        value={value.year}
                        InputProps={{inputProps: {min: 2000, max: 2100}}}
                        disabled={disabled}
                        size={size}
                        onChange={handleYearChange}
                    />
                </Stack>
            </Grid>
        </Grid>
    )

};

export default NominalDateInput;
