import React from 'react';
import PurchaseSlicerProvider from "../component/entity/purchase/PurchaseSlicerProvider";
import PurchasesPage from "../component/entity/purchase/PurchasesPage";

function PurchaseRoute() {

    return (
        <PurchaseSlicerProvider>
            <PurchasesPage/>
        </PurchaseSlicerProvider>
    );

}

export default PurchaseRoute;
