import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React, {useState} from "react";
import {EntityState} from "../../../api/model/Entity";
import {Page} from "../../../api/model/Page";
import {useProject} from "../../../context/ProjectProvider";
import DeleteButton from "../../form/DeleteButton";
import RemoveButton from "../../form/RemoveButton";
import RestoreButton from "../../form/RestoreButton";
import PageInput, {PageInputResult} from "./PageInput";

export interface Props {
    page: Page,
    onDelete: () => void
    onClose?: () => void
}

const PageUpdateForm: React.FunctionComponent<Props> = (props: Props) => {

    const {page: initialPage, onDelete, onClose} = props

    const pageManager = useProject().pageManager;
    const [editing, setEditing] = useState<boolean>(false);
    const [latestPage, setLatestPage] = useState<Page>(initialPage)
    const [currentPage, setCurrentPage] = useState<Page>(initialPage)

    const handleEdit = () => {
        setEditing(true);
        setCurrentPage({...latestPage});
    }

    const handleCancel = () => {
        setEditing(false);
        setCurrentPage(latestPage);
    }

    const handlePersist = async (pageInputResult: PageInputResult) => {
        setEditing(false);
        const updatedPage = await pageManager.persist({...latestPage, ...pageInputResult});
        setLatestPage(updatedPage);
        setCurrentPage(updatedPage);
    }

    const handleRemove = async () => {
        const updatedPage = await pageManager.removeById(latestPage);
        setLatestPage(updatedPage);
        setCurrentPage(updatedPage);
    }

    const handleRestore = async () => {
        const updatedPage = await pageManager.restoreById(latestPage);
        setLatestPage(updatedPage);
        setCurrentPage(updatedPage);
    }

    const handleDelete = async () => {
        await pageManager.deleteById(latestPage);
        onDelete();
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <Stack sx={{p: 1}} spacing={3}>
            <PageInput
                page={currentPage}
                disabled={!editing}
                size="small"
                onPersist={currentPage.entityState === EntityState.CREATED ? handlePersist : undefined}
            />
            <Stack direction={"row"} spacing={1}>
                {editing && <>
                    <Button variant={"outlined"} onClick={handleCancel}>
                        Cancel
                    </Button>
                </>}
                {!editing && <>
                    {currentPage.entityState === EntityState.CREATED &&
                        <Button variant={"contained"} onClick={handleEdit}>
                            Edit
                        </Button>}
                    {currentPage.entityState === EntityState.REMOVED &&
                        <DeleteButton disabled={false} onDelete={handleDelete}/>}
                    {currentPage.entityState === EntityState.REMOVED &&
                        <RestoreButton disabled={false} onRestore={handleRestore}/>}
                    {currentPage.entityState === EntityState.CREATED &&
                        <RemoveButton disabled={false} onRemove={handleRemove}/>}
                    {onClose && <Button variant={"outlined"} onClick={handleClose}>
                        Close
                    </Button>}
                </>}
            </Stack>
        </Stack>
    );

};

export default PageUpdateForm;
