import {DataMapper} from "../Entity";

export type phoneNumber = {
    countryCode: string,
    areaCode: string,
    localNumber: string
}

export class PhoneNumber {

    public readonly countryCode: string;

    public readonly areaCode: string;

    public readonly localNumber: string;

    constructor(countryCode: string, areaCode: string, localNumber: string) {
        this.countryCode = countryCode;
        this.areaCode = areaCode;
        this.localNumber = localNumber;
    }

    public withCountryCode(countryCode: string): PhoneNumber {
        return new PhoneNumber(countryCode, this.areaCode, this.localNumber)
    }

    public withAreaCode(areaCode: string): PhoneNumber {
        return new PhoneNumber(this.countryCode, areaCode, this.localNumber)
    }

    public withLocalNumber(localNumber: string): PhoneNumber {
        return new PhoneNumber(this.countryCode, this.areaCode, localNumber)
    }

    public static toData(value: PhoneNumber): phoneNumber {
        return {
            countryCode: value.countryCode,
            areaCode: value.areaCode,
            localNumber: value.localNumber,
        }
    }

    public static fromData(data: phoneNumber): PhoneNumber {
        return new PhoneNumber(
            data.countryCode,
            data.areaCode,
            data.localNumber
        )
    }

}

export const PHONE_NUMBER_DATA_MAPPER: DataMapper<PhoneNumber, phoneNumber> = {
    toData: PhoneNumber.toData,
    fromData: PhoneNumber.fromData
}



